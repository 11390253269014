import SehoLogo from "src/assets/images/logo.png";
import { styled, Box, LinearProgress } from "@mui/material";

const StyledLoaderContainer = styled(Box)(() => ({
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 48,
}));

export default function LoadingPage() {
  return (
    <StyledLoaderContainer>
      <img src={SehoLogo} />
      <LinearProgress color="primary" sx={{ width: 240 }} />
    </StyledLoaderContainer>
  );
}
