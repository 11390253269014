import { Box, Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { ImgIconSortByAZ } from "src/assets/icons";
import ImageIcon from "src/view/components/ImageIcon";
import SearchIcon from "@mui/icons-material/Search";
import selector from "src/modules/bulk-search/filter/searchFilterSelectors";
import actions from "src/modules/bulk-search/filter/searchFilterActions";
import { useDispatch, useSelector } from "react-redux";
import { SearchFilter } from "src/types/SearchFilter";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import IsLeakedTag from "../components/IsLeakedTag";

const StyledFilterCollapseForm = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 210px)",
}));

const StyledDataSetSearchBox = styled(Box)(() => ({
  padding: 10,
  height: "calc(100% - 20px)",
}));

const StyledSearchBox = styled("div")(() => ({
  position: "relative",
  height: 40,
  width: "100%",
  maxWidth: 680,
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
}));

const StyledSearchIcon = styled(SearchIcon)(() => ({
  position: "absolute",
  left: 12,
  fontSize: 16,
  color: "#043D5D",
}));

const SearchInput = styled("input")(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  paddingLeft: 34,
  paddingRight: 40,
  fontSize: 12,
  color: "#414042",
  border: "1px solid rgba(65, 64, 66, 0.16)",
  "&:focus-visible": {
    outline: "none",
  },
}));

const StyledImageButton = styled("div")(() => ({
  position: "absolute",
  right: 12,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
}));

const StyledInlineFormControl = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 0",
  paddingLeft: "5px",
  borderBottom: "1px solid rgba(65, 64, 66, 0.16)",
  "&:last-child": {
    borderBottom: "none",
  },
  position: "relative",
}));

// TODO: we've won a refactoring battle but the war with incompetence rages on
const DatasetFilter = (props: { editable?: boolean }) => {
  const { editable } = props;
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data: datasets } = getKnowledgeWikis();
  const dispatch = useDispatch();
  const filter = useSelector(selector.selectFilter);
  const [_datasets, setDataset] = useState(datasets);
  const [filtered, setFiltered] = useState(datasets);
  const [formData, setFormData] = React.useState<SearchFilter>({
    dataset_ids: [],
    doc_types: [],
    dataset_types: [],
    countries: [],
    regions: [],
    languages: [],
    ...filter,
  });

  useEffect(() => {
    if (!filter) {
      setFormData({
        dataset_ids: [],
        doc_types: [],
        dataset_types: [],
        countries: [],
        regions: [],
        languages: [],
      });
    } else {
      setFormData(filter);
    }
  }, [filter]);

  useEffect(() => {
    arrangeDatasets();
  }, [datasets]);

  const arrangeDatasets = () => {
    const temp = [...datasets];
    temp.sort((a, b) => a.name.localeCompare(b.name));
    setDataset([...temp]);
    setFiltered([...temp]);
  };

  const handleCheckDataset = (
    e: React.ChangeEvent<HTMLInputElement>,
    dataset: KnowledgeWiki
  ) => {
    const ids = [...formData.dataset_ids];
    if (e.target.checked) {
      ids.push(dataset.dataset);
    } else {
      const index = ids.indexOf(dataset.dataset);
      ids.splice(index, 1);
    }

    setFormData({ ...formData, dataset_ids: [...ids] });
    dispatch(actions.setFilter({ ...formData, dataset_ids: [...ids] }));
  };

  const handleFilterDatasets = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      arrangeDatasets();
    } else {
      const ds = [..._datasets];
      const _filtered = ds.filter((dataset) =>
        dataset.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  return (
    <StyledFilterCollapseForm>
      <StyledDataSetSearchBox
        sx={{ height: editable ? "calc(100% - 180px)" : "calc(100% - 20px)" }}
      >
        <StyledSearchBox>
          <StyledSearchIcon />
          <SearchInput
            placeholder="Search dataset"
            onChange={handleFilterDatasets}
          />
          <StyledImageButton>
            <ImageIcon src={ImgIconSortByAZ} sx={{ width: 20, height: 20 }} />
          </StyledImageButton>
        </StyledSearchBox>
        {/*  */}
        <Box
          sx={{
            overflowY: "auto",
            height: "calc(100% - 24px)",
            pr: 2,
            pl: 2,
            pt: 1,
            pb: 1,
          }}
        >
          {[...filtered]?.map((dataset: KnowledgeWiki, index: number) => {
            if (formData.dataset_ids.includes(dataset.dataset))
              return (
                <StyledInlineFormControl key={index}>
                  <Checkbox
                    sx={{ padding: 0, width: "16px", marginRight: "8px" }}
                    color={"secondary"}
                    checked={formData.dataset_ids.includes(dataset.dataset)}
                    onChange={(e) => handleCheckDataset(e, dataset)}
                  />
                  <Typography variant="caption" sx={{ width: "auto" }}>
                    {dataset["name"]}{" "}
                    <IsLeakedTag
                      dataset={dataset}
                      sx={{
                        display: "inline",
                        marginLeft: "1em",
                      }}
                    />
                  </Typography>
                </StyledInlineFormControl>
              );
          })}
          {/* bruh*/}
          {[...filtered]?.map((dataset: KnowledgeWiki, index: number) => {
            if (!formData.dataset_ids.includes(dataset.dataset))
              return (
                <StyledInlineFormControl key={index}>
                  <Checkbox
                    sx={{ padding: 0, width: "16px", marginRight: "8px" }}
                    color={"secondary"}
                    checked={formData.dataset_ids.includes(dataset.dataset)}
                    onChange={(e) => handleCheckDataset(e, dataset)}
                  />
                  <Typography variant="caption" sx={{ width: "auto" }}>
                    {dataset["name"]}
                    <IsLeakedTag
                      dataset={dataset}
                      sx={{
                        display: "inline",
                        marginLeft: "1em",
                      }}
                    />
                  </Typography>
                </StyledInlineFormControl>
              );
          })}
        </Box>
      </StyledDataSetSearchBox>
    </StyledFilterCollapseForm>
  );
};

export default DatasetFilter;
