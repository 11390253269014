import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmDialog } from "src/components/ui";
import { User } from "src/types/UserGroup";
import { ReactComponent as ResendEmailIcon } from "src/assets/icons/resend_email.svg";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete.svg";

interface Props {
  user: User;
  onRemoveUser?: (user: User) => void;
  onResendEmail?: (data: { user_id: string }) => void;
  editing?: boolean;
  setEditing?: (editing: boolean) => void;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: "8px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
}));

const StyledIcon = styled("img")(() => ({
  width: 16,
  height: 16,
  objectFit: "contain",
  cursor: "pointer",
}));

const UserListActions = ({
  user,
  onRemoveUser,
  onResendEmail,
  editing,
  setEditing,
}: Props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <ConfirmDialog
        title={"Resend Invitation"}
        description={`Are you sure you want to resend an invitation to ${user.email}?`}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        onConfirm={() => onResendEmail({ user_id: user.id })}
      >
        <StyledIconButton size="small">
          <ResendEmailIcon color="primary" />
        </StyledIconButton>
      </ConfirmDialog>
      <ConfirmDialog
        title={"Delete User"}
        description={
          "You are about to delete this user from this group.\nAre you sure?"
        }
        confirmText={"Delete"}
        cancelText={"Cancel"}
        onConfirm={() => onRemoveUser(user)}
      >
        <StyledIconButton size="small">
          <DeleteIcon color="primary" />
        </StyledIconButton>
      </ConfirmDialog>
      <StyledIconButton onClick={() => setEditing(!editing)} size="small">
        {editing ? (
          <CloseIcon sx={{ width: "16px", height: "16px" }} color="primary" />
        ) : (
          <StyledIcon src={require("src/assets/images/edit.png")} />
        )}
      </StyledIconButton>
    </Box>
  );
};

export default UserListActions;
