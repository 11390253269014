import { merge } from "lodash";
import { AuthToken } from "../../auth/authToken";

export const formatUrl = (path: string | string[], searchParams?: string) => {
  let url = process.env.REACT_APP_BACKEND_URL;
  let segments = Array.isArray(path) ? path : path.split("/");
  segments = segments.filter((s) => !!s); // Remove empty segments created by double slashes

  // Add a mandatory trailing slash only if there are no searchParams
  url += segments.join("/");
  if (!searchParams) {
    url += "/";
  }

  // Add searchParams if necessary
  url = searchParams ? url + "?" + searchParams : url;
  return url;
};

export const formatInit = (
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  init: Partial<RequestInit> = {}
): RequestInit => {
  const csrf = AuthToken.getStoredCSRFToken();
  return merge(
    {
      method: method,
      headers: {
        "Content-Type": !(init.body instanceof FormData) && "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: csrf ? "include" : undefined,
      signal: AbortSignal.timeout(15000), // timeout: 15000,
    },
    init
  );
};
