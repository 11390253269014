import { useQuery, useMutation } from "@tanstack/react-query";

import { fetchApi } from "src/modules/shared/api";
import queryKeys, { mutationKeys } from "src/modules/api/queryKeys";
import { OCRTask, OCRTaskResult, OCRTaskStatus } from "src/types/OCR";
import { queryClient } from "src/index";
import QueryKeys from "src/modules/api/queryKeys";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

interface GetOCRTasksParams {
  searchKey?: string;
  pageParam?: number;
}

export function useOCRTasks() {
  const posthog = usePostHog();
  const getOCRTasksFn = async ({ searchKey, pageParam }: GetOCRTasksParams) => {
    try {
      const params = new URLSearchParams({
        ...(searchKey && { name: searchKey }),
        ...(pageParam && { page: pageParam.toString() }),
      });
      const res = await fetchApi("ocr/?" + params, {
        method: "GET",
      });
      return await res.json();
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getOCRTasks = (params: GetOCRTasksParams) =>
    useQuery<OCRTask[]>({
      refetchInterval: (data) => {
        const hasIncompleteTasks = data?.some(
          (task) =>
            task.status === OCRTaskStatus.PROGRESS ||
            task.status === OCRTaskStatus.QUEUED
        );

        return hasIncompleteTasks ? 10000 : false;
      },
      queryFn: () => {
        return getOCRTasksFn({
          searchKey: params.searchKey,
        });
      },
      queryKey: [queryKeys.OCR_TASKS, params],
    });

  const getOCRTaskResultFn = async (id: string) => {
    try {
      const res = await fetchApi(`ocr/result/${id}`, {
        method: "GET",
      });
      return await res.json();
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getOCRTaskResult = (id: string) =>
    useQuery<OCRTaskResult>({
      enabled: false,
      queryFn: () => {
        return getOCRTaskResultFn(id);
      },
      queryKey: [queryKeys.OCR_TASK_RESULT_BY_ID, id],
    });

  const deleteOCRTaskFn = async (id: string) => {
    try {
      const res = await fetchApi(`ocr/delete/${id}/`, {
        method: "DELETE",
      });
      if (!res) return;
      const response = await res.json();

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  };

  const deleteOCRTask = () =>
    useMutation({
      mutationFn: (id: string) => deleteOCRTaskFn(id),
      onSuccess: () => {
        // TODO: 1s refetch timeout?
        // INFO: Task dataset.tasks.delete_ocr_task_and_s3,
        // ('125ab6b8-aadc-493d-ada6-fdd900c6904d',),
        // {}[ebb37ae4-5dbb-4dfc-892a-ffeab6dbee5f] succeeded in 0.7500322799987771s: None

        const invalidateTasks = () =>
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === QueryKeys.OCR_TASKS,
          });
        setTimeout(invalidateTasks, 1000);
      },
    });

  const retryOCRTaskFn = async (id: string) => {
    try {
      const res = await fetchApi(`ocr/retry/${id}/`, {
        method: "POST",
      });
      const response = await res.json();
      if (!res.ok) {
        return {
          error: true,
          detail: response?.error ?? "Failed to retry",
        };
      }
    } catch (err) {
      return {
        error: true,
        detail: "Failed to retry",
      };
    }
  };

  const retryOCRTask = () =>
    useMutation({
      mutationFn: (id: string) => retryOCRTaskFn(id),
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === QueryKeys.OCR_TASKS,
        });
      },
    });

  const requestPresignedUrlAndUpload = async (file: File) => {
    try {
      const response = await fetchApi("ocr/", {
        method: "POST",
        body: JSON.stringify({ file_key: file.name }),
      });

      if (!response)
        return { error: true, detail: "Failed to get presigned URL." };
      const jsonResponse = await response.json();
      if (response.status >= 200 && response.status < 300) {
        const presignedUrl = jsonResponse["url"];

        // Use the presigned URL to upload the file directly to S3
        // The content type must be included in the PUT request
        // and must match the file type or S3 will reject the upload
        const s3Response = await fetch(presignedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        if (!s3Response.ok) {
          const text = await s3Response.text();
          console.error("Failed to upload to S3:", text);
          return { error: true, detail: "Failed to upload file." };
        }

        return {
          error: false,
          task_id: jsonResponse["task_id"],
        };
      } else {
        return { error: true, detail: jsonResponse["error"] };
      }
    } catch (err) {
      console.error("Error during the upload process:", err);
      return { error: true, detail: err };
    }
  };

  const uploadFileForOCR = () =>
    useMutation({
      mutationKey: [mutationKeys.UPLOAD_OCR_FILE],
      mutationFn: (file: File) => requestPresignedUrlAndUpload(file),
      onSuccess: () => {
        posthog.capture(HogEvent.OCR_FILE_UPLOADED);
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === QueryKeys.OCR_TASKS,
        });
      },
    });

  return {
    retryOCRTask,
    getOCRTaskResult,
    getOCRTasks,
    uploadFileForOCR,
    deleteOCRTask,
  };
}
