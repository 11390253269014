import { useState } from "react";
import { MenuItem, Popover, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import { GroupShort } from "src/types/UserGroup";
import { getFullName } from "src/utils";

const Badge = styled("span")({
  width: "max-content",
  padding: "2px 10px",
  fontSize: 14,
  lineHeight: "17px",
  color: "#122945",
  borderRadius: 30,
  backgroundColor: "rgba(20, 74, 104, 0.1)",
});

interface Props {
  admins?: GroupShort["group_admins"];
}

const GroupAdminColumn = ({ admins }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography fontSize="14px" color={Colors.textDark100}>
        {admins?.length ? <>{getFullName(admins[0])}</> : "None"}
      </Typography>

      {admins.length > 1 && (
        <>
          <Badge
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            +{admins.length - 1}
          </Badge>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {admins.slice(1).map((admin) => (
              <MenuItem key={admin.id}>{getFullName(admin)}</MenuItem>
            ))}
          </Popover>
        </>
      )}
    </Box>
  );
};

export default GroupAdminColumn;
