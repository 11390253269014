import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { SearchResultResponse, Source } from "src/types/Search";
import actions from "src/modules/search/view/searchViewActions";
import { SearchResultModel } from "src/view/models/SearchResultModel";

export interface PreviewDocProps {
  title: string;
  type?: string;
  source_type?: number;
  // nothing to do with Dataset, mostly a bunch of tags (see implementation in SearchResultCard)
  dataset?: any;
  updatedOn?: string;
  data?: Source;
  _id?: string;
  wikiItem?: KnowledgeWiki;
  highlight?: any;
}

export interface PreviewDocListProps {
  intervals?: {
    Directorship: {
      _id: string;
      _source: {
        last_updated: string;
        name: string[];
        passportNumber: string[];
        schema: string;
        dataset_id: string;
        [x: string]: string | number | string[] | number[];
      };
    }[];
  };
}

export interface InitialDataProps {
  loading: boolean;
  searchResults?: SearchResultResponse;
  downloadLink?: string;
  searchViewResult: SearchResultModel[];
  previewDocumentList?: PreviewDocListProps | Array<unknown>;
  previewDocInfo?: PreviewDocProps | null;
  gettingDownloadLink?: boolean;
  selectedResultTab?: number;
}

const initialData: InitialDataProps = {
  loading: false,
  searchResults: null,
  downloadLink: null,
  searchViewResult: [],
  previewDocumentList: null,
  previewDocInfo: null,
  gettingDownloadLink: false,
  selectedResultTab: 0,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | boolean
      | string
      | number
      | SearchResultModel[]
      | SearchResultResponse
      | KnowledgeWiki[]
      | PreviewDocProps
      | PreviewDocListProps
      | null;
  }
) => {
  if (type === actions.START_LOADING) {
    return {
      ...state,
      loading: true,
    };
  }
  if (type === actions.STOP_LOADING) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      searchResults: null,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      searchResults: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      searchResults: null,
      loading: false,
    };
  }

  if (type === actions.GET_DOWNLOAD_LINK) {
    return {
      ...state,
      downloadLink: payload,
      loading: false,
      gettingDownloadLink: false,
    };
  }
  if (type === actions.GET_PREVIEW_DOCUMENT) {
    return {
      ...state,
      previewDocumentList: payload,
      loading: false,
    };
  }

  if (type === actions.SET_SEARCH_VIEW_RESULTS) {
    return {
      ...state,
      searchViewResult: payload,
    };
  }
  if (type === actions.SET_PREVIEW_DOC) {
    return {
      ...state,
      previewDocInfo: payload,
    };
  }
  if (type === actions.SELECT_RESULT_TAB) {
    return {
      ...state,
      selectedResultTab: payload,
    };
  }
  if (type === actions.CLEAR) {
    return {
      ...initialData,
    };
  }

  return state;
};
