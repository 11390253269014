import { GroupFeatureNames, GroupFeatures } from "src/types/UserGroup";

export const featureTooltips: Record<GroupFeatureNames, string> = {
  [GroupFeatureNames.DATASET]:
    "Allow users to view datasets. Most groups will need this.",
  [GroupFeatureNames.SEARCH]:
    "Allow users to run searches. Most groups will need this.",
  [GroupFeatureNames.BULK_SEARCH]:
    "Allow users to run bulk searches. Most groups will need this.",
  [GroupFeatureNames.KNOWLEDGE_WIKI]:
    "Allow users to view information about datasets and access the data dictionary. Most groups will need this.",
  [GroupFeatureNames.BULK_DOWNLOAD]:
    "Allow users to download search results in bulk.",
  [GroupFeatureNames.DATA_COLLECTION]:
    "Allow users to create custom dataset collections.",
  [GroupFeatureNames.USER_GROUP]: "Allow users to view their groups and users.",
  [GroupFeatureNames.EXTERNAL_API]: "",
  [GroupFeatureNames.INDEXING]: "",
  [GroupFeatureNames.EXTRA_KW_INFO]: "",
  [GroupFeatureNames.LEAKED_DATASETS]:
    "Allow the search experience to contain datasets from leaked sources.",
};

export const features = {
  [GroupFeatures.SEARCH]: GroupFeatureNames.SEARCH,
  [GroupFeatures.DATASET]: GroupFeatureNames.DATASET,
  [GroupFeatures.BULK_SEARCH]: GroupFeatureNames.BULK_SEARCH,
  [GroupFeatures.KNOWLEDGE_WIKI]: GroupFeatureNames.KNOWLEDGE_WIKI,
  [GroupFeatures.BULK_DOWNLOAD]: GroupFeatureNames.BULK_DOWNLOAD,
  [GroupFeatures.DATA_COLLECTION]: GroupFeatureNames.DATA_COLLECTION,
  [GroupFeatures.USER_GROUP]: GroupFeatureNames.USER_GROUP,
  [GroupFeatures.LEAKED_DATASETS]: GroupFeatureNames.LEAKED_DATASETS,
};
