import React from "react";

import SearchInput from "./SearchInput";

interface Props {
  filterInput: string;
  handleFilter: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const DatasetItemFilter = ({ filterInput, handleFilter }: Props) => {
  return (
    <SearchInput
      value={filterInput}
      placeholder="Search Dataset"
      onChange={handleFilter}
    />
  );
};

export default DatasetItemFilter;
