import { useMutation } from "@tanstack/react-query";
import { snackbar } from "src/view/toaster";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../fetch";
import { getErrorMessage } from "../../../utils/response";
import { AuthToken } from "../../auth/authToken";
import { getTokenExp } from "../../../utils/verifyToken";

export function useUserAuth() {
  const { postApi } = useFetch();
  const navigate = useNavigate();

  const getValidation = async (response: Response): Promise<string> => {
    try {
      const errorResponse: Record<string, string> = await response
        .clone()
        .json();
      const apiError = getErrorMessage(errorResponse);

      apiError && snackbar.error(apiError);

      return apiError || "";
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  const loginUserFn = async ({
    shouldRemember,
    ...credentials
  }: {
    email: string;
    password: string;
    shouldRemember: boolean;
  }) => {
    await AuthToken.updateCSRFToken();
    const csrfToken = AuthToken.getStoredCSRFToken();

    const data = await postApi(
      ["users", "login"],
      {
        body: JSON.stringify({
          ...credentials,
          should_remember: shouldRemember,
        }),
        headers: {
          "X-CSRFToken": csrfToken,
        },
      },
      getValidation
    );

    const jsonData = await data.json();
    if (data.status === 200) {
      AuthToken.setSessionExp(getTokenExp(jsonData.token.access_token));
    } else {
      AuthToken.logout();
    }

    return jsonData;
  };

  const loginUser = useMutation({
    mutationFn: (data: {
      email: string;
      password: string;
      shouldRemember: boolean;
    }) => loginUserFn(data),
    onError: AuthToken.logout,
  });

  const resetPassword = useMutation({
    mutationFn: (data: { email: string }) => {
      return postApi(["users", "reset_password"], {
        body: JSON.stringify(data),
      });
    },
  });

  const updateUserPassword = useMutation({
    mutationFn: (data: {
      reset_password_code: string;
      new_password: string;
    }) => {
      return postApi(
        ["users", "reset_password_update"],
        {
          body: JSON.stringify(data),
        },
        getValidation
      );
    },
    onSuccess: () => {
      snackbar.success("Successfully updated password!");
      navigate("/login", { replace: true });
    },
  });

  const refreshAccessTokenFn = async (credentials: {
    refreshToken: string;
  }) => {
    const response = await postApi(
      ["token", "request_access_token"],
      {
        body: JSON.stringify({ refresh_token: credentials.refreshToken }),
        headers: {
          "Content-Type": "application/json",
        },
      },
      getValidation
    );

    return response.json();
  };

  const refreshAccessToken = useMutation({
    mutationFn: (data: { refreshToken: string }) => refreshAccessTokenFn(data),
  });

  return {
    loginUser,
    resetPassword,
    updateUserPassword,
    refreshAccessToken,
    refreshAccessTokenFn,
  };
}
