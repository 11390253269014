import BulkSearchesDrawer from "./BulkSearchesDrawer";
import { useToggle } from "usehooks-ts";
import { CardOfUnknownPurpose } from "./StyledComponents";
import { Group } from "src/types/UserGroup";

type Props = {
  formData: Group;
};

const BulkSearch = ({ formData }: Props) => {
  const [isBulkSearchesOpen, toggleBulkSearchesOpen] = useToggle();

  return (
    <>
      <CardOfUnknownPurpose
        title="Bulk searches"
        icon={
          <img
            src={require("src/assets/images/bulk-search-red.png")}
            style={{ width: 18, height: 18 }}
            alt="bulk-search-red"
          />
        }
        label={`${formData.screening_lists?.length || 0} ${
          formData.screening_lists?.length === 1 ? "List" : "Lists"
        } Shared with Group`}
        onClick={toggleBulkSearchesOpen}
      />

      <BulkSearchesDrawer
        open={isBulkSearchesOpen}
        onClose={toggleBulkSearchesOpen}
        ids={formData.screening_lists}
      />
    </>
  );
};

export default BulkSearch;
