import { SyntheticEvent } from "react";
import { Checkbox } from "src/components/ui";
import { snackbar } from "src/view/toaster";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { CheckboxLabel } from "./edit";

interface Props {
  datasetId: string;
}

const LeakedDataToggle = ({ datasetId }: Props) => {
  const { getDatasetMetadataById, updateDatasetMetadata } =
    useDatasetMetadata();
  const { mutateAsync } = updateDatasetMetadata(datasetId);
  const { data: datasetMetadata } = getDatasetMetadataById(datasetId);

  const onCheckboxChange = (event: SyntheticEvent, checked: boolean) => {
    event.preventDefault();

    mutateAsync({
      id: datasetId,
      payload: {
        is_leaked: checked,
      },
    })
      .then(() => {
        snackbar.success(
          checked
            ? "Dataset has been marked as leaked"
            : "Dataset has been marked as NOT leaked"
        );
      })
      .catch(() => {
        snackbar.error("Failed to update Dataset");
      });
  };

  return (
    <Checkbox
      id="leaked-data"
      label={<CheckboxLabel htmlFor="leaked-data">Leaked Data</CheckboxLabel>}
      color="secondary"
      checked={!!datasetMetadata?.is_leaked}
      onChange={onCheckboxChange}
    />
  );
};

export default LeakedDataToggle;
