import { forwardRef, CSSProperties, ReactNode } from "react";
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  styled,
} from "@mui/material";
import { ReactComponent as CheckboxCheckedIcon } from "src/assets/icons/checkbox_checked.svg";
import { ReactComponent as CheckboxUncheckedIcon } from "src/assets/icons/checkbox_unchecked.svg";
import { ReactComponent as CheckboxIntermediateIcon } from "src/assets/icons/checkbox_intermediate.svg";
import { CheckboxSizes } from "./types";

interface CheckboxProps {
  label?: ReactNode;
  size?: CheckboxSizes;
  intermediate?: boolean;
}

const Container = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const sizes: Record<CheckboxSizes, CSSProperties> = {
  big: {
    width: "22px",
    height: "22px",
  },
  medium: {
    width: "18px",
    height: "18px",
  },
  small: {
    width: "14px",
    height: "14px",
  },
};

const StyledCheckboxInput = styled(
  forwardRef<HTMLButtonElement, CheckboxProps & MuiCheckboxProps>(
    ({ ...props }, ref) => <MuiCheckbox ref={ref} {...props} />
  )
)(({ theme, size }) => {
  return {
    display: "flex",
    alignItems: "center",
    color: "#B8BFC7",
    borderRadius: "2px",
    padding: 0,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "&.MuiCheckbox-root": {
      padding: 0,
      ...sizes[size],
    },
  };
});

const StyledLabel = styled("label")(({ theme }) => ({
  fontFamily: "Inter",
  color: theme.palette.silver.lighter,
  fontSize: "14px",
  fontWeight: 400,
  cursor: "pointer",
  lineHeight: "17px",
  userSelect: "none",
}));

export const Checkbox = forwardRef<
  HTMLButtonElement,
  CheckboxProps & MuiCheckboxProps
>(({ label, id, size = "medium", intermediate, ...props }, ref) => {
  return (
    <Container>
      <StyledCheckboxInput
        ref={ref}
        size={size}
        id={id}
        icon={<CheckboxUncheckedIcon />}
        checkedIcon={
          intermediate ? <CheckboxIntermediateIcon /> : <CheckboxCheckedIcon />
        }
        {...props}
      />
      {typeof label === "string" ? (
        <StyledLabel htmlFor={id}>{label}</StyledLabel>
      ) : (
        label
      )}
    </Container>
  );
});
