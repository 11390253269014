import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  PreviewDocListProps,
  PreviewDocProps,
} from "src/modules/search/view/searchViewReducers";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandLessSharp, ExpandMoreSharp } from "@mui/icons-material";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { styled } from "@mui/material/styles";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye.svg";
import TableHead from "@mui/material/TableHead";
import { useClickAway } from "src/modules/shared/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import theme from "src/theme";
import { debounce, groupBy, xor } from "lodash";
import { DataIndex, Hit } from "src/types/Search";
import { hiddenKeys } from "src/view/search-result/components/PreviewContent";
import * as ftm from "@alephdata/followthemoney";
import { formatKey } from "src/utils/string";

const intervalTableOrder = ["schema", "role", "name", "percentage", "date"];
const entityPropertiesMap = new Map(
  intervalTableOrder.map((item, index) => [item, index])
);

const prepareEntityProperties = (source: Hit<DataIndex.modeled>["_source"]) => {
  const lastIndex = intervalTableOrder.length;
  return Object.keys(source)
    .filter((property: string) => !hiddenKeys.includes(property))

    .sort((a, b) => {
      const orderA = entityPropertiesMap.has(a)
        ? entityPropertiesMap.get(a)
        : lastIndex;
      const orderB = entityPropertiesMap.has(b)
        ? entityPropertiesMap.get(b)
        : lastIndex;
      return orderA - orderB;
    });
};

interface StyledTabProps {
  label: ReactElement | string;
}

const AntTabs = styled(Tabs)({
  minHeight: 41,
  height: 41,
  overflow: "visible",
  borderBottom: "1px solid #e8e8e8",
  borderRadius: "8px 8px 0px 0px",
  "& .MuiTabs-indicator": {
    height: 3,
  },
  "& .MuiTabs-scroller": {
    overflow: "visible !important",
  },
});

interface StyledTabProps {
  label: ReactElement | string;
  value: string;
}
const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(1),
  color: "#54657A",
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  "& th": {
    whiteSpace: "nowrap",
    padding: "10px 10px 10px 0",
    fontSize: "12px",
    lineHeight: "15px",
    color: theme.palette.grey[600],
    fontWeight: 500,
    border: "none",

    "&:first-of-type": {
      width: 30,
      padding: 0,
    },
  },
}));

const StyledTableBody = styled(TableBody)(() => ({
  "& tr:hover": {
    background: "#F9F9FA",
  },

  "& tr:hover td:first-of-type": {
    position: "sticky",
    left: 0,
    background:
      "linear-gradient(90deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%)",
  },
  "& tr:hover .open-entity-icon": {
    visibility: "unset",
    background: "#F9F9FA",
    borderRadius: 4,
  },

  "& td": {
    "&:first-of-type": {
      border: "none",
      width: 30,
      padding: "0 4px 0 0",
    },
  },
}));

const StyledOptionButton = styled(Button)(() => ({
  borderRadius: 60,
  backgroundColor: "transparent",
  boxShadow: "none",
  zIndex: 1,
  height: 14,
  fontSize: 12,
  fontWeight: "700",
  color: "#536378",
  textTransform: "unset",
  padding: "4px 9px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const StyledDropdown = styled("ul")(() => ({
  position: "absolute",
  background: "#FFFFFF",
  boxShadow: "0px 34px 33px rgba(0, 0, 0, 0.25)",
  marginTop: "2px",
  zIndex: 2,
  padding: 0,
  width: 200,
  marginLeft: -130,
  border: "1px solid #ccd1d7",
  "& li": {
    fontSize: "14px",
    fontWeight: "700",
    color: theme.palette.primary.light,
  },
}));

const ToggleTableButton = styled(Button)(() => ({
  color: theme.palette.primary.main,
  fontSize: "12px",
  fontWeight: "600",
  "& .MuiButton-startIcon": {
    color: theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  paddingLeft: 0,
}));

const FancyScrollableCollapse = styled(Collapse)({
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "12px",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
    borderRadius: 10,
  },
  scrollbarWidth: "thin",
  scrollbarColor: theme.palette.grey[500],
});

interface Props {
  tabData?: [] | null;
  intervalTabs: Array<string>;
  getEntityPreview: (entity: PreviewDocProps) => void;
  modeledData: PreviewDocListProps["intervals"];
  handleChangeTab: (tab: string, ignoredCount: number) => void;
  gettingEntity: boolean;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
  scrollToEntityId: string;
}

const ConnectionsTable = ({
  intervalTabs,
  getEntityPreview,
  modeledData,
  handleChangeTab,
  gettingEntity,
  handleClosePreview,
  scrollToEntityId,
}: Props) => {
  const entitiesRefs = useRef({});
  const [selectedIntervalTab, setSelectedIntervalTab] = useState(
    () => intervalTabs[0]
  );
  const tabContentBySchema = useMemo(() => {
    if (!modeledData?.[selectedIntervalTab]?.length) {
      return {};
    }
    return groupBy(
      modeledData[selectedIntervalTab],
      (obj: Hit<DataIndex.modeled>) => obj._source.schema
    );
  }, [selectedIntervalTab]);

  // collapses whatever schema dropdown is written under a given connection/interval tab (none by default)
  const [hiddenSchemaGroupsByTab, setHiddenSchemaGroupsByTab] = useState(() =>
    Object.values(intervalTabs).reduce((acc, intervalTab) => {
      acc[intervalTab] = [];
      return acc;
    }, {})
  );

  useEffect(() => {
    const targetEntity = entitiesRefs.current[scrollToEntityId];
    targetEntity?.scrollIntoView({ behavior: "smooth" });
  }, [tabContentBySchema]);

  const handleSetCollapsedSchemaDropdown = (
    intervalTabKey: string,
    schemaTableKey: string
  ) => {
    setHiddenSchemaGroupsByTab((prevItems) => {
      const newItems = { ...prevItems };
      newItems[intervalTabKey] = xor(newItems[intervalTabKey], [
        schemaTableKey,
      ]);
      return newItems;
    });
  };

  const sortEntities = (
    a: Hit<DataIndex.modeled>,
    b: Hit<DataIndex.modeled>
  ) => {
    if (!a._source?.["name"]?.[0] || !b._source?.["name"]?.[0]) {
      return 0;
    }
    return a._source["name"][0].localeCompare(b._source.name[0]);
  };

  const formatHeaderTitle = (title: string) => formatKey(title);

  return (
    <>
      <TabContainer>
        {(overflowed, more) => (
          <AntTabs
            value={selectedIntervalTab}
            onChange={(_e, value: string) => setSelectedIntervalTab(value)}
          >
            {modeledData &&
              typeof modeledData == "object" &&
              intervalTabs?.map((tab: string, index: number) => {
                if (index >= intervalTabs.length - more) return;
                const _data = modeledData[tab] || [];
                return (
                  <AntTab
                    value={tab}
                    key={tab}
                    label={
                      <div
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          gap: "6px",
                          lineHeight: "15px",
                        }}
                      >
                        {tab}
                        <span style={{ fontWeight: "400" }}>•</span>
                        <span style={{ fontWeight: "400" }}>
                          {_data?.length}
                        </span>
                      </div>
                    }
                  />
                );
              })}
            {more > 0 && (
              <Box sx={{ margin: "auto", ml: 0 }}>
                <MoreButton
                  tabs={intervalTabs?.slice(intervalTabs.length - more)}
                  handleChange={(tab: string) => handleChangeTab(tab, more)}
                />
              </Box>
            )}
          </AntTabs>
        )}
      </TabContainer>

      <Box>
        {gettingEntity && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "2em",
            }}
          >
            <CircularProgress size={20} disableShrink sx={{ mr: 2 }} /> Fetching
            Known Connections
          </Box>
        )}
        {!gettingEntity &&
          Object.keys(tabContentBySchema)?.length &&
          Object.keys(tabContentBySchema).map((schema): ReactNode => {
            const currentSchemaEntities =
              tabContentBySchema[schema].sort(sortEntities);
            const propertyHeaders = prepareEntityProperties(
              tabContentBySchema[schema][0]["_source"]
            );
            const isOpen =
              !hiddenSchemaGroupsByTab[selectedIntervalTab].includes(schema);
            const handleToggleOpen = () => {
              handleSetCollapsedSchemaDropdown(selectedIntervalTab, schema);
            };

            return (
              <React.Fragment key={`schema-table-${schema}`}>
                <ToggleTableButton
                  variant="text"
                  disableRipple
                  onClick={handleToggleOpen}
                  startIcon={
                    <>{isOpen ? <ExpandLessSharp /> : <ExpandMoreSharp />}</>
                  }
                >
                  {schema} ({currentSchemaEntities.length})
                </ToggleTableButton>

                <FancyScrollableCollapse in={isOpen}>
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        {propertyHeaders.map((tab: string) => {
                          const ftmPropertyLabel =
                            ftm.defaultModel.schemata?.[schema]?.properties?.[
                              tab
                            ]?.label;

                          return (
                            <TableCell key={tab}>
                              {tab === "schema"
                                ? ""
                                : formatHeaderTitle(ftmPropertyLabel ?? tab)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </StyledTableHead>

                    <StyledTableBody>
                      {currentSchemaEntities.map(
                        (item: unknown, index: number) => {
                          const source = item["_source"];
                          return (
                            <EntityRow
                              key={index}
                              getEntityPreview={getEntityPreview}
                              source={source}
                              propertyHeaders={propertyHeaders}
                              item={item as PreviewDocProps}
                              handleClosePreview={handleClosePreview}
                              ref={(entityRow) =>
                                (entitiesRefs.current[
                                  (item as PreviewDocProps)._id
                                ] = entityRow)
                              }
                            />
                          );
                        }
                      )}
                    </StyledTableBody>
                  </Table>
                </FancyScrollableCollapse>
              </React.Fragment>
            );
          })}
      </Box>
    </>
  );
};

const TabContainer = (props: {
  children: (overflowed: boolean, more: number) => React.ReactNode;
}) => {
  const { children } = props;
  const [overflowed, setOverflowed] = useState(() => false);
  const [more, setMore] = useState(() => 0);
  const tabContainerRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(
    debounce(() => {
      setOverflowed(false);
      setMore(0);
      overflowHandler();
    }, 300), // debounce time of 300ms to optimize performance
    []
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    overflowHandler();
  }, [tabContainerRef]);

  const overflowHandler = () => {
    const el = tabContainerRef.current?.querySelector("div:first-child > div");
    if (!el) return;
    const children = el.children[0]?.querySelectorAll("button");
    if (!children) return;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const t_offset =
        child.offsetLeft + child.clientWidth - el.children[0].clientWidth;
      if (t_offset > -70) {
        setMore(children.length - i);
        break;
      }
    }
    if (el.scrollWidth > el.clientWidth) {
      setOverflowed(true);
    }
  };

  return (
    <Box id="preview_tab_container" ref={tabContainerRef}>
      {children(overflowed, more)}
    </Box>
  );
};

const StyledEntityRow = styled(TableRow)(() => ({
  borderBottom: "inherit",
  "& td": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "400",
    padding: "7px 10px 7px 0",
  },
  "&:last-of-type": {
    borderBottom: "none",
  },
}));

const EntityRow = forwardRef(
  (
    props: {
      source: Hit<DataIndex.modeled>["_source"];
      propertyHeaders: Array<string>;
      item: PreviewDocProps;
      getEntityPreview: (item: PreviewDocProps) => void;
      handleClosePreview: (shouldSetURLParam?: boolean) => void;
    },
    ref: React.Ref<HTMLTableRowElement>
  ) => {
    const {
      source,
      propertyHeaders,
      item,
      getEntityPreview,
      handleClosePreview,
    } = props;

    const openEntityPreview = () => {
      getEntityPreview(item as unknown as PreviewDocProps);
    };

    if (!propertyHeaders) return null;

    return (
      <StyledEntityRow ref={ref}>
        {propertyHeaders?.includes("schema") && (
          <TableCell padding="none">
            <Tooltip
              title={
                <Typography sx={{ fontSize: "10px" }}>
                  View this entity
                </Typography>
              }
              sx={{
                fontSize: "10px",
              }}
              placement="top"
              PopperProps={{ style: { zIndex: 1501 } }}
            >
              <IconButton
                size="small"
                className="open-entity-icon"
                onClick={openEntityPreview}
                disableRipple
                sx={{
                  visibility: "hidden",
                }}
              >
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {propertyHeaders.map((propertyHeader: string, _index: number) => {
          if (propertyHeader == "schema") return null;
          const value = source[propertyHeader] || "";
          return (
            <TableCell
              key={_index}
              sx={{
                maxHeight: "50px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordWrap: "nowrap",
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              {Array.isArray(value) && value.length > 1 ? (
                <Tooltip
                  title={
                    <Typography
                      style={{
                        maxHeight: "100px",
                        fontSize: "10px",
                        overflowY: "auto",
                      }}
                    >
                      {value.join(",\n")}
                    </Typography>
                  }
                  PopperProps={{
                    style: { zIndex: 1501 },

                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          fallbackPlacements: ["top", "bottom"],
                        },
                      },
                    ],
                  }}
                >
                  <Box>
                    <HighlightActionMenu
                      keyValueQuery={`${propertyHeader}:"${value[0]}"`}
                      onSearchInTab={handleClosePreview}
                    >
                      <>{value[0] + "..."}</>
                    </HighlightActionMenu>
                  </Box>
                </Tooltip>
              ) : (
                <HighlightActionMenu
                  keyValueQuery={`${propertyHeader}:"${value}"`}
                  onSearchInTab={handleClosePreview}
                >
                  <Box onClick={(e) => e.stopPropagation()}>{value}</Box>
                </HighlightActionMenu>
              )}
            </TableCell>
          );
        })}
      </StyledEntityRow>
    );
  }
);

const MoreButton = (props: {
  handleChange: (value: string) => void;
  tabs: string[];
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const menuRef = useRef(null);
  useClickAway(menuRef, () => setOpenDropdown(false));
  const handleClose = () => {
    setOpenDropdown(false);
  };

  const handleSelectTab = (value: string) => {
    props.handleChange(value);
    handleClose();
  };

  const handleClickButton = () => {
    if (openDropdown == false) setOpenDropdown(true);
  };

  return (
    <Box display="flex">
      <StyledOptionButton
        variant="text"
        disableRipple
        onClick={() => handleClickButton()}
        endIcon={<ArrowDropDownIcon sx={{ color: "inherit" }} />}
      >
        More
      </StyledOptionButton>
      {openDropdown && (
        <StyledDropdown ref={menuRef}>
          {props.tabs?.map((tab: string, index: number) => {
            return (
              <MenuItem
                key={"entity-menu-item-" + index}
                onClick={() => handleSelectTab(tab)}
              >
                {tab}
              </MenuItem>
            );
          })}
        </StyledDropdown>
      )}
    </Box>
  );
};

export default ConnectionsTable;
