import { useMutation } from "@tanstack/react-query";

import { useFetch } from "../fetch";
import { snackbar } from "src/view/toaster";

export function useFeedback() {
  const { postApi } = useFetch();

  const sendFeedback = useMutation({
    mutationFn: (message: string) => {
      return postApi(["intercom/submit_feedback"], {
        body: JSON.stringify({ message }),
      });
    },
    onSuccess: () =>
      snackbar.success(
        "Thanks for your feedback! We'll get back to you soon via email."
      ),
    onError: () =>
      snackbar.error("Failed to send feedback. Please try again later!"),
  });

  return {
    sendFeedback,
  };
}
