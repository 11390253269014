import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  BoxProps,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Colors } from "src/view/constants/colors";
import Dropzone from "react-dropzone";
import { useOCRTasks } from "src/modules/api/ocr";
import { useIsMutating } from "@tanstack/react-query";
import { mutationKeys } from "src/modules/api/queryKeys";
import { ReactComponent as IconPDF } from "src/assets/icons/type/source/type_pdf.svg";
import { ReactComponent as IconError } from "src/assets/icons/error_octagon.svg";
import theme from "src/theme";

const StyledButton = styled(Button)(() => ({
  color: Colors.twilight,
  borderColor: Colors.twilight,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: Colors.textDark100,
  },
}));

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    margin: "150px",
    maxWidth: "975px",
  },
}));

const StyledIconContainer = styled(Box)(() => ({
  display: "flex",
  width: "54px",
  height: "54px",
  padding: "8px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  background: "#E0E5EB",
}));

const StyledProgressContainer = styled(Box)({
  marginTop: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledHeading = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledPromoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "16px",
  fontWeight: 700,
  marginBottom: "12px",
}));

const StyledStatusText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "20px",
  fontWeight: "700",
  color: theme.palette.twilight.main,
  marginBottom: "4px",
}));

const StyledStatusDescription = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: "400",
  color: theme.palette.ocean.dark,
}));

const StyledStatusContainer = styled(Box)({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const UploadAgainButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  height: 42,
}));

const StyledErrorContainer = styled(Box)({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StyledTextError = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "20px",
  fontWeight: "700",
  color: theme.palette.red.main,
}));

interface DropzoneContainerProps extends BoxProps {
  isErrorState: boolean;
  totalFilesUploading: number;
}

const StyledDropzoneContainer = styled((props: DropzoneContainerProps) => (
  <Box {...props} />
))(({ theme, isErrorState, totalFilesUploading }) => ({
  boxSizing: "border-box",
  height: "262px",

  margin: "30px 0",
  padding: "50px",
  border:
    isErrorState && !!totalFilesUploading
      ? `1px dashed ${theme.palette.red.main}`
      : `1px dashed ${theme.palette.twilight.main}`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor:
    isErrorState && !totalFilesUploading
      ? "rgba(247, 81, 81, 0.05)"
      : "rgba(4, 61, 93, 0.05)",
}));

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const OCRUploadModal = ({ isOpen, handleClose }: Props) => {
  const { uploadFileForOCR } = useOCRTasks();
  const {
    mutateAsync: uploadFile,
    isError: isUploadError,
    isSuccess: isUploadSuccess,
  } = uploadFileForOCR();
  const [dropzoneError, setDropzoneError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [index, setIndex] = useState(0);

  const totalFilesUploading = useIsMutating({
    mutationKey: [mutationKeys.UPLOAD_OCR_FILE],
  });

  const handleUploadFiles = (files: File[]) => {
    setDropzoneError(null);
    setSelectedFiles(files);
  };

  interface RejectedFile {
    errors: {
      code: string;
      message: string;
    }[];
  }
  const handleFilesRejected = (rejectedFiles: RejectedFile[]) => {
    if (
      rejectedFiles.some((file) =>
        file.errors.some((fileError) => fileError.code === "too-many-files")
      )
    ) {
      setDropzoneError("Please only upload up to 5 files at a time");
    }
  };

  useEffect(() => {
    if (selectedFiles.length) {
      const timerId = setInterval(
        () => setIndex((i) => (i + 1) % selectedFiles.length),
        1000
      );
      return () => clearInterval(timerId);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles.length) {
      selectedFiles.forEach((file: File) => {
        uploadFile(file);
      });
    }
  }, [selectedFiles]);

  const closeAndResetState = () => {
    handleClose();
    setSelectedFiles([]);
    setDropzoneError(null);
  };

  const getFakeProgress = (selectedFiles: File[]) => {
    const currentFile = selectedFiles[index];
    const portalParent = document.getElementById("filename-container-portal");

    return (
      <>
        <StyledIconContainer>
          <IconPDF />
        </StyledIconContainer>

        {!!totalFilesUploading &&
          portalParent &&
          createPortal(
            <StyledProgressContainer>
              <CircularProgress
                size={20}
                sx={{
                  marginRight: "16px",
                }}
              />
              <Typography fontSize={"14px"} fontWeight="400">
                {currentFile?.name}
              </Typography>
            </StyledProgressContainer>,
            portalParent
          )}
      </>
    );
  };

  useEffect(() => {
    if (isUploadSuccess && !totalFilesUploading) {
      handleClose();
      setSelectedFiles([]);
    }
  }, [isUploadSuccess, totalFilesUploading]);

  const isErrorState = isUploadError || dropzoneError;

  return (
    <StyledDialog
      fullWidth
      open={isOpen}
      onClose={closeAndResetState}
      disableAutoFocus
      maxWidth={false}
    >
      <DialogTitle
        sx={{
          paddingBottom: 0,
          marginRight: 0,
        }}
      >
        <StyledHeading>
          <Typography fontSize="18px" fontWeight="700">
            Upload File for OCR
          </Typography>
          <IconButton
            onClick={closeAndResetState}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        </StyledHeading>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: "hidden",
        }}
      >
        <Dropzone
          maxFiles={5}
          multiple
          accept={{
            "image/jpeg": [".jpg", ".jpeg", ".png"],
            "application/pdf": [".pdf"],
          }}
          onDropAccepted={(acceptedFiles) => handleUploadFiles(acceptedFiles)}
          onDropRejected={(files) => handleFilesRejected(files)}
        >
          {({ getRootProps, getInputProps }) => (
            <StyledDropzoneContainer
              isErrorState={isErrorState}
              totalFilesUploading={totalFilesUploading}
            >
              <Box
                sx={{
                  display: "flex",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isErrorState ? (
                  <StyledErrorContainer>
                    <IconError
                      style={{
                        width: 39,
                        height: 39,
                        color: theme.palette.red.main,
                      }}
                    />
                    <Box sx={{ height: 12 }}></Box>
                    <StyledTextError variant="body2">
                      {dropzoneError
                        ? dropzoneError
                        : "Error encountered when uploading files"}
                    </StyledTextError>
                    <Box sx={{ height: 30 }}></Box>
                    <UploadAgainButton
                      startIcon={
                        <img
                          src={require("src/assets/images/upload_icon_white.png")}
                          style={{ width: 18, height: 18, color: "white" }}
                        />
                      }
                      variant="outlined"
                    >
                      Upload Again
                    </UploadAgainButton>
                  </StyledErrorContainer>
                ) : (
                  <StyledStatusContainer>
                    {totalFilesUploading ? (
                      <Box>
                        <>{getFakeProgress(selectedFiles)}</>
                      </Box>
                    ) : (
                      <img
                        src={require("src/assets/images/cloud-upload-filled.png")}
                        style={{ width: 39, height: 39 }}
                      />
                    )}
                    <Box sx={{ height: 12 }}></Box>
                    <StyledStatusText variant="body2">
                      {totalFilesUploading
                        ? "Uploading..."
                        : "Click to upload files"}
                    </StyledStatusText>
                    <StyledStatusDescription variant="body2">
                      {totalFilesUploading
                        ? "This may take a while"
                        : "or drag and drop them here"}
                    </StyledStatusDescription>
                    <Box id="filename-container-portal" />
                  </StyledStatusContainer>
                )}
              </Box>
            </StyledDropzoneContainer>
          )}
        </Dropzone>

        <StyledPromoText>Things to keep in mind</StyledPromoText>

        <Typography
          sx={{
            color: theme.palette.grey[800],
          }}
        >
          Only PDF, PNG, JPG, and JPEG files are supported, up to 5 files at a
          time.
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};
export default OCRUploadModal;
