import { createSelector } from "reselect";
import { SearchStoreInterface } from "./reducer";

const selectSearchStore = (state: { searchStore: SearchStoreInterface }) =>
  state.searchStore;

const selectUnappliedFilters = createSelector(
  [selectSearchStore],
  (raw) => raw.unappliedFilters
);

const selectVariables = createSelector(
  [selectSearchStore],
  (raw) => raw.variables
);

const selectQueryB = createSelector([selectSearchStore], (raw) => raw.queryB);

const selectActivePageIndex = createSelector(
  [selectSearchStore],
  (raw) => raw.activePageIndex
);

const selectPreviewData = createSelector(
  [selectSearchStore],
  (raw) => raw.previewData
);

const searchStoreSelectors = {
  selectUnappliedFilters,
  selectVariables,
  selectQueryB,
  selectActivePageIndex,
  selectPreviewData,
};

export default searchStoreSelectors;
