import { decompressFromEncodedURIComponent } from "lz-string";
import { QueryVariables } from "src/types/QueryVariables";
import { emptyQueryVariables } from "./constants";

export function parseVariablesFromSearchParams(searchParams: URLSearchParams) {
  const queryString = searchParams.get("q");
  if (queryString) {
    try {
      return JSON.parse(
        decompressFromEncodedURIComponent(queryString)
      ) as QueryVariables;
    } catch {
      return emptyQueryVariables;
    }
  }
  return emptyQueryVariables;
}
