export const getErrorMessage = (errorResponse: Record<string, string>) => {
  let apiErrorMsg: string | undefined;
  if (typeof errorResponse !== "object" || errorResponse === null) {
    return false;
  } else if (errorResponse && errorResponse["error"]) {
    apiErrorMsg = errorResponse["error"];
  }

  return apiErrorMsg || "Something went wrong";
};

const fieldMapping: Record<string, string> = {
  summary: "Summary",
  credibility: "Credibility",
  dataset_type: "Type",
  countries: "Countries",
  languages: "Languages",
  date_snapshot: "Date of Snapshot",
};

export const transformErrorMessage = (message: string): string => {
  return message.replace(/\['(.*?)'\]/, (_, fields) => {
    const transformedFields = fields
      .split(/',\s*'/)
      .map((field: string) => fieldMapping[field] || field)
      .join(", ");

    return `${transformedFields}`;
  });
};

export const accessTokenExpired = async (response: Response) => {
  let signatureError = false;
  const clonedResponse = response.clone();
  try {
    const responseData = await clonedResponse.json();
    signatureError =
      responseData.detail &&
      typeof responseData.detail === "string" &&
      ["Signature has expired", "Signature verification failed"].some(
        (detail) => responseData.detail.includes(detail)
      );
  } catch {
    signatureError = false;
  }

  return signatureError || clonedResponse.url.includes("users/permissions");
};
