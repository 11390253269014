import { useContext, useEffect, useRef, WheelEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import LoadingText from "src/view/components/LoadingText";
import { DatasetHits } from "./DatasetHits";
import { NoResultCard } from "./SearchResultCard";
import { useSearch } from "src/modules/api/search";
import SearchResultPagination from "./SearchResultPagination";
import { QueryInstructions } from "./SearchResultCard/QueryInstructions";
import { parsePreviewFromSearchParams } from "src/modules/api/search/SearchProvider";
import actions from "../../../modules/search/view/searchViewActions";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import { HogEvent } from "src/types/PosthogEvents";
import searchStoreSelectors from "src/modules/api/search/store/selectors";

const StyledSearchResultContent = styled("div")(() => ({
  overflowY: "auto",
  height: "calc(100% - 32px)", // 100% - pagination height
  borderRadius: "6px 6px 0 0",
  padding: "0 26px",
}));

const StyledContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledNoData = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  color: theme.palette.primary.light,
}));

const StyledSearchResultContainer = styled(Box)(() => ({
  height: "100%",
  backgroundColor: Colors.white,
}));

const StyledSearchResultWrapper = styled(Box)(() => ({
  padding: "20px 0",
  height: "calc(100% - 64px - 65px)", // 100% - 64px header height - 65px foundResults height
  position: "relative",
  boxSizing: "border-box",
}));

const StyledLoadingMessage = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: "23px",
  fontWeight: "bold",
  color: Colors.textGray300,
  marginBottom: 10,
  width: 64,
}));

const StyledQueryInstructionsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.light,
}));

export default function SearchResultContent() {
  const { data, status, isFetching, searchDisabled } = useSearch();
  const activePageIndex = useSelector(
    searchStoreSelectors.selectActivePageIndex
  );
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { setSelectedResult } = useContext(PreviewContext);
  const searchResultContentRef = useRef<HTMLDivElement | null>(null);

  // sync preview state with URL (respecting history)
  useEffect(() => {
    const parsedPreview = parsePreviewFromSearchParams(searchParams);

    if (parsedPreview) {
      const { _id, source_type, type, data } = parsedPreview;
      if (data?.[0]) setSelectedResult(data[0]);
      posthog.capture(
        source_type === 1
          ? HogEvent.SOURCE_DATA_PREVIEWED
          : HogEvent.MODEL_DATA_PREVIEWED
      );
      dispatch(actions.getDocumentPreview(_id, source_type, type));
      dispatch({
        type: actions.SET_PREVIEW_DOC,
        payload: parsedPreview,
      });
    } else {
      setSelectedResult(null);
      dispatch({
        type: actions.SET_PREVIEW_DOC,
        payload: null,
      });
      dispatch({
        type: actions.GET_PREVIEW_DOCUMENT,
        payload: null,
      });
    }
  }, [searchParams]);

  const handleWheel = (event: WheelEvent<HTMLDivElement>) => {
    if (searchResultContentRef.current) {
      searchResultContentRef.current.scrollTop += event.deltaY;
    }
  };

  if (searchDisabled) {
    return (
      <StyledSearchResultContainer>
        <StyledSearchResultWrapper>
          <StyledSearchResultContent ref={searchResultContentRef}>
            <StyledQueryInstructionsContainer>
              <QueryInstructions />
            </StyledQueryInstructionsContainer>
          </StyledSearchResultContent>
        </StyledSearchResultWrapper>
      </StyledSearchResultContainer>
    );
  }

  if (isFetching) {
    return (
      <StyledSearchResultContainer onWheel={handleWheel}>
        <StyledSearchResultWrapper>
          <StyledSearchResultContent ref={searchResultContentRef}>
            <StyledContainer>
              <StyledLoadingMessage>
                <LoadingText />
              </StyledLoadingMessage>
              <CircularProgress />
            </StyledContainer>
          </StyledSearchResultContent>
        </StyledSearchResultWrapper>
      </StyledSearchResultContainer>
    );
  }

  if (status === "error") {
    return null;
  }

  const page = data.pages[activePageIndex];

  if (!page) {
    return null;
  }

  return (
    <StyledSearchResultContainer onWheel={handleWheel}>
      <StyledSearchResultWrapper>
        <StyledSearchResultContent ref={searchResultContentRef}>
          {page.totalCounts === 0 && (
            <StyledNoData>
              <NoResultCard />
            </StyledNoData>
          )}
          {Object.entries(page.items).map(([datasetId, hits]) => (
            <DatasetHits key={datasetId} datasetId={datasetId} hits={hits} />
          ))}
        </StyledSearchResultContent>
        <SearchResultPagination />
      </StyledSearchResultWrapper>
    </StyledSearchResultContainer>
  );
}
