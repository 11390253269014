import { styled, Box, Typography } from "@mui/material";
import theme from "src/theme";

const StyledEmptyContainer = styled(Box)({
  padding: 70,
  textAlign: "center",
});

const EmptyState = () => (
  <StyledEmptyContainer>
    <Typography
      fontSize="12px"
      fontWeight="700"
      color={theme.palette.primary.main}
    >
      No results
    </Typography>
  </StyledEmptyContainer>
);

export default EmptyState;
