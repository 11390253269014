import React, { ReactNode } from "react";
import { OCRTask, OCRTaskStatus } from "src/types/OCR";
import { snackbar } from "src/view/toaster";
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Cancel as IconFailed,
  CheckCircle as IconCompleted,
  HourglassTop as IconProgress,
  Replay as IconRetry,
} from "@mui/icons-material";
import { ReactComponent as IconQueue } from "src/assets/images/time.svg";
import { ReactComponent as IconDownload } from "src/assets/icons/download.svg";
import { ReactComponent as IconDelete } from "src/assets/icons/delete.svg";
import { useOCRTasks } from "src/modules/api/ocr";
import { fetchApi } from "src/modules/shared/api";
import { getFileNameFromURL } from "src/utils";
import { format } from "date-fns";
import theme from "src/theme";

const ProgressWrapper = styled(Box)(() => ({
  fontSize: "14px",
  display: "flex",
  fontWeight: 700,
  alignItems: "center",
  "& svg": {
    marginRight: "4px",
    fontSize: "medium",
  },
  "& p": {
    fontWeight: "inherit",
    fontSize: "inherit",
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledLoaderContainer = styled(Box)({
  display: "inline-flex",
  padding: "0 16px 0 0",
});

const StyledActionsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

interface Props {
  task: OCRTask;
}

const OCRTaskItem = ({ task }: Props) => {
  const { deleteOCRTask, getOCRTaskResult, retryOCRTask } = useOCRTasks();
  const { isFetching: isTaskResultFetching, refetch: fetchTaskResult } =
    getOCRTaskResult(task.id);
  const { mutateAsync: deleteTask } = deleteOCRTask();
  const { mutateAsync: retryTask, isLoading: isRetryingTask } = retryOCRTask();

  const getTaskStatus = (status: OCRTaskStatus): ReactNode => {
    let icon: ReactNode;
    let content: ReactNode;

    switch (status) {
      case OCRTaskStatus.QUEUED:
        icon = (
          <SvgIcon
            component={IconQueue}
            inheritViewBox
            sx={{
              color: theme.palette.secondary.light,
            }}
          />
        );
        content = (
          <Typography
            sx={{
              color: theme.palette.secondary.light,
            }}
          >
            In queue
          </Typography>
        );
        break;

      case OCRTaskStatus.PROGRESS:
        icon = (
          <IconProgress
            sx={{
              color: theme.palette.neutral.dark,
            }}
          />
        );
        content = (
          <Typography color={theme.palette.neutral.dark}>Processing</Typography>
        );
        break;

      case OCRTaskStatus.SUCCEED:
        icon = (
          <IconCompleted
            sx={{
              color: theme.palette.success.main,
            }}
          />
        );
        content = (
          <Typography color={theme.palette.success.main}>Completed</Typography>
        );
        break;

      case OCRTaskStatus.FAILED:
        icon = (
          <IconFailed
            sx={{
              color: theme.palette.secondary.light,
            }}
          />
        );
        content = (
          <Typography color={theme.palette.secondary.light}>Failed</Typography>
        );
        break;

      default:
        content = (
          <Typography color={theme.palette.secondary.light}>-</Typography>
        );
    }

    return (
      <ProgressWrapper>
        {icon}
        {content}
      </ProgressWrapper>
    );
  };

  const handleDownloadDoc = async () => {
    try {
      const taskResult = await fetchTaskResult();

      const a = document.createElement("a");
      const response = await fetchApi(taskResult.data.url, {}, true);

      a.id = "temp-download-link";
      a.href = URL.createObjectURL(await response.blob());
      a.download = getFileNameFromURL(taskResult.data.url);
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.getElementById("temp-download-link").remove();
    } catch (err) {
      console.error("failed to fetch download url", err);
      snackbar.error("Failed to get download link");
    }
  };
  const handleDeleteDoc = async () => {
    await deleteTask(task.id);
  };

  const handleRetryTask = async () => {
    await retryTask(task.id).then((result) => {
      if (result.error) snackbar.error(result.detail);
    });
  };

  return (
    <TableRow key={task.id}>
      <TableCell>{task.file_key}</TableCell>
      <TableCell>{getTaskStatus(task.status)}</TableCell>
      <TableCell>{format(new Date(task.created), "MMM dd, yyyy")}</TableCell>
      <TableCell
        sx={{
          overflow: "visible !important",
        }}
      >
        <StyledActionsContainer>
          {(isTaskResultFetching || isRetryingTask) && (
            <StyledLoaderContainer>
              <CircularProgress
                size={12}
                sx={{
                  padding: 0,
                }}
              />
            </StyledLoaderContainer>
          )}

          {!isTaskResultFetching && task.status === OCRTaskStatus.SUCCEED && (
            <StyledIconButton
              disableRipple
              sx={{
                padding: "0 16px 0 0",
              }}
              onClick={handleDownloadDoc}
            >
              <IconDownload
                style={{
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                }}
              />
            </StyledIconButton>
          )}

          {!isRetryingTask && task.status === OCRTaskStatus.FAILED && (
            <StyledIconButton
              disableRipple
              sx={{
                padding: "0 16px 0 0",
              }}
              onClick={handleRetryTask}
            >
              <IconRetry
                style={{
                  fontSize: "medium",
                  color: theme.palette.primary.main,
                  backgroundColor: "transparent",
                }}
              />
            </StyledIconButton>
          )}

          <StyledIconButton
            disableRipple
            sx={{
              paddingLeft: "16px",
            }}
            onClick={handleDeleteDoc}
          >
            <IconDelete
              style={{
                color: theme.palette.primary.main,
              }}
            />
          </StyledIconButton>
        </StyledActionsContainer>
      </TableCell>
    </TableRow>
  );
};

export default OCRTaskItem;
