import { QueryItem } from "src/types/BulkSearch";

// Function to parse the query string back into an array of QueryItem objects
export const parseQueryString = (queryString: string): QueryItem[] => {
  const queryItems: QueryItem[] = [];

  // Tokenize the input string by spaces (and keep the operators intact)
  const tokens = queryString.match(/"[^"]*"|AND|OR|NOT|\S+/g);

  if (!tokens) return queryItems;

  tokens.forEach((token) => {
    if (
      token === "AND" ||
      token === "OR" ||
      token === "NOT" ||
      token === "(" ||
      token === ")"
    ) {
      // Handle operators
      queryItems.push({
        type: "operator",
        value: token,
        quote: false,
        fuzziness: 0,
      });
    } else if (token.startsWith('"') && token.endsWith('"')) {
      // Handle exact terms (quoted)
      queryItems.push({
        type: "term",
        value: token, // Remove surrounding quotes
        quote: true,
        fuzziness: 0,
      });
    } else if (token.includes("~")) {
      // Handle fuzzy terms
      const [term, fuzziness] = token.split("~");
      queryItems.push({
        type: "term",
        value: term,
        fuzziness: parseInt(fuzziness, 10),
        quote: false,
      });
    } else {
      // Handle regular terms
      queryItems.push({
        type: "term",
        value: token,
        quote: false,
        fuzziness: 0,
      });
    }
  });

  return queryItems;
};
