import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";

import SearchInput from "../components/SearchInput";

interface Props {
  users: User[];
  setUserList: React.Dispatch<React.SetStateAction<User[]>>;
}

const UserSearchInput = ({ users, setUserList }: Props) => {
  const handleFilterUsers = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const keyword = e.target.value;
    const filtered = users.filter(
      (user: User) =>
        getFullName(user).toLowerCase().includes(keyword.toLowerCase()) ||
        user.email.toLowerCase().includes(keyword.toLowerCase())
    );
    setUserList(filtered);
  };

  return (
    <SearchInput placeholder="Search Users" onChange={handleFilterUsers} />
  );
};

export default UserSearchInput;
