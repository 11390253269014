import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Features } from "src/types/Auth";
import { snackbar } from "src/view/toaster";
import ErrorPage from "src/view/error";
import LoadingPage from "src/view/loading";
import useUser from "./useUser";

export type AuthRouteGuardProps = {
  feature?: Features;
  component: React.ComponentType;
};

export const AuthRouteGuard = ({ feature, component }: AuthRouteGuardProps) => {
  const { user, isAuthenticated, isUserDataLoading, isError } = useUser();

  const Component = component;

  useEffect(() => {
    if (feature && user && !user?.features.includes(feature)) {
      snackbar.error(
        "Insufficient permissions. Please contact an administrator for assistance."
      );
    }
  }, [feature, user]);

  if (!isAuthenticated) {
    const callbackUrl = location.pathname + location.search;

    return <Navigate to="/login" state={{ requestedPage: callbackUrl }} />;
  }

  if (isUserDataLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!feature) {
    return <Component />;
  }

  return user.features.includes(feature) ? <Component /> : <Navigate to="/" />;
};
