import { Toaster, toast } from "sonner";
import { ErrorOutlineRounded } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { ReactComponent as CrossIcon } from "src/assets/icons/cross.svg";
import { ReactComponent as CheckmarkIcon } from "src/assets/icons/checkmark.svg";
const StyledIconButton = styled(IconButton)(() => ({
  position: "absolute",
  top: "50%",
  right: "8px",
  transform: "translateY(-50%)",
}));

export const Snackbar = () => (
  <Toaster
    toastOptions={{
      style: { paddingRight: "35px", fontSize: "14px" },
      className: "customSnackbar",
    }}
    offset={22}
    icons={{
      success: <CheckmarkIcon />,
      error: <ErrorOutlineRounded fontSize="small" color="error" />,
    }}
  />
);

// Create additional methods if you want to use another snackbar types, like 'info', 'promise' etc.

export const snackbar = {
  success(text: string) {
    const toastId = toast.success(text, {
      action: (
        <StyledIconButton onClick={() => toast.dismiss(toastId)}>
          <CrossIcon fontSize="small" color="error" />
        </StyledIconButton>
      ),
    });
  },

  error(content: string | JSX.Element) {
    const toastId = toast.error(content, {
      action: (
        <StyledIconButton onClick={() => toast.dismiss(toastId)}>
          <CrossIcon fontSize="small" color="error" />
        </StyledIconButton>
      ),
    });
  },

  warning(text: string) {
    const toastId = toast.warning(text, {
      action: (
        <StyledIconButton onClick={() => toast.dismiss(toastId)}>
          <CrossIcon fontSize="small" color="error" />
        </StyledIconButton>
      ),
    });
  },
};
