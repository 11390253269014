import React, { ReactElement } from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Button)(({ theme }) => ({
  borderRadius: 60,
  backgroundColor: theme.palette.grey[300],
  display: "flex",
  height: 24,
  padding: "4px 6px",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[900],
  fontSize: 16,
  minWidth: "unset",
  fontWeight: "600",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.primary.contrastText,
  },
}));

interface Props extends ButtonProps {
  icon?: ReactElement;
  text?: string;
}

export default function StyledButton({ icon, text, ...otherProps }: Props) {
  return (
    <StyledContainer startIcon={icon} {...otherProps}>
      {text}
    </StyledContainer>
  );
}
