import React, { CSSProperties, ReactNode } from "react";
import Multiselect from "multiselect-react-dropdown";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { SelectOption } from "src/types/Shared";
import theme from "../../../theme";

export interface SelectProps {
  placeholder?: string;
  name: string;
  label?: string;
  labelStyle?: CSSProperties;
  inputContainerStyle?: CSSProperties;
  hideSelectedList?: boolean;
  showCheckbox?: boolean;
  options?: SelectOption[];
  onChange?: (value: Array<string | number>) => void;
  disabled?: boolean;
  selectedValues?: SelectOption[];
  fullWidth?: boolean;
  helperText?: ReactNode;
}

const StyledMultiselect = styled(Multiselect)(({ theme }) => ({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme.palette.twilight.main,
  width: "100%",
  "& .searchBox ": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.twilight.main,
    paddingLeft: 5,
    margin: 0,
  },
  "& .searchWrapper": {
    border: "none",
    padding: "10px 16px",
  },
  "& .searchWrapper .chip": {
    marginBottom: 0,
  },
}));

const StyledMultiSelectWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  background: theme.palette.common.white,
  border: "1px solid rgba(65, 64, 66, 0.16)",
  borderRadius: 8,
  marginBottom: 10,
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: theme.palette.text.primary,
  marginBottom: 10,
}));

export const MultiSelect = ({
  placeholder = "Select",
  label = null,
  inputContainerStyle = {},
  hideSelectedList = false,
  showCheckbox = false,
  options,
  onChange,
  disabled,
  selectedValues,
  helperText,
}: SelectProps) => {
  const handleChangeSelected = (selectOptions: SelectOption[]) => {
    const values = selectOptions.map((item: SelectOption) => item.value);
    onChange(values);
  };

  return (
    <Box>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledMultiSelectWrapper style={{ ...inputContainerStyle }}>
        <StyledMultiselect
          placeholder={placeholder}
          displayValue="label"
          hideSelectedList={hideSelectedList}
          onRemove={handleChangeSelected}
          onSelect={handleChangeSelected}
          options={options}
          showCheckbox={showCheckbox}
          selectedValues={selectedValues || []}
          style={{
            chips: {
              background: theme.palette.secondary.main,
            },
            searchBox: {},
          }}
          disable={disabled}
        />
      </StyledMultiSelectWrapper>
      {helperText}
    </Box>
  );
};
