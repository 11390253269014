import { ReactElement } from "react";
import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import { Features } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { Menu } from "../../../../components/ui";

interface DownloadMenuProps {
  type: string;
  handleDownloadDoc?: (entireFile?: boolean) => void;
  handleDownloadDocBulk?: () => void;
  sourceType: number;
  trigger: ReactElement;
}

const StyledMenuItem = styled(MenuItem)(() => ({
  color: Colors.twilight,
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  height: 35,
}));

export default function DownloadMenu({
  type,
  handleDownloadDoc,
  handleDownloadDocBulk,
  sourceType,
  trigger,
}: DownloadMenuProps) {
  const { user } = useUser();

  const isSingleFileResult =
    type === "htm" ||
    type === "html" ||
    type === "jhtml" ||
    type === "shtml" ||
    type === "jpg" ||
    type === "jpeg" ||
    type === "png";
  const isEntireFileAllowed =
    !(type === "csv" || type === "parquet") &&
    !isSingleFileResult &&
    sourceType === 1;

  // 3 download options are supported
  // 1: download just the result
  // 2: download the entire source file
  // 3: bulk download
  // For anything that isn't a CSV, modeled data or single-file result, we should offer options 1 and 2
  // For CSV and modeled data, we should offer 1 and 3
  const getDownloadOptions = () => {
    return (
      <Box>
        <StyledMenuItem onClick={() => handleDownloadDoc?.()}>
          Download This Page
        </StyledMenuItem>
        {isEntireFileAllowed ? (
          <StyledMenuItem onClick={() => handleDownloadDoc?.(true)}>
            Download Source File
          </StyledMenuItem>
        ) : (
          handleDownloadDocBulk &&
          !isSingleFileResult &&
          user?.features?.includes(Features.BULK_DOWNLOAD) && (
            <StyledMenuItem onClick={handleDownloadDocBulk}>
              Download Preview
            </StyledMenuItem>
          )
        )}
      </Box>
    );
  };

  return (
    <Menu
      id="account-menu"
      PaperProps={{
        sx: {
          mt: 1.5,
        },
      }}
      trigger={trigger}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      {getDownloadOptions()}
    </Menu>
  );
}
