import { styled } from "@mui/material/styles";

const StyledContainer = styled("div")(() => ({
  borderRadius: "4.56px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  objectFit: "contain",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  cursor: "pointer",
}));

const StyledImg = styled("img")(() => ({
  width: 40,
  height: 40,
}));

const DataTypeIcon = (props: { type: string; onClick?: () => void }) => {
  const svgRequireContext = require.context(
    "/src/assets/icons/type",
    true,
    /\.svg$/
  );

  const svgs = svgRequireContext.keys().reduce((images, path) => {
    images[path] = svgRequireContext(path);
    return images;
  }, {});

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const defaultSrc = require("src/assets/icons/default_document.png");

  return (
    <StyledContainer onClick={() => props.onClick && props.onClick()}>
      <StyledImg
        src={
          svgs[`./source/type_${props.type.toLowerCase()}.svg`] ||
          svgs[`./modeled/type_${props.type.toLowerCase()}.svg`] ||
          defaultSrc
        }
        alt={props.type}
      />
    </StyledContainer>
  );
};

export default DataTypeIcon;
