import { Box, Switch, Tooltip } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";

import theme from "src/theme";

interface Props {
  tooltip: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
}

export function FeatureSwitch({ tooltip, value, onChange, isDisabled }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip
        placement="top"
        title={tooltip}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: theme.palette.primary.main,
              "& .MuiTooltip-arrow": { color: theme.palette.primary.main },
            },
          },
        }}
      >
        <InfoIcon fontSize="small" sx={{ color: theme.palette.grey["600"] }} />
      </Tooltip>

      <Switch
        size="small"
        color="secondary"
        checked={value}
        onChange={onChange}
        disabled={isDisabled}
      />
    </Box>
  );
}
