import {
  ReactNode,
  MouseEvent,
  ChangeEvent,
  useRef,
  useState,
  useEffect,
} from "react";
import {
  Grid,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
} from "@mui/material";
import IsLeakedTag from "../components/IsLeakedTag";
import { Tooltip } from "../../components/ui";
import { Dataset } from "../../types/Dataset";

interface DataTypeCardProps {
  title: ReactNode;
  type: string;
  datasets: Dataset[];
  someDatasetsSelected?: boolean;
  onMove?: (e: MouseEvent<HTMLButtonElement>, type: string) => void;
  onChange?: (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    type: string
  ) => void;
  isChecked?: (datasetId: string) => boolean;
  isLeaked?: boolean;
}

interface DatasetItemProps {
  dataset: Dataset;
  isLeaked?: boolean;
}

const StyledCard = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, 0.1)",
  borderRadius: 8,
  backgroundColor: theme.palette.common.white,
  width: "100%",
  height: "100%",
}));

const CardHeader = styled(Box)(() => ({
  backgroundColor: "rgba(4, 61, 93, 0.04)",
  padding: "14px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: "25px",
  fontWeight: "700",
  color: theme.palette.twilight.main,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  color: theme.palette.twilight.main,
  height: 24,
  padding: 0,
  minWidth: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginRight: "1em",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: theme.palette.twilight.main,
  width: "100%",
}));

const CardBody = styled(Box)(() => ({
  alignItems: "flex-start",
  justifyContent: "flex-start",
}));

const Item = styled(Box)(() => ({
  padding: "20px 20px 0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  "&:last-child": {
    paddingBottom: "20px",
  },
}));

const MoveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.twilight.main,
  color: theme.palette.common.white,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: theme.palette.twilight.main,
  },
}));

const DatasetItem = ({ dataset, isLeaked }: DatasetItemProps) => {
  const titleRef = useRef<HTMLParagraphElement>(null);
  const maskTitleRef = useRef<HTMLParagraphElement>(null);
  const [disableHoverListener, setDisableHoverListener] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const currentSize = titleRef.current.getBoundingClientRect().width;
      const size = maskTitleRef.current.getBoundingClientRect().width;

      setDisableHoverListener(size < currentSize);
    };

    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const insertSoftHyphens = (text: string, interval = 5) => {
    return text.split("").reduce((acc, char, index) => {
      return acc + char + ((index + 1) % interval === 0 ? "\u00AD" : "");
    }, "");
  };

  return (
    <Box>
      <Tooltip
        title={insertSoftHyphens(dataset.name)}
        zIndex={10000}
        placement="top-start"
        stylesTooltip={{
          maxWidth: 250,
          whiteSpace: "normal",
          overflowWrap: "break-word",
          wordBreak: "break-word",
          hyphens: "auto",
        }}
        disableHoverListener={disableHoverListener}
      >
        <Title ref={titleRef}>{dataset.name}</Title>
      </Tooltip>
      <Box sx={{ position: "absolute", opacity: 0, zIndex: -1 }}>
        <Title ref={maskTitleRef}>{dataset.name}</Title>
      </Box>
      {isLeaked && (
        <IsLeakedTag dataset={dataset} sx={{ width: "fit-content" }} />
      )}
      <Label>
        {`${dataset.groups_count} ${
          dataset.groups_count === 1 ? "Group" : "Groups"
        }`}
      </Label>
    </Box>
  );
};

export const DataTypeCard = ({
  title,
  type,
  datasets,
  someDatasetsSelected,
  onMove,
  onChange,
  isChecked,
  isLeaked = false,
}: DataTypeCardProps) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <StyledCard>
        <CardHeader>
          <HeaderTitle>{title}</HeaderTitle>
          {someDatasetsSelected && (
            <MoveButton
              startIcon={
                <img
                  src={require("src/assets/images/move_white.png")}
                  style={{ width: 16, height: 16 }}
                />
              }
              onClick={(e: MouseEvent<HTMLButtonElement>) => onMove?.(e, type)}
            >
              Move
            </MoveButton>
          )}
        </CardHeader>
        <CardBody>
          {!datasets.length && (
            <Item>
              <Title>No datasets found.</Title>
            </Item>
          )}
          {datasets.map((dataset) => (
            <Item key={"nonsensitive-item-" + dataset.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ padding: 0, marginRight: "10px" }}
                    checked={!!isChecked?.(dataset.id)}
                    color="secondary"
                    name="remember me"
                    onChange={(e) => onChange(e, dataset.id, type)}
                  />
                }
                sx={{
                  alignItems: "flex-start",
                  marginRight: 0,
                  width: "100%",
                  "& .MuiFormControlLabel-label": { width: "100%" },
                }}
                label={<DatasetItem dataset={dataset} isLeaked={isLeaked} />}
              />
            </Item>
          ))}
        </CardBody>
      </StyledCard>
    </Grid>
  );
};
