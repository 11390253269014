import { AnyAction } from "redux";
import GroupsService from "src/modules/groups/groupsService";
import Errors from "src/modules/shared/error/errors";

const GroupsViewActions: AnyAction = {
  getGroupDetails: (group_id: string) => async () => {
    try {
      const res = await GroupsService.getGroupDetails(group_id);
      return res;
    } catch (error) {
      Errors.handle(error);
    }
  },

  shareScreeningListItemToGroup:
    (screening_list_id: string, data: { group_id: string }) => async () => {
      try {
        const res = await GroupsService.shareScreeningListItemToGroup(
          screening_list_id,
          data
        );
        return res;
      } catch (error) {
        console.log(error);

        // Errors.handle(error);
      }
    },

  removeScreeningListItemFromGroup:
    (group_id: string, data: { screening_lists: string[] }) => async () => {
      try {
        const res = await GroupsService.removeScreeningListItemFromGroup(
          group_id,
          data
        );
        return res;
      } catch (error) {
        console.log(error);

        // Errors.handle(error);
      }
    },
  type: undefined,
};

export default GroupsViewActions;
