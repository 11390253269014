import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import { snackbar } from "src/view/toaster";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "src/view/components/Carousel";
import image1 from "src/assets/images/new_features/search_history_1.png";
import image2 from "src/assets/images/new_features/search_history_2.png";
import image3 from "src/assets/images/new_features/search_history_3.png";
import image4 from "src/assets/images/new_features/search_history_4.png";
import image5 from "src/assets/images/new_features/search_history_5.png";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 18,
  fontWeight: "700",
  color: Colors.twilight,
  paddingBottom: 8,
}));

const StyledShareButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: Colors.secondary2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "500",
  width: 225,
  height: 40,
  padding: 15,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.secondary,
  },
}));

interface Props {
  open: boolean;
  title?: string;
  onClose?: () => void;
  onAgree?: () => void;
  onEnableFeature: () => Promise<void>;
  description?: string;
  readonly?: boolean;
}

const NewFeaturesDialog = ({
  open,
  title,
  onClose,
  onAgree,
  onEnableFeature,
}: Props) => {
  const [isEnabling, setIsEnabling] = useState(false);

  const handleEnableClick = async () => {
    setIsEnabling(true);
    try {
      await onEnableFeature();
      onAgree();
    } catch (error) {
      console.error("Error enabling feature:", error);
      snackbar.error("Couldn't enable the feature. Please try again.");
    } finally {
      setIsEnabling(false);
    }
  };
  return (
    <Dialog
      open={open}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
    >
      <Box
        sx={{
          width: "750px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "0px 0px",
        }}
      >
        <Box>
          <StyledDialogTitle id="scroll-dialog">{title}</StyledDialogTitle>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingRight: "10px",
            paddingTop: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              marginLeft: 1,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        dividers={false}
        sx={{
          minHeight: 300,
        }}
      >
        <Carousel images={[image1, image2, image3, image4, image5]} />
        <Typography
          variant="h5"
          sx={{
            paddingTop: "24",
          }}
        >
          Recent Searches
        </Typography>
        <Typography>
          Recent Searches feature allows you to track your recent searches and
          easily run them again.
        </Typography>
        <Typography>
          You can enable or disable this feature at any time.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end", padding: 1 }}>
        <StyledShareButton
          onClick={onClose}
          variant="outlined"
          sx={{
            border: "none",
            width: 120,
            background: "white",
            color: "#122945",
            "&:hover": {
              background: "#eeeeee",
            },
          }}
        >
          Skip for now
        </StyledShareButton>
        <StyledShareButton onClick={handleEnableClick} disabled={isEnabling}>
          {isEnabling ? "Enabling..." : "Click to Enable Recent Searches"}
        </StyledShareButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewFeaturesDialog;
