import { useRef, useState, useMemo, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { DatasetHitsHeader } from "./DatasetHitsHeader";
import { DataIndex, MappedHit } from "../../../../types/Search";
import { getTypeLabelForIndex } from "../../../../modules/api/search/SearchProvider";
import SearchResultCard from "../SearchResultCard/SearchResultCard";
import { createDatasetHitsProvider } from "./DatasetHitsContext";

interface DatasetHitsProps {
  datasetId: string;
  hits: MappedHit[];
}

const StyledHeader = styled(Box)({
  position: "sticky",
  zIndex: 2,
  top: 0,
});

const StyledCard = styled(Box)(() => ({
  backgroundColor: "white",
  borderRadius: 4,
  marginBottom: 12,
  height: "auto",
}));

const ResultCardsContainer = styled(Box)(() => ({
  padding: "8px",
  zIndex: 1,
  position: "relative",
}));

export const DatasetHits = ({ datasetId, hits }: DatasetHitsProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const [showDatasetResults, setShowDatasetResults] = useState<
    Record<string, boolean>
  >({});

  const handleHideResults = (type: string) => () => {
    const currentVisibleState = showDatasetResults[type] ?? true;
    setShowDatasetResults({
      ...showDatasetResults,
      [type]: !currentVisibleState,
    });
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
    }
  };

  const datasetHitsGroupedByType = useMemo(() => {
    const groupedDatasets: Record<
      string,
      { source: MappedHit[]; modeled: MappedHit[] }
    > = {};

    hits.forEach((hit) => {
      const type = getTypeLabelForIndex(hit);

      if (!groupedDatasets[type]) {
        groupedDatasets[type] = { source: [], modeled: [] };
      }

      groupedDatasets[type][
        hit.dataIndex === DataIndex.modeled ? "modeled" : "source"
      ].push(hit);
    });

    return Object.entries(groupedDatasets);
  }, [hits, datasetId]);

  const onResultsVisibility = (type: string) => () => {
    const currentVisibleState = showDatasetResults[type] ?? true;
    setShowDatasetResults({
      ...showDatasetResults,
      [type]: !currentVisibleState,
    });
  };

  const DatasetHitsProvider = useMemo(
    () => createDatasetHitsProvider(datasetId),
    [datasetId]
  );

  return (
    <DatasetHitsProvider>
      {datasetHitsGroupedByType.map(([type, hits]) => (
        <Fragment key={type}>
          {!!hits.source.length && (
            <StyledCard className="search_result_card_item">
              <StyledHeader>
                <DatasetHitsHeader
                  type={type}
                  datasetId={datasetId}
                  onClick={handleHideResults(type)}
                  onResultsVisibility={onResultsVisibility(type)}
                />
              </StyledHeader>
              {(showDatasetResults[type] ?? true) && (
                <ResultCardsContainer ref={containerRef}>
                  {hits.source.map((hit, index) => (
                    <SearchResultCard
                      key={hit.id}
                      hit={hit}
                      datasetId={datasetId}
                      isLastEntity={hits.source.length - 1 === index}
                    />
                  ))}
                </ResultCardsContainer>
              )}
            </StyledCard>
          )}
          {(showDatasetResults[type] ?? true) && (
            <>
              {hits.modeled.map((hit) => (
                <SearchResultCard
                  withoutWrapper
                  key={hit.id}
                  hit={hit}
                  datasetId={datasetId}
                />
              ))}
            </>
          )}
        </Fragment>
      ))}
    </DatasetHitsProvider>
  );
};
