import React, { ReactNode, useState, useEffect, useMemo } from "react";
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  styled,
} from "@mui/material";

type TabProps = MuiTabProps;

interface TabItem {
  key: string;
  label?: ReactNode;
  content?: ReactNode;
  customTabElement?: ReactNode;
  tabProps?: TabProps;
}

interface TabsProps extends Omit<MuiTabsProps, "children"> {
  items: TabItem[];
  commonTabProps?: TabProps;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
}

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "1px",
    backgroundColor: theme.palette.grey[400],
    zIndex: 1,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.main,
    bottom: 0,
    height: 2,
    zIndex: 2,
    position: "absolute",
  },
}));

const StyledTab = styled(MuiTab)(({ theme }) => ({
  fontSize: 14,
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
  fontWeight: "500",
  lineHeight: "18px",
  color: theme.palette.silver.lighter,
  "&:hover": {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.ocean.light,
  },
}));

const Tab = ({ ...props }: TabProps) => <StyledTab {...props} />;

const Tabs = ({
  items,
  onChange: propOnChange,
  value: propValue,
  commonTabProps,
  leftAdornment,
  rightAdornment,
  ...props
}: TabsProps) => {
  const [value, setValue] = useState(propValue ?? 0);

  useEffect(() => setValue(propValue ?? value), [propValue]);

  const onChange = (event: React.SyntheticEvent, newIndex: number) => {
    setValue(newIndex);
    propOnChange?.(event, newIndex);
  };

  const tabContent = useMemo(() => {
    return items[value]?.content;
  }, [items, value]);

  return (
    <>
      <StyledTabs onChange={onChange} value={value} {...props}>
        {leftAdornment}
        {items.map(
          (item) =>
            item.customTabElement ?? (
              <Tab
                key={item.key}
                label={item.label}
                {...{ ...commonTabProps, ...item.tabProps }}
              />
            )
        )}
        {rightAdornment}
      </StyledTabs>
      {tabContent}
    </>
  );
};

export const CustomTabs = {
  Tab,
  Tabs,
};
