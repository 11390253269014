import { Paginated } from "src/types/Shared";

export interface Dataset {
  file_name?: string;
  id?: string;
  name?: string;
  description?: string;
  bucket_name?: string;
  bucket_prefix?: string;
  dataset_type?: DatasetType;
  sensitivity?: SensitivityValues | SensitivityString;
  was_ocrd?: boolean;
  update_schedule?: UpdateSchedule;
  is_modeled?: boolean;
  model_name?: string;
  doc_types?: Array<DocumentType>;
  record_count?: number;
  doc_count?: number;
  created?: string;
  updated?: string;
  groups_count?: number;
  draft?: boolean;
  is_leaked?: boolean;
}

export enum TaskStatusFiltersKeys {
  IN_QUEUE = "In Task Queue",
  PROCESSING = "In Progress",
  COMPLETED = "Succeeded",
  FAILED = "Failed",
  // other possible status values from the API
  // IN_OCR = "In OCR",
  // RUNNING_MAPPING = "Running Mapping",
  // MERGING_PARTIALS = "Merging Partials",
  // WAITING = "Waiting",
  // WRITING_BACKUP = "Writing Backup",
  // LOGSTASH_INGESTING = "Logstash Ingesting",
}

export enum TaskStatusFiltersLabels {
  IN_QUEUE = "In Queue",
  PROCESSING = "Processing",
  COMPLETED = "Completed",
  FAILED = "Failed",
}

export interface DatasetIndexingTask {
  bucket: string;
  error_message: string | null;
  file_key: string;
  records: number;
  task_id: string;
  task_status: TaskStatusFiltersKeys;
}

export interface DatasetIndexingTasksReturn
  extends Paginated<DatasetIndexingTask[]> {
  total_count: number;
  status_counts: Record<TaskStatusFiltersKeys, number>;
}

export interface RecordCount {
  count: number;
  doc_type_total_count: number;
  doc_type_counts: {
    [key: string]: number;
  };
  doc_type_file_counts: {
    [key: string]: number;
  };
  schema_type_total_count: number;
  schema_type_counts: {
    [key: string]: number;
  };
}
export interface DatasetSensitivityUpdateRequest {
  id: string;
  data: { sensitivity: SensitivityValues | SensitivityString };
}

export enum DocTypes {
  TXT = "txt",
  HTM = "htm",
  HTML = "html",
  SHTML = "shtml",
  JHTML = "jhtml",
  PDF = "pdf",
  EML = "eml",
  MSG = "msg",
  JPEG = "jpeg",
  JPG = "jpg",
  PNG = "png",
  DOC = "doc",
  DOCX = "docx",
  CSV = "csv",
  XLS = "xls",
  XLSX = "xlsx",
  JSON = "json",
  JSONL = "jsonl",
  PARQUET = "parquet",
  PPTX = "pptx",
  PPT = "ppt",
  HEIC = "heic",
}

export enum DatasetType {
  AIRCRAFT_REG = 0,
  BIO_DATA = 1,
  COMM_REC = 2,
  CORP_REG = 3,
  COUNTRY_GAZETTE = 4,
  FIN_DATA = 5,
  INVEST_DATA = 6,
  JUDICIAL_REG = 7,
  MINING_REG = 8,
  PORT_REC = 9,
  PROC_REG = 10,
  REAL_ESTATE_REG = 11,
  RESOURCE_CON_REG = 12,
  SCR_LIST = 13,
  SHIP_REG = 14,
  TRADE_DATA = 15,
  TRAVEL_REC = 16,
  VEHICLE_REG = 17,
  WEBSITE_SNAPSHOT = 18,
}

export enum Sensitivity {
  NONSENSITIVE = 0,
  PRIVILEGED = 1,
  SENSITIVE = 2,
  RESTRICTED = 3,
}

export enum SensitivityValues {
  NONSENSITIVE = "NONSENSITIVE",
  PRIVILEGED = "PRIVILEGED",
  SENSITIVE = "SENSITIVE",
  RESTRICTED = "RESTRICTED",
}

export enum UpdateSchedule {
  NEVER = 0,
  QUARTERLY = 1,
  MONTHLY = 2,
  WEEKLY = 3,
}

export enum SensitivityString {
  NONSENSITIVE = "Nonsensitive",
  PRIVILEGED = "Privileged",
  SENSITIVE = "Sensitive",
  RESTRICTED = "Restricted",
}
