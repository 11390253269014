import { merge } from "lodash";
import { AuthToken } from "../auth/authToken";
import { accessTokenExpired } from "../../utils/response";

const prepURL = (path: string | URL): URL => {
  let newURL = path;
  const isFullURL = (url: string | URL) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  // check for possible external URLs, default to own API otherwise
  if (!isFullURL(path)) {
    newURL = process.env.REACT_APP_BACKEND_URL + path;
  }

  return newURL as URL;
};

export const fetchApi = async (
  path: string | URL,
  initOptions: RequestInit = {},
  skipDefaults = false,
  accessTokenRefreshed = false
): Promise<any> => {
  try {
    const url = prepURL(path);
    const csrfToken = AuthToken.getStoredCSRFToken();
    const defaultInitOptions = {
      headers: {
        "Content-Type":
          !(initOptions.body instanceof FormData) && "application/json",
        "X-CSRFToken": csrfToken,
      },
      credentials: csrfToken ? "include" : undefined,
      signal: AbortSignal.timeout(15000), // timeout: 15000,
    };

    const res = await fetch(
      url,
      merge(skipDefaults ? {} : defaultInitOptions, initOptions)
    );

    if (res.status === 403) {
      // wut dis anyway?
      if (url.pathname.includes("add_to_group")) return res;

      window.dispatchEvent(
        new CustomEvent("APIErrorOccurred", {
          detail: { message: "Unauthorized", code: res.status },
        })
      );
      return res;
    }

    if (res.status == 401) {
      if (accessTokenRefreshed || !AuthToken.getShouldRemember()) {
        AuthToken.logout(true);
        return;
      } else {
        const accessTokenIsExpired = await accessTokenExpired(res);
        if (accessTokenIsExpired) {
          if (!AuthToken.getShouldRemember()) {
            AuthToken.logout(true);
            return;
          }

          const tokenRefreshed = await AuthToken.refreshAccessToken();
          if (!tokenRefreshed) {
            AuthToken.logout(true);
          } else {
            await AuthToken.updateCSRFToken();

            return fetchApi(path, initOptions, skipDefaults, true);
          }
        }
      }
    }
    if (res.status === 400 || res.status === 404) {
      return res;
    }

    if (res.status >= 500) {
      window.dispatchEvent(
        new CustomEvent("APIErrorOccurred", {
          detail: { message: "Something went wrong", code: res.status },
        })
      );
      return res;
    }
    return res;
  } catch (err: any) {
    if (typeof err === "object" && err?.message?.includes("Failed to fetch")) {
      window.dispatchEvent(
        new CustomEvent("APIErrorOccurred", {
          detail: {
            message:
              "Something went wrong, please check your network or contact a site administrator",
          },
        })
      );
    }
  }
};
