import React, { useMemo } from "react";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  TabProps,
  styled,
  Pagination,
  Typography,
} from "@mui/material";
import * as xlsx from "@sheet/core";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import {
  StyledContainer,
  Title,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import { useDocument } from "src/hooks/useDocument";
import { Colors } from "src/view/constants/colors";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { processHighlightContent } from "src/modules/api/search/SearchProvider";
import { getArrayWithPlainHighlights } from "src/utils/get-highlight-text";

const PreviewTabs = styled(Tabs)(() => ({
  borderTop: "solid",
  borderTopWidth: 1,
  borderTopColor: Colors.mist,
  "& .MuiTabs-indicator": {
    backgroundColor: Colors.primary,
  },
}));

const PreviewTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontFamily: "Inter",
  "&:hover": {
    color: Colors.primary,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: Colors.primary,
    fontWeight: 700,
  },
}));

const PreviewFrame = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "90%",
  backgroundColor: Colors.bgGray1,
  border: "solid",
  borderRadius: theme.spacing(1),
  borderWidth: 1,
  borderColor: Colors.mist,
}));

const PreviewHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const PreviewContent = styled(Box)(() => ({
  backgroundColor: "white",
  height: "calc(100% - 76px)",
  overflow: "auto",
}));

const PreviewFooter = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const ContentIsBroken = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: "center",
  padding: "48px 24px",
  fontSize: "22px",
}));

const StyledPagination = styled(Pagination)({
  marginTop: "8px",
});

interface XLSPreviewProps {
  data?: { id: string; content: Record<string, any>[] }[];
  loading: boolean;
  previewDocInfo: PreviewDocProps;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

type Sheet = {
  rowCount: number;
  rows: xlsx.WorkSheet;
};

type Book = Record<string, Sheet>;

const XLSPreview = ({
  loading: loadingData,
  data,
  previewDocInfo,
  handleClosePreview,
}: XLSPreviewProps) => {
  const {
    documentURL,
    isLoading: loadingXlsx,
    error,
  } = useDocument(previewDocInfo);

  const [activeSheet, setActiveSheet] = React.useState<number>(0);
  const [book, setBook] = React.useState<Book>({});
  const [loading, setLoading] = React.useState<boolean>(
    loadingData || loadingXlsx
  );
  const [pageIndexes, setPageIndexes] = React.useState<Record<number, number>>(
    {}
  );

  const handleActiveSheet = (_: React.SyntheticEvent, index: number) => {
    setActiveSheet(index);
  };

  React.useEffect(() => {
    (async () => {
      if (documentURL) {
        const f = await fetch(documentURL);
        const ab = await f.arrayBuffer();
        const wb = xlsx.read(ab);

        const bookData: Book = {};

        wb.SheetNames.forEach((name: string) => {
          const range = xlsx.utils.decode_range(wb.Sheets[name]["!ref"]);
          const totalRows = range.e.r + 1 || 0;

          bookData[name] = {
            rowCount: totalRows,
            rows: wb.Sheets[name],
          };
        });

        setBook(bookData);
        setLoading(false);
      }
    })();
  }, [data, documentURL, previewDocInfo]);

  const renderPage = (sheetIndex: number, page: number) => {
    const sheetName = Object.keys(book)[sheetIndex];
    const sheet = book[sheetName]?.rows;

    if (!sheet) return null;

    const startRow = page * 50;
    const endRow = Math.min(startRow + 50, book[sheetName].rowCount);

    const range = {
      s: { r: startRow, c: 0 },
      e: { r: endRow - 1, c: sheet["!ref"].split(":")[1].charCodeAt(0) - 65 },
    };
    const partialSheet = {
      ...sheet,
      "!ref": xlsx.utils.encode_range(range),
    };

    const html = processHighlightContent({
      data: xlsx.utils.sheet_to_html(partialSheet),
      highlight:
        getArrayWithPlainHighlights(
          previewDocInfo.highlight?.content || []
        )[0] ?? [],
      docType: previewDocInfo.type,
    });

    if (html) {
      return html
        .replaceAll("border-color:black", `border-color:${Colors.mist}`)
        .replaceAll("border-bottom-color: transparent", "")
        .replaceAll("border-right-color: transparent", "");
    }

    return null;
  };

  const content = useMemo(
    () => renderPage(activeSheet, pageIndexes[activeSheet] || 0),
    [book, pageIndexes, activeSheet]
  );

  const handlePageChange = (sheetIndex: number, newPage: number) => {
    setPageIndexes((prev) => ({
      ...prev,
      [sheetIndex]: newPage,
    }));
  };

  return (
    <StyledContainer
      sx={{
        width: "unset",
        height: "100%",
        px: 4,
        pt: 4,
        overflow: "hidden",
      }}
    >
      <PreviewFrame>
        <PreviewHeader>
          <Title>{previewDocInfo?.dataset?.["fileName"]}</Title>
        </PreviewHeader>
        <PreviewContent>
          {!error && loading && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress disableShrink />
            </Box>
          )}
          {error && (
            <HighlightActionMenu onSearchInTab={handleClosePreview}>
              <ContentIsBroken>Content is corrupted.</ContentIsBroken>
            </HighlightActionMenu>
          )}
          {Object.keys(book).length > 0 && (
            <HighlightActionMenu onSearchInTab={handleClosePreview}>
              {content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              ) : (
                <ContentIsBroken>Content is corrupted.</ContentIsBroken>
              )}
            </HighlightActionMenu>
          )}
        </PreviewContent>
        <PreviewFooter>
          <PreviewTabs value={activeSheet} onChange={handleActiveSheet}>
            {Object.keys(book).map((sheetName, i) => (
              <PreviewTab key={i} value={i} label={sheetName} />
            ))}
          </PreviewTabs>
        </PreviewFooter>
      </PreviewFrame>
      {book[Object.keys(book)[activeSheet]]?.rowCount > 0 && (
        <StyledPagination
          count={Math.ceil(book[Object.keys(book)[activeSheet]]?.rowCount / 50)}
          page={(pageIndexes[activeSheet] || 0) + 1}
          onChange={(_, page) => handlePageChange(activeSheet, page - 1)}
        />
      )}
    </StyledContainer>
  );
};

export default XLSPreview;
