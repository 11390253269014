import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  styled,
  Box,
  Button,
  CircularProgress,
  Link as MuiLink,
} from "@mui/material";
import { useUser, useUserAuth } from "src/modules/api/auth";
import { AuthToken } from "src/modules/auth/authToken";
import UserExpiredModal from "../components/UserExpiredModal";
import {
  Layout,
  FormTitle,
  FormTemplate,
  StyledRouterLink,
  StyledLogo,
  StyledLink,
} from "./components";
import AgreementDialog from "../layout/AgreementDialog";
import { CustomInput, Checkbox } from "../../components/ui";
import { email as emailRegex } from "src/utils/regex";
import { snackbar } from "src/view/toaster";
import logo from "src/assets/logo.svg";

const FormContainer = styled(Box)(() => ({
  width: "100%",
  maxWidth: "510px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const FormElement = styled("form")(() => ({
  width: "100%",
  marginTop: "24px",
}));

const StyledLoginButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 45,
  textTransform: "capitalize",
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: 16,
  backgroundColor: theme.palette.mist.light,
  color: theme.palette.primary.main,
  borderRadius: 6,
  marginTop: 24,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& .MuiCircularProgress-svg": {
      color: theme.palette.common.white,
    },
  },
  "& > span.MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 0,
    position: "absolute",
    left: "35%",
  },
}));

const LinksContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 12,
  height: 15,
  marginTop: 24,
});

const ForgotPasswordLink = styled(StyledLink)(() => ({
  lineHeight: "15.6px",
}));

const StyledSeparationDot = styled(Box)(({ theme }) => ({
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: theme.palette.grey[400],
}));

const StyledWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledExternalLink = styled(MuiLink)(() => ({
  textDecoration: "none",
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 20,
  height: 20,
}));

export default function LoginPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useUser();
  const [isEULAOpen, setIsEULAOpen] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const toggleEulaOpen = () => setIsEULAOpen(!isEULAOpen);
  const [resetPassLinkSend, setResetPassLinkSend] = useState<boolean | null>(
    null
  );
  const { loginUser } = useUserAuth();
  const { mutateAsync: login, isLoading, error } = loginUser;
  const { control, formState, handleSubmit, setError } = useForm({
    defaultValues: {
      email: "",
      password: "",
      shouldRemember: false,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const errors = formState.errors;

  useEffect(() => {
    if (location.state && location.state["sentLink"] === true) {
      setResetPassLinkSend(true);
    }
  }, [location]);

  useEffect(() => {
    if (resetPassLinkSend) {
      snackbar.success("Password Reset Link Emailed");
    }
  }, [resetPassLinkSend]);

  useEffect(() => {
    if (error) {
      setError("email", {
        type: "manual",
        message: "",
      });
      setError("password", {
        type: "manual",
        message: "",
      });
    }
  }, [error, setError]);

  const onSubmit = async ({
    email,
    password,
    shouldRemember,
  }: {
    email: string;
    password: string;
    shouldRemember: boolean;
  }) => {
    try {
      await login({ email, password, shouldRemember });
      setIsAuthenticated(true);
      AuthToken.setShouldRemember(shouldRemember);
      navigate("/callback", {
        replace: true,
        state: { requestedPage: location.state?.requestedPage },
      });
    } catch (error: any) {
      const [statusCode] = error?.message?.split("|") ?? [];

      const status = Number(statusCode);
      if (!isNaN(status)) {
        setIsExpired(status === 403);
      }

      console.error(error);
    }
  };

  return (
    <Layout>
      <UserExpiredModal
        closable
        isExpired={isExpired}
        onClose={() => setIsExpired(false)}
      />
      <FormTemplate>
        <FormContainer>
          <StyledLogo src={logo} alt="SEAMLESS HORIZONS" />
          <FormTitle>Our all-in-one search and discovery platform.</FormTitle>
          <FormElement onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: {
                  value: emailRegex,
                  message:
                    "Please enter a valid email address (i.e. name@example.com)",
                },
              }}
              render={({ field }) => (
                <Box>
                  <CustomInput.Input
                    {...field}
                    containerStyle={{ marginBottom: 16 }}
                    id="email"
                    placeholder="Email"
                    type="email"
                    invalid={!!errors.email}
                  />
                  {errors.email && (
                    <CustomInput.InputMessage error>
                      {errors.email.message}
                    </CustomInput.InputMessage>
                  )}
                </Box>
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{ required: true }}
              render={({ field }) => (
                <Box>
                  <CustomInput.Input
                    {...field}
                    containerStyle={{ marginBottom: 16 }}
                    id="password"
                    placeholder="Password"
                    type="password"
                    invalid={!!errors.password}
                  />
                  {errors.password && (
                    <CustomInput.InputMessage error>
                      {errors.password.message}
                    </CustomInput.InputMessage>
                  )}
                </Box>
              )}
            />
            <StyledWrapper>
              <Controller
                control={control}
                name="shouldRemember"
                rules={{ required: false }}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    label="Remember Me"
                    id="remember-me"
                    checked={field.value}
                    inputProps={{ "aria-label": "Remember me" }}
                  />
                )}
              />
              <StyledRouterLink to="/forgot-password">
                <ForgotPasswordLink
                  variant="caption"
                  color="secondary"
                  fontWeight="500"
                >
                  Forgot Password?
                </ForgotPasswordLink>
              </StyledRouterLink>
            </StyledWrapper>
            <StyledLoginButton
              type="submit"
              variant="contained"
              startIcon={
                isLoading ? <StyledCircularProgress size="small" /> : null
              }
            >
              Log in
            </StyledLoginButton>
          </FormElement>
          <LinksContainer>
            <StyledRouterLink to="/request-access">
              <StyledLink variant="caption" color="primary" fontWeight="400">
                Request Access
              </StyledLink>
            </StyledRouterLink>
            <StyledSeparationDot />
            <StyledLink
              variant="caption"
              color="primary"
              fontWeight="400"
              onClick={toggleEulaOpen}
            >
              EULA
            </StyledLink>
            <StyledSeparationDot />
            <StyledExternalLink
              href="https://c4ads.org/about-us/"
              target="_blank"
            >
              <StyledLink variant="caption" color="primary" fontWeight="400">
                About
              </StyledLink>
            </StyledExternalLink>
            <StyledSeparationDot />
            <StyledRouterLink to="/help">
              <StyledLink variant="caption" color="primary" fontWeight="400">
                Help
              </StyledLink>
            </StyledRouterLink>
          </LinksContainer>
        </FormContainer>
      </FormTemplate>
      {isEULAOpen && (
        <AgreementDialog
          open={isEULAOpen}
          title="EULA Agreement"
          readonly
          onClose={toggleEulaOpen}
        />
      )}
    </Layout>
  );
}
