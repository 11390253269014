import { forwardRef, CSSProperties, useState, ChangeEvent } from "react";
import {
  TextField,
  StandardTextFieldProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { merge } from "lodash";
import theme from "src/theme";

export interface ExtendedCSSProperties extends CSSProperties {
  "&::placeholder"?: CSSProperties;
}

export interface InputProps extends StandardTextFieldProps {
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  containerStyle?: CSSProperties;
  inputStyle?: ExtendedCSSProperties;
  invalid?: boolean;
  success?: boolean;
}

export interface InputDescriptionProps {
  error?: boolean;
}

interface TextWrapperProps {
  invalid?: boolean;
  focused?: boolean;
  filled?: boolean;
  success?: boolean;
  disabled?: boolean;
}

interface InputLabelProps extends TypographyProps {
  disabled?: boolean;
  htmlFor?: string;
}

const StyledTextFieldWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    !["disabled", "invalid", "focused", "filled", "success"].includes(
      prop.toString()
    ),
})<TextWrapperProps>(
  ({ disabled, invalid, focused, filled, success, theme }) => {
    let borderColor = theme.palette.grey["400"];
    if (disabled) {
      borderColor = theme.palette.grey["300"];
    } else if (invalid) {
      borderColor = theme.palette.secondary.main;
    } else if (success) {
      borderColor = theme.palette.success.main;
    } else if (focused || filled) {
      borderColor = theme.palette.primary.main;
    }

    return {
      width: "100%",
      background: theme.palette.common.white,
      borderRadius: 8,
      marginBottom: 8,
      padding: 10,
      display: "flex",
      alignItems: "center",
      border: "1px solid",
      borderColor,
      "& .MuiInputBase-input": {
        fontSize: "14px",
        height: "22px",
        lineHeight: "22px",
      },
    };
  }
);

export const StyledTextFieldLabel = styled(Typography)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "400",
    color: disabled ? theme.palette.grey["600"] : theme.palette.neutral.main,
    marginBottom: "8px",
  })
);

const StyledErrorText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "error",
})<InputDescriptionProps>(({ theme, error }) => ({
  color: error ? theme.palette.error.main : theme.palette.grey["900"],
  fontSize: "10px",
  fontWeight: 400,
  marginTop: -5,
}));

const InputLabel = ({ children, disabled, ...props }: InputLabelProps) => {
  return (
    <StyledTextFieldLabel disabled={disabled} {...props}>
      {children}
    </StyledTextFieldLabel>
  );
};

const InputMessage = ({
  error,
  children,
  ...props
}: TypographyProps & InputDescriptionProps) => (
  <StyledErrorText error={error} {...props}>
    {children}
  </StyledErrorText>
);

const Input = forwardRef(
  (
    {
      prefixIcon,
      suffixIcon,
      containerStyle = {},
      inputStyle = {},
      tabIndex = 0,
      autoFocus = false,
      invalid,
      InputLabelProps,
      InputProps,
      success,
      onChange: propOnChange,
      ...props
    }: InputProps,
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const [currentValue, setCurrentValue] = useState(props.value);

    const onFocus = (e: any) => {
      setFocused(true);
      props.onFocus?.(e);
    };

    const onBlur = (e: any) => {
      setFocused(false);
      props.onBlur?.(e);
    };

    const onChange = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      propOnChange?.(e);
      setCurrentValue(e.target.value);
    };

    return (
      <StyledTextFieldWrapper
        style={containerStyle}
        invalid={invalid}
        focused={focused}
        success={success}
        disabled={props.disabled}
        filled={!!currentValue}
      >
        {prefixIcon}
        <TextField
          inputRef={ref}
          fullWidth
          onChange={onChange}
          sx={{ border: "none", background: "none" }}
          InputLabelProps={merge(
            {
              style: {
                fontSize: "14px",
                fontWeight: 400,
                zIndex: 0,
              },
            },
            InputLabelProps
          )}
          InputProps={merge(
            {
              // disableUnderline: true,
              sx: {
                background: "none",
                color: theme.palette.primary.main,
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "26px",
                outline: "unset",
                border: "none",
                "& input": {
                  marginTop: "0px",
                  padding: 0,
                  marginLeft: "10px",
                  ...inputStyle,
                },
                "&:hover": {
                  background: "none",
                },
                "& fieldset": {
                  border: "none",
                },
              },
            },
            InputProps
          )}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          {...props}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {suffixIcon}
      </StyledTextFieldWrapper>
    );
  }
);

export const CustomInput = {
  InputLabel,
  InputMessage,
  Input,
};
