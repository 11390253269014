import { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Chip, IconButton, Switch, Box } from "@mui/material";
import { useToggle } from "usehooks-ts";

import theme from "src/theme";

import {
  Group,
  GroupDatasetPermissionsFields,
  GroupFeatures,
} from "src/types/UserGroup";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { ConfirmDialog } from "src/components/ui";

import { SensitivityString } from "src/types/Dataset";

import DatasetTable from "../components/DatasetTable";
import DatasetItemFilter from "../components/DatasetItemFilter";
import { StyledArrowIcon } from "../StyledComponents";

const StyledContainer = styled(Box)(() => ({
  width: "100%",

  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  padding: "16px 0",

  "&:last-child": {
    borderBottom: "none",
    marginBottom: "10px",
  },
}));

const StyledRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(() => ({
  display: "flex",
  width: "100%",
  gap: "20px",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 10,
}));

const SensitivityGroupContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledTitleContainer = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledTitle = styled(Box)(() => ({
  fontSize: 14,
  fontWeight: "600",
  color: theme.palette.primary.main,
}));

interface Props {
  sensitivityGroup: SensitivityString;
  handleUpdateDatasets: (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => void;
  data: Group;
  datasets: KnowledgeWiki[];
  handleUpdateFeatures: (checked: boolean, featureName: GroupFeatures) => void;
  isDatasetsUpdating: boolean;
}

const SensitivityGroup = ({
  sensitivityGroup,
  data,
  datasets,
  handleUpdateDatasets,
  handleUpdateFeatures,
  isDatasetsUpdating,
}: Props) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [filterInput, setFilterInput] = useState("");
  const [filtered, setFiltered] = useState(datasets || []);

  const isChecked = useMemo(
    () => data?.sensitivity_levels?.includes(sensitivityGroup),
    [data?.sensitivity_levels, sensitivityGroup]
  );

  const handleToggleChange = (
    e: React.ChangeEvent<HTMLInputElement> | boolean
  ) => {
    const value = typeof e === "boolean" ? e : e.target.checked;
    handleUpdateDatasets(value, "sensitivity_levels", sensitivityGroup);
  };

  const handleFilter = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilterInput(e.target.value);
    if (e.target.value === "") {
      setFiltered(datasets);
    } else {
      const filter = datasets.filter((dataset: KnowledgeWiki) =>
        dataset.name.toLowerCase()?.includes(e.target.value.toLowerCase())
      );
      setFiltered(filter);
    }
  };

  return (
    <StyledContainer>
      <StyledRow open={isOpen}>
        <StyledTitleContainer>
          <IconButton size="small" onClick={toggleIsOpen}>
            <StyledArrowIcon isOpen={isOpen} />
          </IconButton>

          <StyledTitle>{sensitivityGroup}</StyledTitle>
        </StyledTitleContainer>

        {isOpen && (
          <DatasetItemFilter
            filterInput={filterInput}
            handleFilter={handleFilter}
          />
        )}

        <Chip
          size="small"
          label={`${datasets.length} Dataset${
            datasets.length === 1 ? "" : "s"
          }`}
        />

        {!["Sensitive", "Restricted"].includes(sensitivityGroup) && (
          <ConfirmDialog
            title={`Access to all ${sensitivityGroup} Datasets`}
            description={`Do you want all ${sensitivityGroup} datasets added to this group?`}
            onConfirm={() => handleToggleChange(!isChecked)}
            skip={isChecked}
            confirmText="Confirm"
            cancelText="Cancel"
          >
            <Switch
              color="secondary"
              disabled={isDatasetsUpdating}
              checked={isChecked}
              size="small"
            />
          </ConfirmDialog>
        )}
      </StyledRow>

      {isOpen && (
        <SensitivityGroupContainer>
          <DatasetTable
            group={data}
            sensitivity={sensitivityGroup}
            wikis={datasets}
            filteredWikis={filtered}
            handleUpdateDatasets={handleUpdateDatasets}
            handleUpdateFeatures={handleUpdateFeatures}
            shouldDisableToggles={isDatasetsUpdating}
          />
        </SensitivityGroupContainer>
      )}
    </StyledContainer>
  );
};

export default SensitivityGroup;
