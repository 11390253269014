import {
  Box,
  styled,
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from "@mui/material";
import { ReactNode } from "react";

interface DividerProps extends MuiDividerProps {
  startComponent?: ReactNode;
  endComponent?: ReactNode;
}

const StyledDivider = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& hr": {
    flex: "1 1 100%",
    color: theme.palette.grey[400],
  },
  "& span": {
    fontSize: 16,
    fontWeight: 600,
    marginRight: theme.spacing(1),
    fontFamily: "Inter, sans-serif",
    whiteSpace: "nowrap",
    color: theme.palette.primary.main,
  },
}));

export const Divider = ({
  startComponent,
  endComponent,
  ...props
}: DividerProps) => {
  return (
    <StyledDivider>
      {startComponent}
      <MuiDivider {...props} />
      {endComponent}
    </StyledDivider>
  );
};
