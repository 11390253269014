import React from "react";
import { Navigate } from "react-router-dom";
import { AuthToken } from "src/modules/auth/authToken";

type Props = {
  component: React.ComponentType;
  redirectTo?: string;
};

export const PublicPageGuard = ({
  component: Component,
  redirectTo,
}: Props) => {
  const token = AuthToken.getStoredCSRFToken();
  const sessionExp = AuthToken.getSessionExp();

  if (token && sessionExp > Date.now() && redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return <Component />;
};
