type WindowConfig = {
  url: string;
  title: string;
  width: number;
  height: number;
};

export const openCenteredWindow = ({
  url,
  title,
  width,
  height,
}: WindowConfig) => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const left = (screenWidth - width) / 2;
  const top = (screenHeight - height) / 2;

  const features = `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`;

  window.open(url, title, features);
};
