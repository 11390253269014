import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import queryKeys from "src/modules/api/queryKeys";
import { queryClient } from "src";
import { useUserGroups } from "src/modules/api/userGroups";
import { Group } from "src/types/UserGroup";

import { ReactComponent as CrossIcon } from "src/assets/icons/cross-2.svg";

const StyledIconButton = styled(IconButton)(() => ({
  borderRadius: "50%",
  minWidth: "25px",
  padding: "12px",
  color: "#122945",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
}));

const CreateButton = styled(Button)({
  fontWeight: "700",
  padding: "10px 16px",
  borderRadius: 4,
  backgroundColor: "#043D5D",
  color: "#ffffff",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#043D5D99",
  },
  "&:disabled": {
    backgroundColor: "#d8d8d899",
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateGroupDialog = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const {
    createGroup: { mutateAsync: createNewGroup },
  } = useUserGroups();
  const [groupName, setGroupName] = useState("");

  const handleCreateGroup = async () => {
    const data: Group = {
      name: groupName,
      description: null,
      members: [],
      sensitivity_levels: ["Nonsensitive"],
      datasets: [],
      dataset_downloads: [],
      external_apis: [],
      features: [],
      kw_read: [],
      kw_write: [],
      permissions: [],
      screening_lists: [],
    };
    window.dispatchEvent(new CustomEvent("startBackdrop"));
    const res = await createNewGroup(data);
    window.dispatchEvent(new CustomEvent("endBackdrop"));

    if (res?.group_id) {
      queryClient.removeQueries([queryKeys.USERS_GROUP_BY_ID]);
      queryClient.removeQueries([queryKeys.GROUP_ID_USERS]);

      navigate("edit/" + res.group_id);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="600px"
          padding="15px 5px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #CED3D9"
            paddingBottom="15px"
          >
            <Typography fontSize="18px" fontWeight="700" textAlign="center">
              Add Group
            </Typography>

            <StyledIconButton onClick={onClose}>
              <CrossIcon style={{ width: 13, minWidth: 13 }} />
            </StyledIconButton>
          </Box>
          <TextField
            variant="outlined"
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter Group Name"
            sx={{ margin: "15px 0" }}
          />
          <Box display="flex" justifyContent="flex-end">
            <CreateButton
              disabled={groupName === ""}
              onClick={handleCreateGroup}
            >
              Add
            </CreateButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateGroupDialog;
