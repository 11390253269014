import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }

  interface Palette {
    midnight: Palette["primary"];
    red: Palette["primary"];
    skyMist: Palette["primary"];
    mist: Palette["primary"];
    silver?: Palette["primary"];
    ocean: Palette["primary"];
    neutral?: Palette["primary"];
    twilight: Palette["primary"];
    salmon: Palette["primary"];
    highlight: Palette["primary"];
  }

  interface PaletteOptions {
    midnight?: Palette["primary"];
    red?: Palette["primary"];
    mist?: Palette["primary"];
    silver?: Palette["primary"];
    ocean?: Palette["primary"];
    neutral?: Palette["primary"];
    twilight?: Palette["primary"];
    salmon?: Palette["primary"];
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 920,
      lg: 1320,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: "#1D1D1B", // black
      white: "#FFFFFF", // white
    },
    primary: {
      lighter: "#225B7B", // ocean
      light: "#043D5D", // twilight
      main: "#122945", // midnight
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#EF8479", // salmon
      main: "#F75151", // red
      contrastText: "#FFFFFF",
    },
    error: {
      light: "#EF8479", // salmon
      main: "#E50B0B", // red 4.5
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#A0B9D0", // mist
    },
    success: {
      light: "#B2EDD9", // green 0.5
      main: "#3BBC97", // green
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#1D1D1B", // black
      secondary: "#414042", // dark
    },
    grey: {
      50: "#FAFAFA",
      100: "#EDEEF5",
      200: "#F0F1F3",
      300: "#E1E4E8",
      400: "#CED3D9",
      500: "#BBBFC7",
      600: "#9BA5B1",
      700: "#7A8796",
      800: "#536378",
      900: "#122945",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "'Inter'",
      "BlinkMacSystemFont",
      "'Segoe UI'",
      "Roboto",
      "'Helvetica Neue'",
      "Arial",
      "sans-serif",
      "'Apple Color Emoji'",
      "'Segoe UI Emoji'",
      "'Segoe UI Symbol'",
    ].join(","),
    h1: {
      fontFamily: "'Inter' sans-serif",
      fontSize: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "'Inter' sans-serif",
      fontSize: "2.625rem",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "'Inter' sans-serif",
      fontSize: "2rem",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "'Inter' sans-serif",
      fontSize: "1.125rem",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "'Inter' sans-serif",
      fontSize: "1rem",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "'Inter' sans-serif",
      fontSize: ".875rem",
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: "'Inter' sans-serif",
      fontSize: ".75rem",
      fontWeight: 400,
    },
    body1: {
      fontFamily: "'Inter' sans-serif",
      fontSize: "1rem",
      fontWeight: 400,
    },
    button: {
      fontFamily: "'Inter' sans-serif",
      fontWeight: 500,
      textTransform: "none",
    },
    caption: {
      fontSize: ".75rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 700,
          lineHeight: 1,
          ...(ownerState.size === "small" && {
            padding: 8,
            fontSize: 10,
          }),
          ...(ownerState.size === "medium" && {
            padding: 11,
            fontSize: 14,
          }),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          fontSize: "1.125rem",
          fontWeight: 700,
          color: theme.palette.primary.main,
          marginRight: theme.spacing(6), // allow room for close button
          padding: theme.spacing(2, 3, 1, 3),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: () => ({
          padding: theme.spacing(1, 3),
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: () => ({
          padding: theme.spacing(2, 1, 1, 1),
        }),
      },
    },
  },
  zIndex: {
    snackbar: 1505,
  },
});

theme = createTheme(theme, {
  palette: {
    midnight: theme.palette.augmentColor({
      color: {
        main: "#122945",
        lighter: "#12294588",
      },
      name: "midnight",
    }),
    red: theme.palette.augmentColor({
      color: {
        main: "#F75151",
        lighter: "#F7515188",
      },
      name: "red",
    }),
    skyMist: theme.palette.augmentColor({
      color: {
        main: "#C3D0E5",
        lighter: "#F8F9FB",
        light: "#F5F7FA",
      },
      name: "skyMist",
    }),
    mist: theme.palette.augmentColor({
      color: {
        lighter: "#DDE4EC",
        main: "#A0B9D0",
        light: "#E0E8F1",
        dark: "#131521",
      },
      name: "mist",
    }),
    silver: theme.palette.augmentColor({
      color: {
        main: "#E8E8E8",
        lighter: "#536478",
        dark: "#A5B8CE40",
      },
      name: "silver",
    }),
    ocean: theme.palette.augmentColor({
      color: {
        light: "#D1EAFF",
        main: "#225B7B",
        dark: "#5A7290",
      },
      name: "ocean",
    }),
    neutral: theme.palette.augmentColor({
      color: {
        main: "#536478",
        dark: "#3B83EF",
      },
      name: "neutral",
    }),
    twilight: theme.palette.augmentColor({
      color: {
        light: "#40A9FF",
        main: "#043D5D",
        dark: "#144A68",
      },
      name: "twilight",
    }),
    highlight: theme.palette.augmentColor({
      color: {
        main: "#FFD266",
      },
      name: "highlight",
    }),
  },
});

export default theme;
