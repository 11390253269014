import { PropsWithChildren } from "react";
import { styled, Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "4px",
  fontSize: "12px",
  background: theme.palette.mist.light,
  borderRadius: "8px",
  color: theme.palette.primary.main,
}));

export const SelectChip = ({ children }: PropsWithChildren) => (
  <StyledBox>{children}</StyledBox>
);
