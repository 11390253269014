import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useFetch } from "../fetch";
import {
  DataCollection,
  DataCollectionShareProps,
  DataCollectionsResponse,
} from "src/types/DataCatalog";
import { useUser } from "../auth";
import { snackbar } from "src/view/toaster";
import { useNavigate } from "react-router-dom";
import { Features } from "src/types/Auth";
import QueryKeys from "src/modules/api/queryKeys";
import queryKeys from "src/modules/api/queryKeys";
import { queryClient } from "src/index";

export function useDataCollections() {
  const { user } = useUser();
  const navigate = useNavigate();
  const { getApi, postApi, putApi, deleteApi } = useFetch();

  const invalidateDataCollections = () => {
    queryClient.invalidateQueries([queryKeys.DATA_COLLECTIONS]);
  };

  const getDataCollectionsFn = async () => {
    const data = await getApi("data_collection");
    return data;
  };

  const getDataCollections = (
    options: UseQueryOptions<DataCollectionsResponse> = {}
  ) =>
    useQuery<DataCollectionsResponse>({
      enabled: !!user && user.features.includes(Features.DATA_COLLECTION),
      queryFn: getDataCollectionsFn,
      queryKey: [QueryKeys.DATA_COLLECTIONS],
      initialData: { created_by: [], shared_with_groups: [] },
      ...options,
    });

  const getDataCollectionFn = async (id: string) => {
    const data = await getApi(["data_collection", id]);
    return data;
  };

  const getDataCollection = (id: string) =>
    useQuery<DataCollection>({
      enabled:
        !!user &&
        user.features.includes(Features.DATA_COLLECTION) &&
        !!id &&
        id !== "create",
      queryFn: () => getDataCollectionFn(id),
      queryKey: [QueryKeys.DATA_COLLECTION_BY_ID, { id: id }],
    });

  const createDataCollection = useMutation({
    mutationFn: (data: Omit<DataCollection, "id">) => {
      return postApi("data_collection", { body: JSON.stringify(data) });
    },
    onSuccess: async (response) => {
      const data = await response.json();
      snackbar.success("Data collection created");
      invalidateDataCollections();
      navigate(`/data-collections/collection/${data.id}`, { replace: true });
    },
    onError: () => {
      snackbar.error("Error creating data collection.");
    },
  });

  const updateDataCollection = useMutation({
    mutationFn: ({ id, ...data }: DataCollection) => {
      return putApi(["data_collection", id], { body: JSON.stringify(data) });
    },
    onSuccess: () => {
      snackbar.success("Data collection updated.");
      invalidateDataCollections();
    },
    onError: () => {
      snackbar.error("Error updating data collection.");
    },
  });

  const duplicateDataCollection = useMutation({
    mutationFn: ({ id }: DataCollection) => {
      return postApi(["data_collection", id, "duplicate"]);
    },
    onSuccess: () => {
      invalidateDataCollections();
      snackbar.success("Data collection duplicated.");
    },
    onError: () => {
      snackbar.error("Error duplicating data collection");
    },
  });

  const shareDataCollection = useMutation({
    mutationFn: ({ collection, group_ids }: DataCollectionShareProps) => {
      return postApi(
        ["data_collection", collection.id, "add_to_group"],
        {
          body: JSON.stringify({
            group_ids: group_ids,
          }),
        },
        (response) => {
          let errorMsg = "";
          response?.json().then((data: any) => (errorMsg = data?.["message"]));
          return errorMsg || "Error sharing data collection";
        }
      );
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QueryKeys.DATA_COLLECTIONS ||
          (query.queryKey[0] === QueryKeys.DATA_COLLECTION_BY_ID &&
            query.queryKey[1]?.["id"] === variables.collection.id),
      });
      snackbar.success("Data collection shared.");
    },
    onError: (error: Error) => {
      const [status, message] = (error?.message ?? "").split("|");
      if (Number(status) === 403) {
        return snackbar.error(
          "Group does not have access to one or more datasets in this collection."
        );
      }
      snackbar.error(message || "Error sharing data collection");
    },
  });

  const deleteDataCollection = useMutation({
    mutationFn: (id: string) => {
      return deleteApi(["data_collection", id]);
    },
    onSuccess: () => {
      invalidateDataCollections();
    },
  });

  return {
    getDataCollections,
    getDataCollection,
    createDataCollection,
    updateDataCollection,
    deleteDataCollection,
    duplicateDataCollection,
    shareDataCollection,
  };
}
