import { createContext } from "react";
import { User } from "src/types/Auth";

export interface UserContextInterface {
  user: User | null;
  isUserDataLoading: boolean;
  logout: () => void;
  isError: boolean;
  updateUserData: (userFields: Partial<User> | null) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (status: boolean) => void;
}

const UserContext = createContext<UserContextInterface | null>(null);

UserContext.displayName = "UserContext";

export default UserContext;
