import { UserGroup } from "../types/UserGroup";
import { useUser } from "../modules/api/auth";

export const checkIfGroupAdmin = (userGroupsMember: UserGroup[]) => {
  const { user } = useUser();

  return userGroupsMember.some((group) =>
    user.group_admins.includes(group.group_id)
  );
};
