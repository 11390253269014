import { QueryItem } from "src/types/BulkSearch";
import { BalancedInQuery } from "./check-parentheses-balanced-in-query";

interface FocusedQuery {
  index: number | null;
}

// Inserts items into an array at a specific index.
const insertAtIndex = (
  array: QueryItem[],
  itemsToInsert: QueryItem[],
  index: number
) => [...array.slice(0, index), ...itemsToInsert, ...array.slice(index)];

// Creates a query item object for the given type and value.
const createQueryItem = (
  type: "operator" | "term",
  value: string,
  extra = {}
) => ({
  type,
  value,
  fuzziness: 0,
  ...extra,
});

// Handles parentheses insertion based on balance state.
export const handleParentheses = (
  prev: QueryItem[],
  isPreviousQueryEmptyTerm: boolean,
  focusedQuery: FocusedQuery,
  insertionIndex: number,
  areParenthesesBalanced: BalancedInQuery
) => {
  const termIndex = isPreviousQueryEmptyTerm
    ? focusedQuery.index
    : insertionIndex;
  const operatorValue = areParenthesesBalanced.balance
    ? "("
    : areParenthesesBalanced.openCount > areParenthesesBalanced.closeCount
    ? ")"
    : "(";

  if (areParenthesesBalanced.balance) {
    const newItems = [
      createQueryItem("operator", "("),
      createQueryItem("term", ""),
      createQueryItem("operator", ")"),
      createQueryItem("term", ""),
    ];
    return insertAtIndex(prev, newItems, termIndex);
  }

  return insertAtIndex(
    prev,
    [createQueryItem("operator", operatorValue), createQueryItem("term", "")],
    insertionIndex
  );
};

// Handles insertion of quoted terms.
export const handleQuotes = (
  prev: QueryItem[],
  isPreviousQueryEmptyTerm: boolean,
  focusedQuery: FocusedQuery,
  insertionIndex: number
) => {
  const term = createQueryItem("term", `""`, { quote: true });
  const index = isPreviousQueryEmptyTerm ? focusedQuery.index : insertionIndex;

  return insertAtIndex(prev, [term], index);
};

// Handles insertion of other operators.
export const handleOtherOperators = (
  prev: QueryItem[],
  operator: string,
  insertionIndex: number
) => {
  const itemsToInsert = [
    createQueryItem("operator", operator),
    ...(prev[insertionIndex]?.type === "term"
      ? []
      : [createQueryItem("term", "")]),
  ];

  return insertAtIndex(prev, itemsToInsert, insertionIndex);
};
