import React, { createContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFetch } from "../fetch";
import doc_types from "./json/doc_types.json";
import dataset_types from "./json/dataset_types.json";
import regions from "./json/regions.json";
import languages from "./json/languages.json";
import { useDataCollections } from "../dataCollections";
import {
  DataCollectionFilterOption,
  FilterKeys,
  FilterOption,
  FilterOptions,
} from "src/types/SearchFilter";
import { useDatasetMetadata } from "../datasetMetadata";
import { useUser } from "../auth";
import { useKnowledgeWiki } from "../knowledgeWiki";

export const FilterOptionsContext = createContext<FilterOptions>({
  isLoading: false,
  getFilterOptionsKey: () => "",
  datasets: [],
  data_collections: [],
  doc_types,
  dataset_types,
  countries: [],
  regions,
  languages,
});

export default function FilterOptionsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUser();
  const { getApi } = useFetch();
  const { getDataCollections } = useDataCollections();
  const { getDatasetMetadata } = useDatasetMetadata();
  const { getKnowledgeWikis } = useKnowledgeWiki();
  getKnowledgeWikis();

  const [options, setOptions] = useState<
    Omit<FilterOptions, "isLoading" | "getFilterOptionsKey">
  >({
    datasets: [],
    data_collections: [],
    doc_types,
    dataset_types,
    countries: [],
    regions,
    languages,
  });

  const getCountriesFn = async () => {
    const data = await getApi("countries");
    return Object.entries(data)
      .filter(
        ([value, label]) =>
          typeof value === "string" && typeof label === "string"
      ) // temp fix because backend endpoint returns numeric status code in body
      .map(
        ([value, label]) =>
          ({
            value,
            label: label as string,
          } as FilterOption)
      );
  };

  const { isLoading: isLoadingCountries } = useQuery({
    enabled: !!user,
    queryFn: getCountriesFn,
    queryKey: ["country"],
    initialData: [],
    onSuccess: (data) => setOptions((prev) => ({ ...prev, countries: data })),
  });

  const { isLoading: isLoadingDataCollections } = getDataCollections({
    onSuccess: (data) => {
      const allDataCollections = [
        ...data.created_by,
        ...data.shared_with_groups,
      ];

      setOptions((prev) => ({
        ...prev,
        data_collections: allDataCollections.map(
          (d) =>
            ({
              label: d.name,
              value: d.id,
              dataset_ids: d.datasets,
            } as DataCollectionFilterOption)
        ),
      }));
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { isLoading: isLoadingDatasets } = getDatasetMetadata({
    onSuccess: (data) =>
      setOptions((prev) => ({
        ...prev,
        datasets: data.map((d) => ({ label: d.name, value: d.id })),
      })),
  });

  function getFilterOptionsKey(filterKey: FilterKeys) {
    return filterKey === FilterKeys.DATASET_IDS ? "datasets" : filterKey;
  }

  return (
    <FilterOptionsContext.Provider
      value={{
        isLoading:
          isLoadingCountries || isLoadingDataCollections || isLoadingDatasets,
        getFilterOptionsKey,
        ...options,
      }}
    >
      {children}
    </FilterOptionsContext.Provider>
  );
}
