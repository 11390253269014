import {
  Context,
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import languages from "src/assets/json/languages.json";
import regions from "src/assets/json/regions.json";
import { useKnowledgeWiki } from "../../../../modules/api/knowledgeWiki";
import { KnowledgeWiki } from "../../../../types/KnowledgeWiki";
import { SelectOption } from "../../../../types/Shared";
import { useFilterOptions } from "../../../../modules/api/filters";

const prepareDatasetMetadata = (
  knowledgeWikis: KnowledgeWiki[],
  countries: any[],
  datasetId: string
) => {
  const wikis = knowledgeWikis ?? [];

  const wiki = wikis.find(({ dataset }) => dataset === datasetId);
  if (!wiki) {
    return;
  }

  const dataset: unknown = {};
  const datasetCountries = wiki.countries.map(
    (country) =>
      countries.filter((c: SelectOption) => c.value === country)[0]?.label
  );
  const datasetLanguages = wiki.languages.map(
    (lang: unknown) =>
      languages.filter((c: SelectOption) => c.label === lang)[0]?.label
  );
  const datasetRegions = wiki.regions.map(
    (region: unknown) =>
      regions.filter((c: SelectOption) => c.label === region)[0]?.label
  );

  const title = wiki.name ?? "";
  const updatedOn = wiki?.updated ? new Date(wiki.updated) : null;
  dataset["Country"] = datasetCountries?.join(",");
  dataset["Language"] = datasetLanguages?.join(",");
  dataset["Region"] = datasetRegions?.join(",");
  dataset["Dataset Type"] = wiki?.dataset_type ?? "None";
  dataset["Sensitivity"] = wiki?.sensitivity ?? "None";
  dataset["Data Dictionary"] = wiki?.name;
  dataset["title"] = title;

  return {
    title,
    dataset,
    updatedOn,
    wikiItem: wiki,
  };
};

const useDatasetHits = (datasetId: string) => {
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { countries } = useFilterOptions();
  const { data: knowledgeWikis, isLoading } = getKnowledgeWikis();
  const [datasetMetadata, setDatasetMetadata] = useState(
    prepareDatasetMetadata(knowledgeWikis, countries, datasetId)
  );

  useEffect(() => {
    setDatasetMetadata(
      prepareDatasetMetadata(knowledgeWikis, countries, datasetId)
    );
  }, [knowledgeWikis, countries, datasetId]);

  return { datasetMetadata, isLoading };
};

const datasetHitsContexts: Record<
  string,
  Context<ReturnType<typeof useDatasetHits>>
> = {};

export const useDatasetHitsContext = (datasetId: string) =>
  useContext(datasetHitsContexts[datasetId]);

export const createDatasetHitsProvider = (datasetId: string) => {
  datasetHitsContexts[datasetId] = createContext<
    ReturnType<typeof useDatasetHits>
  >({
    datasetMetadata: undefined,
    isLoading: false,
  });

  const Context = datasetHitsContexts[datasetId];

  return ({ children }: PropsWithChildren) => {
    const value = useDatasetHits(datasetId);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
};
