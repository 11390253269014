import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import c4adsLogo from "src/assets/images/c4ads.png";
import { Colors } from "../constants/colors";
import AgreementDialog from "src/view/layout/AgreementDialog";
import theme from "src/theme";

const LogoWrapper = styled("div")(() => ({
  height: 47,
  width: 138,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: "11px",
  color: Colors.textDark,
  padding: theme.spacing(0, 5, 0, 5),
  textDecoration: "none",
  fontWeight: "700",
  cursor: "pointer",
  "&:last-child": {
    paddingRight: 0,
  },
  [theme.breakpoints.down("sm")]: {
    "&:last-child": {
      paddingRight: 5,
    },
  },
}));

export default function Footer() {
  const [isEULAOpen, setIsEULAOpen] = useState(false);

  const toggleEulaOpen = () => setIsEULAOpen(!isEULAOpen);

  return (
    <>
      <Box sx={{ flexGrow: 1, borderTop: "1px solid #E9E9E9" }}>
        <Container maxWidth="lg" sx={{}}>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              py: "40px",
            }}
          >
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item sx={{ width: { md: "50%", sm: "100%" } }}>
                  <Grid item>
                    <LogoWrapper>
                      <img src={c4adsLogo} alt="C4ADS Logo" />
                    </LogoWrapper>
                  </Grid>
                  <Grid item sx={{ mt: 1, mb: 1 }}>
                    <Box
                      sx={{
                        border: `1px solid ${Colors.secondary}`,
                        borderRadius: 2,
                        width: { sm: "50%", xs: "100%" },
                        maxWidth: "221px",
                      }}
                    ></Box>
                  </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1 }} />
                <Grid
                  item
                  sx={{
                    width: { md: "50%", sm: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { sm: "space-between", md: "flex-end" },
                      marginBottom: "14px",
                    }}
                  >
                    <StyledLink
                      href="https://c4ads.org/about-us/"
                      target="_blank"
                    >
                      About C4ADS
                    </StyledLink>
                    <StyledLink
                      href="https://c4ads.org/contact/"
                      target="_blank"
                    >
                      Contact C4ADS
                    </StyledLink>
                    <StyledLink
                      href="https://c4ads.org/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </StyledLink>
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "11px",
                        padding: theme.spacing(0, 0, 0, 5),
                        color: Colors.textDark,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={toggleEulaOpen}
                    >
                      License Agreement
                    </Button>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "end",
                      paddingLeft: { sm: 0, md: "40px" },
                      color: Colors.textDark,
                    }}
                  >
                    © {new Date().getFullYear()} Center for Advanced Defense
                    Studies, Inc.
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Container>
      </Box>
      {isEULAOpen && (
        <AgreementDialog
          open={isEULAOpen}
          title="EULA Agreement"
          readonly
          onClose={toggleEulaOpen}
        />
      )}
    </>
  );
}
