import { SetStateAction } from "react";
import { AnyAction, Dispatch } from "redux";
import { QueryVariables } from "src/types/QueryVariables";
import { QueryItem } from "src/types/BulkSearch";
import { store } from "src/modules/store";
import {
  SET_VARIABLES,
  SET_QUERY_B,
  SET_PREVIEW_DATA,
  SET_ACTIVE_PAGE_INDEX,
} from "./constants";

type SetAction<T> = T | SetStateAction<T>;

const searchStoreActions = {
  setVariables:
    (newVariables: SetAction<QueryVariables>) =>
    (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: SET_VARIABLES,
        payload:
          typeof newVariables === "function"
            ? newVariables(store.getState().searchStore.variables)
            : newVariables,
      });
    },
  setQueryB:
    (newQueryB: SetAction<QueryItem[]>) => (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: SET_QUERY_B,
        payload:
          typeof newQueryB === "function"
            ? newQueryB(store.getState().searchStore.queryB)
            : newQueryB,
      });
    },
  setActivePageIndex:
    (newActivePageIndex: SetAction<number>) =>
    (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: SET_ACTIVE_PAGE_INDEX,
        payload:
          typeof newActivePageIndex === "function"
            ? newActivePageIndex(store.getState().searchStore.activePageIndex)
            : newActivePageIndex,
      });
    },
  setPreviewData:
    (newPreviewData: SetAction<any | null>) =>
    (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: SET_PREVIEW_DATA,
        payload:
          typeof newPreviewData === "function"
            ? newPreviewData(store.getState().searchStore.previewData)
            : newPreviewData,
      });
    },
};

export default searchStoreActions;
