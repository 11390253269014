import { CSSProperties, ReactNode } from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from "@mui/material";
import { buttonBg, buttonColor } from "./constants";
import { ButtonSize, ButtonVariant } from "./types";

interface ButtonProps extends Omit<MuiButtonProps, "variant" | "size"> {
  btnVariant?: ButtonVariant;
  btnSize?: ButtonSize;
  noWrap?: boolean;
  children?: ReactNode;
}

const buttonSize: Record<ButtonSize, CSSProperties> = {
  big: {
    padding: "12px 16px",
    gap: 8,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    height: "42px",
  },
  medium: {
    padding: "12px 16px",
    gap: 8,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "18px",
    height: "36px",
  },
  small: {
    padding: "4px 12px",
    gap: 8,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16px",
    height: "28px",
  },
  "small+": {
    padding: "8px 12px",
    gap: 8,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "15.6px",
  },
};

const buttonIconSize: Record<ButtonSize, CSSProperties> = {
  big: {
    width: 20,
    height: 20,
  },
  medium: {
    width: 16,
    height: 16,
  },
  small: {
    width: 12,
    height: 12,
  },
  "small+": {
    width: 14,
    height: 14,
  },
};

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    !["btnVariant", "btnSize", "noWrap"].includes(prop.toString()),
})<ButtonProps>(({ btnVariant, btnSize, noWrap }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: 6,
  backgroundColor: buttonBg[btnVariant].main,
  color: buttonColor[btnVariant].main,
  whiteSpace: noWrap ? "nowrap" : "break-spaces",
  "&:hover": {
    backgroundColor: buttonBg[btnVariant].hover,
    color: buttonColor[btnVariant].hover,
  },
  "&:disabled": {
    backgroundColor: buttonBg[btnVariant].disabled,
    color: buttonColor[btnVariant].disabled,
  },
  "&:active": {
    backgroundColor: buttonBg[btnVariant].active,
    color: buttonColor[btnVariant].active,
  },
  "&>span.MuiButton-startIcon": {
    margin: 0,
  },
  "&>span.MuiButton-startIcon svg path": {
    fill: "currentColor",
  },
  "&>span.MuiButton-startIcon svg": {
    fill: "currentColor",
    ...buttonIconSize[btnSize],
  },
  "&>span.MuiButton-endIcon": {
    margin: 0,
  },
  "&>span.MuiButton-endIcon svg path": {
    fill: "currentColor",
  },
  "&>span.MuiButton-endIcon svg": {
    fill: "currentColor",
    ...buttonIconSize[btnSize],
  },
  ...buttonSize[btnSize],
}));

export const Button = ({
  btnVariant = "secondary",
  btnSize = "small",
  noWrap = false,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      btnSize={btnSize}
      btnVariant={btnVariant}
      noWrap={noWrap}
      {...props}
    >
      {children}
    </StyledButton>
  );
};
