import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import DownloadFile from "./DownloadFile";
import { StyledFileName } from "./DataTableView";
import HighlightingFailure from "./HighlightingFailure";
import { Typography } from "@mui/material";
import { Tooltip } from "src/components/ui";

export const StyledHeader = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.common.white,
  width: "calc(100% - 12px)",
  height: 35,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 4px 5px",
}));

const ButtonsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

interface Props {
  data: string;
  fileName: string;
  docId: string;
  type: string;
  getDocumentPreview: () => void;
  isHighlightingFailure: boolean;
}

export const JSONView = ({
  data,
  docId,
  fileName,
  type,
  getDocumentPreview,
  isHighlightingFailure,
}: Props) => {
  return (
    <Box sx={{ paddingLeft: "8px" }}>
      <StyledHeader>
        <Tooltip
          arrow={true}
          placement="top-start"
          enterDelay={0}
          title={<StyledTooltipContent>{fileName}</StyledTooltipContent>}
        >
          <StyledFileName onClick={getDocumentPreview}>
            {fileName}
          </StyledFileName>
        </Tooltip>
        <ButtonsContainer>
          {isHighlightingFailure && <HighlightingFailure />}
          <DownloadFile docId={docId} type={type} />
        </ButtonsContainer>
      </StyledHeader>
      <div
        className="seho-search-result-json"
        dangerouslySetInnerHTML={{ __html: data }}
        style={{
          marginTop: "-13px", // 1em -> 13px in this case
          fontFamily: "Andale Mono",
          fontSize: 13,
        }}
      />
    </Box>
  );
};
