import "react-json-pretty/themes/monikai.css";
import SearchResultCardPreview from "./SearchResultCardPreview";
import { MappedHit } from "src/types/Search";

interface Props {
  datasetId: string;
  hit: MappedHit;
  isLastEntity?: boolean;
  withoutWrapper?: boolean;
}

const SearchResultCard = ({
  hit,
  datasetId,
  isLastEntity,
  withoutWrapper,
}: Props) => {
  return (
    <SearchResultCardPreview
      id={hit.id}
      isLastEntity={isLastEntity}
      datasetId={datasetId}
      hit={hit}
      type={hit.source.doc_type ?? hit.source["schema"]}
      highlight={hit?.highlight}
      withoutWrapper={withoutWrapper}
    />
  );
};

export default SearchResultCard;
