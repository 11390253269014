import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

interface FocusContextProps {
  focusedQuery: { index: number | null };
  setFocusedQuery: React.Dispatch<
    React.SetStateAction<{ index: number | null }>
  >;
}

const FocusContext = createContext<FocusContextProps | undefined>(undefined);

type Props = PropsWithChildren;

export const FocusProvider = ({ children }: Props) => {
  const [focusedQuery, setFocusedQuery] = useState<{
    index: number | null;
  }>({ index: 0 });

  return (
    <FocusContext.Provider value={{ focusedQuery, setFocusedQuery }}>
      {children}
    </FocusContext.Provider>
  );
};

export const useFocus = () => {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error("useFocus must be used within a FocusProvider");
  }
  return context;
};
