import React, { ForwardedRef, useEffect, useMemo, useState } from "react";
import { TextField, StandardTextFieldProps } from "@mui/material";
import { debounce } from "lodash";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";

const StyledTextFieldWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "invalid",
})<{ invalid?: boolean }>(({ invalid, theme }) => ({
  width: "100%",
  height: "40px",
  background: theme.palette.common.white,
  borderRadius: 4,
  marginBottom: 10,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  border: "1px solid",
  borderColor: invalid ? theme.palette.secondary.main : theme.palette.grey[400],
}));

interface Props extends StandardTextFieldProps {
  debounced?: boolean;
  ref?: ForwardedRef<any>;
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  tabIndex?: number;
  autoFocus?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  invalid?: boolean;
}

const CustomTextField = React.forwardRef((props: Props, ref) => {
  const {
    type = "text",
    label,
    name,
    value: propValue,
    debounced = false,
    onChange,
    onKeyPress,
    helperText,
    prefixIcon,
    suffixIcon,
    placeholder,
    containerStyle = {},
    inputStyle = {},
    tabIndex = 0,
    autoFocus = false,
    invalid,
  } = props;
  const [value, setValue] = useState(propValue);

  useEffect(() => setValue(propValue ?? value), [propValue]);

  const debouncedOnChange = useMemo(() => {
    let event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const debouncedFunction = debounce(
      () => {
        onChange?.(event);
      },
      debounced ? 300 : 0
    );
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
      event = e;
      debouncedFunction();
    };
  }, [onChange, debounced]);

  return (
    <StyledTextFieldWrapper style={containerStyle} invalid={invalid}>
      {prefixIcon}
      <TextField
        inputRef={ref as React.ForwardedRef<any>}
        label={label}
        name={name}
        value={value}
        onChange={debouncedOnChange}
        onKeyPress={onKeyPress}
        helperText={helperText}
        type={type}
        placeholder={placeholder}
        // variant="filled"
        fullWidth
        sx={{ border: "none", background: "none" }}
        InputLabelProps={{
          style: {
            fontSize: "14px",
            fontWeight: 400,
            zIndex: 0,
          },
        }}
        InputProps={{
          // disableUnderline: true,
          sx: {
            background: "none",
            fontSize: "14px",
            lineHeight: "17px",
            fontWeight: 700,
            color: Colors.twilight,
            outline: "unset",
            border: "none",
            "& input": {
              marginTop: "0px",
              padding: 0,
              marginLeft: "10px",
              ...inputStyle,
            },
            "&:hover": {
              background: "none",
            },
            "& fieldset": {
              border: "none",
            },
          },
        }}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
      />
      {suffixIcon}
    </StyledTextFieldWrapper>
  );
});

export default CustomTextField;
