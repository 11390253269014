import { useMemo } from "react";
import { MenuItem, Box, Typography } from "@mui/material";
import { uniqBy } from "lodash";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";
import { Popover } from "../../../../components/ui";

const Badge = styled("span")({
  width: "max-content",
  padding: "2px 10px",
  fontSize: 14,
  lineHeight: "17px",
  color: "#122945",
  borderRadius: 30,
  backgroundColor: "rgba(20, 74, 104, 0.1)",
});

interface Props {
  user_groups?: User["user_groups"];
}

const UserGroupMemberColumn = ({ user_groups }: Props) => {
  // Fixed badge count by removing duplicates ("syndrome" fix).
  // Proper fix of whole auto updating logic will be in this ticket: https://linear.app/c4ads/issue/ENG-1756/fix-the-asynchronous-group-auto-save-logic
  // Also this deduping could be removed in the future after deduping will be made also on backend side
  const uniqueMembers = useMemo(
    () => uniqBy(user_groups?.member, "group_id"),
    [user_groups?.member]
  );

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography fontSize="14px" color={Colors.textDark100}>
        {uniqueMembers?.length ? <>{uniqueMembers[0].name}</> : "-"}
      </Typography>

      {uniqueMembers.length > 1 && (
        <Popover
          id="mouse-over-popover"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableRestoreFocus
          triggerType="hover"
          trigger={<Badge>+{uniqueMembers.length - 1}</Badge>}
        >
          {uniqueMembers.slice(1).map((group) => (
            <MenuItem key={group.group_id}>{group.name}</MenuItem>
          ))}
        </Popover>
      )}
    </Box>
  );
};

export default UserGroupMemberColumn;
