import { useRef, useState, useLayoutEffect, useMemo } from "react";
import { BoxProps } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LoadMoreButton from "../SearchResultCard/SearchResultCardPreview/LoadMoreButton";
import FilterTag from "../SearchResultCard/FilterTag";
import ActionIconButton from "../SearchResultCard/ActionIconButton";
import IsLeakedTag from "src/view/components/IsLeakedTag";
import SearchResultCardActions from "../SearchResultCard/SearchResultCardActions";
import { EntityTemplate } from "../EntityTemplate";
import { useDatasetHitsContext } from "./DatasetHitsContext";
import { TagBox } from "../TagBox";

export interface TagBoxProps extends BoxProps {
  expand?: boolean;
}

interface DatasetHitsHeaderProps {
  type: string;
  datasetId: string;
  onClick?: () => void;
  onResultsVisibility?: (visible: boolean) => void;
}

export const DatasetHitsHeader = ({
  type,
  onClick,
  onResultsVisibility,
  datasetId,
}: DatasetHitsHeaderProps) => {
  const ref = useRef<HTMLDivElement>();
  const [datasetHitsVisibile, setDatasetHitsVisible] = useState(true);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const { datasetMetadata, isLoading } = useDatasetHitsContext(datasetId);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const trigger = () => {
        setExpand(false);
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflow(hasOverflow);
      };

      window.addEventListener("resize", trigger);
      trigger();
      return () => window.removeEventListener("resize", trigger);
    }
  }, [ref]);

  const toggleExpand = () => setExpand(!expand);

  const tags = useMemo(() => {
    return [
      {
        label: "Type",
        value: datasetMetadata?.dataset?.["Dataset Type"] || "--",
      },
      {
        label: "Region",
        value: datasetMetadata?.dataset?.["Region"] || "--",
      },
      {
        label: "Language",
        value: datasetMetadata?.dataset?.["Language"] || "--",
      },
      {
        label: "Sensitivity",
        value: datasetMetadata?.dataset?.["Sensitivity"] || "--",
      },
    ];
  }, [datasetMetadata]);

  const toggleResultsVisibility = () => {
    const newState = !datasetHitsVisibile;
    setDatasetHitsVisible(newState);
    onResultsVisibility?.(newState);
  };

  return (
    <EntityTemplate
      isLoading={isLoading}
      type={type}
      onClick={onClick}
      title={datasetMetadata?.title}
      titleActions={
        <ActionIconButton
          onClick={toggleResultsVisibility}
          buttonStyles={{ backgroundColor: "transparent" }}
          icon={
            datasetHitsVisibile ? (
              <ExpandMoreIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )
          }
        />
      }
      tags={
        <>
          <IsLeakedTag dataset={datasetMetadata?.dataset} />
          <TagBox id="card-tag-box" expand={expand} ref={ref}>
            {tags.map(({ value, label }) => (
              <FilterTag
                key={`${label}:"${value}"`}
                compact
                label={label}
                value={value}
              />
            ))}
          </TagBox>
          <LoadMoreButton
            expand={expand}
            isOverflow={isOverflow}
            onClick={toggleExpand}
          />
        </>
      }
      addedOn={datasetMetadata?.wikiItem?.created}
      cardActions={
        <SearchResultCardActions
          resultMetadata={datasetMetadata as any}
          gettingDownloadLink={false}
          type={type}
        />
      }
    />
  );
};
