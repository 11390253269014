import { useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import { useMutation } from "@tanstack/react-query";
import CustomTextField from "src/view/components/CustomTextField";
import UserService from "src/modules/auth/authService";
import { useUser } from "src/modules/api/auth";
import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";
import theme from "src/theme";
import { snackbar } from "../../../../../toaster";

interface UserNameCellProps {
  user: User;
  editing: boolean;
  setEditing: (editing: boolean) => void;
}

const StyledUserForm = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  flex: "3 1",
}));

const StyledNameContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

const StyledUserFormButtonsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: 16,
  margin: 0,
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: 13,
  height: 13,
  marginBottom: 2,
}));

const StyledErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: "Inter",
  fontSize: "10px",
  fontWeight: 400,
  marginTop: -5,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.mist.light,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 4,
  color: theme.palette.primary.main,
  fontSize: 12,
  lineHeight: "15.6px",
  fontWeight: "600",
  padding: "6px 10px",
  height: 28,
  textTransform: "capitalize",
  "&>span.MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const StyledCancelFormButton = styled(StyledButton)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.primary.main,
  textDecoration: "underline",
  fontSize: "12px",
  lineHeight: "15.6px",
  fontWeight: "500",
  border: "none",
  "&:hover": {
    background: theme.palette.common.white,
  },
}));

const InputsBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const inputStyles = {
  containerStyles: {
    maxWidth: 200,
    minWidth: 80,
    height: 26,
    padding: "4.5px 8px",
    marginBottom: 2,
    borderRadius: 6,
  },
  input: {
    marginLeft: 0,
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: "Inter, sans-serif",
  },
};

export const UserNameCell = ({
  user: propUser,
  editing,
  setEditing,
}: UserNameCellProps) => {
  const { user: authUser } = useUser();
  const [user, setUser] = useState(propUser);
  const [rawUser, setRawUser] = useState(user);
  const { isLoading: updating, mutate } = useMutation({
    mutationFn: async (data: Pick<User, "first_name" | "last_name">) => {
      return await UserService.updateUserDetail(user.id, data);
    },
    onSuccess: async () => {
      setEditing(false);
      setUser(rawUser);
      snackbar.success("User's names were updated successfully.");
    },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
    },
  });

  const onSubmit = async (data: Pick<User, "first_name" | "last_name">) => {
    setRawUser((prev) => ({ ...prev, ...data }));
    mutate(data);
  };

  const saveButtonIcon = useMemo(() => {
    if (updating) {
      return <CircularProgress size={12} color="inherit" />;
    } else if (propUser.id === authUser.id) {
      return <StyledDoneIcon fontSize="small" color="primary" />;
    }

    return null;
  }, [authUser, updating, propUser]);

  return (
    <>
      {editing ? (
        <StyledUserForm>
          <InputsBox>
            <Controller
              control={control}
              name="first_name"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Box>
                  <CustomTextField
                    {...field}
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    tabIndex={1}
                    autoFocus
                    invalid={!!errors.first_name}
                    containerStyle={inputStyles.containerStyles}
                    inputStyle={inputStyles.input}
                  />
                  {errors.first_name && (
                    <StyledErrorText>
                      {errors.first_name.message}
                    </StyledErrorText>
                  )}
                </Box>
              )}
            />
            <Controller
              control={control}
              name="last_name"
              rules={{ required: true }}
              render={({ field }) => (
                <Box>
                  <CustomTextField
                    {...field}
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    tabIndex={2}
                    invalid={!!errors.last_name}
                    containerStyle={inputStyles.containerStyles}
                    inputStyle={inputStyles.input}
                  />
                  {errors.last_name && (
                    <StyledErrorText>
                      {errors.last_name.message}
                    </StyledErrorText>
                  )}
                </Box>
              )}
            />
          </InputsBox>
          <StyledUserFormButtonsContainer>
            <StyledCancelFormButton onClick={() => setEditing(false)}>
              Cancel
            </StyledCancelFormButton>
            <StyledButton
              startIcon={saveButtonIcon}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </StyledButton>
          </StyledUserFormButtonsContainer>
        </StyledUserForm>
      ) : (
        <StyledNameContainer>
          <span>{getFullName(user)}</span>
        </StyledNameContainer>
      )}
    </>
  );
};
