import { CustomInput } from "src/components/ui";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";
import { styled } from "@mui/material/styles";

interface Props {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  setSearchKey: (searchInput: string) => void;
  placeholder: string;
}

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.grey["600"],
  cursor: "pointer",
}));

const SearchBox = ({
  searchInput,
  setSearchInput,
  placeholder,
  setSearchKey,
}: Props) => {
  const handleSearch = () => {
    setSearchKey(searchInput);
  };

  return (
    <CustomInput.Input
      value={searchInput}
      onChange={(e) => setSearchInput(e.target.value)}
      onKeyDown={(e) => e.key == "Enter" && handleSearch()}
      containerStyle={{
        width: "25%",
        minWidth: 200,
      }}
      placeholder={placeholder}
      prefixIcon={<StyledSearchIcon onClick={handleSearch} />}
    />
  );
};

export default SearchBox;
