import { cloneElement, ReactElement, ReactNode } from "react";
import {
  Box,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled, SxProps } from "@mui/material/styles";
import { IconButton } from "../Button";
import Theme from "../../../theme";

export interface DialogProps extends Omit<MuiDialogProps, "title"> {
  closeable?: boolean;
  title?: ReactNode;
  hideHeader?: boolean;
  closeComponent?: ReactElement;
  footer?: ReactNode;
  contentSx?: SxProps<typeof Theme>;
}

const StyledDialog = styled(MuiDialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: 6,
    padding: "20px",
    minWidth: "280px",
    boxShadow: "0px 3px 13px 4px #0000001A",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
}));

const ModalContentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "600",
  color: theme.palette.twilight.main,
  padding: 0,
  margin: 0,
  lineHeight: 1,
}));

const DialogStyledHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingBottom: "12px",
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: -8,
  right: -6,
  color: theme.palette.primary.main,
  "& svg": {
    height: 21,
    width: 21,
  },
}));

export const Dialog = ({
  closeable = true,
  title,
  hideHeader,
  children,
  footer,
  closeComponent,
  contentSx,
  ...props
}: DialogProps) => {
  return (
    <StyledDialog {...props}>
      <ModalContentContainer sx={contentSx}>
        {!hideHeader && (
          <DialogStyledHeader>
            {typeof title === "string" ? (
              <StyledDialogTitle id="scroll-dialog">{title}</StyledDialogTitle>
            ) : (
              title ?? null
            )}
            {closeable &&
              (closeComponent ? (
                cloneElement(closeComponent, {
                  onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
                    props.onClose?.(e, "backdropClick"),
                })
              ) : (
                <StyledCloseButton
                  disableIconSizing
                  btnVariant="text"
                  aria-label="close"
                  onClick={(e) => props.onClose?.(e, "backdropClick")}
                >
                  <CloseIcon width={24} height={24} />
                </StyledCloseButton>
              ))}
          </DialogStyledHeader>
        )}
        {children}
        {footer}
      </ModalContentContainer>
    </StyledDialog>
  );
};
