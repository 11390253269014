import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemProps,
  SvgIconProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Colors } from "../constants/colors";
import CustomTextField from "../components/CustomTextField";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useUserAuth } from "src/modules/api/auth";
import { snackbar } from "../toaster";
import logo from "src/assets/logo.svg";
import theme from "src/theme";

const RootContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Form = styled(Box)(() => ({
  height: 652,
  width: 510,
  padding: "72px 80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `0px 3px 13px 0px  #0000001A`,
  borderRadius: 6,
  boxSizing: "border-box",
}));

const FormContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Logo = styled("img")(() => ({
  width: 250,
  height: 84,
  objectFit: "contain",
  marginBottom: "24px",
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "19px",
  fontWeight: "600",
  color: theme.palette.primary.main,
  textAlign: "center",
  fontFamily: "Inter",
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Inter",
  lineHeight: "14.5px",
  color: theme.palette.primary.main,
  fontWeight: 400,
}));

const ErrorText = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  fontFamily: "Inter",
  color: Colors.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 45,
  textTransform: "capitalize",
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: 16,
  backgroundColor: theme.palette.mist.light,
  color: theme.palette.primary.main,
  borderRadius: 6,
  margin: "27px 0",
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  "&>span.MuiButton-startIcon": {
    marginRight: 0,
    position: "absolute",
    left: "25%",
  },
}));

const StyledWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  marginTop: 24,
});

interface FormIconProps extends SvgIconProps {
  isVisible: boolean;
}

const FormIcon = styled(({ isVisible, ...rest }: FormIconProps) =>
  isVisible ? (
    <VisibilityOffOutlinedIcon {...rest} fontSize="medium" color="primary" />
  ) : (
    <VisibilityOutlinedIcon {...rest} fontSize="medium" color="primary" />
  )
)(() => ({
  width: 20,
  height: 20,
  marginRight: 12,
  cursor: "pointer",
}));

const StyledValidationRulesList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const StyledNestedValidationRulesList = styled("ul")({
  margin: 0,
  padding: "0 0 0 12px",
  listStyle: "none",
});

interface ValidationItemProps extends ListItemProps {
  isMatching?: boolean;
}

const StyledValidationRulesItem = styled((props: ValidationItemProps) => (
  <ListItem {...props} />
))(({ theme, isMatching = false }) => ({
  display: "block",
  padding: 0,
  fontSize: "12px",
  fontFamily: "Inter",
  lineHeight: "14.5px",
  color: isMatching ? theme.palette.success.main : theme.palette.primary.main,
  fontWeight: 400,
  "&:not(:last-child)": {
    marginBottom: 8,
  },
}));

const StyledValidationHeading = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: 500,
  color: theme.palette.primary.main,
  marginBottom: 8,
}));

export default function ResetPassword() {
  const { control, formState, handleSubmit, setError, watch, register } =
    useForm({
      defaultValues: {
        new_password: "",
        repeat_password: "",
      },
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const [visible, setVisible] = useState({
    new: false,
    repeat: false,
  });

  const [validationRules, setValidationRules] = useState({
    length: false,
    lowerCase: false,
    upperCase: false,
    numbers: false,
    specialSymbols: false,
  });

  const { updateUserPassword } = useUserAuth();
  const { mutateAsync: updatePassword, isLoading } = updateUserPassword;
  const location = useLocation();
  const errors = formState.errors;

  const searchParams = new URLSearchParams(location.search);
  const verifyCode = searchParams.get("verifyCode");

  const password = watch("new_password", "");

  useEffect(() => {
    setValidationRules({
      length: password.length >= 8,
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      numbers: /\d/.test(password),
      specialSymbols: /[@#$%^&*!]/.test(password),
    });
  }, [password]);

  const changeVisible = (type: string) => {
    const _visi = { ...visible };
    _visi[type] = !_visi[type];
    setVisible(_visi);
  };

  const handleResetPassword = async ({
    new_password,
    repeat_password,
  }: {
    new_password: string;
    repeat_password: string;
  }) => {
    if (!verifyCode) {
      snackbar.error("Verify code was not provided!");
      return;
    }

    const isValidPassword =
      Object.values(validationRules).filter(Boolean).length > 3 &&
      new_password.length >= 8;
    const isPasswordsMatch = new_password === repeat_password;

    if (!isValidPassword) {
      setError("new_password", {
        type: "manual",
        message: "Password do not match all of the required rules.",
      });
    }

    if (!isPasswordsMatch) {
      setError("repeat_password", {
        type: "manual",
        message: "These two passwords don't currently match.",
      });
    }

    if (isValidPassword && isPasswordsMatch) {
      try {
        await updatePassword({
          new_password,
          reset_password_code: verifyCode,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const textFieldInputStyles = {
    height: "44px",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: theme.palette.silver.lighter,
    width: "100%",
    "&:placeholder": {
      color: theme.palette.silver.lighter,
    },
  };

  return (
    <RootContainer>
      <Form>
        <FormContainer>
          <Logo src={logo} alt="SEAMLESS HORIZONS" />
          <FormTitle>Reset Your Password</FormTitle>
          <form
            onSubmit={handleSubmit(handleResetPassword)}
            style={{ width: "100%" }}
          >
            <StyledWrapper>
              <Box>
                <Controller
                  control={control}
                  name="new_password"
                  rules={{
                    required: true,
                  }}
                  {...register("new_password")}
                  render={({ field }) => (
                    <Box>
                      <CustomTextField
                        {...field}
                        inputStyle={textFieldInputStyles}
                        containerStyle={{
                          marginBottom: 4,
                        }}
                        id="new_password"
                        placeholder="Enter New Password"
                        type={visible.new ? "text" : "password"}
                        invalid={!!errors.new_password}
                        suffixIcon={
                          <FormIcon
                            isVisible={visible.new}
                            onClick={() => changeVisible("new")}
                          />
                        }
                      />
                      {errors.new_password && (
                        <ErrorText>{errors.new_password.message}</ErrorText>
                      )}
                    </Box>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="repeat_password"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Box>
                      <CustomTextField
                        {...field}
                        inputStyle={textFieldInputStyles}
                        containerStyle={{
                          marginBottom: 4,
                        }}
                        id="repeat_password"
                        placeholder="Repeat Your Password"
                        type={visible.repeat ? "text" : "password"}
                        invalid={!!errors.repeat_password}
                        suffixIcon={
                          <FormIcon
                            isVisible={visible.repeat}
                            onClick={() => changeVisible("repeat")}
                          />
                        }
                      />
                      {errors.repeat_password && (
                        <ErrorText>{errors.repeat_password.message}</ErrorText>
                      )}
                    </Box>
                  )}
                />
              </Box>
              <Box>
                <StyledValidationHeading variant="caption">
                  Your Password must contain:
                </StyledValidationHeading>
                <StyledValidationRulesList>
                  <StyledValidationRulesItem
                    isMatching={validationRules.length}
                  >
                    Minimum 8 characters
                  </StyledValidationRulesItem>
                  <StyledValidationRulesItem>
                    <StyledValidationHeading>
                      At least 3 of the following:
                    </StyledValidationHeading>
                    <StyledNestedValidationRulesList>
                      <StyledValidationRulesItem
                        isMatching={validationRules.lowerCase}
                      >
                        Lower case letters (a-z)
                      </StyledValidationRulesItem>
                      <StyledValidationRulesItem
                        isMatching={validationRules.upperCase}
                      >
                        Upper case letters (A-Z)
                      </StyledValidationRulesItem>
                      <StyledValidationRulesItem
                        isMatching={validationRules.numbers}
                      >
                        Numbers (0-9)
                      </StyledValidationRulesItem>
                      <StyledValidationRulesItem
                        isMatching={validationRules.specialSymbols}
                      >
                        Special symbols (e.g., !@#$%^&*)
                      </StyledValidationRulesItem>
                    </StyledNestedValidationRulesList>
                  </StyledValidationRulesItem>
                </StyledValidationRulesList>
              </Box>
            </StyledWrapper>
            <StyledButton
              variant="contained"
              type="submit"
              startIcon={
                isLoading ? (
                  <CircularProgress
                    size="small"
                    style={{
                      color: theme.palette.primary.main,
                      width: 20,
                      height: 20,
                    }}
                  />
                ) : null
              }
            >
              Reset Password
            </StyledButton>
          </form>
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <StyledLink variant="caption">Back to Forgot Password</StyledLink>
          </Link>
        </FormContainer>
      </Form>
    </RootContainer>
  );
}
