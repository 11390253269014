import { useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Dataset } from "src/types/Dataset";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { snackbar } from "src/view/toaster";
import Tasks from "./Tasks";
import DropdownArrow from "./DropdownArrow";
import IsLeakedTag from "src/view/components/IsLeakedTag";
import DatasetItemActions from "./DatasetItemActions";
import { ReactComponent as ThreeDots } from "src/assets/icons/three-dots.svg";

const DatasetRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen?: boolean }>(({ isOpen, theme }) => ({
  boxShadow: isOpen //
    ? "0px 10px 54px 0px rgba(160, 185, 208, 0.30)"
    : "none",
  "&:hover": {
    backgroundColor: isOpen //
      ? "#FFFFFF"
      : theme.palette.grey[200],
  },

  "& > td": {
    backgroundColor: "transparent",
    color: theme.palette.grey[800],
    padding: "12px 4px",
    border: "unset",

    "&:first-of-type": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      paddingLeft: "18px",
    },
    "&:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "18px",
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "& > *": {
    color: theme.palette.primary.main,
  },
}));

const TaskDetailRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen?: boolean }>(({ isOpen }) => ({
  backgroundColor: "#FFFFFF",
  boxShadow: isOpen //
    ? "0px 3px 20px 0px rgba(160, 185, 208, 0.30)"
    : "none",

  "& > td": {
    // this boxshadow kills boxshadow that falls to the top before the table
    boxShadow: isOpen //
      ? "0px -16px 0 white"
      : "none",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const NameTableCell = styled(TableCell)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  ".MuiTypography-root": {
    fontSize: "16px",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface Props {
  dataset: Dataset;
}

const DatasetItem = ({ dataset }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { deleteDatasetMetadata, reindexDataset } = useDatasetMetadata();
  const { mutate: reindex, isLoading: isReindexLoading } = reindexDataset();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpenMenu = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event?: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteDataset = () => {
    deleteDatasetMetadata.mutate(
      { id: dataset.id },
      {
        onSuccess: () => {
          snackbar.success("Dataset successfully deleted");
        },
        onError: () => {
          snackbar.error("Failed to delete dataset");
        },
        onSettled: () => setAnchorEl(null),
      }
    );
  };

  const handleReindexDataset = (replaceDataset = true) => {
    reindex(
      { body: { dataset_id: dataset.id, replace_dataset: replaceDataset } },
      {
        onSuccess: () => {
          snackbar.success("Reindex request successfully created");
          setIsOpen(false);
        },
        onError: () => {
          snackbar.error("Failed to create reindex request");
        },
        onSettled: () => setAnchorEl(null),
      }
    );
  };

  return (
    <>
      <DatasetRow isOpen={isOpen} key={dataset.id}>
        <TableCell>
          <DropdownArrow isOpen={isOpen} setIsOpen={setIsOpen} />
        </TableCell>
        <NameTableCell>
          <Typography>{dataset.name}</Typography>
          <IsLeakedTag dataset={dataset} />
        </NameTableCell>
        <TableCell>
          {dataset.doc_types.join(", ").toUpperCase() || "---"}
        </TableCell>
        <TableCell>{dataset.sensitivity}</TableCell>
        <TableCell sx={{ overflow: "visible !important" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {isReindexLoading ? (
              <Box sx={{ display: "inline-flex", padding: "0 16px 0 0" }}>
                <CircularProgress size={12} sx={{ padding: 0 }} />
              </Box>
            ) : (
              <StyledIconButton
                size="small"
                onClick={(e) => handlePopoverOpen(e)}
                aria-label="Open dataset item actions"
              >
                <ThreeDots />
              </StyledIconButton>
            )}

            <DatasetItemActions
              isOpen={isOpenMenu}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              onDeleteDataset={handleDeleteDataset}
              onReindexDataset={handleReindexDataset}
              datasetName={dataset?.name}
            />
          </Box>
        </TableCell>
      </DatasetRow>

      <TaskDetailRow isOpen={isOpen}>
        <TableCell colSpan={5}>
          {isOpen && <Tasks dataset={dataset} close={() => setIsOpen(false)} />}
        </TableCell>
      </TaskDetailRow>
    </>
  );
};

export default DatasetItem;
