import React, {
  CSSProperties,
  FormEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "usehooks-ts";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Filters from "src/view/search-result/components/Filters";
import SearchDropdown from "src/view/components/Search/SearchDropdown";
import SearchControls from "./SearchControls";
import QueryBox from "./QueryBox";
import { useFocus } from "./context/focus";
import { useClickAway } from "src/modules/shared/hooks";
import { useSearch } from "src/modules/api/search";
import { QueryItem } from "src/types/BulkSearch";
import {
  getFinalQuery,
  getOperatorColor,
  checkParenthesesBalancedInQuery,
  removePreviousQuery,
  handleQuotes,
  handleParentheses,
  handleOtherOperators,
  parseQueryString,
} from "./utils";
import { ReactComponent as ImgIconSearch } from "src/assets/icons/search.svg";
import { useDebounce } from "../../../hooks/useDebounce";
import searchStoreSelectors from "src/modules/api/search/store/selectors";
import searchStoreActions from "src/modules/api/search/store/actions";

const drawerWidth = 370;
const initialQueryB: QueryItem[] = [
  {
    type: "term", // operator | term
    value: "",
    quote: false,
    fuzziness: 0,
  },
];

const StyledDrawer = styled(Drawer)(() => ({
  height: "100%",
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
  "& .MuiModal-backdrop": {
    backgroundColor: "transparent",
  },
}));

const StyledContainer = styled("div")(() => ({
  borderRadius: 0,
  width: "100%",
}));

const SearchForm = styled("form")<{
  showDropdown: boolean;
}>(({ theme, showDropdown }) => ({
  position: "relative",
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.primary.contrastText,
  marginTop: -1,
  marginLeft: 0,
  padding: "18px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: showDropdown ? "4px 4px 4px 0" : "4px",
  transition: "border-radius 300ms cubic-bezier(0.4, 0, 0.2, 1)",
  zIndex: 2,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const FilterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: 30,
  width: 30,
  height: 30,
  margin: 4,
  padding: 0,
  position: "absolute",
  left: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.common.white,
  zIndex: 3,
  "&:hover": {
    color: theme.palette.grey[400],
    backgroundColor: "transparent",
  },
  "& svg": {
    height: 24,
    width: 24,
  },
}));

const InputWrapper = styled("div")<{
  queryB: QueryItem[];
  showFilters?: boolean;
  showBookmark?: boolean;
}>(({ queryB, showFilters, showBookmark }) => {
  let widthCalc = 0;
  if (queryB.length >= 1 && queryB.some((i) => i.value)) widthCalc += 60; // Add 60 if show Clear button
  if (showFilters) widthCalc += 60; // Add 60 if showFilters is true
  if (showBookmark) widthCalc += 42; // Add 42 if showBookmark is true

  return {
    display: "flex",
    alignItems: "center",
    width: `calc(100% - ${widthCalc}px)`,
  };
});

const QueriesBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "34px",
  width: "auto",
  overflowY: "hidden",
  overflowX: "auto",
  className: "queries-box-class",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const OperatorTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "areParenthesesBalanced",
})<{
  query: QueryItem;
  areParenthesesBalanced: boolean;
  isSelected: boolean;
}>(({ query, areParenthesesBalanced, theme, isSelected }) => {
  const isParenthesis = query.value === "(" || query.value === ")";
  const isOpeningParenthesis = query.value === "(";
  const isClosingParenthesis = query.value === ")";
  const isOperator = query.type === "operator";

  return {
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    backgroundColor: isSelected ? theme.palette.grey[400] : "transparent",
    borderRadius: theme.shape.borderRadius,
    justifyContent: "center",
    padding: isOperator && isParenthesis ? 0 : theme.spacing(0.5),
    marginRight: isOperator && isOpeningParenthesis ? 0 : theme.spacing(0.5),
    marginLeft: isOperator && isClosingParenthesis ? 0 : theme.spacing(0.5),
    color: getOperatorColor(query, areParenthesesBalanced, isSelected),
  };
});

interface Props {
  customStyles?: Record<string, CSSProperties>;
  showFilters?: boolean;
  showBookmark?: boolean;
  icon?: ReactElement;
}

const SearchBox = ({
  customStyles,
  showFilters = true,
  showBookmark = false,
  icon = <ImgIconSearch />,
}: Props) => {
  const dispatch = useDispatch();
  const { updateContent, handleSearch, resetFilters } = useSearch();
  const variables = useSelector(searchStoreSelectors.selectVariables);
  const storeQueryB = useSelector(searchStoreSelectors.selectQueryB);
  const [queryB, setQueryB] = useState(storeQueryB);

  const { focusedQuery, setFocusedQuery } = useFocus();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [openFilter, toggleOpenFilter] = useToggle();
  const [showDropdown, setShowDropdown] = useState(false);
  const [openedTipsModalEl, setOpenedTipsModalEl] = useState(null);

  const searchValue = useMemo(() => getFinalQuery(queryB), [queryB]);
  const areParenthesesBalanced = useMemo(
    () => checkParenthesesBalancedInQuery(queryB),
    [queryB]
  );

  const updateStoreQueryB = useDebounce<QueryItem[]>((currentQueryB) => {
    searchStoreActions.setQueryB(currentQueryB)(dispatch);
  }, 300);

  useEffect(() => updateStoreQueryB(queryB), [queryB]);

  useEffect(() => {
    if (variables?.content?.length >= 1) {
      const newQueryB: QueryItem[] = parseQueryString(variables.content);
      setQueryB(newQueryB);
      setFocusedQuery({ index: null });
    }
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/search")) {
      setQueryB(initialQueryB);
      setFocusedQuery({ index: 0 });
    }
  }, [location.pathname]);

  const handleWrapperClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const targetElement = e.target as Element;
      const clickedInput = targetElement.closest("input");

      if (
        targetElement.closest("button") ||
        targetElement.closest(".queries-box-class") ||
        targetElement.closest(".operator-class") ||
        (clickedInput && document.activeElement === clickedInput) ||
        openFilter
      ) {
        return;
      }
      setShowDropdown(true);
      setFocusedQuery({ index: queryB.length - 1 });
    },
    [queryB.length, setFocusedQuery, openFilter]
  );

  const handleOpenTipsModal = (event: React.MouseEvent<HTMLElement>) => {
    setOpenedTipsModalEl(event.currentTarget);
  };

  const handleCloseTipsModal = () => {
    setOpenedTipsModalEl(null);
    setFocusedQuery({ index: queryB.length - 1 });
  };

  const handleAddOperator = useCallback(
    (operator: string) => {
      setQueryB((prev) => {
        const isPreviousQueryEmptyTerm =
          prev[focusedQuery.index]?.type === "term" &&
          !prev[focusedQuery.index]?.value;
        const insertionIndex =
          focusedQuery.index !== null ? focusedQuery.index + 1 : prev.length;

        let newQueryB;

        if (operator === "()") {
          newQueryB = handleParentheses(
            prev,
            isPreviousQueryEmptyTerm,
            focusedQuery,
            insertionIndex,
            areParenthesesBalanced
          );
          setFocusedQuery({
            index: isPreviousQueryEmptyTerm
              ? focusedQuery.index + 1
              : insertionIndex + 1,
          });
        } else if (operator === '""') {
          newQueryB = handleQuotes(
            prev,
            isPreviousQueryEmptyTerm,
            focusedQuery,
            insertionIndex
          );
          setFocusedQuery({
            index: isPreviousQueryEmptyTerm
              ? focusedQuery.index
              : insertionIndex,
          });
        } else {
          newQueryB = handleOtherOperators(prev, operator, insertionIndex);
          setFocusedQuery({ index: insertionIndex + 1 });
        }
        return newQueryB;
      });
    },
    [areParenthesesBalanced, focusedQuery, setFocusedQuery]
  );

  const handleClickQueryBox = useCallback(
    (index: number) => {
      setFocusedQuery({ index });
      setShowDropdown(true);
    },
    [setFocusedQuery]
  );

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ) => {
      const inputValue = e.target.value;

      setQueryB((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...item,
                value: inputValue,
                ...(!inputValue && { fuzziness: 0 }),
                ...(inputValue.includes('"') && { quote: true }),
              }
            : item
        )
      );
      if (queryB.length === 1) {
        setFocusedQuery({ index });
      }
    },
    [queryB.length, setFocusedQuery]
  );

  const handleRemovePreviousQuery = useCallback(
    (index: number) => {
      removePreviousQuery({ index, setQueryB, setFocusedQuery });
    },
    [queryB, setQueryB, setFocusedQuery]
  );

  const handleChangeFuzziness = useCallback(
    (fuzziness: number) => {
      setQueryB((prev) =>
        prev.map((item, idx) =>
          idx === focusedQuery.index
            ? {
                ...item,
                fuzziness,
                quote: fuzziness !== 0 ? false : item.quote,
                value:
                  fuzziness !== 0 ? item.value.replace(/"/g, "") : item.value,
              }
            : item
        )
      );
    },
    [focusedQuery]
  );

  const handleSaveQuery = (actualQueryB?: QueryItem[]) => {
    const finalSearchValue = getFinalQuery(actualQueryB ?? storeQueryB);
    updateContent(finalSearchValue);
  };

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!searchValue || !areParenthesesBalanced.balance) return;

      searchStoreActions.setQueryB(queryB)(dispatch);
      handleSaveQuery(queryB);

      handleSearch();
    },
    [searchValue, areParenthesesBalanced, handleSearch, queryB]
  );

  const handleResetAllFilters = useCallback(() => {
    resetFilters();
    setQueryB(initialQueryB);
    setFocusedQuery({ index: 0 });
  }, [resetFilters, setFocusedQuery, setQueryB]);

  useClickAway(wrapperRef, () => {
    setFocusedQuery({ index: null });
  });

  useEffect(() => handleSaveQuery(), [storeQueryB]);

  useEffect(() => {
    if (focusedQuery.index === null && !openedTipsModalEl && !openFilter) {
      setShowDropdown(false);
    }
  }, [focusedQuery]);

  const handleBackspaceKey = (event: KeyboardEvent) => {
    if (queryB[focusedQuery.index].type === "operator") {
      event.preventDefault();
      removePreviousQuery({
        index: focusedQuery.index + 1,
        setQueryB,
        setFocusedQuery,
      });
    }
  };

  const handleSpaceKey = (event: KeyboardEvent) => {
    const focusedItem = queryB[focusedQuery.index];
    if (focusedItem?.type === "operator") {
      event.preventDefault();
      setQueryB((prev) => {
        const updatedQueryB = [...prev];
        const itemsAfterFocused = updatedQueryB.slice(focusedQuery.index + 1);
        updatedQueryB.splice(focusedQuery.index + 1, 0, {
          type: "term",
          value: "",
          quote: false,
          fuzziness: 0,
        });
        updatedQueryB.splice(
          focusedQuery.index + 2,
          itemsAfterFocused.length,
          ...itemsAfterFocused
        );
        return updatedQueryB;
      });
      setFocusedQuery({ index: focusedQuery.index + 1 });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === "Backspace" && focusedQuery.index) {
        handleBackspaceKey(event);
      }
      if (event.code === "Space" && focusedQuery.index !== null) {
        handleSpaceKey(event);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedQuery.index, queryB]);

  return (
    <StyledContainer
      ref={wrapperRef}
      onClick={(e) => handleWrapperClick(e)}
      sx={customStyles?.common}
    >
      <StyledDrawer
        variant="temporary"
        onClose={toggleOpenFilter}
        anchor="right"
        open={openFilter}
      >
        <Filters disableCounts={true} handleClose={toggleOpenFilter} />
      </StyledDrawer>
      <SearchForm
        onSubmit={handleSubmit}
        sx={customStyles?.form}
        showDropdown={showDropdown}
      >
        <InputWrapper
          sx={customStyles?.wrapper}
          queryB={queryB}
          showFilters={showFilters}
          showBookmark={showBookmark}
        >
          <FilterButton sx={customStyles?.icon} type={"submit"}>
            {icon}
          </FilterButton>
          <QueriesBox>
            {queryB.length > 0 &&
              queryB.map((query, index) =>
                query.type !== "operator" ? (
                  <QueryBox
                    key={`term-${index}`}
                    index={index}
                    query={query}
                    queryB={queryB}
                    onRemovePreviousQuery={handleRemovePreviousQuery}
                    onChangeInput={handleInputChange}
                    onClick={handleClickQueryBox}
                    inputStyles={customStyles?.input}
                  />
                ) : (
                  <OperatorTypography
                    query={query}
                    areParenthesesBalanced={areParenthesesBalanced.balance}
                    key={index}
                    isSelected={index === focusedQuery.index}
                    className="operator-class"
                    onClick={() => handleClickQueryBox(index)}
                  >
                    {query.value}
                  </OperatorTypography>
                )
              )}
          </QueriesBox>
        </InputWrapper>
        <SearchControls
          hasQuery={queryB.length > 1 || !!queryB[0]?.value}
          onClear={handleResetAllFilters}
          onToggleFilter={toggleOpenFilter}
          showFilters={showFilters}
          showBookmark={showBookmark}
          customStyles={customStyles?.controls}
          iconStyles={customStyles?.icon}
        />
      </SearchForm>
      <SearchDropdown
        queryB={queryB}
        handleChangeFuzziness={handleChangeFuzziness}
        onAddOperator={handleAddOperator}
        showDropdown={showDropdown}
        customStyles={customStyles?.dropdown}
        openedTipsModalEl={openedTipsModalEl}
        onOpenTipsModalEl={handleOpenTipsModal}
        onCloseTipsModalEl={handleCloseTipsModal}
      />
    </StyledContainer>
  );
};

export default SearchBox;
