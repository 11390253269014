import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tooltip } from "src/components/ui";
import { ReactComponent as HighlightFailureIcon } from "src/assets/icons/highlight.svg";

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

const StyledHighlightFailureIcon = styled(HighlightFailureIcon)(
  ({ theme }) => ({
    color: theme.palette.grey[400],
    width: "12px",
    height: "12px",
    padding: "4px",
    cursor: "pointer",
  })
);

const HighlightingFailure = () => {
  return (
    <Tooltip
      arrow={true}
      placement="top-end"
      enterDelay={0}
      title={
        <StyledTooltipContent>
          Due to the large amount of text, we are unable to show you where your
          search matched within this document.
        </StyledTooltipContent>
      }
    >
      <StyledHighlightFailureIcon />
    </Tooltip>
  );
};

export default HighlightingFailure;
