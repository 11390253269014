import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { add, addDays, format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { snackbar } from "src/view/toaster";
import CustomDatePicker from "src/view/components/DatePicker/CustomDatePicker";
import { useUsers } from "src/modules/api/users";
import QueryKeys from "src/modules/api/queryKeys";
import { User } from "src/types/UserGroup";
import { convertToUTCWithTime } from "src/view/components/DatePicker/utils/convert-to-UTC-with-time";
import { queryClient } from "src/index";

interface Props extends PropsWithChildren {
  user: User;
  editable: boolean;
  onEdit?: ({ user, newDate }: { user: User; newDate: string }) => void;
  openDatepickerId: string | null;
  toggleDatepickerVisibility: (id: string | null) => void;
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "editable",
})<{
  editable?: boolean;
}>(({ editable, theme }) => ({
  cursor: editable ? "pointer" : "default",
  display: "flex",
  alignItems: "center",
  ".membership-renewal-date-datepicker-trigger": {
    opacity: 0,
    height: 30,
    width: 30,
    padding: 0,
    "& svg": {
      color: theme.palette.primary.main,
      height: 18,
      width: 18,
    },
  },
}));

const EditSubscriptionDate = ({
  user,
  editable,
  onEdit,
  children,
  openDatepickerId,
  toggleDatepickerVisibility,
}: Props) => {
  const { updateUser } = useUsers();
  const [selectedDate, setSelectedDate] = useState<Date>(
    parseISO(user.membership_renewal_date)
  );
  const minDate = addDays(new Date(), 1);
  const { mutateAsync: updateUserAsync } = updateUser();

  const datepickerRef = useRef<HTMLDivElement>(null);

  const handleDateSelect = async (date: Date) => {
    const utcDateString = convertToUTCWithTime(date);

    const res = await updateUserAsync({
      id: user.id,
      payload: { membership_renewal_date: utcDateString },
    });

    if (!res.error) {
      snackbar.success("User's subscription date has been changed");
      queryClient.invalidateQueries([QueryKeys.USERS]);
      onEdit && onEdit({ user, newDate: utcDateString });
    }

    setSelectedDate(date);
    toggleOpen();
  };

  const isOpen = openDatepickerId === user.id;

  const toggleOpen = () => toggleDatepickerVisibility(user.id);

  const handleDateChangeRaw = (
    event?:
      | React.FocusEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target as Node)
      ) {
        toggleDatepickerVisibility(null);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDatepickerVisibility]);

  return (
    <Container ref={datepickerRef} editable={editable}>
      <Typography
        component="div"
        sx={{
          whiteSpace: "nowrap",
          color: "inherit",
          fontSize: "inherit",
          fontWeight: "inherit",
          width: "90px",
          display: "block",
        }}
      >
        {format(
          toZonedTime(new Date(user.membership_renewal_date), "UTC"),
          "MMM dd, yyyy"
        )}
      </Typography>
      {children}

      {editable && (
        <CustomDatePicker
          open={openDatepickerId === user.id}
          setOpen={toggleOpen}
          selected={selectedDate}
          onChange={handleDateSelect}
          minDate={minDate}
          maxDate={add(new Date(), { years: 1 })}
          showStepButton={true}
          showSaveButton={true}
          onClose={() => toggleDatepickerVisibility(null)}
          disabled={!editable}
          iconButton={true}
          iconClassName="membership-renewal-date-datepicker-trigger"
          onChangeRaw={handleDateChangeRaw}
          popperProps={{
            strategy: "fixed",
          }}
          iconStyles={{
            opacity: 0,
          }}
          sx={{
            position: "relative",
            marginBottom: 0,
            width: 0,
            "& .react-datepicker": {
              top: 40,
            },
          }}
        />
      )}
    </Container>
  );
};

export default EditSubscriptionDate;
