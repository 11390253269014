import React from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Container, ListItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import whiteLogo from "src/assets/logo-white.svg";
import logo from "src/assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from "src/modules/api/auth";
import { ReactComponent as ImgIconSearchHistory } from "src/assets/icons/search_history.svg";
import { usePostHog } from "posthog-js/react";
import { useSearchHistory } from "src/modules/api/searchHistory";
import { gotoProfilePage } from "src/utils/navigationUtils";
import theme from "src/theme";
import SearchHistoryPopup from "src/view/search-result/components/SearchHistory/SearchHistoryPopup";
import { SearchHistory } from "src/types/Search";
import { formatVariablesSearchParam } from "src/modules/api/search/SearchProvider";
import { emptyQueryVariables } from "src/modules/api/search";
import { HogEvent } from "src/types/PosthogEvents";

const LogoWrapper = styled("a")(() => ({
  height: 50,
  width: 160,
  minWidth: 160,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

const StyledContainer = styled(Container)(() => ({
  paddingLeft: "0px",
  paddingRight: "0px",
  backgroundColor: "transparent",
}));

export default function Header(props: { darkTheme?: boolean }) {
  const { user, logout } = useUser();
  const { getSearchHistory } = useSearchHistory();
  const { refetch: refetchSearchHistory } = getSearchHistory;
  const { darkTheme } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isOnTop, setIsOnTop] = useState(true);
  const [showSearchHistoryPopup, setShowSearchHistoryPopup] = useState(false);
  const posthog = usePostHog();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();

  const listenScrollEvent = () => {
    if (window.scrollY < 15) {
      return setIsOnTop(true);
    } else if (window.scrollY > 50) {
      return setIsOnTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const handleProfileMenuOpen = (event: unknown) => {
    setAnchorEl(event["currentTarget"]);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: unknown) => {
    setMobileMoreAnchorEl(event["currentTarget"]);
  };

  const openSidebar = () => {
    document.dispatchEvent(new CustomEvent("openSideBar"));
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const toggleShowSearchHistory = () => {
    if (!showSearchHistoryPopup) {
      refetchSearchHistory();
    }
    setShowSearchHistoryPopup(!showSearchHistoryPopup);

    if (showSearchHistoryPopup)
      posthog.capture(HogEvent.SEARCH_HISTORY_POPUP_OPENED);
    else posthog.capture(HogEvent.SEARCH_HISTORY_POPUP_CLOSED);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ListItem sx={{ cursor: "default" }}>{user?.email}</ListItem>
      <MenuItem onClick={() => gotoProfilePage(navigate, handleMenuClose)}>
        My account
      </MenuItem>
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleSearchInHistory = (data: SearchHistory) => {
    const {
      id,
      content,
      result_count,
      result_count_relation,
      timestamp,
      ...filters
    } = data;
    const newVariables = {
      content: data.content,
      filters: {
        ...emptyQueryVariables.filters,
        ...filters,
      },
    };

    const searchParams = formatVariablesSearchParam(newVariables);
    return window.open(`${window.location.origin}/search?q=${searchParams}`);
  };

  return (
    <>
      <SearchHistoryPopup
        isSearchHistoryOpen={showSearchHistoryPopup}
        onClosePopup={toggleShowSearchHistory}
        handleSearch={handleSearchInHistory}
      />

      <AppBar
        position="fixed"
        sx={{
          backgroundColor: darkTheme
            ? "rgba(18, 41, 69, 1})"
            : isOnTop
            ? "transparent"
            : "#122945",
          boxShadow: "none",
          py: "10px",
        }}
      >
        <StyledContainer>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color={darkTheme || !isOnTop ? "inherit" : "primary"}
              aria-label="open drawer"
              sx={{ mr: 0 }}
              onClick={openSidebar}
            >
              <MenuIcon />
            </IconButton>
            <LogoWrapper href="/">
              <img src={darkTheme || !isOnTop ? whiteLogo : logo} />
            </LogoWrapper>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="Recent Searches of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={toggleShowSearchHistory}
                color="inherit"
              >
                <ImgIconSearchHistory
                  style={{
                    color: isOnTop
                      ? theme.palette.primary.main
                      : theme.palette.common.white,
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color={darkTheme || !isOnTop ? "inherit" : "primary"}
              >
                <AccountCircle />
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color={darkTheme || !isOnTop ? "inherit" : "primary"}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
          {renderMobileMenu}
          {renderMenu}
        </StyledContainer>
      </AppBar>
    </>
  );
}
