import { useEffect, useMemo } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useIntersectionObserver } from "usehooks-ts";
import { styled } from "@mui/material/styles";

import { Colors } from "src/view/constants/colors";
import { useUserGroups } from "src/modules/api/userGroups";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";

import Table from "./Table";

const StyledLoadingContainer = styled("div")({
  minHeight: 200,
  backgroundColor: "#FFFFFF88",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

interface Props {
  searchKey: string;
}

const GroupsTable = ({ searchKey }: Props) => {
  const { user } = useUser();
  const { getGroupsInfinite, deleteGroup } = useUserGroups();
  const {
    data: groups,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = getGroupsInfinite({ searchKey });
  const { mutateAsync: deleteGroupAsync } = deleteGroup();

  const availableGroups = useMemo(() => {
    const allGroups = groups?.pages?.flatMap((page) => page?.results) || [];
    if (user?.role === Role.ADMIN) {
      return allGroups;
    }
    return allGroups.filter((group) =>
      user?.group_admins?.includes(group.group_id)
    );
  }, [groups, user]);

  const { ref: endOfListRef, isIntersecting } = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage]);

  if (isLoading)
    return (
      <StyledLoadingContainer>
        <CircularProgress />
      </StyledLoadingContainer>
    );

  if (!availableGroups?.length)
    return (
      <StyledLoadingContainer>
        <Typography fontSize="12px" fontWeight="700" color={Colors.textDark}>
          No results
        </Typography>
      </StyledLoadingContainer>
    );

  return (
    <Box>
      <Table
        groups={availableGroups}
        isLoading={isLoading}
        deleteGroupAsync={deleteGroupAsync}
      />

      <div ref={endOfListRef} />

      {isFetchingNextPage && (
        <Box
          sx={{
            display: "flex",
            padding: "30px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default GroupsTable;
