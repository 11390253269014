import { PropsWithChildren } from "react";
import { ConfirmDialog } from "src/components/ui";
import {
  GroupDatasetPermissionsFields,
  GroupFeatures,
} from "src/types/UserGroup";

interface Props extends PropsWithChildren {
  description: string;
  handleUpdate?: (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => void;
  handleUpdateFeatures?: (checked: boolean, featureName: GroupFeatures) => void;
}

const DialogEnableLeaked = ({
  children,
  description,
  handleUpdateFeatures,
}: Props) => {
  return (
    <ConfirmDialog
      title="Enable Leaked Dataset Feature"
      description={description}
      confirmText="Enable"
      cancelText="Cancel"
      onConfirm={() => {
        handleUpdateFeatures(true, GroupFeatures.LEAKED_DATASETS);
      }}
    >
      {children}
    </ConfirmDialog>
  );
};

export default DialogEnableLeaked;
