import { ButtonEffectColor, ButtonVariant } from "./types";
import theme from "../../../theme";

export const buttonBg: Record<ButtonVariant, ButtonEffectColor> = {
  primary: {
    main: theme.palette.primary.main,
    hover: "rgba(18, 41, 69, 0.914)",
    active: "",
    disabled: theme.palette.skyMist.lighter,
  },
  secondary: {
    main: theme.palette.mist.light,
    hover: theme.palette.skyMist.light,
    active: theme.palette.skyMist.main,
    disabled: theme.palette.skyMist.lighter,
  },
  text: {
    main: "none",
    active: "",
    hover: "rgba(18, 41, 69, 0.04)",
    disabled: theme.palette.skyMist.lighter,
  },
};

export const buttonColor: Record<ButtonVariant, ButtonEffectColor> = {
  primary: {
    main: theme.palette.common.white,
    hover: theme.palette.common.white,
    active: theme.palette.common.white,
    disabled: theme.palette.grey["600"],
  },
  secondary: {
    main: theme.palette.primary.main,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.main,
    disabled: theme.palette.grey["600"],
  },
  text: {
    main: theme.palette.neutral.main,
    hover: theme.palette.neutral.main,
    active: theme.palette.neutral.main,
    disabled: theme.palette.grey["600"],
  },
};
