import { useMemo, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import DataGrid from "src/view/components/DataGrid";
import UserListActions from "../UserListActions";
import InfoTooltip from "../InfoTooltip";
import EditSubscription from "../EditSubscription";
import { User } from "src/types/UserGroup";
import { UserNameCell } from "./UserNameCell";

interface Props {
  userList: User[];
  onRemoveUser?: (user: User) => void;
  onResendEmail?: (data: { user_id: string }) => void;
  onEditUserSubscriptionDate?: ({
    user,
    newDate,
  }: {
    user: User;
    newDate: string;
  }) => void;
}

const StyledEmptyState = styled(Box)({
  width: "100%",
  marginTop: 16,
  padding: 10,
  minHeight: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  fontFamily: "Inter, sans-serif",
  color: theme.palette.primary.main,
}));

const TableItself = ({
  userList,
  onRemoveUser,
  onResendEmail,
  onEditUserSubscriptionDate,
}: Props) => {
  const apiRef = useGridApiRef();
  const [columnsState, setColumnsState] = useState<
    Record<string, Record<string, boolean>>
  >({});

  const columns = useMemo<GridColDef<User>[]>(() => {
    const updateColumnsState = (fieldName: string, id: string) => {
      return (open: boolean) => {
        /*
          MUI DataGrid does not support setting minWidth: fit-content for GridCell,
          so we manually set minWidth to adjust the size of the GridCell.
        */
        setColumnsState((prev) => {
          if (!prev[fieldName]) {
            prev[fieldName] = {};
          }
          prev[fieldName][id] = open;

          return { ...prev };
        });
      };
    };

    return [
      {
        field: "name",
        headerName: "Name",
        renderCell: ({ row }) => (
          <UserNameCell
            user={row}
            editing={!!columnsState["name"]?.[row.id]}
            setEditing={updateColumnsState("name", row.id)}
          />
        ),
        flex: 1,
        minWidth: Object.values(columnsState["name"] ?? {}).filter(Boolean)
          .length
          ? 360
          : 240,
      },
      {
        field: "email",
        headerName: "Email Address",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "membership_renewal_date",
        headerName: "Active Until",
        renderCell: ({ row }) => {
          return (
            <EditSubscription
              row={row}
              onEditUserSubscriptionDate={onEditUserSubscriptionDate}
            />
          );
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 120,
        align: "center",
        renderCell: ({ row }) => (
          <UserListActions
            user={row}
            onRemoveUser={onRemoveUser}
            onResendEmail={onResendEmail}
            editing={!!columnsState["name"]?.[row.id]}
            setEditing={updateColumnsState("name", row.id)}
          />
        ),
      },
    ];
  }, [columnsState]);

  if (!userList.length) {
    return (
      <StyledEmptyState>
        <StyledDescription>No Results</StyledDescription>
      </StyledEmptyState>
    );
  }

  return (
    <DataGrid
      apiRef={apiRef}
      rows={userList}
      columns={columns as GridColDef[]}
      onCellKeyDown={(_params, muiEvent) => {
        muiEvent.defaultMuiPrevented = true;
      }}
      hideFooter
      disableRowSelectionOnClick
      disableColumnMenu
      components={{
        ColumnHeaderFilterIconButton: (column) => {
          if (column.field === "membership_renewal_date") {
            return (
              <InfoTooltip
                title={
                  "The date after which a user's account is no longer active."
                }
              />
            );
          }
        },
      }}
      sx={{
        mt: 2,
        ".MuiDataGrid-columnHeaders": {
          fontSize: "12px",
          borderBottom: "none",
          ".MuiDataGrid-columnHeaderTitleContainerContent": {
            mr: 1,
          },
        },
        ".MuiDataGrid-row": {
          color: "#122945",
          "&:hover .membership-renewal-date-datepicker-trigger": {
            opacity: 1,
          },
        },
        ".MuiDataGrid-virtualScroller": {
          overflow: "visible",
        },
        ".MuiDataGrid-main": {
          overflow: "visible",
        },
        ".MuiDataGrid-cell": {
          overflow: "visible !important",
        },
      }}
    />
  );
};

export default TableItself;
