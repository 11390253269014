import { Fragment } from "react";
import {
  Dataset,
  DatasetIndexingTask,
  DatasetIndexingTasksReturn,
  TaskStatusFiltersKeys,
} from "src/types/Dataset";
import { Box, Table, TableCell, TableRow, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import theme from "src/theme";

import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import StatusFilter from "src/view/indexing/datasetList/components/Tasks/StatusFilter";
import TaskRow from "src/view/indexing/datasetList/components/Tasks/TaskRow";
import { InfiniteData } from "@tanstack/react-query";

const StyledTable = styled(Table)(() => ({
  tableLayout: "fixed",
  borderBottom: "none",
  borderColor: "transparent",

  "&>tbody>tr>td": {
    fontSize: "14px",
    fontWeight: 400,
    padding: "16px 12px",
    borderBottom: "unset",

    "&:first-of-type": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    "&:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  },

  "&>thead>tr>th": {
    fontSize: "12px",
    fontWeight: 600,
    padding: "16px 12px",
  },

  " .ellipsis": {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: "unset",
  },
}));

const TasksTableHead = styled(TableHead)(({ theme }) => ({
  "&>tr>th": {
    color: theme.palette.grey[600],
    fontSize: "12px",
    fontWeight: 600,
    border: "unset",
  },
}));

const TasksTableBody = styled(TableBody)(({ theme }) => ({
  "&>tr": {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

interface Props {
  dataset: Dataset;
  tasks: InfiniteData<DatasetIndexingTasksReturn>;
  filter: TaskStatusFiltersKeys;
  handleSetFilter: (filter: TaskStatusFiltersKeys) => void;
  isFetchingTasks: boolean;
  isFetchingRecordCounts: boolean;
}

const TasksTable = ({
  dataset,
  tasks,
  filter,
  handleSetFilter,
  isFetchingTasks,
  isFetchingRecordCounts,
}: Props) => {
  return (
    <StyledTable>
      <TasksTableHead>
        <TableRow>
          <TableCell width={"40%"}>S3 Path</TableCell>
          <TableCell width={"20%"}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <Typography sx={{ fontWeight: "inherit", fontSize: "inherit" }}>
                Status
              </Typography>
              <StatusFilter filter={filter} setFilter={handleSetFilter} />
            </Box>
          </TableCell>
          <TableCell width={"20%"}>Error Message</TableCell>
          <TableCell width={"10%"} />
        </TableRow>
      </TasksTableHead>
      <TasksTableBody>
        {tasks?.pages?.map((tasksPage) => {
          return (
            <Fragment key={`tasksPage-${tasksPage.page}`}>
              {tasksPage?.results?.length ? (
                tasksPage?.results.map((task: DatasetIndexingTask) => {
                  return (
                    <TaskRow
                      key={task.task_id}
                      task={task}
                      dataset={dataset}
                      isFetchingTasks={isFetchingTasks}
                      isFetchingRecordCounts={isFetchingRecordCounts}
                    />
                  );
                })
              ) : (
                <TableRow sx={{ backgroundColor: "transparent !important" }}>
                  <TableCell sx={{ textAlign: "center" }} colSpan={5}>
                    <Typography
                      sx={{ fontWeight: 400, color: theme.palette.grey[600] }}
                    >
                      No tasks found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          );
        })}
      </TasksTableBody>
    </StyledTable>
  );
};

export default TasksTable;
