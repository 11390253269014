import { Box, Grid, GridProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";

interface StyledDatasetItemProps extends GridProps {
  isLast: boolean;
}

export interface NewDatasetItemProps {
  isLast: boolean;
  name: string;
  datasetId: string;
  created: string;
}

const StyledDatasetItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isLast",
})<StyledDatasetItemProps>(({ theme, isLast }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "10.5px",
  paddingBottom: "10.5px",
  borderBottom: isLast ? "none" : `1px solid ${theme.palette.grey[400]}`,
  cursor: "pointer",
}));

const StyledItemInfo = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontFamily: "Inter, sans-serif",
  lineHeight: "17px",
  fontWeight: "500",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  fontFamily: "Inter, sans-serif",
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
}));

const NewDatasetItem: FC<NewDatasetItemProps> = ({
  name,
  created,
  isLast,
  datasetId,
}) => {
  const navigate = useNavigate();
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data } = getKnowledgeWikis();
  const dataDictionaryId = data.find((item) => item.dataset === datasetId);

  return (
    <StyledDatasetItem
      container
      isLast={isLast}
      onClick={() =>
        navigate(`/data-dictionary/data/${dataDictionaryId.id}`, {
          replace: true,
        })
      }
    >
      <StyledItemInfo>
        <StyledTitle>{name}</StyledTitle>
        <StyledLabel>{"• "}</StyledLabel>
        <StyledLabel>{format(new Date(created), "MMMM do, yyyy")}</StyledLabel>
      </StyledItemInfo>
    </StyledDatasetItem>
  );
};

export default NewDatasetItem;
