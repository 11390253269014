import { Button, Box, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add as AddIcon, Done as DoneIcon } from "@mui/icons-material";

interface Props {
  showStepButton?: boolean;
  showSaveButton?: boolean;
  step: number;
  isAdjustedDateLessThanMaxDate: boolean;
  handleAddThreeMonths: () => void;
  handleSave: () => void;
}

interface DatePickerActionButtonProps extends ButtonProps {
  variantType?: "submit";
}

const DatePickerActionButton = styled(
  ({ variantType, ...rest }: DatePickerActionButtonProps) => (
    <Button {...rest} />
  )
)<DatePickerActionButtonProps>(({ variantType, theme }) => ({
  flex: 1,
  fontSize: "14px",
  padding: "9px",
  color: theme.palette.primary.main,
  backgroundColor: "#E7EAEC",
  borderColor: "#E7EAEC",
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
  "&:disabled": {
    borderColor: "rgba(231, 234, 236, 0.5)",
    color: theme.palette.grey[400],
  },
  ...(variantType === "submit" && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[400],
    },
  }),
}));

const CustomDatePickerFooter = ({
  showStepButton,
  showSaveButton,
  step,
  isAdjustedDateLessThanMaxDate,
  handleAddThreeMonths,
  handleSave,
}: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginTop="8px"
      gap="10px"
    >
      {showStepButton && (
        <DatePickerActionButton
          onClick={handleAddThreeMonths}
          disabled={!isAdjustedDateLessThanMaxDate}
        >
          <AddIcon sx={{ width: "15px", height: "15px", mr: "2px" }} /> Add{" "}
          {step} months
        </DatePickerActionButton>
      )}
      {showSaveButton && (
        <DatePickerActionButton variantType="submit" onClick={handleSave}>
          <DoneIcon sx={{ width: "15px", height: "15px", mr: "2px" }} /> Save
        </DatePickerActionButton>
      )}
    </Box>
  );
};

export default CustomDatePickerFooter;
