import { Ref, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, Typography } from "@mui/material";

import { Colors } from "src/view/constants/colors";

const RootContainer = styled(Box)(() => ({
  width: "100%",
}));

const StyledTextFieldWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  paddingLeft: 10,
  paddingRight: 10,
  background: theme.palette.common.white,
  border: "1px solid #D9D9D9",
  borderRadius: 8,
}));

export const StyledTextFieldLabel = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: Colors.textDark100,
  marginBottom: 10,
}));

interface Props {
  ref: Ref<HTMLDivElement>;
  type?: string;
  label?: string;
  labelStyle?: React.CSSProperties;
  name?: string;
  value?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  helperText?: string;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  containerStyle?: any;
  inputContainerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
  fontSize?: number;
  fontWeight?: number; // TODO: replace with sth like: Property.FontWeight;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

// TODO: CustomTextField vs this?
const MyTextField = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      type,
      label,
      labelStyle = {},
      name,
      value,
      onChange,
      prefixIcon,
      suffixIcon,
      helperText,
      disabled,
      placeholder,
      fullWidth = false,
      multiline = false,
      rows,
      containerStyle = {},
      inputContainerStyle = {},
      inputStyle = {},
      textFieldStyle = {},
      fontSize,
      fontWeight,
      onKeyPress,
    } = props;

    return (
      <RootContainer sx={{ flex: fullWidth ? 1 : "none", ...containerStyle }}>
        {label && (
          <StyledTextFieldLabel style={{ ...labelStyle }}>
            {label}
          </StyledTextFieldLabel>
        )}
        <StyledTextFieldWrapper sx={{ ...inputContainerStyle }}>
          {prefixIcon}
          <TextField
            inputRef={ref}
            name={name}
            type={type}
            value={value}
            disabled={disabled}
            onChange={onChange}
            helperText={helperText}
            variant="filled"
            fullWidth={fullWidth}
            placeholder={placeholder}
            sx={{
              border: "none",
              "& input": {
                paddingTop: 1,
                paddingBottom: 1,
                height: 32,
                ...inputStyle,
              },
              "&:placeholder": { color: "#122945", fontSize: "14px" },
              ...textFieldStyle,
            }}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
                fontWeight: 400,
                zIndex: 0,
              },
              color: "primary",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                background: "none",
                fontSize: fontSize || "12px",
                fontWeight: fontWeight || 700,
              },
            }}
            multiline={multiline}
            rows={rows ? rows : 1}
            onKeyPress={onKeyPress}
          />
          {suffixIcon}
        </StyledTextFieldWrapper>
      </RootContainer>
    );
  }
);

export default MyTextField;
