import { styled } from "@mui/material/styles";
import { IconButton, Typography } from "@mui/material";
import { useToggle } from "usehooks-ts";

import theme from "src/theme";
import { GroupFeatureNames, GroupFeatures } from "src/types/UserGroup";

import { StyledArrowIcon, StyledRowTitle } from "../StyledComponents";

import { FeatureSwitch } from "./FeatureSwitch";
import { featureTooltips } from "./constants";

const StyledRow = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 0",

  borderTop: `1px solid ${theme.palette.grey[400]}`,

  "&:first-of-type": {
    borderTop: "none",
  },
}));

export const StyledFeatureDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "visible",
})<{ visible: boolean }>(({ visible }) => ({
  display: visible ? "block" : "none",
  marginBottom: "16px",
  fontSize: 12,
}));

interface Props {
  featureName: GroupFeatureNames | "Knowledge Wiki";
  featureKey: GroupFeatures;
  handleUpdate: (checked: boolean, value: GroupFeatures) => void;
  isOn: boolean;
  isFeaturesUpdating: boolean;
}

export const FeatureRow = ({
  featureName,
  featureKey,
  handleUpdate,
  isFeaturesUpdating,
  isOn,
}: Props) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  const handleChange = (checked: boolean, value: GroupFeatures) => {
    handleUpdate(checked, value);
  };

  return (
    <>
      <StyledRow>
        <StyledRowTitle>
          <IconButton size="small" onClick={toggleIsOpen}>
            <StyledArrowIcon isOpen={isOpen} />
          </IconButton>

          {featureName}
        </StyledRowTitle>

        <FeatureSwitch
          key={featureKey}
          tooltip={featureTooltips[featureName as GroupFeatureNames]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.checked, featureKey)
          }
          value={isOn}
          isDisabled={isFeaturesUpdating}
        />
      </StyledRow>
      <StyledFeatureDescription visible={isOpen}>
        {featureTooltips[featureName as GroupFeatureNames]}
      </StyledFeatureDescription>
    </>
  );
};
