import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getArrayWithPlainHighlights } from "src/utils/get-highlight-text";
import { StyledHeader } from "./JSONView";
import { StyledFileName } from "./DataTableView";
import HighlightingFailure from "./HighlightingFailure";
import DownloadFile from "./DownloadFile";
import { Tooltip } from "../../../../../components/ui";

export interface ObjectViewProps {
  data: any;
  highlight: Record<string, string[]>;
  fileName: string;
  docId: string;
  type: string;
  getDocumentPreview: () => void;
  isHighlightingFailure: boolean;
}

const StyledContainer = styled(Box)(() => ({
  paddingLeft: "8px",
}));

const KeyText = styled(Typography)(() => ({
  width: "214px",
  textOverflow: "ellipsis",
  fontSize: 12,
  fontWeight: 700,
  color: "#536378",
  marginRight: "20px",
}));

const ValueText = styled(KeyText)(({ theme }) => ({
  width: "auto",
  color: theme.palette.primary.main,
  wordBreak: "break-word",
}));

const ButtonsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

const StyledStack = styled(Stack)(() => ({
  width: "100%",
  marginLeft: 72, // === sx style Stack, ml: 9
}));

const ObjectView = ({
  data,
  highlight,
  isHighlightingFailure,
  type,
  getDocumentPreview,
  fileName,
  docId,
}: ObjectViewProps) => {
  const sentencesToHighlight: RegExpMatchArray[] = getArrayWithPlainHighlights(
    highlight?.content ?? []
  );

  const replaceText = (originalText: string) => {
    if (!sentencesToHighlight.length) return originalText;

    let updatedText = originalText;

    sentencesToHighlight[0].forEach((item: string) => {
      const plainText = item.replace(/<\/?em>/g, "");
      const regex = new RegExp(`${plainText}`, "gi");

      updatedText = updatedText.replace(regex, `<mark>${plainText} </mark>`);
    });

    return updatedText;
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <Tooltip
          arrow
          placement="top-start"
          enterDelay={0}
          title={<StyledTooltipContent>{fileName}</StyledTooltipContent>}
        >
          <StyledFileName onClick={getDocumentPreview}>
            {fileName}
          </StyledFileName>
        </Tooltip>
        <ButtonsContainer>
          {isHighlightingFailure && <HighlightingFailure />}
          <DownloadFile docId={docId} type={type} />
        </ButtonsContainer>
      </StyledHeader>
      <StyledStack direction="column" gap={1}>
        {Object.entries(data).map(([key, value], index) => (
          <Box
            sx={{ display: "inline-flex" }}
            key={`object-view-${key}-${index}`}
          >
            <KeyText>{key} :</KeyText>
            <ValueText
              dangerouslySetInnerHTML={{
                __html: replaceText(value.toString()),
              }}
            />
          </Box>
        ))}
      </StyledStack>
    </StyledContainer>
  );
};

ObjectView.displayName = "ObjectView";

export default ObjectView;
