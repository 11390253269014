import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import MyTextField from "src/view/components/MyTextField";
import { TermItem } from "../QueryGroupEdit/type";
import Header from "../QueryGroupEdit/components/Header";
import TableBody from "./TableBody";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";
import filterActions from "src/modules/bulk-search/filter/searchFilterActions";
import queryGroupActions from "src/modules/bulk-search/query-group/queryGroupActions";
import screeningListActions from "src/modules/bulk-search/screeningList/screeningActions";
import filterSelector from "src/modules/bulk-search/filter/searchFilterSelectors";
import { SearchTerm } from "src/types/BulkSearch";
import { SearchFilter } from "src/types/SearchFilter";
import { Dataset } from "src/types/Dataset";
import { snackbar } from "src/view/toaster";
import { Colors } from "src/view/constants/colors";
import regions from "src/assets/json/regions.json";

const StyledHeader = styled("div")(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

const StyledTHD = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: theme.spacing(1, 2, 1, 1),
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  color: "#9BA5B1",
  textTransform: "capitalize",
  "& img": {
    cursor: "pointer",
  },
  ...props,
}));

const Wrapper = styled(Container)({
  display: "flex",
  flexDirection: "column",
  paddingTop: 24,
  paddingBottom: 24,
  gap: 24,
});

const StyledGroupTitle = styled(Typography)(() => ({
  fontSize: 24,
  lineHeight: "30px",
  fontWeight: "700",
  color: Colors.textGray800,
  marginBottom: 24,
}));

const StyledTableRow = styled(Box, {
  shouldForwardProp: (prop) => prop != "last",
})<{ last?: boolean }>(({ last }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #DFE5E9",
  ...(last && {
    borderBottom: "none",
  }),
}));

const StyledTableCol = styled(Box)(() => ({
  display: "flex",
  height: "auto",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRight: "1px solid #DFE5E9",
  padding: 13,
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  color: Colors.twilight,
}));

const StyledButton = styled(Button)(() => ({
  color: Colors.twilight,
  borderColor: Colors.twilight,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
}));

const StyledIcon = styled("img")(() => ({
  width: 36,
  height: 20,
  objectFit: "contain",
}));

const ModalTableRaw = (props: {
  index: number;
  last: boolean;
  item: SearchTerm;
  data: SearchTerm[];
  handleSearchAll: (id: number, all: boolean) => void;
}) => {
  const { index, last, item, handleSearchAll } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <StyledTableRow
      key={index}
      last={last}
      sx={{
        height: "44px",
        "&:hover": {
          bgcolor: "#F9F9FA",
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <StyledTableCol sx={{ width: "60%" }}>{item.query}</StyledTableCol>
      <StyledTableCol sx={{ width: "15%" }}>
        {format(new Date(item.updated), "MMM dd, yyyy")}
      </StyledTableCol>
      <StyledTableCol sx={{ width: "15%" }}>
        {item.total_results == null ? "-" : item.total_results}
        {item.results_change ? (
          <span style={{ color: Colors.secondary }}>
            +{item.results_change}
          </span>
        ) : (
          ""
        )}
      </StyledTableCol>
      <StyledTableCol
        sx={{
          width: "5%",
          justifyContent: "center",
          borderRight: "none",
        }}
      >
        <LaunchIcon
          action={() => handleSearchAll(index, true)}
          hovered={hovered}
        />
      </StyledTableCol>
    </StyledTableRow>
  );
};

const QueryGroupView = () => {
  const mobileView = useMediaQuery("(max-width:678px)");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupName] = useState(location.state?.groupData?.title || "");
  const [search, setSearch] = useState("");
  const [searchTerms, setSearchTerms] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [ordering, setOrdering] = useState({
    query: 0,
    total_results: 0,
  });
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const filter = useSelector(filterSelector.selectFilter);
  const refData = useRef<Record<string, TermItem>>({});
  const screeningListId = location.state?.data?.id;
  const groupId = location.state?.groupData?.id;

  useEffect(() => {
    getSearchTerms(1, true);
  }, [ordering]);

  const getSearchTerms = async (p: number, clear?: boolean) => {
    if (!groupId) return;
    if (search && search.length < 2) return;
    setLoading(true);
    const res = await BulkSearchService.getSearchItems(
      groupId,
      search,
      ordering,
      p
    );
    setLoading(false);
    if (res) {
      const _data = clear ? res.results : [...searchTerms, ...res.results];
      setSearchTerms(_data);
      setHasNextPage(Math.ceil(res.count / 50) > res.page_number);
      setPage(res.page_number);
      setCount(res.count);
    }
  };

  const goBack = useCallback(() => {
    dispatch(
      filterActions.setFilter({
        dataset_ids: [],
        doc_types: [],
        dataset_types: [],
        countries: [],
        regions: [],
        languages: [],
      })
    );
    const state = { ...location?.state };
    delete state.groupData;
    navigate("/bulk-search/query-group/" + screeningListId, {
      replace: true,
      state,
    });
  }, [navigate, dispatch]);

  const handleSave = () => {
    if (!groupName) {
      snackbar.error("The name is required.");
      return;
    }

    const deletedIds = Object.values(refData.current)
      .filter((term) => term.deleted)
      .map(({ id }) => id);
    Promise.all(deletedIds.map((id) => BulkSearchService.deleteSearchTerm(id)));

    const searchTerms: unknown[] = [];
    Object.values(refData.current)
      .filter((term) => !term.deleted)
      .forEach((term) => {
        const searchTerm = term.items
          .filter(({ value }) => value.trim())
          .map(({ value, match }) => `"${value}"` + (match ? `~${match}` : ""))
          .join(" AND ");
        if (!term.query) {
          searchTerms.push({ id: "", searchTerm });
        } else if (term.query !== searchTerm) {
          searchTerms.push({ id: term.id, searchTerm });
        }
      });

    const filterData = {
      dataset_filters: filter.dataset_ids,
      doc_types: filter.doc_types,
      dataset_types: filter.dataset_types,
      countries: filter.countries,
      regions: filter.regions.map(
        (label) => _.find(regions, { label })?.["value"]
      ),
      languages: filter.languages,
    };

    if (groupId) {
      dispatch(
        queryGroupActions.update(
          {
            name: groupName,
            search_terms: searchTerms,
            ...filterData,
          },
          groupId
        )
      );
    } else {
      dispatch(
        queryGroupActions.create({
          screening_list: screeningListId,
          name: groupName,
          search_terms: searchTerms.map((term) => term["search_term"]),
          ...filterData,
        })
      );
    }

    goBack();
  };

  const handleSearchAll = async (index: number, showList: boolean) => {
    await dispatch(
      screeningListActions.runScreeningListSearch(screeningListId)
    );
    navigate("/bulk-search/query-group/" + screeningListId + "/run", {
      replace: false,
      state: {
        data: searchTerms,
        groupData: location.state.groupData,
        showFullList: showList,
        selected: index,
        page: page,
        ordering: ordering,
        hasNextPage: hasNextPage,
        screeningListId: groupId,
        count: count,
      },
    });
  };

  const handleChangeOrder = (key: string) => {
    const order = { ...ordering };
    order[key] = order[key] == 0 ? 1 : order[key] == 1 ? -1 : 0;
    setOrdering(order);
  };

  const handleChangeSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      getSearchTerms(1, true);
    }
  };

  const handleClickEdit = () => {
    const data = location.state.groupData.data;
    const filter: SearchFilter = {
      dataset_ids:
        data.dataset_filters.map((item: Partial<Dataset>) => item.id) || [],
      doc_types: data.doc_types || [],
      dataset_types: data.dataset_types || [],
      countries: data.countries || [],
      regions:
        regions
          .filter((region) => data?.regions.includes(region.value))
          .map(({ label }) => label) || [],
      languages: data.languages || [],
    };
    dispatch(filterActions.setFilter(filter));
    navigate("/bulk-search/query-group/" + screeningListId + "/edit", {
      replace: false,
      state: { ...location?.state, groupData: location.state.groupData },
    });
  };

  return (
    <Wrapper sx={{ mt: mobileView ? "56px" : "64px" }}>
      <Header onSave={handleSave} onBack={goBack} />
      <Box>
        <StyledGroupTitle>{groupName}</StyledGroupTitle>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <MyTextField
            containerStyle={{ width: "30%", minWidth: "200px" }}
            prefixIcon={
              <img
                alt="search icon"
                src={require("src/assets/images/search-primary.png")}
                style={{ width: 20, height: 20, cursor: "pointer" }}
                onClick={() => getSearchTerms(1, true)}
              />
            }
            placeholder={"Search this query group"}
            onChange={handleChangeSearch}
            onKeyPress={handleSearch}
          />
          <Box>
            {location.state.groupData.isMine && (
              <IconButton
                aria-label="close"
                onClick={handleClickEdit}
                sx={{
                  width: 35,
                  marginRight: 1,
                }}
              >
                <StyledIcon
                  src={require("src/assets/images/edit.png")}
                  alt="edit icon"
                />
              </IconButton>
            )}
            <StyledButton
              variant="outlined"
              sx={{
                backgroundColor: Colors.textDark100,
                color: "#FFFFFF",
                height: 41,
                "&:hover": {
                  color: Colors.textDark100,
                },
              }}
              onClick={() => handleSearchAll(0, true)}
              disabled={searchTerms.length === 0}
            >
              View Results
            </StyledButton>
          </Box>
        </Box>
        <StyledHeader>
          <StyledTHD
            style={{ width: "60%" }}
            onClick={() => handleChangeOrder("query")}
          >
            Term{" "}
            <img
              alt="sort order arrow icon"
              src={require("src/assets/images/order.png")}
              style={{ width: 16, height: 16 }}
            />
          </StyledTHD>
          <StyledTHD style={{ width: "15%" }}>Last Run</StyledTHD>
          <StyledTHD
            style={{ width: "15%" }}
            onClick={() => handleChangeOrder("total_results")}
          >
            Result Count{" "}
            <img
              alt="sort order arrow icon"
              src={require("src/assets/images/order.png")}
              style={{ width: 16, height: 16 }}
            />
          </StyledTHD>
          <StyledTHD style={{ width: "5%", justifyContent: "flex-end" }}>
            Options
          </StyledTHD>
        </StyledHeader>
        {searchTerms.length === 0 && (
          <Box
            sx={{
              width: "100%",
              mt: 10,
              mb: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Results
          </Box>
        )}
        {searchTerms?.length > 0 && (
          <TableBody>
            {searchTerms.map((item: SearchTerm, index: number) => {
              return (
                <ModalTableRaw
                  index={index}
                  key={index}
                  last={index + 1 === searchTerms.length}
                  item={item}
                  data={searchTerms}
                  handleSearchAll={handleSearchAll}
                />
              );
            })}
          </TableBody>
        )}
        {loading && (
          <Box
            sx={{
              width: "100%",
              mt: 10,
              mb: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

const LaunchIcon = (props: { hovered: boolean; action: () => void }) => {
  const { action, hovered } = props;
  return (
    <IconButton onClick={() => action()}>
      {hovered ? (
        <img
          alt="action icon"
          src={require("src/assets/images/launch_icon.png")}
          style={{ width: 24, height: 24 }}
        />
      ) : (
        <img
          alt="action icon"
          src={require("src/assets/images/launch_icon_grey.png")}
          style={{ width: 24, height: 24 }}
        />
      )}
    </IconButton>
  );
};

export default QueryGroupView;
