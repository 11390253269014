// Helper function to update the selected date with a new year or month
interface Props {
  newYear?: number;
  newMonth?: number;
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  minDate: Date;
  maxDate: Date;
}

export const updateSelectedDateByYearOrMonth = ({
  newYear,
  newMonth,
  selectedDate,
  onChange,
  minDate,
  maxDate,
}: Props) => {
  if (selectedDate) {
    let updatedDate = new Date(
      newYear !== undefined ? newYear : selectedDate.getFullYear(),
      newMonth !== undefined ? newMonth : selectedDate.getMonth(),
      1 // Always set the day to the first of the month
    );

    // Ensure the updated date is not before minDate or after maxDate
    if (updatedDate < minDate) {
      updatedDate = new Date(minDate);
    } else if (updatedDate > maxDate) {
      updatedDate = new Date(maxDate);
    }

    onChange(updatedDate);
  }
};
