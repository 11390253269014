import React, { useMemo } from "react";
import {
  DatePickerProps,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
import { filterMonths, getYearsRange, isMonthDisabled } from "./utils";

const StyledHeaderWrapper = styled(Box)(() => ({
  margin: 8,
  display: "flex",
  justifyContent: "center",
  gap: 8,
}));

const StyledMonthControlButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.primary.main,
  height: "32px",
  width: "32px",
}));

const StyledMonthYearOption = styled("option")(() => ({
  padding: "0 8px",
  height: "24px",
  borderRadius: "4px",
  fontSize: "14px",
  margin: "4px 0",
  cursor: "pointer",
}));

const StyledMonthYearSelect = styled("select")(({ theme }) => ({
  flex: "1 1 35%",
  padding: "0 8px",
  borderRadius: "4px",
  fontSize: "12px",
  cursor: "pointer",
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.grey["400"],
  outline: "none",
  color: "#343741",
}));

export type CustomDatePickerHeaderProps =
  Partial<ReactDatePickerCustomHeaderProps> & {
    minDate?: DatePickerProps["minDate"];
    maxDate?: DatePickerProps["maxDate"];
  };

const CustomDatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  minDate,
  maxDate,
}: CustomDatePickerHeaderProps) => {
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const filteredYears = useMemo(
    () => getYearsRange(minDate, maxDate),
    [minDate, maxDate]
  );
  const filteredMonths = useMemo(
    () => filterMonths(date, months, minDate, maxDate),
    [date, minDate, maxDate]
  );
  const { isPrevMonthDisabled, isNextMonthDisabled } = useMemo(
    () => isMonthDisabled(date, minDate, maxDate),
    [date, minDate, maxDate]
  );

  return (
    <StyledHeaderWrapper>
      <StyledMonthControlButton
        disableRipple
        onClick={decreaseMonth}
        disabled={isPrevMonthDisabled}
      >
        <ArrowBackIcon fontSize="small" />
      </StyledMonthControlButton>
      <StyledMonthYearSelect
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) =>
          changeMonth(months.indexOf(value as string))
        }
      >
        {filteredMonths.map((option: string) => (
          <StyledMonthYearOption key={option} value={option}>
            {option}
          </StyledMonthYearOption>
        ))}
      </StyledMonthYearSelect>

      <StyledMonthYearSelect
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(Number(value))}
      >
        {filteredYears.map((option) => (
          <StyledMonthYearOption key={option} value={option}>
            {option}
          </StyledMonthYearOption>
        ))}
      </StyledMonthYearSelect>

      <StyledMonthControlButton
        disableRipple
        onClick={increaseMonth}
        disabled={isNextMonthDisabled}
      >
        <ArrowForwardIcon fontSize="small" />
      </StyledMonthControlButton>
    </StyledHeaderWrapper>
  );
};

export default CustomDatePickerHeader;
