import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as QueryInstructionsImg } from "src/assets/images/query-instructions.svg";
import { Colors } from "src/view/constants/colors";
import { Button } from "src/components/ui";

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

const StyledSVG = styled(QueryInstructionsImg)(() => ({
  width: "100%",
  height: "auto",
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: "12px",
  lineHeight: "126%",
  fontWeight: 600,
  color: Colors.textDark100,
  marginTop: 24,
  marginBottom: 12,
  maxWidth: 530,
}));

export const QueryInstructions = () => {
  return (
    <Container>
      <StyledSVG />
      <StyledTitle>
        Enter your query in the search bar to get started and filter your
        results with the side panel. Or, check out our search tips below:
      </StyledTitle>
      <a
        style={{ textDecoration: "none" }}
        href="https://support.seamlesshorizons.com/articles/2861652594-advanced-search-guide"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          btnSize="small+"
          sx={{
            textTransform: "capitalize",
          }}
        >
          view search tips
        </Button>
      </a>
    </Container>
  );
};
