import React, { FC } from "react";
import { IconButton, List, Popover, styled, Typography } from "@mui/material";
import { ConfirmDialog } from "src/components/ui";
import { ReactComponent as IconDelete } from "src/assets/icons/delete.svg";
import { ReactComponent as IconReindex } from "src/assets/icons/retry.svg";
import { ReactComponent as IconNewReindex } from "src/assets/icons/reindex-failed.svg";

const StyledPopover = styled(Popover)({
  padding: 12,
  "& .MuiPaper-root": {
    padding: 0,
    minWidth: 100,
    boxShadow: "0px 3px 13px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: 6,
  },
});

const StyledList = styled(List)(() => ({
  padding: 12,
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& > *": {
    fontSize: "medium",
    color: theme.palette.grey[600],
    backgroundColor: "transparent",
  },
  padding: 0,
  marginRight: 8,
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: "16px",
  fontWeight: 500,
  fontFamily: "Inter, sans-serif",
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

interface FolderActionsProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onDeleteDataset: () => void;
  onReindexDataset: (replaceDataset?: boolean) => void;
  datasetName: string;
}

const DatasetItemActions: FC<FolderActionsProps> = ({
  isOpen,
  onClose,
  anchorEl,
  onDeleteDataset,
  onReindexDataset,
  datasetName,
}) => {
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose(event);
  };
  return (
    <StyledPopover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <StyledList>
        <ConfirmDialog
          title="Re-index failed and new files"
          description={`Are you sure you want to re-index all failed and new files in "${datasetName}"?
            This will re-index all new and modified files from the S3 bucket.
            `}
          onConfirm={() => onReindexDataset(false)}
          confirmText="Confirm"
          cancelText="Cancel"
        >
          <>
            <StyledIconButton disableRipple>
              <IconReindex />
            </StyledIconButton>
            <StyledLabel variant="caption">
              Re-index failed and new files
            </StyledLabel>
          </>
        </ConfirmDialog>

        <ConfirmDialog
          title="Re-index all files"
          description={`Are you sure you want to re-index all files in "${datasetName}"?
            This will delete all files that were removed from the S3 bucket.`}
          onConfirm={onReindexDataset}
          confirmText="Confirm"
          cancelText="Cancel"
        >
          <>
            <StyledIconButton disableRipple>
              <IconNewReindex />
            </StyledIconButton>
            <StyledLabel variant="caption">Re-index all files</StyledLabel>
          </>
        </ConfirmDialog>

        <ConfirmDialog
          title="Delete Dataset"
          description={`Are you sure you want to delete "${datasetName}"?`}
          onConfirm={onDeleteDataset}
          confirmText="Confirm"
          cancelText="Cancel"
        >
          <StyledIconButton disableRipple>
            <IconDelete />
          </StyledIconButton>
          <StyledLabel variant="caption">Delete</StyledLabel>
        </ConfirmDialog>
      </StyledList>
    </StyledPopover>
  );
};

export default DatasetItemActions;
