import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Dataset } from "src/types/Dataset";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";

const Tag = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "500",
  lineHeight: "10px",
  fontSize: "10px",

  height: "fit-content",
  width: "fit-content",

  padding: "4px",

  borderColor: theme.palette.primary.main,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "4px",
}));

interface Props extends BoxProps {
  dataset: Dataset | KnowledgeWiki;
}

const IsLeakedTag = ({ dataset, children, ...boxProps }: Props) => {
  return dataset?.is_leaked ? (
    <Tag {...boxProps}>{children || "Leaked"}</Tag>
  ) : null;
};

export default IsLeakedTag;
