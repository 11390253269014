import { Box, CircularProgress } from "@mui/material";

import { Group, User } from "src/types/UserGroup";

import UsersTableControlsWrapper from "src/view/users-groups/groups/EditGroup/GroupUsersTable/UsersTableControlsWrapper";
import { queryClient } from "src";
import queryKeys from "src/modules/api/queryKeys";
import { useUserGroups } from "src/modules/api/userGroups";

type Props = {
  loadingUsers: boolean;
  groupUsers: User[];
  formData: Group;
  setFormData: React.Dispatch<React.SetStateAction<Group>>;
  toggleAddNewUserModalOpen: () => void;
  groupId: string;
};

const GroupUsersTable = ({
  loadingUsers,
  groupUsers,
  formData,
  setFormData,
  toggleAddNewUserModalOpen,
  groupId,
}: Props) => {
  const { resendEmail } = useUserGroups();
  const { mutateAsync: resendUserEmail } = resendEmail();

  const handleEditUserSubscriptionDate = ({
    user,
    newDate,
  }: {
    user: User;
    newDate: string;
  }) => {
    queryClient.setQueryData(
      [queryKeys.GROUP_ID_USERS, { id: groupId }],
      (oldUsers: User[]) =>
        oldUsers.map((currentUser) => {
          if (currentUser.id === user.id) {
            return { ...currentUser, membership_renewal_date: newDate };
          }
          return currentUser;
        })
    );
  };

  const handleRemoveUser = (user: User) => {
    if (!user.id && !groupId) return;
    const _users = groupUsers?.filter((u: User) => u.id !== user.id);
    const data = { ...formData };
    data.members = [..._users].map((u: User) => u.id);
    setFormData(data);
  };

  const handleResendEmail = async (data: { user_id: string }) => {
    await resendUserEmail(data);
  };

  const onOpenUserCreateModal = () => {
    toggleAddNewUserModalOpen();
  };

  if (loadingUsers || !groupUsers)
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 200,
          overflow: "visible",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <UsersTableControlsWrapper
      users={groupUsers}
      openCreateUserModal={onOpenUserCreateModal}
      handleRemoveUser={handleRemoveUser}
      handleResendEmail={handleResendEmail}
      handleEditUserSubscriptionDate={handleEditUserSubscriptionDate}
    />
  );
};

export default GroupUsersTable;
