import React, { useMemo } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import DownloadFile from "./DownloadFile";
import { StyledFileName } from "./DataTableView";
import { StyledHeader } from "./JSONView";
import HighlightingFailure from "./HighlightingFailure";
import { isHtmlEmpty } from "src/utils";
import { getHighlightText } from "src/utils/get-highlight-text";
import { Tooltip } from "src/components/ui";

const KeyText = styled(Typography)(() => ({
  width: "100px",
  minWidth: "100px",
  fontSize: 14,
  fontWeight: 700,
  color: "#536378",
}));

const ButtonsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const Splitter = styled(Typography)(() => ({
  width: "auto",
  marginRight: "20px",
}));

const ValueText = styled(KeyText)(() => ({
  width: "auto",
  color: "#122945",
  wordBreak: "break-word",
}));

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

interface SelectableTextProps {
  children?: JSX.Element | string;
  innerHTML?: string;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const SelectableText = ({
  children,
  innerHTML,
  handleClosePreview,
}: SelectableTextProps) => {
  return (
    <HighlightActionMenu onSearchInTab={handleClosePreview}>
      <ValueText
        sx={{ fontSize: "12px" }}
        dangerouslySetInnerHTML={innerHTML ? { __html: innerHTML } : null}
      >
        {children}
      </ValueText>
    </HighlightActionMenu>
  );
};

interface Props {
  data: any;
  fileName?: string;
  docId?: string;
  type?: string;
  getDocumentPreview?: () => void;
  handleClosePreview?: () => void;
  isHighlightingFailure?: boolean;
}

export const EMLView = ({
  data,
  docId,
  fileName,
  getDocumentPreview,
  handleClosePreview,
  type,
  isHighlightingFailure,
}: Props) => {
  const htmlContent = data.body?.html;
  const plainContent = data.body?.plain_text?.trim();
  const { subject, from, to, bodyHtml, bodyPlain, attachments } =
    useMemo(() => {
      return {
        subject: getHighlightText(data.subject),
        from: getHighlightText(data.from),
        to: getHighlightText(data.to),
        bodyHtml:
          !isHtmlEmpty(htmlContent) &&
          getHighlightText(htmlContent, undefined, "string"),
        bodyPlain: isHtmlEmpty(htmlContent) && getHighlightText(plainContent),
        attachments: data.body?.attachments || null,
      };
    }, [data]);
  // TODO: bruh
  const paddingStyle = { paddingLeft: "4px" };

  return (
    <Box sx={{ paddingLeft: "8px" }}>
      <StyledHeader>
        <Tooltip
          arrow={true}
          placement="top-start"
          enterDelay={0}
          title={<StyledTooltipContent>{fileName}</StyledTooltipContent>}
        >
          <StyledFileName onClick={getDocumentPreview}>
            {fileName}
          </StyledFileName>
        </Tooltip>
        <ButtonsContainer>
          {isHighlightingFailure && <HighlightingFailure />}
          <DownloadFile docId={docId} type={type} />
        </ButtonsContainer>
      </StyledHeader>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" style={paddingStyle}>
          <KeyText sx={{ fontSize: "12px" }}>Subject</KeyText>
          <Splitter>:</Splitter>
          <SelectableText handleClosePreview={handleClosePreview}>
            {subject}
          </SelectableText>
        </Stack>
        <Stack direction="row" style={paddingStyle}>
          <KeyText sx={{ fontSize: "12px" }}>From</KeyText>
          <Splitter>:</Splitter>
          <SelectableText handleClosePreview={handleClosePreview}>
            {from}
          </SelectableText>
        </Stack>
        <Stack direction="row" style={paddingStyle}>
          <KeyText sx={{ fontSize: "12px" }}>To</KeyText>
          <Splitter>:</Splitter>
          <SelectableText handleClosePreview={handleClosePreview}>
            {to}
          </SelectableText>
        </Stack>
        <Stack direction="row" style={paddingStyle}>
          <KeyText sx={{ fontSize: "12px" }}>Content</KeyText>
          <Splitter>:</Splitter>
          {bodyHtml ? (
            <SelectableText
              innerHTML={bodyHtml as string}
              handleClosePreview={handleClosePreview}
            ></SelectableText>
          ) : (
            <SelectableText handleClosePreview={handleClosePreview}>
              {bodyPlain}
            </SelectableText>
          )}
        </Stack>
        {attachments && attachments.length > 0 && (
          <Stack direction="row" style={paddingStyle}>
            <KeyText sx={{ fontSize: "12px" }}>Attachments</KeyText>
            <Splitter>:</Splitter>
            <SelectableText handleClosePreview={handleClosePreview}>
              {getHighlightText(attachments.join(", "))}
            </SelectableText>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
