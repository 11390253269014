import { Box, styled } from "@mui/material";
import { Button } from "src/components/ui";
import {
  Layout,
  StyledLogo,
  FormTitle,
  FormTemplate,
  StyledRouterLink,
  StyledLink,
} from "./components";
import logo from "src/assets/logo.svg";
import { openCenteredWindow } from "../../utils/window";

const StyledFormTemplate = styled(FormTemplate)({
  height: "auto",
  maxWidth: "528px",
  width: "100%",
});

const CustomLogo = styled(StyledLogo)({
  textAlign: "center",
  display: "block",
  margin: "0 auto 24px",
});

const StyledBox = styled(Box)({
  width: "100%",
});

const StyledFormTitle = styled(FormTitle)({
  marginBottom: "24px",
});

const SupportButtonsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  marginBottom: "24px",
});

const StyledButton = styled(Button)({
  fontWeight: 500,
});

const Help = () => {
  const openIHaveTroubleWindow = () => {
    openCenteredWindow({
      url: "https://portal.usepylon.com/c4ads/forms/login-issue",
      title: "I’m having trouble logging in",
      width: 768,
      height: window.screen.height * 0.7,
    });
  };

  const openSomethingElse = () => {
    openCenteredWindow({
      url: "https://portal.usepylon.com/c4ads/forms/general-support",
      title: "Something else",
      width: 768,
      height: window.screen.height * 0.7,
    });
  };

  return (
    <Layout>
      <StyledFormTemplate>
        <StyledBox>
          <CustomLogo src={logo} alt="SEAMLESS HORIZONS" />
          <StyledFormTitle>What can we help you with?</StyledFormTitle>
          <SupportButtonsContainer>
            <StyledButton
              onClick={openIHaveTroubleWindow}
              type="button"
              btnSize="medium"
            >
              I’m having trouble logging in
            </StyledButton>
            <StyledButton
              onClick={openSomethingElse}
              type="button"
              btnSize="medium"
            >
              Something else
            </StyledButton>
          </SupportButtonsContainer>
          <Box display="flex" justifyContent="center">
            <StyledRouterLink to="/login">
              <StyledLink variant="caption" color="primary" fontWeight="400">
                Back to Login
              </StyledLink>
            </StyledRouterLink>
          </Box>
        </StyledBox>
      </StyledFormTemplate>
    </Layout>
  );
};

export default Help;
