import React, { PropsWithChildren } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  Box,
  Typography,
  useTheme,
  styled,
  ThemeProvider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "../Button";

export interface ConfirmDialogProps extends PropsWithChildren {
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  dialogChildren?: JSX.Element;
  disabled?: boolean;
  skip?: boolean;
  isStopPropagation?: boolean;
}

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
  padding: 20,
  minWidth: 350,
  maxWidth: 661,
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  boxShadow: "0px 3px 13px 4px #0000001A",
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  paddingBottom: "12px",
  position: "relative",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: "600",
  fontFamily: "Inter, sans-serif",
  color: theme.palette.primary.main,
  lineHeight: 1,
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "400",
  lineHeight: "21px",
  fontFamily: "Inter, sans-serif",
  color: theme.palette.primary.main,
  margin: 0,
}));

const StyledActions = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  gap: "8px",
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  padding: 2,
  position: "absolute",
  top: -8,
  right: -6,
  color: theme.palette.primary.main,
  "& svg": {
    height: 21,
    width: 21,
  },
}));

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "max-content",
});

export const ConfirmDialog = ({
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  dialogChildren,
  children,
  disabled,
  skip,
  isStopPropagation = false,
}: ConfirmDialogProps) => {
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isStopPropagation) {
      e.stopPropagation();
    }
    submit();
  };

  const handleConfirm = (onClose: () => void) => {
    onConfirm();
    onClose();
  };

  const submit = () => {
    if (disabled) {
      return;
    }
    if (skip) {
      onConfirm();
      return;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ThemeProvider theme={theme}>
            <StyledContainer>
              <StyledHeader>
                <StyledTitle>{title || "Confirm to submit"}</StyledTitle>
                <StyledCloseButton btnVariant="text" onClick={onClose}>
                  <CloseIcon />
                </StyledCloseButton>
              </StyledHeader>
              <StyledDescription>
                {description || "Are you sure to do this?"}
              </StyledDescription>
              {dialogChildren && <Box>{dialogChildren}</Box>}
              <StyledActions>
                {cancelText && (
                  <Button
                    btnVariant="text"
                    btnSize="medium"
                    sx={{ textDecoration: "underline" }}
                    onClick={onClose}
                  >
                    {cancelText}
                  </Button>
                )}
                <Button
                  btnVariant="secondary"
                  btnSize="medium"
                  onClick={() => handleConfirm(onClose)}
                >
                  {confirmText || "Yes"}
                </Button>
              </StyledActions>
            </StyledContainer>
          </ThemeProvider>
        );
      },
    });
  };

  return <StyledBox onClick={handleClick}>{children}</StyledBox>;
};

export default ConfirmDialog;
