import { QueryItem } from "src/types/BulkSearch";

interface RemovePreviousQueryProps {
  index: number;
  setQueryB: React.Dispatch<React.SetStateAction<QueryItem[]>>;
  setFocusedQuery: React.Dispatch<
    React.SetStateAction<{ index: number | null }>
  >;
}

export const removePreviousQuery = ({
  index,
  setQueryB,
  setFocusedQuery,
}: RemovePreviousQueryProps): void => {
  if (index <= 0) return;

  setQueryB((prev) => {
    const updatedQueries = [...prev];
    const currentItem = updatedQueries[index];
    const previousItem = updatedQueries[index - 1];
    const beforePreviousItem = updatedQueries[index - 2];

    const isOperator = (item: QueryItem) => item?.type === "operator";
    const isTerm = (item: QueryItem) => item?.type === "term";

    // If the previous item is an operator
    if (isOperator(previousItem)) {
      // If the current item has no value, check the item before the previous one
      if (!currentItem.value) {
        // If the item before the previous one is not an operator, remove both the previous item and the current one
        if (!isOperator(beforePreviousItem)) {
          updatedQueries.splice(index - 1, 2);
          setFocusedQuery({ index: index - 2 });
        } else {
          // Otherwise, remove only the previous item
          updatedQueries.splice(index - 1, 1);
          setFocusedQuery({ index: index - 1 });
        }
      } else {
        updatedQueries.splice(index - 1, 1);
        setFocusedQuery({ index: index - 2 });
      }
    }
    // If the previous item is a term
    else if (isTerm(previousItem)) {
      // If the current item has no value, remove the current item
      if (!currentItem.value) {
        updatedQueries.splice(index, 1);
        setFocusedQuery({ index: index - 1 });
      } else {
        // Otherwise, just adjust the focus to the previous item
        setFocusedQuery({ index: index - 1 });
      }
    }

    return updatedQueries;
  });
};
