import { fetchApi } from "../shared/api";
import { getCookie } from "../../utils/cookies";

const CSRF_TOKEN_KEY = "csrf-token";
const EXP_KEY = "session-exp";
const SHOULD_REMEMBER = "should-remember";

export class AuthToken {
  static saveCSRFToken(token: string) {
    localStorage.setItem(CSRF_TOKEN_KEY, token);
  }

  static getStoredCSRFToken() {
    return localStorage.getItem(CSRF_TOKEN_KEY);
  }

  static setShouldRemember(shouldRemember: boolean) {
    if (shouldRemember) {
      localStorage.setItem(SHOULD_REMEMBER, "true");
    }
  }

  static getShouldRemember() {
    return localStorage.getItem(SHOULD_REMEMBER) === "true";
  }

  static async getCSRFToken() {
    await fetchApi("csrf/", { credentials: "include" });

    return getCookie("csrftoken");
  }

  static setSessionExp(date: Date) {
    localStorage.setItem(EXP_KEY, `${date.getTime()}`);
  }

  static getSessionExp() {
    const exp = localStorage.getItem(EXP_KEY);

    return Number(exp || 0);
  }

  static async refreshAccessToken() {
    try {
      const response = await fetchApi("token/request_access_token/", {
        method: "POST",
      });
      return response.status === 200;
    } catch {
      return false;
    }
  }

  static async updateCSRFToken() {
    const csrfToken = await AuthToken.getCSRFToken();
    AuthToken.saveCSRFToken(csrfToken);
  }

  static logout(redirectToLoginPage = false) {
    localStorage.removeItem(CSRF_TOKEN_KEY);
    localStorage.removeItem(EXP_KEY);
    localStorage.removeItem(SHOULD_REMEMBER);

    if (redirectToLoginPage === true) {
      window.location.replace("/login");
    }
  }
}
