import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";

import { OCRTask } from "src/types/OCR";
import OCRTaskItem from "src/view/ocr/components/OCRTaskItem";

const StyledTable = styled(Table)(() => ({
  tableLayout: "fixed",
  "& td": {
    fontSize: 12,
    padding: "18px 18px 18px 0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
const StyledTableHead = styled(TableHead)(() => ({
  "& th": {
    color: "#9BA5B1",
    fontSize: 12,
    fontWeight: "700",
    padding: "10px 10px 10px 0",
    border: "unset",
  },
}));

const StyledLoaderContainer = styled(Box)({
  marginTop: 30,
  textAlign: "center",
  width: "100%",
});

interface Props {
  tasks: OCRTask[];
  isLoading: boolean;
}

const OCRTaskTable = ({ tasks, isLoading }: Props) => {
  return (
    <>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell width={"40%"}>Filename</TableCell>
            <TableCell width={"22%"}>Status</TableCell>
            <TableCell width={"32%"}>Created at</TableCell>
            <TableCell width={"6%"}>Actions</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {!!tasks?.length &&
            !isLoading &&
            tasks.map((task) => {
              return <OCRTaskItem key={task.id} task={task} />;
            })}
        </TableBody>
      </StyledTable>

      {!isLoading && !tasks.length && (
        <StyledLoaderContainer>
          <Typography>No tasks found</Typography>
        </StyledLoaderContainer>
      )}
    </>
  );
};

export default OCRTaskTable;
