import React, { useRef, useState } from "react";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Button, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useClickAway } from "src/modules/shared/hooks";
import { Colors } from "src/view/constants/colors";

const StyledOptionButtonWrapper = styled("div")(() => ({
  position: "relative",
}));

const StyledOptionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  zIndex: 1,
  fontSize: 12,
  lineHeight: "17px",
  fontWeight: "600",
  color: Colors.textGray800,
  width: "68px",
  textTransform: "unset",
  padding: 0,
  whiteSpace: "nowrap",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(() => ({
  color: "inherit",
  fontSize: "20px",
  padding: 0,
}));

const StyledDropdown = styled("ul")(({ theme }) => ({
  position: "absolute",
  width: "auto",
  background: theme.palette.common.white,
  boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 12px 4px",
  borderRadius: 4,
  marginTop: "2px",
  zIndex: 2,
  padding: 0,
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  padding: "5px 10px",
}));

const FuzzyButton = ({
  text,
  handleChangeFuzziness,
  disabled = false,
}: {
  text: number;
  handleChangeFuzziness: (value: number) => void;
  disabled?: boolean;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(
    text ? `~${text}` : "Exact"
  );
  const menuRef = useRef<HTMLUListElement>(null);
  useClickAway(menuRef, () => setIsDropdownOpen(false));

  const handleSelectQuery = (
    value: number,
    event: React.MouseEvent<HTMLLIElement>
  ) => {
    event.stopPropagation();
    const query = event.currentTarget.innerText;
    setSelectedQuery(query);
    handleChangeFuzziness(value);
    setIsDropdownOpen(false);
  };

  const handleButtonClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <StyledOptionButtonWrapper>
      <StyledOptionButton
        onClick={handleButtonClick}
        endIcon={<StyledKeyboardArrowDownIcon />}
        disabled={disabled}
      >
        {selectedQuery}
      </StyledOptionButton>
      {isDropdownOpen && (
        <StyledDropdown ref={menuRef}>
          <StyledMenuItem onClick={(e) => handleSelectQuery(0, e)}>
            Exact
          </StyledMenuItem>
          {[...Array(9)].map((_i: number, index: number) => {
            return (
              <StyledMenuItem
                key={"fuzziness-menu-item-" + index}
                onClick={(e) => handleSelectQuery(index + 1, e)}
              >
                {index + 1} {index == 0 ? "edit" : "edits"}
              </StyledMenuItem>
            );
          })}
        </StyledDropdown>
      )}
    </StyledOptionButtonWrapper>
  );
};

export default FuzzyButton;
