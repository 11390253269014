import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFeedback } from "../../modules/api/feedback";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import { snackbar } from "src/view/toaster";
import CloseIcon from "@mui/icons-material/Close";

const RootContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 18,
  lineHeight: "22px",
  fontWeight: "400",
  color: "#000000",
}));
const StyledButton = styled(Button)(() => ({
  fontSize: 16,
  lineHeight: "20px",
  fontWeight: "400",
  color: "#FFFFFF",
  height: 47,
  width: 107,
  textTransform: "capitalize",
  background: Colors.secondary,
  borderRadius: 8,
  "&:hover": {
    background: Colors.secondary2,
  },
}));

const FeedbackModal = () => {
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [messageContent, setMessageContent] = useState<string>("");

  const { sendFeedback } = useFeedback();

  useEffect(() => {
    document.addEventListener("openFeedbackModal", handleOpenFeedbackModal);
    return () => {
      document.addEventListener("openFeedbackModal", handleOpenFeedbackModal);
    };
  }, []);

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setOpenFeedbackModal(false);
  };

  const handleEditMessage = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setMessageContent(event.currentTarget.value);
  };

  const handleSendButtonClick = async () => {
    if (messageContent == "") {
      snackbar.warning("Please input your feedback content");
      return;
    }
    const { isError } = await sendFeedback.mutateAsync(messageContent);
    !isError && handleCloseFeedbackModal();
  };

  return (
    <RootContainer>
      <Dialog
        open={openFeedbackModal}
        onClose={handleCloseFeedbackModal}
        maxWidth={"md"}
        sx={{ padding: 2 }}
      >
        <DialogTitle>
          <StyledTitle>Send Feedback</StyledTitle>
          <IconButton
            onClick={handleCloseFeedbackModal}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText
            sx={{ fontSize: 12, fontWeight: "400", color: "#7D7D7D" }}
          >
            How can we improve Seamless Horizons? If you are experiencing a bug,
            please be as descriptive as possible to help us reproduce the
            behavior. We&apos;ll get back to you via email as soon as possible.
          </DialogContentText>
          <TextareaAutosize
            autoFocus
            id="message"
            minRows={7}
            onChange={handleEditMessage}
            value={messageContent}
            placeholder={"Share your thoughts here..."}
            style={{
              width: "calc(100% - 20px)",
              marginTop: "20px",
              fontSize: 14,
              fontWeight: "700",
              border: "none",
              borderRadius: "8px",
              resize: "none",
              padding: 10,
              background: "#F2F2F2",
            }}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleSendButtonClick}>Submit</StyledButton>
        </DialogActions>
      </Dialog>
    </RootContainer>
  );
};

export default FeedbackModal;
