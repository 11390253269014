import { CSSProperties } from "react";
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
} from "@mui/material";
import { buttonBg, buttonColor } from "./constants";
import { ButtonSize, ButtonVariant } from "./types";

interface IconButtonProps extends MuiIconButtonProps {
  btnVariant?: ButtonVariant;
  btnSize?: ButtonSize;
  disableIconSizing?: boolean;
}

const buttonSize: Record<ButtonSize, CSSProperties> = {
  big: {
    padding: 8,
    borderRadius: 6,
  },
  medium: {
    padding: 5,
    borderRadius: 6,
  },
  small: {
    padding: 3,
    borderRadius: 4,
  },
  "small+": {
    padding: 4,
    borderRadius: 4,
  },
};

const iconSize: Record<ButtonSize, CSSProperties> = {
  big: {
    width: 24,
    height: 24,
  },
  medium: {
    width: 20,
    height: 20,
  },
  small: {
    width: 14,
    height: 14,
  },
  "small+": {
    width: 16,
    height: 16,
  },
};

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) =>
    !["btnVariant", "btnSize", "disableIconSizing"].includes(prop.toString()),
})<IconButtonProps>(({ btnVariant, btnSize, disableIconSizing }) => ({
  fontFamily: "Inter, sans-serif",
  display: "flex",
  alignItems: "center",
  backgroundColor: buttonBg[btnVariant].main,
  color: buttonColor[btnVariant].main,
  "&:hover": {
    backgroundColor: buttonBg[btnVariant].hover,
    color: buttonColor[btnVariant].hover,
  },
  "&:disabled": {
    backgroundColor: buttonBg[btnVariant].disabled,
    color: buttonColor[btnVariant].disabled,
  },
  "&:active": {
    backgroundColor: buttonBg[btnVariant].active,
    color: buttonColor[btnVariant].active,
  },
  "&>svg": {
    margin: 0,
    ...(disableIconSizing ? {} : iconSize[btnSize]),
  },
  "&>svg path": {
    fill: "currentColor",
  },
  ...buttonSize[btnSize],
}));

export const IconButton = ({
  btnVariant = "primary",
  btnSize = "small",
  disableIconSizing = false,
  ...props
}: IconButtonProps) => {
  return (
    <StyledIconButton
      disableIconSizing={disableIconSizing}
      btnSize={btnSize}
      btnVariant={btnVariant}
      {...props}
    />
  );
};
