import { useState, useEffect } from "react";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { styled } from "@mui/material";
import { ReactComponent as NumericSortIcon } from "src/assets/icons/numeric_sort.svg";
import { IconButton } from "../../../../components/ui";

export type SortType = "alphabetic" | "numeric";

interface SortButtonProps {
  onSort?: (sort: SortType) => void;
  sort?: SortType;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SortButton = ({
  sort: propSort = "numeric",
  onSort,
}: SortButtonProps) => {
  const [sort, setSort] = useState(propSort);

  useEffect(() => setSort(propSort ?? sort), [propSort]);

  const onClick = () => {
    const newSort = sort === "alphabetic" ? "numeric" : "alphabetic";
    setSort(newSort);
    onSort?.(newSort);
  };

  return (
    <StyledIconButton btnSize="medium" btnVariant="text" onClick={onClick}>
      {sort !== "alphabetic" ? <SortByAlphaIcon /> : <NumericSortIcon />}
    </StyledIconButton>
  );
};
