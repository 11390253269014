import { replaceHighlightTags } from ".";
import { CSSProperties } from "react";
import theme from "src/theme";

export const getHighlightText = (
  text: string | JSX.Element,
  style?: CSSProperties,
  type?: string
) => {
  text = replaceHighlightTags(text as string);

  const parser = new DOMParser();
  const parsed = parser.parseFromString(text, "text/html");
  const nodes = parsed.body.childNodes;

  let output = "";
  const elements = Array.from(nodes).map((element, index) => {
    if (element.nodeName === "SPAN") {
      const spanStyle = `background-color: ${theme.palette.highlight.main}; ${
        style
          ? Object.entries(style)
              .map(([key, value]) => `${key}: ${value};`)
              .join(" ")
          : ""
      }`;

      output += `<span style="${spanStyle}">${element.textContent}</span>`;

      return (
        <span
          key={`highlight-${index}`}
          style={{ ...style, backgroundColor: theme.palette.highlight.main }}
        >
          {element.textContent}
        </span>
      );
    }

    if (element.nodeType === Node.TEXT_NODE) {
      output += element.textContent;
      return <span key={`highlight-${index}`}>{element.textContent}</span>;
    }

    if (element.nodeType === Node.ELEMENT_NODE) {
      output += (element as HTMLElement).outerHTML;
      return <span key={`highlight-${index}`}>{element.textContent}</span>;
    }

    return null;
  });

  if (type && type === "string") {
    return output;
  }
  return <>{elements}</>;
};

export const getPlainTextFromHighlight = (content: string[]): string => {
  if (!content) return "";

  return Array.from(
    // use Set to prevent duplicates
    new Set(
      ...content.map(
        // find text for highlight
        (item: string) => item.match(/<\/em>(.*?)<\/em>/g)
      )
    )
  )
    .join(" ")
    .replace(/<\/?em>/g, ""); // get plain text
};

export const getArrayWithPlainHighlights = (content: string[]) => {
  if (!content) return;

  return content.map((item: string) => item.match(/<\/em>(.*?)<\/em>/g));
};
