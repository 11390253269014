import { styled, Typography } from "@mui/material";

export const FormTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "19px",
  fontWeight: 600,
  color: theme.palette.primary.main,
  textAlign: "center",
  fontFamily: "Inter",
}));
