import {
  cloneElement,
  ReactElement,
  useState,
  MouseEvent,
  useMemo,
} from "react";
import {
  IconButton,
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
  styled,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { merge } from "lodash";

interface PopoverProps extends Omit<MuiPopoverProps, "open"> {
  trigger: ReactElement;
  triggerType?: "click" | "hover";
  closeButtonVisible?: boolean;
  closeOnClick?: boolean;
}

const StyledPopover = styled(MuiPopover)({
  padding: 12,
  "& .MuiPaper-root": {
    padding: "12px 6px",
    minWidth: 100,
    boxShadow: "0px 3px 13px 4px #0000001A",
    borderRadius: 6,
  },
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 10,
  top: 10,
  "& svg": {
    fill: theme.palette.primary.main,
  },
}));

export const Popover = ({
  trigger,
  triggerType = "click",
  onClose: propOnClose,
  closeOnClick = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  closeButtonVisible = false,
  children,
  disableRestoreFocus,
  sx,
  ...props
}: PopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);

  const onClose = (event: any, reason?: "backdropClick" | "escapeKeyDown") => {
    propOnClose?.(event, reason ?? "backdropClick");
    setAnchorEl(undefined);
  };

  const triggerProps = useMemo(() => {
    switch (triggerType) {
      case "hover":
        return {
          onMouseOver: (e: MouseEvent<HTMLElement>) => {
            setAnchorEl(e.currentTarget);
            trigger.props?.onClick?.(e);
          },
          onMouseLeave: (e: MouseEvent<HTMLElement>) => {
            onClose(e, "backdropClick");
          },
        };
      case "click":
      default:
        return {
          onClick: (e: MouseEvent<HTMLElement>) => {
            setAnchorEl(e.currentTarget);
            trigger.props?.onClick?.(e);
          },
        };
    }
  }, [trigger, triggerType]);

  return (
    <>
      {cloneElement(trigger, triggerProps)}
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        onClick={closeOnClick ? onClose : undefined}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        transitionDuration={{ enter: 300, exit: 300 }}
        sx={merge(
          { pointerEvents: triggerType === "hover" ? "none" : undefined },
          sx
        )}
        {...props}
        disableRestoreFocus={
          triggerType === "hover" ? true : disableRestoreFocus
        }
      >
        {closeButtonVisible && (
          <StyledCloseButton onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
        )}
        {children}
      </StyledPopover>
    </>
  );
};
