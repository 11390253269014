import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { isEqual, omitBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";
import { isJsonString, replaceHighlightTags } from "src/utils";

import { QueryFilters, QueryVariables } from "src/types/QueryVariables";
import {
  DataIndex,
  GetDocumentPreviewParams,
  GetDownloadAllDocumentsURLParams,
  GetDownloadDocumentURLParams,
  MappedHit,
  MappedSearchResult,
} from "src/types/Search";
import { queryClient } from "src/index";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { HogEvent } from "src/types/PosthogEvents";
import { useFetch } from "../fetch";
import { emptyQueryVariables } from "./constants";
import { parseVariablesFromSearchParams } from "./utils";
import searchStoreSelectors from "./store/selectors";
import searchStoreActions from "./store/actions";
import { store } from "../../store";
import { searchMapper } from "./searchMapper";

const placeholderSearchResultData: InfiniteData<MappedSearchResult> = {
  pages: [],
  pageParams: [],
};

const useSearchProvider = () => {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const variables = useSelector(searchStoreSelectors.selectVariables);
  const activePageIndex = useSelector(
    searchStoreSelectors.selectActivePageIndex
  );
  const [enableSearch, setEnableSearch] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(
    JSON.stringify(parseVariablesFromSearchParams(searchParams)) ===
      JSON.stringify(emptyQueryVariables)
  );
  // This ref needed to reduce amount of context re-renders
  const variablesRef = useRef(variables);

  const { postApi, getApi } = useFetch();
  const memoAbortController: AbortController = useMemo(
    () => new AbortController(),
    [variables, searchParams]
  );

  const searchFn = useCallback(
    async ({ pageParam = 1 }) => {
      const { content, filters } = parseVariablesFromSearchParams(searchParams);
      const body = {
        content,
        page: pageParam,
        ...omitBy(filters, (f) => f.length === 0),
      };

      const res = await postApi("search", {
        body: JSON.stringify(body),
        ...(memoAbortController.signal.aborted && {
          signal: memoAbortController.signal,
        }),
      });

      return searchMapper(await res.json());
    },
    [searchParams]
  );

  const {
    status,
    data,
    error,
    isFetching,
    isFetched,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
  } = useInfiniteQuery<MappedSearchResult>({
    enabled: enableSearch,
    queryFn: searchFn,
    queryKey: ["search", searchParams.get("q")],
    getNextPageParam: (lastPage) => lastPage.nextPageNumber,
    placeholderData: placeholderSearchResultData,
  });

  useEffect(() => {
    variablesRef.current = variables;
  }, [variables]);

  useEffect(() => {
    // clear store when leaving search page
    if (!location.pathname.includes("/search")) {
      setEnableSearch(false);
      resetVariables();
      abortRequest();
    } else {
      const parsedVariables = parseVariablesFromSearchParams(searchParams);

      document.title = `Search for '${
        parsedVariables.content.length > 50
          ? parsedVariables.content.slice(0, 50) + "..."
          : parsedVariables.content
      }'`;

      if (!isEqual(variables, parsedVariables)) {
        searchStoreActions.setVariables(parsedVariables)(dispatch);
      }

      if (searchDisabled) {
        return;
      }
      if (!isFetched) {
        setEnableSearch(true);
      }
    }
  }, [location.pathname, searchParams]);

  const updateFilters = useCallback((filters: Partial<QueryFilters>) => {
    searchStoreActions.setVariables((v) => ({
      ...v,
      filters: {
        ...v.filters,
        ...filters,
      },
    }))(dispatch);
  }, []);

  const resetFilters = useCallback(() => {
    searchStoreActions.setVariables((v) => ({
      ...v,
      filters: emptyQueryVariables.filters,
    }))(dispatch);
  }, []);

  const updateContent = useCallback((content: string) => {
    searchStoreActions.setVariables((v) => ({
      ...v,
      content,
    }))(dispatch);
  }, []);

  const updateVariables = useCallback((variables: QueryVariables) => {
    searchStoreActions.setVariables((v) => ({
      ...v,
      filters: { ...v.filters, ...variables.filters },
      content: variables.content ?? v.content,
    }))(dispatch);
  }, []);

  const resetVariables = useCallback(
    () => searchStoreActions.setVariables(emptyQueryVariables)(dispatch),
    []
  );

  const handleSearch = useCallback(
    (directPayload?: QueryVariables, fromHomePage?: boolean) => {
      if (!fromHomePage) {
        setSearchDisabled(false);
      }

      const actualVariables = store.getState().searchStore.variables;
      posthog.capture(HogEvent.SEARCH_PERFORMED);
      if (
        !directPayload ||
        directPayload.content ||
        Object.values(directPayload.filters).some((filter) => filter.length)
      ) {
        setEnableSearch(true);
      }

      if (directPayload) {
        searchStoreActions.setVariables(directPayload)(dispatch);
      }

      if (activePageIndex > 0) {
        searchStoreActions.setActivePageIndex(0)(dispatch);
      }

      navigate(
        `/search?q=${formatVariablesSearchParam(
          directPayload || actualVariables
        )}`
      );
    },
    [location.pathname, searchParams, activePageIndex]
  );

  const getDownloadDocumentUrlFn = useCallback(
    async ({
      id,
      dataIndex = DataIndex.source,
      entireFile = false,
    }: GetDownloadDocumentURLParams) => {
      const docType = dataIndex === DataIndex.source ? "source" : "modeled";
      const path = ["document", "download", docType, id];
      // Construct the query parameters separately
      const searchParams = entireFile ? `entire_file=true` : "";
      const { url } = await getApi(path, searchParams);
      return url;
    },
    []
  );

  const getDownloadDocumentUrl = useCallback(
    (params: GetDownloadDocumentURLParams | null) =>
      useQuery({
        enabled: !!params,
        queryFn: () => getDownloadDocumentUrlFn(params),
        queryKey: ["getDownloadDocumentUrl", params?.id],
      }),
    []
  );

  const getDownloadAllDocumentsUrlFn = useCallback(
    async (params: GetDownloadAllDocumentsURLParams) => {
      const body = JSON.stringify(params);
      const path = ["document", "bulk_download"];
      const res = await postApi(path, { body });
      const { url } = await res.json();
      return url;
    },
    []
  );

  const getDownloadAllDocumentsUrl = useCallback(
    (params: GetDownloadAllDocumentsURLParams) =>
      useQuery({
        enabled: params.doc_ids.length > 0,
        queryFn: () => getDownloadAllDocumentsUrlFn(params),
        queryKey: ["getDowloadAllDocumentsUrl", params],
      }),
    []
  );

  const getDocumentPreview = useCallback(
    async ({
      id,
      dataIndex = DataIndex.source,
      range = 5,
    }: GetDocumentPreviewParams) => {
      let path = [];
      if (dataIndex === DataIndex.source) {
        path = ["document", "range", id, range.toString()];
      } else {
        const q = `?id=${id}`;
        path = ["modeled_path", "preview", q];
      }
      const data = await getApi(path);
      return data;
    },
    []
  );

  const abortRequest = useCallback(() => {
    memoAbortController.abort("Leaving view");
    queryClient.cancelQueries({
      predicate: (query) => query.queryKey[0] === "search",
    });
    resetVariables();
  }, []);

  const value = useMemo(() => {
    return {
      searchDisabled,
      updateFilters,
      resetFilters,
      updateContent,
      updateVariables,
      resetVariables,
      handleSearch,
      status,
      data,
      error,
      isFetching,
      fetchNextPage,
      fetchPreviousPage,
      hasNextPage,
      getDownloadDocumentUrl,
      getDownloadAllDocumentsUrl,
      getDocumentPreview,
    };
  }, [
    searchDisabled,
    updateFilters,
    resetFilters,
    updateContent,
    updateVariables,
    resetVariables,
    handleSearch,
    status,
    data,
    error,
    isFetching,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    getDownloadDocumentUrl,
    getDownloadAllDocumentsUrl,
    getDocumentPreview,
  ]);

  return value;
};

export const SearchContext =
  createContext<ReturnType<typeof useSearchProvider>>(null);

const SearchProvider = ({ children }: PropsWithChildren) => {
  const value = useSearchProvider();

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

SearchProvider.displayName = "SearchProvider";

export default SearchProvider;

export function parsePreviewFromSearchParams(searchParams: URLSearchParams) {
  const queryString = searchParams.get("preview");
  if (queryString) {
    try {
      return JSON.parse(decompressFromEncodedURIComponent(queryString)) as any;
    } catch {
      return null;
    }
  }
  return null;
}

export function formatVariablesSearchParam(variables: QueryVariables) {
  return compressToEncodedURIComponent(JSON.stringify(variables));
}

export function formatPreviewSearchParam(previewMetadata: PreviewDocProps) {
  return previewMetadata
    ? compressToEncodedURIComponent(JSON.stringify(previewMetadata))
    : null;
}

// TODO: a better place to keep/export/do this
export function parseDataIndex(_index: string) {
  return _index.split("_v", 2)[0] as DataIndex;
}

export const getTypeLabelForIndex = (hit: MappedHit) =>
  hit.dataIndex === DataIndex.modeled
    ? hit.source["schema"]
    : hit.source.doc_type;

export const getFileNameForIndex = (hit: MappedHit) => {
  if (hit.dataIndex === DataIndex.modeled) {
    return hit.source["fileName"] ? hit.source["fileName"][0] : "";
  } else {
    return hit.source.file_name;
  }
};

export function processHighlightContent({
  data,
  highlight,
  docType,
}: {
  data: string;
  highlight: string[];
  docType: string;
}): string | null {
  if (
    !highlight?.length ||
    !["eml", "msg", "json", "jsonl", "csv", "parquet", "xls", "xlsx"].includes(
      docType
    )
  ) {
    return data;
  }

  let processedData = data;

  highlight.forEach((highlightText) => {
    const highlightReplaced = replaceHighlightTags(highlightText);
    const rawText = highlightText.replace(/<\/em>/g, "");
    processedData = processedData.replaceAll(rawText, highlightReplaced);
  });
  return processedData || null;
}

// RoT: 1 by @inperegelion
export function prepSearchResultData(hit: MappedHit): Record<string, any> {
  let data =
    hit.dataIndex === DataIndex.modeled
      ? hit.source
      : typeof hit.source.content == "string" &&
        isJsonString(hit.source.content)
      ? JSON.parse(hit.source.content)
      : hit.source.content;

  if (
    hit.highlight?.content &&
    Array.isArray(hit.highlight.content) &&
    ["eml", "msg", "json", "jsonl", "csv", "pdf", "parquet"].includes(
      hit.source.doc_type
    )
  ) {
    data = hit.source.content;
    hit.highlight.content.forEach((el: string) => {
      const h_txt = replaceHighlightTags(el);
      const r_txt = el.replaceAll(/<\/em>/g, "");
      data = data.replaceAll(r_txt, h_txt);
    });
    if (hit?.source?.doc_type !== "pdf" && isJsonString(data)) {
      data = JSON.parse(data);
    } else if (hit?.source?.doc_type !== "pdf") {
      console.error("Invalid JSON string:", data);
    }
  }

  return data;
}

SearchProvider.displayName = "SearchProvider";
