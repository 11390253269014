import { useLocation } from "react-router-dom";
import { toZonedTime } from "date-fns-tz";
import { differenceInDays, format } from "date-fns";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Tab,
  Tabs,
  Typography,
  TypographyProps,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useMemo, forwardRef } from "react";
import actions from "src/modules/auth/actions";
import selector from "src/modules/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../components/CustomTextField";
import PasswordManagement from "./PasswordManagement";
import { Tooltip } from "../../components/ui";
import Settings from "./Settings";
import { useUser } from "src/modules/api/auth";
import theme from "src/theme";
import { snackbar } from "src/view/toaster";

const AntTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.silver.main}`,
  "& .MuiTabs-indicator": {
    height: 3,
  },
}));

const StyledWarningIcon = forwardRef<
  SVGSVGElement,
  {
    customStyles?: React.CSSProperties;
    icon: React.ElementType;
  }
>(function StyledIcon(
  { customStyles, icon: IconComponent, ...otherProps },
  ref
) {
  return (
    <IconComponent
      {...otherProps}
      ref={ref}
      style={{
        cursor: "pointer",
        fontSize: "20px",
        color: theme.palette.error.light,
        margin: "0 5px",
        ...customStyles,
      }}
    />
  );
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  fontSize: 14,
  fontFamily: "Inter, sans-serif",
  fontWeight: "500",
  marginRight: theme.spacing(1),
  color: theme.palette.silver.lighter,
  "&:hover": {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.ocean.light,
  },
}));

interface StyledTabProps {
  label: string;
}

interface StyledExpirationProps extends TypographyProps {
  subscriptionDaysLeft: number;
}

const StyledProfileContainer = styled(Container)(() => ({
  marginTop: "104px",
  padding: "68px 64px 0 64px !important",
}));

const StyledName = styled("div")(({ theme }) => ({
  fontWeight: "700",
  lineHeight: "20px",
  color: theme.palette.primary.main,
}));

const StyledEmail = styled(Box)(() => ({
  fontSize: 14,
  fontWeight: "400",
}));

const StyledAvatar = styled(Box)(({ theme }) => ({
  minWidth: 40,
  minHeight: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: "600",
  color: theme.palette.common.white,
}));

const StyledIcon = styled("img")(() => ({
  width: 16,
  height: 16,
  marginLeft: 10,
  objectFit: "contain",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: 6,
  backgroundColor: theme.palette.mist.light,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 4,
  color: theme.palette.primary.main,
  fontSize: 12,
  lineHeight: "15.6px",
  fontWeight: "600",
  padding: "6px 10px",
  height: 28,
  textTransform: "capitalize",
  "&>span.MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const StyledUserForm = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  flex: "3 1",
}));

const StyledUserFormButtonsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: 16,
  margin: 0,
}));

const StyledCancelFormButton = styled(StyledButton)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.primary.main,
  textDecoration: "underline",
  fontFamily: "Inter, sans-serif",
  fontSize: "12px",
  lineHeight: "15.6px",
  minWidth: "42px",
  fontWeight: "500",
  padding: 0,
  border: "none",
  "&:hover": {
    background: theme.palette.common.white,
  },
}));

const StyledUserInfoContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
}));

const StyledUserInfo = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flex: "1 1",
}));

const StyledUserCredentials = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  maxHeight: 40,
  marginLeft: 10,
}));

const StyledUserAdditionalInformation = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  lineHeight: "20px",
  fontSize: 14,
}));

const StyledExpirationDate = styled((props: StyledExpirationProps) => (
  <Typography {...props} />
))(({ subscriptionDaysLeft, theme }) => ({
  display: "flex",
  gap: "5px",
  alignItems: "bottom",
  color:
    subscriptionDaysLeft < 10
      ? theme.palette.secondary.main
      : theme.palette.grey[400],
  fontSize: "inherit",
}));

const StyledSettingsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginTop: 32,
  width: "100%",
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: 13,
  height: 13,
  marginBottom: 2,
}));

const Profile = () => {
  const location = useLocation();
  const { user, updateUserData } = useUser();
  const loading = useSelector(selector.selectLoading);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [editUserName, setEditUserName] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
  });
  const [updating, setUpdating] = useState(false);
  const subscriptionDaysLeft = useMemo(() => {
    if (user?.membership_renewal_date) {
      return (
        differenceInDays(new Date(user.membership_renewal_date), new Date()) + 1
      );
    }

    return null;
  }, [user]);
  const inputStyles = {
    containerStyles: {
      maxWidth: 200,
      minWidth: 100,
      height: 26,
      padding: "4.5px 8px",
      marginBottom: 2,
      borderRadius: 6,
    },
    input: {
      marginLeft: 0,
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
    },
  };

  useEffect(() => {
    if (user) {
      const _fd = { ...formData };
      _fd.first_name = user.first_name;
      _fd.last_name = user.last_name;
      setFormData(_fd);
    }
  }, [user]);

  useEffect(() => {
    if (location.state?.tabIndex !== undefined) {
      setTabIndex(location.state.tabIndex);
    }
  }, [location]);

  const getData = () => {
    dispatch(actions.getUserDetail(user?.id));
  };

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangeFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _fd = { ...formData };
    _fd[e.target.name] = e.target.value;
    setFormData(_fd);
  };

  const handleUpdateUserName = async () => {
    if (!formData.first_name || !formData.last_name) {
      snackbar.warning("Please enter user names.");
      return;
    }

    setUpdating(true);
    const res = await dispatch(actions.updateUserDetail(user?.id, formData));
    if (!res?.error) {
      updateUserData(formData); // updating user data in user context
      setUpdating(false);
      snackbar.success("Your names were updated successfully.");
      setEditUserName(false);
      // TODO: Decide where to store and handle user data
      // Currently, we have user object in redux and also in user context, so that forces us to update two instances for e.g here.
      getData(); // updating user state in redux
    } else {
      snackbar.error("Something went wrong!");
    }
  };

  return (
    <StyledProfileContainer>
      <StyledUserInfoContainer>
        {!loading ? (
          <StyledUserInfo>
            {user && (
              <>
                <StyledAvatar>
                  {user.first_name ? user.first_name[0].toUpperCase() : "N"}
                  {user.last_name ? user.last_name[0].toUpperCase() : "N"}
                </StyledAvatar>
                <StyledUserCredentials>
                  {!editUserName ? (
                    <StyledName>
                      {user.first_name + " " + user.last_name}{" "}
                      <StyledIcon
                        src={require("src/assets/images/edit.png")}
                        onClick={() => setEditUserName(true)}
                      />
                    </StyledName>
                  ) : (
                    <StyledUserForm>
                      <CustomTextField
                        type={"text"}
                        value={formData.first_name || ""}
                        name="first_name"
                        tabIndex={1}
                        autoFocus
                        containerStyle={inputStyles.containerStyles}
                        inputStyle={inputStyles.input}
                        onChange={handleChangeFormData}
                      />
                      <CustomTextField
                        type={"text"}
                        value={formData.last_name || ""}
                        name="last_name"
                        tabIndex={2}
                        containerStyle={inputStyles.containerStyles}
                        inputStyle={inputStyles.input}
                        onChange={handleChangeFormData}
                      />
                      <StyledUserFormButtonsContainer>
                        <StyledCancelFormButton
                          onClick={() => setEditUserName(false)}
                        >
                          Cancel
                        </StyledCancelFormButton>
                        <StyledButton
                          onClick={handleUpdateUserName}
                          startIcon={
                            updating ? (
                              <CircularProgress size={12} color="inherit" />
                            ) : (
                              <StyledDoneIcon
                                fontSize="small"
                                color="primary"
                              />
                            )
                          }
                        >
                          Save
                        </StyledButton>
                      </StyledUserFormButtonsContainer>
                    </StyledUserForm>
                  )}

                  <StyledUserAdditionalInformation>
                    <StyledEmail>{user.email}</StyledEmail>
                    {user.membership_renewal_date && (
                      <StyledExpirationDate
                        subscriptionDaysLeft={subscriptionDaysLeft}
                      >
                        Account Expiration:{" "}
                        {format(
                          toZonedTime(
                            new Date(user.membership_renewal_date),
                            "UTC"
                          ),
                          "MM/dd/yyyy"
                        )}
                        {subscriptionDaysLeft < 10 && (
                          <Tooltip
                            arrow={true}
                            placement="top"
                            enterDelay={0}
                            stylesTooltip={{ marginTop: "10px" }}
                            title={
                              <span>
                                {"Your account will expire in "}
                                <b>{subscriptionDaysLeft}</b>
                                {` day${
                                  subscriptionDaysLeft === 1 ? "" : "s"
                                }. Contact admin to request renewal`}
                              </span>
                            }
                          >
                            <StyledWarningIcon
                              icon={WarningAmberIcon}
                              customStyles={{
                                color: theme.palette.error.main,
                              }}
                            />
                          </Tooltip>
                        )}
                      </StyledExpirationDate>
                    )}
                  </StyledUserAdditionalInformation>
                </StyledUserCredentials>
              </>
            )}
          </StyledUserInfo>
        ) : (
          <CircularProgress />
        )}
      </StyledUserInfoContainer>
      <StyledSettingsContainer>
        <AntTabs
          value={tabIndex}
          onChange={handleChangeTab}
          aria-label="ant example"
        >
          <AntTab label={"Settings"} />
          <AntTab label={"Password Management"} />
        </AntTabs>
        {tabIndex === 0 ? <Settings /> : <PasswordManagement />}
      </StyledSettingsContainer>
    </StyledProfileContainer>
  );
};

export default Profile;
