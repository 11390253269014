import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { snackbar } from "src/view/toaster";
import { ReactComponent as DownloadIcon } from "src/assets/icons/download_icon.svg";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingText from "src/view/components/LoadingText";
import { Features } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { useSearch } from "src/modules/api/search";
import searchStoreSelectors from "src/modules/api/search/store/selectors";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";
import { Button } from "../../../components/ui";

const StyledSearchResultHeader = styled(Box)(({ theme }) => ({
  height: 64,
  borderBottom: `solid 1px ${theme.palette.info.main}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 8,
  paddingLeft: 25,
  paddingRight: 25,
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: theme.palette.ocean.dark,
}));

export default function SearchResultHeader() {
  const { user } = useUser();
  const posthog = usePostHog();
  const enableDownload = user?.features?.includes(Features.BULK_DOWNLOAD);
  const [gettingDownloadLink, setGettingDownloadLink] = useState(false);
  const {
    data,
    status,
    searchDisabled,
    isFetching,
    getDownloadAllDocumentsUrl,
  } = useSearch();
  const activePageIndex = useSelector(
    searchStoreSelectors.selectActivePageIndex
  );
  const totalResultCount = data?.pages[0]?.hits?.total?.value || 0;
  const totalResultRelation = data?.pages[0]?.hits?.total?.relation;

  const [downloadDocIds, setDownloadDocIds] = useState<string[]>([]);

  const { data: downloadUrl, status: downloadUrlStatus } =
    getDownloadAllDocumentsUrl({
      doc_ids: downloadDocIds,
    });

  if (downloadUrlStatus === "success") {
    setDownloadDocIds([]);
    const downloadWindow = window.open(downloadUrl, "_blank");
    downloadWindow.focus();
    setGettingDownloadLink(false);
  }

  if (downloadUrlStatus === "error") {
    snackbar.error("Download failed");
  }

  const handleDownloadDocAll = async () => {
    posthog.capture(HogEvent.BULK_DOWNLOAD_RUN);

    setDownloadDocIds(
      Object.values(data.pages[activePageIndex].items).flatMap((docs) =>
        docs.map((doc) => doc.id)
      )
    );
  };

  const message = useMemo(() => {
    if (searchDisabled) {
      return "No results";
    }

    if (isFetching) {
      return <LoadingText text="Searching" />;
    }

    if (status === "error") {
      return "Error";
    }

    if (status === "success") {
      return `Found ${totalResultCount.toLocaleString()}${
        totalResultRelation === "gte" ? "+ " : " "
      }${totalResultCount == 1 ? "result" : "results"}`;
    }
  }, [status, isFetching, totalResultCount, totalResultRelation]);

  return (
    <StyledSearchResultHeader>
      <StyledText>{message}</StyledText>

      {enableDownload && !searchDisabled && (
        <Button
          noWrap
          startIcon={
            gettingDownloadLink ? (
              <CircularProgress size={20} />
            ) : (
              <DownloadIcon />
            )
          }
          onClick={handleDownloadDocAll}
          disabled={!data || isFetching}
        >
          Bulk Download Results
        </Button>
      )}
    </StyledSearchResultHeader>
  );
}
