import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { snackbar } from "src/view/toaster";
import { isEmpty } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VerifiedIcon from "@mui/icons-material/Verified";
import { ReactComponent as IconDelete } from "src/assets/icons/delete.svg";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { ConfirmDialog } from "src/components/ui";
import { format } from "date-fns";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { useFilterOptions } from "src/modules/api/filters";
import theme from "src/theme";
import PropertyTags from "src/view/knowledge-wiki/detail/components/PropertyTags";
import { useSearch } from "src/modules/api/search";
import { emptyQueryVariables } from "src/modules/api/search";
import RecordCounts from "src/view/knowledge-wiki/detail/components/RecordCounts";
import { dictionaryDetailsSections } from "../../utilIObjects";
import { MDXRenderer } from "src/view/components/MarkdownEditor";
import IsLeakedTag from "src/view/components/IsLeakedTag";

const StyledBanner = styled("div")(() => ({
  position: "relative",
  // header offset (yeah, I know)
  marginTop: 64,
  height: "calc(390px - 64px)",
  backgroundImage: `url(${"/images/landing-wiki.png"})`,
  backgroundSize: "50vw",
  backgroundPosition: "right 0px",
  backgroundRepeat: "no-repeat",
}));

const StyledBackground = styled("div")(() => ({
  background:
    "linear-gradient(305.5deg, rgba(18, 42, 71, 0.972136) 29.19%, #225B7B 70.81%)",
}));

const StyledButton = styled(IconButton)(() => ({
  color: "#FFFFFF",
  backgroundColor: Colors.textDark100,
  borderRadius: 4,
  border: "1px solid #FFFFFF",
  height: 48,
  width: 48,
  marginLeft: 15,
  "&:hover": {
    backgroundColor: Colors.textDark100,
  },
}));

const StyledDetailHeaderItem = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  borderBottom: "5px solid #F75151",
  minWidth: 170,
}));

const StyledHeaderItemText = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: "700",
  lineHeight: "25px",
  fontSize: "20px",
}));

const StyledHeaderItemLabel = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: "400",
  lineHeight: "20px",
  fontSize: "16px",
  marginTop: "3px",
  marginBottom: "10px",
}));

const StyledPropertyContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[200],
  padding: 20,
  display: "flex",
  flexDirection: "column",
}));

const StyledPropertyLink = styled("div")(({ theme }) => ({
  color: theme.palette.grey[900],
  fontWeight: 400,
  lineHeight: "17px",
  fontSize: "14px",
  cursor: "pointer",
  width: "100%",
  textTransform: "capitalize",
}));

const StyledPropertySection = styled(Box)(() => ({
  width: "100%",
  position: "relative",
  marginBottom: 50,
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  width: "100%",
  lineHeight: "29px",
  cursor: "pointer",
  marginBottom: theme.spacing(4),
  textTransform: "capitalize",
  "& p": {
    fontWeight: 600,
    fontSize: "18px",
  },
}));

export const LinkLabel = styled("div")(() => ({
  color: "#122945",
  fontWeight: "700",
  lineHeight: "20px",
  fontSize: "14px",
  cursor: "pointer",
}));

const StyledPrimaryButton = styled(Button)(() => ({
  color: "#FFFFFF",
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 700,
  backgroundColor: Colors.secondary,
  height: 48,
  marginLeft: 15,
  textTransform: "none",
  whiteSpace: "nowrap",
  width: "max-content",
  "&:hover": {
    backgroundColor: Colors.secondary2,
  },
}));

const StyledCountryListButton = styled(Button)(() => ({
  color: "#FFFFFF",
  fontSize: 16,
  fontWeight: "600",
  backgroundColor: "rgba(255, 255, 255, 0.20)",
  borderRadius: "30px",
  marginLeft: 6,
  minWidth: "min-content",
  padding: "4px 10px",
  "&:hover": {
    backgroundColor: Colors.secondary2,
  },
}));

const StyledImage = styled("img")(() => ({
  width: "100%",
  height: 226,
  objectFit: "contain",
}));

const StyledIsLeakedTag = styled(IsLeakedTag)(() => ({
  color: "#FFFFFF",
  borderColor: "#FFFFFF",
}));

const StyledDatasetName = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: "700",
  lineHeight: "39px",
  fontSize: "32px",
  overflow: "hidden",
}));

const DataDetail = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { handleSearch } = useSearch();

  const { user } = useUser();
  const { getKnowledgeWiki } = useKnowledgeWiki();
  const {
    getDatasetMetadataById,
    getDatasetRecordCountById,
    updateDatasetMetadata,
    deleteDatasetMetadata,
  } = useDatasetMetadata();
  const { data: detail, isLoading: isLoadingKnowledgeWiki } = getKnowledgeWiki(
    params?.id
  );
  const { data: datasetMetadata } = getDatasetMetadataById(detail?.dataset);
  const { data: recordCount, isLoading: recordCountLoading } =
    getDatasetRecordCountById(detail?.dataset);
  const { mutateAsync: updateDatasetMetadataAsync } = updateDatasetMetadata(
    detail?.dataset
  );
  const [collapseSideMenu, setCollapseSideMenu] = useState(true);
  const isAdmin = useMemo(() => user?.role === Role.ADMIN, [user]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [linkAnchorEl, setLinkAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [countryListAnchor, setCountryListAnchor] =
    React.useState<null | HTMLElement>(null);

  const { countries: sharedCountries } = useFilterOptions();

  // TODO: useDisclosure/useMenu hook
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openCountryList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCountryListAnchor(event.currentTarget);
  };
  const closeCountryList = () => {
    setCountryListAnchor(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isEmptyDetails = useMemo(
    () =>
      dictionaryDetailsSections.every(
        ({ key }) => !(detail?.[key] as string)?.length
      ),
    [detail]
  );

  const toPalantir = () => {
    let url = detail?.foundry_link;
    if (url.includes("http") == false) url = "https://" + url;
    window.open(url, "_blank");
  };

  const toSource = () => {
    let url = detail?.external_source;
    if (url.includes("http") == false) url = "https://" + url;
    window.open(url, "_blank");
  };

  const handleScrollToPosition = (id: string) => {
    const { top } = document.getElementById(id).getBoundingClientRect();
    const currentPosition = window.scrollY;
    window.scrollTo(0, top + currentPosition - 100);
  };

  const handleSubmit = () => {
    handleSearch({
      filters: {
        ...emptyQueryVariables.filters,
        dataset_ids: [detail.dataset],
      },
      content: "*",
    });
  };

  const renderCountries = (countries: (string | number)[]) => {
    if (!countries?.length) return "None";

    let visibleCountries = countries;
    const hiddenCountries = [...countries.slice(2, countries.length)];
    const shouldTruncate = countries.length > 2;

    if (shouldTruncate) {
      visibleCountries = [...countries].slice(0, 2);
    }

    const listFullCountryNames = (countryList: KnowledgeWiki["countries"]) =>
      countryList
        ?.map((countryCode: string | number) => {
          return sharedCountries.filter((c) => c.value == countryCode)?.[0]?.[
            "label"
          ];
        })
        .join(", ");

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StyledHeaderItemText>
          {listFullCountryNames(visibleCountries)}
        </StyledHeaderItemText>

        {shouldTruncate && (
          <>
            <StyledCountryListButton onClick={openCountryList}>
              +{hiddenCountries.length}
            </StyledCountryListButton>

            <Popover
              id="counry-list-menu"
              anchorEl={countryListAnchor}
              open={!!countryListAnchor}
              onClose={closeCountryList}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                color: Colors.primary,
                mt: 5,
                ml: "-50px",
              }}
            >
              <Box
                sx={{
                  maxWidth: "450px",
                  minWidth: "250px",
                  padding: 2,
                  fontWeight: 600,
                }}
              >
                {listFullCountryNames(hiddenCountries)}
              </Box>
            </Popover>
          </>
        )}
      </Box>
    );
  };

  const goToList = () => {
    navigate({ pathname: "/data-dictionary/data", search: location.search });
  };

  const publishDataset = () => {
    updateDatasetMetadataAsync({
      id: detail.dataset,
      payload: {
        draft: false, //* changing draft to False makes a dataset published */
      },
    }).then(() => {
      snackbar.success("Dataset has been published");
    });
  };

  const handleDelete = () => {
    deleteDatasetMetadata.mutate(
      { id: detail?.dataset },
      {
        onSuccess: () => {
          snackbar.success("Dataset successfully deleted");
          goToList();
        },
        onError: () => {
          snackbar.error("Failed to delete dataset");
        },
      }
    );
  };

  const showRecordCountBreakdown =
    recordCount &&
    (!isEmpty(recordCount.doc_type_counts) ||
      !isEmpty(recordCount.doc_type_file_counts) ||
      !isEmpty(recordCount.schema_type_counts));

  return (
    <>
      <StyledBackground>
        <StyledBanner>
          <Container
            disableGutters
            sx={{
              pt: 4,
              pb: 1,
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  cursor: "pointer",
                }}
                onClick={goToList}
              >
                <img
                  src={require("src/assets/images/arrow_left_white.png")}
                  style={{ width: 24, height: 24 }}
                  alt="arrow_left_white"
                />
                <Typography
                  variant="inherit"
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: "700",
                    lineHeight: "17px",
                    fontSize: "14px",
                    marginLeft: "4px",
                  }}
                >
                  Back
                </Typography>
              </Box>

              <Tooltip
                arrow
                title={detail?.name}
                placement="top"
                enterDelay={500}
              >
                <StyledDatasetName variant="inherit">
                  {detail?.name}
                </StyledDatasetName>
              </Tooltip>
              <StyledIsLeakedTag dataset={datasetMetadata}>
                This dataset was sourced from a leak.
              </StyledIsLeakedTag>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {(detail?.foundry_link || detail?.external_source || isAdmin) && (
                <StyledButton onClick={(e) => setLinkAnchorEl(e.currentTarget)}>
                  <img
                    src={require("src/assets/images/three-dots.png")}
                    style={{ width: 20, height: 20 }}
                    alt="three-dots"
                  />
                </StyledButton>
              )}

              <Menu
                id="basic-menu"
                anchorEl={linkAnchorEl}
                open={!!linkAnchorEl}
                onClose={() => setLinkAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{ style: { width: "230px" } }}
                sx={{ mt: 2 }}
              >
                {detail?.external_source && (
                  <MenuItem onClick={toSource} sx={{ p: "10px 20px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 1,
                      }}
                    >
                      <img
                        src={require("src/assets/images/globe-1.png")}
                        style={{ width: 20, height: 20 }}
                        alt="globe-1"
                      />
                      <LinkLabel>External Source</LinkLabel>
                      <img
                        src={require("src/assets/images/call-made.png")}
                        style={{ width: 20, height: 20, marginLeft: "auto" }}
                        alt="call-made"
                      />
                    </Box>
                  </MenuItem>
                )}
                {detail?.foundry_link && (
                  <MenuItem onClick={toPalantir} sx={{ p: "10px 20px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 1,
                      }}
                    >
                      <img
                        src={require("src/assets/images/menu-link.png")}
                        style={{ width: 20, height: 20 }}
                        alt="menu-link"
                      />
                      <LinkLabel>Foundry Link</LinkLabel>
                      <img
                        src={require("src/assets/images/call-made.png")}
                        style={{ width: 20, height: 20, marginLeft: "auto" }}
                        alt="call-made"
                      />
                    </Box>
                  </MenuItem>
                )}
                {isAdmin && datasetMetadata?.draft && (
                  <MenuItem onClick={publishDataset} sx={{ p: "10px 20px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 1,
                      }}
                    >
                      <VerifiedIcon sx={{ width: 20, height: 20 }} />
                      <LinkLabel>Publish</LinkLabel>
                    </Box>
                  </MenuItem>
                )}
                {isAdmin && (
                  <MenuItem sx={{ p: "10px 20px" }}>
                    <ConfirmDialog
                      onConfirm={handleDelete}
                      title="Delete Dataset"
                      description="Do you want to delete it? This action cannot be undone."
                      confirmText="Confirm"
                      cancelText="Cancel"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <IconDelete
                          style={{
                            color: Colors.secondary,
                            width: 20,
                            height: 20,
                          }}
                        />
                        <Typography
                          fontSize={14}
                          fontWeight={700}
                          style={{ color: Colors.secondary }}
                        >
                          Delete Dataset
                        </Typography>
                      </Box>
                    </ConfirmDialog>
                  </MenuItem>
                )}
              </Menu>
              {(isAdmin || user?.kw_write?.includes(detail?.id)) && (
                <StyledButton
                  onClick={() =>
                    navigate({
                      pathname: `/data-dictionary/data/edit/${detail.id}`,
                      search: location.search,
                    })
                  }
                >
                  <img
                    src={require("src/assets/images/edit-white.png")}
                    style={{ width: 20, height: 20 }}
                    alt="edit-white"
                  />
                </StyledButton>
              )}
              <StyledPrimaryButton
                variant="contained"
                endIcon={<SearchIcon />}
                onClick={() => handleSubmit()}
              >
                Search this data
              </StyledPrimaryButton>
            </Box>
          </Container>
        </StyledBanner>
      </StyledBackground>

      <Container
        disableGutters
        sx={{
          mt: -12,
          mb: 20,
          px: 4,
          maxWidth: theme.breakpoints.values.lg,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            gap: "50px",
          }}
        >
          <StyledDetailHeaderItem>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <StyledHeaderItemText>
                {recordCountLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : recordCount?.count ? (
                  recordCount?.count.toLocaleString()
                ) : null}
              </StyledHeaderItemText>

              {showRecordCountBreakdown && (
                <Box
                  sx={{
                    borderRadius: "50%",
                    background: "#45708B",
                  }}
                >
                  <IconButton size="small" onClick={handleClick}>
                    <img
                      src={require("src/assets/images/record_count.png")}
                      style={{ width: 16, height: 16 }}
                      alt="record_count"
                    />
                  </IconButton>
                </Box>
              )}
            </Box>

            <Box>
              <StyledHeaderItemLabel variant="inherit">
                Record Count
              </StyledHeaderItemLabel>

              {showRecordCountBreakdown && (
                <RecordCounts
                  recordCount={recordCount}
                  datasetId={detail?.dataset}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              )}
            </Box>
          </StyledDetailHeaderItem>

          <StyledDetailHeaderItem>
            <StyledHeaderItemText>
              {renderCountries(detail?.countries)}
            </StyledHeaderItemText>
            <StyledHeaderItemLabel variant="inherit">
              Countries
            </StyledHeaderItemLabel>
          </StyledDetailHeaderItem>
          {detail?.dataset_owners && detail.dataset_owners.length > 0 && (
            <StyledDetailHeaderItem>
              <StyledHeaderItemText>
                {detail.dataset_owners[0].name}
              </StyledHeaderItemText>
              <StyledHeaderItemLabel variant="inherit">
                Dataset Owner
              </StyledHeaderItemLabel>
            </StyledDetailHeaderItem>
          )}
          <StyledDetailHeaderItem>
            <StyledHeaderItemText>
              {detail?.date_snapshot
                ? format(
                    new Date(detail.date_snapshot.replace(/-/g, "/")),
                    "yyyy-MM-dd"
                  )
                : "Unknown"}
            </StyledHeaderItemText>
            <StyledHeaderItemLabel variant="inherit">
              Date of Snapshot
            </StyledHeaderItemLabel>
          </StyledDetailHeaderItem>
        </Box>

        <PropertyTags dataset={detail} />

        <Container
          disableGutters
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {isLoadingKnowledgeWiki ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[400],
                gap: 4,
              }}
            >
              <CircularProgress size={120} color="inherit" />
              <Typography>Fetching datasets...</Typography>
            </Box>
          ) : (
            <>
              <Box>
                {!isEmptyDetails && (
                  <StyledPropertyContainer
                    sx={{
                      boxSizing: "border-box",
                      width: "250px",
                      position: "sticky",
                      top: 150,
                    }}
                  >
                    <div
                      role="button"
                      onClick={() => setCollapseSideMenu(!collapseSideMenu)}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          lineHeight: "17px",
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            color: theme.palette.secondary.main,
                            marginRight: theme.spacing(1),
                          }}
                        >
                          {collapseSideMenu ? "▼" : "►"}
                        </span>
                        Properties
                      </Typography>
                    </div>
                    <Collapse in={collapseSideMenu}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          gap: 2,
                          mt: 2,
                        }}
                      >
                        <Divider />

                        {dictionaryDetailsSections.map(({ key, label }) => {
                          if (!detail?.[key]) return null;

                          return (
                            <StyledPropertyLink
                              key={`${key}-section`}
                              onClick={() => handleScrollToPosition(key)}
                            >
                              {label ?? key}
                            </StyledPropertyLink>
                          );
                        })}
                      </Box>
                    </Collapse>
                  </StyledPropertyContainer>
                )}
              </Box>
              <Box
                sx={{
                  ml: 4,
                  width: "100%",
                }}
              >
                {dictionaryDetailsSections.map(({ key, label }) => {
                  if (!detail?.[key]) return null;

                  return (
                    <StyledPropertySection key={key + "data-section"}>
                      <SectionTitle id={key}>
                        <Typography>{label ?? key}</Typography>
                        <Divider sx={{ flex: "1 1 100%" }} />
                      </SectionTitle>

                      <Suspense fallback={<CircularProgress />}>
                        <MDXRenderer
                          content={(detail?.[key] as string) ?? ""}
                        />
                      </Suspense>
                    </StyledPropertySection>
                  );
                })}
              </Box>
            </>
          )}
        </Container>

        {!isLoadingKnowledgeWiki && isEmptyDetails && (
          <Box>
            <StyledImage
              src={require("src/assets/images/not-found.png")}
              alt="Not Found"
            ></StyledImage>
            <Typography
              sx={{
                color: Colors.textDark,
                fontSize: 18,
                lineHeight: "20px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              No details for record
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default DataDetail;
