interface SearchOperator {
  operator: string;
  description: string;
}

export const SearchOperatorsInfo: SearchOperator[] = [
  {
    operator: "AND",
    description:
      "Ensure that the terms on both sides of the operator are present within results.",
  },
  {
    operator: "OR",
    description:
      "Ensure that the terms on either side of the operator are present within results.",
  },
  {
    operator: "NOT",
    description:
      "Excludes results that contain the term following the NOT operator. Use it to filter out unwanted results.",
  },
  {
    operator: '""',
    description:
      "Search for an exact phrase or term. The terms enclosed in double quotes must appear exactly as typed, in the same order, within the results.",
  },
  {
    operator: "()",
    description:
      "Group multiple terms or clauses to form a subquery. Useful for controlling the precedence of operators and creating more complex queries.",
  },
];
