import Box from "@mui/material/Box";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import {
  StyledContainer,
  StyledHeader,
  Title,
  Label,
  renderContent,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";

interface Props {
  data: PreviewDocListProps | unknown[];
  loading: boolean;
}

const RTFPreview = ({ data, loading }: Props) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <Box sx={{ display: "flex" }}>
          <Title></Title>
          <Label>Extracted Text</Label>
        </Box>
      </StyledHeader>
      {renderContent(
        data,
        loading,
        <Box
          sx={{
            width: "calc(100% - 70px)",
            height: "calc(100vh - 250px)",
            display: "block",
            ml: "43px",
            mr: "36px",
            padding: 1,
          }}
          aria-label="simple table"
        >
          <Box
            sx={{
              pt: 2,
              pb: 2,
              fontSize: "12px",
              lineHeight: "15px",
              color: "#000000",
            }}
          >
            {Array.isArray(data) && data.map((row: unknown) => row["content"])}
          </Box>
        </Box>
      )}
    </StyledContainer>
  );
};

export default RTFPreview;
