// https://colinhacks.com/essays/reasonable-email-regex
export const email =
  /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

export const createPhraseRegex = (phrase: string) => {
  const escapedPhrase = phrase
    .trim()
    .split(/\s+/) // Splitting into separate words
    .map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")) // escaping special symbols
    .join("\\s*"); // including `\s*` for flexible text spaces
  return new RegExp(`\\b${escapedPhrase}\\b`, "g");
};
