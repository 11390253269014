import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import useUser from "src/modules/api/auth/useUser";

interface UserExpiredModalProps {
  isExpired?: boolean;
  closable?: boolean;
  onClose?: () => void;
}

const StyledTitle = styled("div")(({ theme }) => ({
  fontSize: 18,
  lineHeight: "22px",
  fontWeight: "400",
  color: theme.palette.common.black,
}));

const StyledLink = styled("a")(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Inter",
  lineHeight: "14.5px",
  color: theme.palette.primary.main,
  fontWeight: 400,
  textDecoration: "underline",
}));

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  padding: "24px 16px",
  fontSize: 14,
  fontWeight: "400",
  color: theme.palette.primary.main,
}));

const StyledCloseModalButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 8,
  top: 8,
}));

const checkIsExpired = (renewalDate?: string) => {
  return new Date().getTime() > new Date(renewalDate).getTime();
};

const UserExpiredModal = ({
  isExpired: propIsExpired,
  closable,
  onClose: propOnClose,
}: UserExpiredModalProps) => {
  const { user } = useUser();
  const [isExpired, setIsExpired] = useState(
    checkIsExpired(user?.membership_renewal_date)
  );

  useEffect(() => {
    setIsExpired(checkIsExpired(user?.membership_renewal_date));
  }, [user]);

  const onClose = () => {
    setIsExpired(false);
    propOnClose?.();
  };

  return (
    <Dialog open={propIsExpired ?? isExpired} maxWidth="md" sx={{ padding: 2 }}>
      <DialogTitle>
        <StyledTitle>Account is expired</StyledTitle>
        {closable && (
          <StyledCloseModalButton onClick={onClose}>
            <CloseIcon />
          </StyledCloseModalButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <StyledDialogContentText>
          Your account has expired. To request access, please email:{" "}
          <StyledLink href="mailto:software@c4ads.org">
            software@c4ads.org
          </StyledLink>
        </StyledDialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default UserExpiredModal;
