import { QueryItem } from "src/types/BulkSearch";
import theme from "src/theme";

// Function to determine the color for operators in the query based on its type and parentheses balance
export const getOperatorColor = (
  query: QueryItem,
  areParenthesesBalanced: boolean,
  isSelected: boolean
) => {
  if (query.type === "operator" && !isSelected) {
    if (["AND", "OR", "NOT"].includes(query.value)) {
      return theme.palette.grey[400];
    }

    if (
      (query.value === "(" || query.value === ")") &&
      !areParenthesesBalanced
    ) {
      return theme.palette.error.main;
    }
  }

  return theme.palette.primary.main;
};
