import React from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon, Info as InfoIcon } from "@mui/icons-material";
import { Colors } from "src/view/constants/colors";
import { SearchOperatorsInfo } from "src/view/constants/searchOperatorsInfo";

const StyledInfoButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  padding: 0,
  margin: "12px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "& svg": {
    fontSize: "18px",
    fill: theme.palette.primary.contrastText,
    borderRadius: "50%",
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 10,
  top: 10,
  "& svg": {
    fill: Colors.aubergine,
  },
}));

const TitleTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "28px",
  color: Colors.aubergine,
});

const StyledList = styled(List)({
  marginTop: "16px",
  overflowY: "scroll",
  height: 150,
  padding: 0,
});

const StyledListItem = styled(ListItem)({
  margin: 0,
  padding: 0,
});

interface SearchTooltipProps {
  openedEl: HTMLDivElement;
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
}

const SearchTooltip = ({ openedEl, onOpen, onClose }: SearchTooltipProps) => {
  return (
    <>
      <StyledInfoButton
        edge="start"
        aria-label="open tips modal"
        onClick={(e: React.MouseEvent<HTMLElement>) => onOpen(e)}
      >
        <InfoIcon />
      </StyledInfoButton>
      <Popover
        id="search-tips-popover"
        open={!!openedEl}
        anchorEl={openedEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transitionDuration={{ enter: 300, exit: 300 }}
        PaperProps={{
          style: {
            marginTop: "55px",
            width: 310,
            maxHeight: 215,
            padding: "16px",
            borderRadius: "8px",
            boxShadow: "0px 0px 12px 4px rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <StyledCloseButton onClick={onClose}>
          <CloseIcon />
        </StyledCloseButton>
        <TitleTypography>Search Tips</TitleTypography>
        <StyledList className="styled-scrollbars">
          {[
            ...SearchOperatorsInfo,
            {
              operator: "Fuzziness ~",
              description:
                "Use fuzziness to find terms that are similar to the search term based on edit distance. For example, `term~1` allows one edit (insertion, deletion, or substitution) and `term~2` allows two edits.",
            },
          ].map((item) => (
            <StyledListItem key={item.operator}>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: Colors.aubergine,
                }}
                secondaryTypographyProps={{
                  color: Colors.aubergine,
                }}
                primary={item.operator}
                secondary={item.description}
              />
            </StyledListItem>
          ))}
        </StyledList>
      </Popover>
    </>
  );
};

export default SearchTooltip;
