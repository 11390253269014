import { useInfiniteQuery } from "@tanstack/react-query";
import { useFetch } from "../fetch";

import queryKeys from "src/modules/api/queryKeys";
import { SearchResultResponse } from "src/types/Search";

interface SearchParams {
  content: string;
  datasetId: string;
  pageParam?: number;
}

export function useSearchInFile() {
  const { postApi } = useFetch();

  const getSearchResultsFn = async ({
    content,
    pageParam,
    datasetId,
  }: SearchParams): Promise<SearchResultResponse> => {
    const body = {
      content,
      page: pageParam,
      filters: {
        dataset_ids: [datasetId],
      },
    };

    const res = await postApi("search/", { body: JSON.stringify(body) });
    return res.json();
  };

  const getSearchResults = ({ content, datasetId, pageParam }: SearchParams) =>
    useInfiniteQuery({
      queryFn: ({ pageParam = 1 }) => {
        return getSearchResultsFn({ content, pageParam, datasetId });
      },
      queryKey: [queryKeys.SEARCH_IN_FILE, datasetId, content, pageParam],
      getNextPageParam: (lastPage) => lastPage.next_page_number,
    });

  return {
    getSearchResults,
  };
}
