import { useEffect, useState } from "react";
import UserContext, { UserContextInterface } from "./UserContext";
import { User } from "src/types/Auth";
import { AuthToken } from "src/modules/auth/authToken";
import { useFetch } from "src/modules/api/fetch";
import { useQuery } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { queryClient } from "src/index";

export interface UserProviderProps {
  context?: React.Context<UserContextInterface | null>;
  children?: React.ReactNode;
}

const UserProvider = ({
  context: Context = UserContext,
  children,
}: UserProviderProps) => {
  const { getApi } = useFetch();
  const posthog = usePostHog();
  const [isError, setIsError] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!AuthToken.getStoredCSRFToken()
  );

  const logout = () => {
    posthog.reset();
    AuthToken.logout();
    window.location.replace("/login");
  };

  const getUserFn = async () => {
    const data = await getApi(
      ["users", "permissions"],
      null,
      null,
      validateUser
    );
    return data;
  };

  const { data: user, isLoading: isUserDataLoading } = useQuery({
    enabled: isAuthenticated,
    queryFn: getUserFn,
    queryKey: ["user"],
    onSuccess: () => setIsError(false),
    retry: (failureCount) => {
      if (failureCount === 2) {
        setIsError(true);
        return false;
      } else {
        return true;
      }
    },
  });

  const updateUserData = (userFields: Partial<User> | null) => {
    if (!userFields) return;

    queryClient.setQueryData(["user"], (oldUserFields: User) => ({
      ...oldUserFields,
      ...userFields,
    }));
  };

  useEffect(() => {
    if (user) {
      posthog.identify(user.email);
    }
  }, [user]);

  const validateUser = (response: Response): string => {
    let apiErrorMsg = "";

    response?.json().then((errorResponse: Record<string, string>) => {
      if (typeof errorResponse !== "object" || errorResponse === null) {
        return false;
      } else if (errorResponse && errorResponse?.["error"]) {
        apiErrorMsg = errorResponse["error"];
      }
    });

    return apiErrorMsg;
  };

  return (
    <Context.Provider
      value={{
        user,
        isUserDataLoading,
        logout,
        isError,
        isAuthenticated,
        updateUserData,
        setIsAuthenticated,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default UserProvider;
