import { useState } from "react";
import SearchInput from "../components/SearchInput";

interface Props {
  onSearch: (key: string) => void;
}

const SearchBox = ({ onSearch }: Props) => {
  const [searchKey, setSearchKey] = useState("");

  const handleSearch = () => {
    onSearch(searchKey.trim().toLowerCase());
  };

  return (
    <SearchInput
      placeholder="Search User"
      value={searchKey}
      onChange={(e) => setSearchKey(e.target.value)}
      onKeyPress={(e) => e.key == "Enter" && handleSearch()}
      style={{ width: "100%" }}
    />
  );
};

export default SearchBox;
