import SearchService from "src/modules/search/searchService";
import Errors from "src/modules/shared/error/errors";
import { formatSearchQuery, isHTML } from "src/utils";
import { AnyAction, Dispatch } from "redux";
import { SearchResultModel } from "src/view/models/SearchResultModel";

const prefix = "SEARCH_VIEW";

const searchViewActions: AnyAction = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  GET_DOWNLOAD_LINK: `${prefix}_GET_DOWNLOAD_LINK`,
  SET_SEARCH_VIEW_RESULTS: `${prefix}_SET_SEARCH_VIEW_RESULTS`,
  GET_PREVIEW_DOCUMENT: `${prefix}_GET_PREVIEW_DOCUMENT`,
  SET_PREVIEW_DOC: `${prefix}_SET_PREVIEW_DOC`,
  SELECT_RESULT_TAB: `${prefix}_SELECT_RESULT_TAB`,
  START_LOADING: `${prefix}_START_LOADING`,
  STOP_LOADING: `${prefix}_STOP_LOADING`,
  CLEAR: `${prefix}_CLEAR`,

  doFind: (page: number) => async (dispatch: Dispatch<AnyAction>) => {
    const params = new URLSearchParams(window.location.search);
    const query = formatSearchQuery(params.get("query"));
    if (!query) return;

    const _query = {
      content: query,
      page,
    };

    const dataset_types = params.get("dataset_types");
    if (dataset_types) _query["dataset_types"] = dataset_types.split(",");
    const regions = params.get("regions");
    if (regions) _query["regions"] = regions?.split(",");
    const languages = params.get("languages");
    if (languages) _query["languages"] = languages?.split(",");
    const countries = params.get("countries");
    if (countries) _query["countries"] = countries?.split(",");
    const dataset_ids = params.get("dataset_ids");
    if (dataset_ids) _query["dataset_ids"] = dataset_ids?.split(",");
    const exclude_dataset_ids = params.get("exclude_dataset_ids");
    if (exclude_dataset_ids)
      _query["exclude_dataset_ids"] = exclude_dataset_ids.split(",");
    const data_collection_ids = params.get("data_collection_ids");
    if (data_collection_ids)
      _query["data_collection_ids"] = data_collection_ids.split(",");
    const exclude_data_collection_ids = params.get(
      "exclude_data_collection_ids"
    );
    if (exclude_data_collection_ids) {
      _query["exclude_data_collection_ids"] =
        exclude_data_collection_ids.split(",");
    }

    const doc_types = params.get("doc_types");
    if (doc_types) _query["doc_types"] = doc_types?.split(",");

    try {
      dispatch({
        type: searchViewActions.FIND_STARTED,
      });

      const record = await SearchService.find(_query);

      dispatch({
        type: searchViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: searchViewActions.FIND_ERROR,
      });
    }
  },
  setSearchViewResult:
    (data: SearchResultModel) => async (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: searchViewActions.SET_SEARCH_VIEW_RESULTS,
        payload: data,
      });
    },
  downloadDoc:
    (id: string, type: number, entireFile = false) =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        const response = await SearchService.getDownloadDocLink(
          id,
          type,
          entireFile
        );

        dispatch({
          type: searchViewActions.GET_DOWNLOAD_LINK,
          payload: response?.url || null,
        });

        return response?.url || null;
      } catch (error) {
        Errors.handle(error);
      }
    },
  getDocumentPreview:
    (doc_id: string, type: number, doc_type: string) =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        let range = 5;
        if (doc_type === "pdf") range = 1;
        const response = await SearchService.getDocumentPreview(
          doc_id,
          type,
          range
        );
        if (response.error) {
          dispatch({
            type: searchViewActions.FIND_ERROR,
            payload: response.detail,
          });
        } else {
          if (type == 0) {
            dispatch({
              type: searchViewActions.GET_PREVIEW_DOCUMENT,
              payload: response,
            });
          } else if (type == 1) {
            const arr = [
              "pdf",
              "txt",
              "doc",
              "docx",
              "htm",
              "html",
              "jhtml",
              "shtml",
              "xlsx",
              "jpg",
              "jpeg",
              "png",
              "heic",
            ];
            if (arr.includes(doc_type)) {
              dispatch({
                type: searchViewActions.GET_PREVIEW_DOCUMENT,
                payload: response,
              });
              return;
            }
            let _result = [];
            for (let i = 0; i < response.length; i++) {
              let item;
              try {
                item = JSON.parse(response[i].content);
              } catch (e) {
                item = response[i].content;
              }
              if (doc_type === "csv" || doc_type === "parquet") {
                _result.push({
                  id: response[i].id,
                  content: item,
                });
              } else if (doc_type === "json" || doc_type === "jsonl") {
                _result.push(item);
              } else {
                if (isHTML(response[i].content)) {
                  _result = response[i].content;
                  break;
                }
                _result.push(...item);
              }
            }
            dispatch({
              type: searchViewActions.GET_PREVIEW_DOCUMENT,
              payload: _result,
            });
          }
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: searchViewActions.FIND_ERROR,
        });
      }
    },
  downloadDocAll: (data: { doc_ids: string[] }) => async () => {
    try {
      const response = await SearchService.getDownloadDocAllLink(data);

      return response?.url || null;
    } catch (error) {
      console.log(error);
    }
  },
  clear: (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: searchViewActions.CLEAR,
    });
  },
  type: undefined,
};

export default searchViewActions;
