import { cloneElement, ReactElement, useState, ReactNode } from "react";
import {
  styled,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from "@mui/material";
import { merge } from "lodash";

interface MenuProps extends Omit<MuiMenuProps, "open"> {
  trigger: ReactElement;
  closeOnClick?: boolean;
}

interface MenuItemProps extends MuiMenuItemProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0%",
  borderRadius: "6px",
  color: theme.palette.primary.main,
  minHeight: 0,
  padding: "3px 6px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "& svg": {
    width: "16px",
    height: "16px",
  },
}));

const StyledMuiMenu = styled(MuiMenu)({
  zIndex: 1505,
  "& .MuiList-root": {
    padding: 0,
  },
});

export const MenuItem = ({
  startIcon,
  endIcon,
  children,
  ...props
}: MenuItemProps) => (
  <StyledMenuItem {...props}>
    {startIcon}
    {children}
    {endIcon}
  </StyledMenuItem>
);

export const Menu = ({
  onClose: propOnClose,
  onClick: propOnClick,
  closeOnClick,
  PaperProps,
  transformOrigin = {
    horizontal: "right",
    vertical: "top",
  },
  anchorOrigin = {
    horizontal: "right",
    vertical: "bottom",
  },
  children,
  trigger,
  ...props
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const isMenuOpen = Boolean(anchorEl);

  const onClose = (event: any, reason: "backdropClick" | "escapeKeyDown") => {
    propOnClose?.(event, reason);
    setAnchorEl(undefined);
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    propOnClick?.(e);
    closeOnClick && onClose(e, "backdropClick");
  };

  return (
    <>
      {cloneElement(trigger, {
        onClick: (e: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(e.currentTarget);
          trigger.props?.onClick?.(e);
        },
      })}
      <StyledMuiMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={onClose}
        onClick={onClick}
        PaperProps={merge(
          {
            elevation: 0,
            sx: {
              padding: "12px 6px",
              borderRadius: "6px",
              boxShadow: "0px 3px 13px 4px #0000001A",
            },
          },
          PaperProps
        )}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        {...props}
      >
        {children}
      </StyledMuiMenu>
    </>
  );
};
