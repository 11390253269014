import { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import TransparentEyeIcon from "@mui/icons-material/Visibility";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";

import theme from "src/theme";
import {
  Group,
  GroupDatasetPermissionsFields,
  GroupFeatures,
} from "src/types/UserGroup";
import { SensitivityString } from "src/types/Dataset";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";

import IsLeakedTag from "src/view/components/IsLeakedTag";
import DataGrid from "src/view/components/DataGrid";

import WikiPreview from "../WikiPreview";
import CellDecoratedWithFeatureToggle from "./CellDecoratedWithFeatureToggle";
import ColumnHeader from "./ColumnHeader";

interface Props {
  group: Group;
  knowledgeWikis: KnowledgeWiki[];
  sensitivity: SensitivityString;
  isLimited: boolean;
  handleUpdate: (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => void;
  checkedAll: Omit<
    Record<GroupDatasetPermissionsFields, boolean>,
    "sensitivity_levels"
  >;
  handleUpdateFeatures: (checked: boolean, featureName: GroupFeatures) => void;
  shouldDisableToggles: boolean;
}

const DatasetPermissionsTable = ({
  group,
  knowledgeWikis,
  sensitivity,
  isLimited,
  handleUpdate,
  checkedAll,
  handleUpdateFeatures,
  shouldDisableToggles,
}: Props) => {
  const apiRef = useGridApiRef();
  const [wikiPreviewItem, setWikiPreviewItem] = useState<KnowledgeWiki | null>(
    null
  );
  const tableDatasetIds = knowledgeWikis.map((row) => row.dataset);

  const columns: GridColDef<KnowledgeWiki>[] = [
    {
      field: "name",
      headerName: "Datasets",
      flex: 1,
      cellClassName: "WikiNameCell",
      renderCell: ({ row: knowledgeWiki, value }) => (
        <>
          <Typography>{value}</Typography>
          <IsLeakedTag dataset={knowledgeWiki} />

          <IconButton
            onClick={() => setWikiPreviewItem(knowledgeWiki)}
            sx={{
              "&:hover": { color: theme.palette.grey[900] },
              color: theme.palette.grey[400],
            }}
          >
            <TransparentEyeIcon />
          </IconButton>
        </>
      ),
    },

    {
      field: "datasets",
      headerName: "Search",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-columnHeaderTitle",
      renderHeader: () => {
        function handleSwitch(isChecked: boolean) {
          knowledgeWikis.forEach((row) => {
            handleUpdate(isChecked, "datasets", row.dataset);
            handleUpdate(isChecked, "kw_read", row.id);
          });
        }

        return (
          <>
            Search
            <ColumnHeader
              handleSwitch={handleSwitch}
              handleUpdateFeatures={handleUpdateFeatures}
              value={checkedAll.datasets}
              label={sensitivity}
              withConfirm={isLimited}
              datasetsIds={tableDatasetIds}
              group={group}
              shouldDisableToggles={shouldDisableToggles}
            />
          </>
        );
      },
      renderCell: ({ row: knowledgeWiki }) => {
        const handleSwitch = (isChecked: boolean) => {
          handleUpdate(isChecked, "datasets", knowledgeWiki.dataset);
          handleUpdate(isChecked, "kw_read", knowledgeWiki.id);
        };
        return (
          <CellDecoratedWithFeatureToggle
            value={group.datasets?.includes(knowledgeWiki.dataset)}
            label={sensitivity}
            withConfirm={isLimited}
            handleSwitch={handleSwitch}
            dataset={knowledgeWiki}
            handleUpdateFeatures={handleUpdateFeatures}
            shouldDisableToggles={shouldDisableToggles}
            group={group}
          />
        );
      },
    },

    {
      field: "dataset_downloads",
      headerName: "Download",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-columnHeaderTitle",
      renderHeader: () => {
        const handleSwitch = (isChecked: boolean) => {
          knowledgeWikis.forEach((row) => {
            handleUpdate(isChecked, "dataset_downloads", row.dataset);
          });
        };
        return (
          <>
            Download
            <ColumnHeader
              handleSwitch={handleSwitch}
              handleUpdateFeatures={handleUpdateFeatures}
              value={checkedAll.dataset_downloads}
              label={sensitivity}
              withConfirm={isLimited}
              datasetsIds={tableDatasetIds}
              group={group}
              shouldDisableToggles={shouldDisableToggles}
            />
          </>
        );
      },
      renderCell: ({ row: knowledgeWiki }) => {
        const handleSwitch = (isChecked: boolean) => {
          handleUpdate(isChecked, "dataset_downloads", knowledgeWiki.dataset);
        };
        return (
          <CellDecoratedWithFeatureToggle
            value={group.dataset_downloads?.includes(knowledgeWiki.dataset)}
            label={sensitivity}
            withConfirm={isLimited}
            handleSwitch={handleSwitch}
            dataset={knowledgeWiki}
            handleUpdateFeatures={handleUpdateFeatures}
            group={group}
            shouldDisableToggles={shouldDisableToggles}
          />
        );
      },
    },

    {
      field: "kw_write",
      headerName: "Edit",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "MuiDataGrid-columnHeaderTitle",
      renderHeader: () => {
        const handleSwitch = (isChecked: boolean) => {
          knowledgeWikis.forEach((row) => {
            handleUpdate(isChecked, "kw_write", row.id);
          });
        };
        return (
          <>
            Edit
            <ColumnHeader
              handleSwitch={handleSwitch}
              handleUpdateFeatures={handleUpdateFeatures}
              value={checkedAll.kw_write}
              label={sensitivity}
              withConfirm={isLimited}
              datasetsIds={tableDatasetIds}
              group={group}
              shouldDisableToggles={shouldDisableToggles}
            />
          </>
        );
      },
      renderCell: ({ row: knowledgeWiki }) => {
        const handleSwitch = (isChecked: boolean) => {
          handleUpdate(isChecked, "kw_write", knowledgeWiki.id);
        };

        return (
          <CellDecoratedWithFeatureToggle
            value={group.kw_write?.includes(knowledgeWiki.id)}
            label={sensitivity}
            withConfirm={isLimited}
            handleSwitch={handleSwitch}
            dataset={knowledgeWiki}
            handleUpdateFeatures={handleUpdateFeatures}
            group={group}
            shouldDisableToggles={shouldDisableToggles}
          />
        );
      },
    },
  ];

  return (
    <>
      <WikiPreview
        open={wikiPreviewItem !== null}
        wikiId={wikiPreviewItem?.id}
        title={wikiPreviewItem?.name}
        handleClose={() => setWikiPreviewItem(null)}
      />

      <DataGrid
        apiRef={apiRef}
        rows={knowledgeWikis}
        columns={columns as GridColDef[]}
        hideFooter
        disableRowSelectionOnClick
        disableColumnMenu
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ".MuiDataGrid-columnHeaders": {
            fontSize: "12px",
          },

          ".MuiDataGrid-cell": {
            borderColor: theme.palette.grey[400],
            borderTopWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            borderStyle: "solid",
          },

          ".MuiDataGrid-cell:last-child": {
            borderRightWidth: 1,
          },

          ".MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },

          ".WikiNameCell": {
            display: "flex",
            alignItems: "center",
            justifyContent: "start",

            ".MuiTypography-root": {
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "1em",
            },

            button: {
              visibility: "hidden",
              backgroundColor: "transparent",
              marginLeft: "auto",
              width: "0",
            },

            "&:hover": {
              button: {
                visibility: "visible",
                width: "auto",
              },
            },
          },
        }}
      />
    </>
  );
};

export default DatasetPermissionsTable;
