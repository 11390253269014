import React from "react";
import { styled, Box } from "@mui/material";
import ErrorNotice from "../components/ErrorNotice";
import SehoLogo from "src/assets/images/logo.png";

interface Props {
  message?: string;
}

const StyledLoaderContainer = styled(Box)(() => ({
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 48,
}));

export default function ErrorPage({
  message = "Something went wrong. Please try again shortly.",
}: Props) {
  return (
    <StyledLoaderContainer>
      <img src={SehoLogo} />
      <ErrorNotice message={message} />
    </StyledLoaderContainer>
  );
}
