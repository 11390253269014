import { fetchApi } from "../shared/api";

export default class GroupsService {
  static gettingGroupDetail = false;

  static async getGroupDetails(group_id: string) {
    try {
      if (this.gettingGroupDetail) return;
      this.gettingGroupDetail = true;

      const res = await fetchApi("groups/" + group_id + "/", {
        method: "GET",
      });
      const response = res.json();
      this.gettingGroupDetail = false;
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      this.gettingGroupDetail = false;
      return false;
    }
  }

  static async shareScreeningListItemToGroup(
    screening_list_id: string,
    data: { group_id: string }
  ) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" + screening_list_id + "/add_to_group/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (!res)
        return {
          error: false,
        };

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: res,
        };
      }
    } catch (err) {
      return {
        error: false,
      };
    }
  }
  static async removeScreeningListItemFromGroup(
    group_id: string,
    data: { screening_lists: string[] }
  ) {
    try {
      const res = await fetchApi("groups/" + group_id + "/delete_objects/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (!res)
        return {
          error: false,
        };
      const response = await res.json();

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return {
        error: false,
      };
    }
  }
}
