import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { toZonedTime } from "date-fns-tz";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import regions from "src/assets/json/regions.json";
import languages from "src/assets/json/languages.json";
import DictionaryDetailsMDEditor from "./DictionaryDetailsMDEditor";
import LeakedDataToggle from "./LeakedDataToggle";
import CustomDatePicker from "src/view/components/DatePicker/CustomDatePicker";
import { KnowledgeWiki as KnowledgeWikiType } from "src/types/KnowledgeWiki";
import { SelectOption } from "src/types/Shared";
import {
  ConfirmDialog,
  Button,
  Checkbox,
  CustomInput,
  Select,
  AutocompleteSelect,
} from "src/components/ui";
import { Role } from "src/types/Auth";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { useUser } from "src/modules/api/auth";
import { useFilterOptions } from "src/modules/api/filters";
import {
  dictionaryDetailsSections,
  KnowledgeWikiMarkDownKeys,
} from "../../utilIObjects";
import { convertToUTC } from "src/view/components/DatePicker/utils";
import dataSetPropertiesJson from "src/assets/json/dataset_properties.json";
import datasetTypes from "src/assets/json/dataset_types.json";

const StyledBanner = styled("div")(() => ({
  position: "relative",
  marginTop: 100,
  height: "100%",
  backgroundImage: `url(${"/images/landing-bg.png"})`,
  backgroundSize: "50vw",
  backgroundPosition: "right 0px",
  backgroundRepeat: "no-repeat",
}));

const StyledBackground = styled("div")(() => ({
  background: "linear-gradient(0deg, #DDE4EC 0%, #FFFFFF 110.92%)",
  // transform: 'rotate(-180deg)'
}));

const StyledHeader = styled(Box)({
  paddingTop: "112px",
  paddingBottom: "8px",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  paddingRight: "54px",
  paddingLeft: "48px",
});

const StyledDetailContainer = styled(Box)(() => ({
  backgroundColor: "white",
  border: "1px solid #D9D9D9",
  borderRadius: 8,
  marginBottom: 20,
  position: "relative",
  padding: "20px 48px",
}));

const StyledBlockTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  lineHeight: "25px",
  fontSize: "20px",
}));

const CheckboxWrapper = styled(Grid)({
  padding: "12px 0",
});

const InputsContainer = styled(Grid)(({ theme }) => ({
  gap: 10,
  marginBottom: 10,
  flexWrap: "nowrap",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    gap: 32,
  },
}));

export const CheckboxLabel = styled("label")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  lineHeight: "17px",
  fontSize: "14px",
  cursor: "pointer",
}));

export const KnowledgeWikiSchema = z.object({
  sensitivity: z.string({ message: "Required" }),
  name: z
    .string({ message: "Required" })
    .min(3, "The alias name must be at least 3 characters long"),
  dataset_type: z.string({ message: "Required" }),
  countries: z.array(z.union([z.string(), z.number()])),
  languages: z
    .array(z.union([z.string(), z.number()]))
    .min(1, "Please select at least one language"),
  regions: z.array(z.union([z.string(), z.number()])),
  external_source: z.string().optional(),
  dataset_properties: z.array(z.string()),
  date_snapshot: z.string({ message: "Required" }),
  foundry_link: z.string().optional(),
});

const MDField = {
  required: z
    .string({ message: "Required" })
    .min(50, "The input must be at least 50 characters long"),
  optional: z.string().optional(),
};

const MDFields = {
  summary: MDField,
  credibility: MDField,
  coverage: MDField,
  search_tips_tricks: MDField,
  sample_records: MDField,
  article: MDField,
};

export type KnowledgeWiki = z.infer<typeof KnowledgeWikiSchema>;

const DataEdit = () => {
  const location = useLocation();
  const { id: knowledgeWikiId } = useParams();
  const { getKnowledgeWiki, updateKnowledgeWiki } = useKnowledgeWiki();
  const { data: knowledgeWikiDetail, isLoading: loading } =
    getKnowledgeWiki(knowledgeWikiId);
  const navigate = useNavigate();
  const [edited, setEdited] = useState(false);
  const [enableProps, setEnableProps] = useState<
    Record<KnowledgeWikiMarkDownKeys, boolean>
  >({
    summary: true,
    credibility: true,
    coverage: false,
    search_tips_tricks: false,
    sample_records: false,
    article: false,
  });
  const { user } = useUser();
  const [datasetProperties, setDatasetProperties] = useState<SelectOption[]>(
    []
  );
  const datepickerRef = useRef<HTMLDivElement>(null);

  const mdFields = useMemo(() => {
    const result: Record<string, any> = {};
    Object.entries(MDFields).forEach(([field, { required, optional }]) => {
      result[field] = enableProps[field] ? required : optional;
    });

    return result;
  }, [enableProps]);
  const { handleSubmit, control, reset, watch, getValues } =
    useForm<KnowledgeWiki>({
      resolver: zodResolver(KnowledgeWikiSchema.extend(mdFields)),
      defaultValues: {},
      shouldFocusError: true,
    });

  useEffect(() => {
    reset(getValues());
  }, [mdFields]);

  const prepareObject = (obj: any) => {
    const preparedObj = { ...obj };
    Object.entries(preparedObj).forEach(([key, value]) => {
      preparedObj[key] = value ?? undefined;
    });
    return preparedObj;
  };

  useEffect(() => {
    if (!knowledgeWikiDetail) {
      return;
    }
    const { unsubscribe } = watch((formData) => {
      setEdited(
        JSON.stringify(prepareObject(formData)) !==
          JSON.stringify(prepareObject(knowledgeWikiDetail))
      );
    });

    setEdited(
      JSON.stringify(prepareObject(getValues())) !==
        JSON.stringify(prepareObject(knowledgeWikiDetail))
    );
    return () => unsubscribe();
  }, [knowledgeWikiDetail]);

  useEffect(() => {
    if (knowledgeWikiDetail) {
      reset(prepareObject({ ...knowledgeWikiDetail }));
    }
  }, [knowledgeWikiDetail]);

  const { countries } = useFilterOptions();
  const isAdmin = user?.role === Role.ADMIN;

  useEffect(() => {
    if (knowledgeWikiDetail) {
      const dsp = dataSetPropertiesJson[knowledgeWikiDetail.dataset_type];
      setDatasetProperties(dsp);
    }
  }, [knowledgeWikiDetail]);

  useEffect(() => {
    if (
      user?.role != Role.ADMIN &&
      !user?.kw_write?.includes(knowledgeWikiDetail?.id)
    ) {
      goToDetail();
    }
  }, [user, knowledgeWikiDetail]);

  useEffect(() => {
    if (knowledgeWikiDetail) {
      setEnableProps({
        summary: true,
        credibility: true,
        coverage: !!knowledgeWikiDetail.coverage,
        search_tips_tricks: !!knowledgeWikiDetail.search_tips_tricks,
        sample_records: !!knowledgeWikiDetail.sample_records,
        article: !!knowledgeWikiDetail.article,
      });
    }
  }, [knowledgeWikiDetail]);

  const goToDetail = () => {
    navigate({
      pathname: `/data-dictionary/data/${knowledgeWikiId}`,
      search: location.search,
    });
  };

  const handleSave = async (formData: KnowledgeWiki) => {
    const payload = {
      ...knowledgeWikiDetail,
      ...formData,
    } as KnowledgeWikiType;

    if (!payload.countries || !payload.countries.length) {
      delete payload.countries;
    }

    await updateKnowledgeWiki.mutateAsync(
      { id: knowledgeWikiId, payload },
      {
        onSuccess: goToDetail,
      }
    );
  };

  const handleChangeProps = (key: string, value: boolean) => {
    const original = { ...enableProps };
    original[key] = value;
    setEnableProps(original);
  };

  const datasetTypeOptions = useMemo(() => {
    return datasetTypes.map((item) => ({
      label: item.label,
      value: item.label,
    }));
  }, []);

  const regionOptions = useMemo(
    () =>
      regions.map((region) => ({
        value: region.label,
        label: region.label,
      })),
    []
  );

  const languageOptions = useMemo(
    () =>
      languages.map((language) => ({
        value: language.label,
        label: language.label,
      })),
    []
  );

  const datasetPropertiesOptions = useMemo(
    () =>
      datasetProperties?.map((item) => ({
        label: item.label,
        value: item.label,
      })) ?? [],
    [datasetProperties]
  );

  return (
    <Box>
      <StyledBackground>
        <StyledBanner>
          <Container>
            <StyledHeader>
              <Box sx={{ alignItems: "flex-start" }}>
                <Grid item>
                  <ConfirmDialog
                    cancelText="Cancel"
                    confirmText="Leave"
                    description="Are you sure you want to leave this page? You have unsaved changes that will be lost."
                    onConfirm={goToDetail}
                    title="Unsaved changes"
                    skip={!edited}
                  >
                    <Button
                      disableRipple
                      style={{
                        padding: 0,
                        color: "black",
                        background: "none",
                      }}
                      btnSize="medium"
                      startIcon={
                        <img
                          src={require("src/assets/images/arrow_left_black.png")}
                          style={{ width: 24, height: 24 }}
                        />
                      }
                      btnVariant="text"
                    >
                      Back
                    </Button>
                  </ConfirmDialog>
                </Grid>
              </Box>
              <Button
                btnSize="medium"
                btnVariant="secondary"
                onClick={handleSubmit(handleSave)}
                disabled={loading}
                startIcon={loading ? <CircularProgress size="small" /> : null}
              >
                Save
              </Button>
            </StyledHeader>
          </Container>
          <Container sx={{ pt: 4, pb: 4 }}>
            <StyledDetailContainer>
              <InputsContainer container>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Alias Name
                        </CustomInput.InputLabel>
                        <CustomInput.Input
                          type="text"
                          name="name"
                          helperText=""
                          fullWidth
                          {...field}
                        />
                        {errors.name && (
                          <CustomInput.InputMessage error>
                            {errors.name.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="sensitivity"
                    render={({ field }) => (
                      <>
                        <CustomInput.InputLabel>
                          Sensitivity of dataset
                        </CustomInput.InputLabel>
                        <CustomInput.Input
                          type="text"
                          name="sensitivity"
                          value={field.value}
                          helperText=""
                          fullWidth
                          disabled
                        />
                      </>
                    )}
                  />
                </Grid>
              </InputsContainer>
              <InputsContainer container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  ref={datepickerRef}
                  sx={{ position: "relative" }}
                >
                  <Controller
                    control={control}
                    name="date_snapshot"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Date of Snapshot
                        </CustomInput.InputLabel>
                        <CustomDatePicker
                          selected={
                            field.value
                              ? toZonedTime(new Date(field.value), "UTC")
                              : null
                          }
                          placeholderText="Select Date of Snapshot"
                          onChange={(date: Date) => {
                            field.onChange({
                              target: { value: convertToUTC(date) },
                            });
                          }}
                          invalid={!!errors.date_snapshot}
                          maxDate={new Date()}
                          sx={{
                            position: "relative",
                            "& .react-datepicker": {
                              top: 80,
                            },
                          }}
                        />
                        {errors.date_snapshot && (
                          <CustomInput.InputMessage error>
                            {errors.date_snapshot.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="countries"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Countries
                        </CustomInput.InputLabel>
                        <AutocompleteSelect
                          multiple
                          placeholder="Select Country"
                          inputPlaceholder="Search Country"
                          onChange={(value) => {
                            field.onChange({
                              target: { value },
                            });
                          }}
                          value={field.value}
                          invalid={!!errors.countries}
                          options={countries}
                        />
                        {errors.countries && (
                          <CustomInput.InputMessage error>
                            {errors.countries.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
              </InputsContainer>
              <InputsContainer container>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="regions"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Regions (optional)
                        </CustomInput.InputLabel>
                        <AutocompleteSelect
                          hideSearch
                          multiple
                          placeholder="Select Regions"
                          inputPlaceholder="Search Regions"
                          onChange={(value) => {
                            field.onChange({
                              target: { value },
                            });
                          }}
                          value={field.value}
                          invalid={!!errors.regions}
                          options={regionOptions}
                        />
                        {errors.regions && (
                          <CustomInput.InputMessage error>
                            {errors.regions.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="languages"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Languages
                        </CustomInput.InputLabel>
                        <AutocompleteSelect
                          multiple
                          placeholder="Select Languages"
                          inputPlaceholder="Search Languages"
                          onChange={(value) => {
                            field.onChange({
                              target: { value },
                            });
                          }}
                          value={field.value}
                          invalid={!!errors.languages}
                          options={languageOptions}
                        />
                        {errors.languages && (
                          <CustomInput.InputMessage error>
                            {errors.languages.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
              </InputsContainer>
              <InputsContainer container>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="foundry_link"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Foundry URL (optional)
                        </CustomInput.InputLabel>
                        <CustomInput.Input
                          type="text"
                          name="foundry_link"
                          placeholder="Enter url"
                          fullWidth
                          {...field}
                        />
                        {errors.foundry_link && (
                          <CustomInput.InputMessage error>
                            {errors.foundry_link.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="external_source"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          External Resource URL (optional)
                        </CustomInput.InputLabel>
                        <CustomInput.Input
                          type="text"
                          name="external_source"
                          placeholder="Enter url"
                          fullWidth
                          {...field}
                        />
                        {errors.external_source && (
                          <CustomInput.InputMessage error>
                            {errors.external_source.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
              </InputsContainer>
              <InputsContainer container>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="dataset_type"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>Type</CustomInput.InputLabel>
                        <Select<string>
                          name="dataset_type"
                          placeholder="Select Dataset type"
                          onChange={(value: string) => {
                            field.onChange({
                              target: {
                                value,
                              },
                            });
                          }}
                          options={datasetTypeOptions}
                          value={field.value}
                          invalid={!!errors.dataset_type}
                        />
                        {errors.dataset_type && (
                          <CustomInput.InputMessage error>
                            {errors.dataset_type.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} />
              </InputsContainer>
            </StyledDetailContainer>
            <StyledDetailContainer>
              <StyledBlockTitle>
                Add Optional Dataset Properties
              </StyledBlockTitle>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="dataset_properties"
                    render={({ field, formState: { errors } }) => (
                      <>
                        <CustomInput.InputLabel>
                          Dataset Properties (optional)
                        </CustomInput.InputLabel>
                        <AutocompleteSelect
                          multiple
                          hideSearch
                          placeholder="Select"
                          onChange={(value) => {
                            field.onChange({
                              target: {
                                value,
                              },
                            });
                          }}
                          value={field.value}
                          options={datasetPropertiesOptions}
                          invalid={!!errors.dataset_properties}
                        />
                        {errors.dataset_properties && (
                          <CustomInput.InputMessage error>
                            {errors.dataset_properties.message}
                          </CustomInput.InputMessage>
                        )}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </StyledDetailContainer>
            <StyledDetailContainer>
              <Grid container>
                {knowledgeWikiDetail && isAdmin && (
                  <CheckboxWrapper item xs={12} md={6}>
                    <LeakedDataToggle
                      datasetId={knowledgeWikiDetail?.dataset}
                    />
                  </CheckboxWrapper>
                )}
                <CheckboxWrapper item xs={12} md={6}>
                  <Checkbox
                    id="script"
                    label={
                      <CheckboxLabel htmlFor="script">
                        Script or Parser Available
                      </CheckboxLabel>
                    }
                    color="secondary"
                  />
                </CheckboxWrapper>
                <CheckboxWrapper item xs={12} md={6}>
                  <Checkbox
                    id="ocr"
                    label={
                      <CheckboxLabel htmlFor="ocr">OCR&apos;ed</CheckboxLabel>
                    }
                    color="secondary"
                  />
                </CheckboxWrapper>
                <CheckboxWrapper item xs={12} md={6}>
                  <Checkbox
                    id="partner"
                    label={
                      <CheckboxLabel htmlFor="partner">
                        Partner Data
                      </CheckboxLabel>
                    }
                    color="secondary"
                  />
                </CheckboxWrapper>
              </Grid>
            </StyledDetailContainer>

            {knowledgeWikiDetail &&
              dictionaryDetailsSections.map(({ key, label }) => (
                <Controller
                  key={key}
                  control={control}
                  name={key as any}
                  render={({ field, formState: { errors } }) => (
                    <DictionaryDetailsMDEditor
                      fieldname={key}
                      label={label}
                      content={(field.value as string) || ""}
                      isEnabled={enableProps?.[key]}
                      handleChangeProps={handleChangeProps}
                      handleChangeFormData={(value) => {
                        field.onChange({ target: { value } });
                      }}
                      footer={
                        errors[key] && (
                          <CustomInput.InputMessage error>
                            {errors[key].message}
                          </CustomInput.InputMessage>
                        )
                      }
                    />
                  )}
                />
              ))}
          </Container>
        </StyledBanner>
      </StyledBackground>
    </Box>
  );
};

export default DataEdit;
