import { CSSProperties, ReactNode, forwardRef, ForwardedRef } from "react";
import {
  Box,
  styled,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";
import { SwitchVariant } from "./types";
import theme from "../../../theme";

interface CustomSwitchProps {
  label?: ReactNode;
  variant?: SwitchVariant;
}

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Label = styled("label")<CustomSwitchProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: "12px",
  cursor: "pointer",
  userSelect: "none",
}));

const switchVariants: Record<SwitchVariant, CSSProperties> = {
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
};

const StyledSwitch = styled(MuiSwitch, {
  shouldForwardProp: (prop) => !["variant"].includes(prop.toString()),
})<CustomSwitchProps>(({ variant }) => ({
  fontWeight: 500,
  fontSize: "12px",
  "& .Mui-checked+.MuiSwitch-track": {
    opacity: 0.25,
  },
  ...switchVariants[variant],
}));

export const Switch = forwardRef(
  (
    {
      label,
      id,
      variant = "primary",
      ...props
    }: CustomSwitchProps & MuiSwitchProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Container>
        <StyledSwitch
          ref={ref}
          id={id}
          variant={variant}
          color={variant}
          size="small"
          {...props}
        />
        {typeof label === "string" ? (
          <Label htmlFor={id} variant={variant}>
            {label}
          </Label>
        ) : (
          label
        )}
      </Container>
    );
  }
);
