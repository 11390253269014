import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import NewDatasetItem from "./NewDatasetIem";

import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { useMemo } from "react";
import { compareDesc } from "date-fns";

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: "600",
  fontFamily: "Inter, sans-serif",
}));

const StyledContent = styled(Grid)(({ theme }) => ({
  paddingTop: 38,
  paddingBottom: 12,
  alignItems: "flex-start",
  [theme.breakpoints.up("xs")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
}));

const StyledDecorationLine = styled(Box)(({ theme }) => ({
  width: 148,
  height: 2,
  backgroundColor: theme.palette.red.main,
  marginTop: 8,
}));

const StyledDatasetsList = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[400]}`,
  paddingLeft: 8,
  paddingRight: 8,
  marginTop: 24,
}));

const StyledNoResults = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 24,
}));

const NewestDatasetsSection = () => {
  const { getDatasetMetadata } = useDatasetMetadata();
  const { data, isFetching } = getDatasetMetadata();

  const rows = useMemo(
    () => data.sort((a, b) => compareDesc(a.created, b.created)).slice(0, 5),
    [data]
  );

  return (
    <Container>
      <StyledContent item>
        <Grid item>
          <StyledHeading variant="h2">Recently Added</StyledHeading>
          <StyledDecorationLine />
        </Grid>
        <StyledDatasetsList>
          {isFetching ? (
            Array.from(Array(5).keys()).map((item) => (
              <Skeleton height="40px" width="100%" key={item} />
            ))
          ) : rows?.length > 0 ? (
            rows.map((item, index) => (
              <NewDatasetItem
                key={item.id}
                name={item.name}
                datasetId={item.id}
                created={item.created}
                isLast={rows.length - 1 === index}
              />
            ))
          ) : (
            <StyledNoResults>
              No datasets found. Add one&nbsp;<a href="/indexing"> here</a>.
            </StyledNoResults>
          )}
        </StyledDatasetsList>
      </StyledContent>
    </Container>
  );
};

export default NewestDatasetsSection;
