import React, { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, IconButtonProps } from "@mui/material";
import theme from "src/theme";

interface StyledIconButtonProps extends IconButtonProps {
  buttonStyles?: React.CSSProperties;
}

const StyledIconButton = styled(
  forwardRef<HTMLButtonElement, StyledIconButtonProps>(
    ({ buttonStyles, children, ...props }, ref) => (
      <IconButton ref={ref} {...props}>
        {children}
      </IconButton>
    )
  )
)(({ buttonStyles }) => ({
  background: theme.palette.grey[200],
  borderRadius: "4px",
  mx: "auto",
  width: "20px",
  height: "20px",
  padding: "4px",
  "& .icon": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ...buttonStyles,
}));

const StyledIcon = styled("img")(() => ({
  objectFit: "contain",
  cursor: "pointer",
  width: "15px",
  height: "15px",
}));

interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  icon: React.ReactNode | string;
  buttonStyles?: React.CSSProperties;
}

const ActionIconButton = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, icon, buttonStyles, ...rest }, ref) => {
    return (
      <StyledIconButton
        ref={ref}
        onClick={onClick}
        buttonStyles={buttonStyles}
        {...rest}
      >
        {typeof icon === "string" ? (
          <StyledIcon src={icon} alt="" />
        ) : (
          <span className="icon">{icon}</span>
        )}
      </StyledIconButton>
    );
  }
);

export default ActionIconButton;
