import { useMutation, useQuery } from "@tanstack/react-query";
import { useUser } from "../auth";
import { snackbar } from "src/view/toaster";
import { useFetch } from "../fetch";

import {
  Dataset,
  DatasetSensitivityUpdateRequest,
  SensitivityValues,
} from "src/types/Dataset";

import QueryKeys from "src/modules/api/queryKeys";
import queryKeys from "src/modules/api/queryKeys";
import { queryClient } from "src/index";

export function useDatasetSensitivity() {
  const { user } = useUser();
  const { getApi, putApi } = useFetch();

  const getDatasetSensitivityListFn = async () => {
    const data = await getApi("dataset_metadata/groups_count");
    return data;
  };

  const getDatasetSensitivityList = () =>
    useQuery<Dataset[]>({
      enabled: !!user,
      queryFn: getDatasetSensitivityListFn,
      queryKey: [QueryKeys.DATASET_SENSITIVITY],
      initialData: [],
    });

  const moveDataSensitivityItem = useMutation({
    mutationFn: ({ id, data }: DatasetSensitivityUpdateRequest) => {
      return putApi(["dataset_metadata", id], { body: JSON.stringify(data) });
    },
    onSuccess(_, variables) {
      const {
        id,
        data: { sensitivity },
      } = variables;
      queryClient.setQueryData(
        [queryKeys.DATASET_SENSITIVITY],
        (oldDatasetSensitivity: Dataset[]) =>
          oldDatasetSensitivity.map((item) =>
            item.id == id
              ? {
                  ...item,
                  sensitivity: sensitivity.toUpperCase() as SensitivityValues,
                }
              : item
          )
      );
    },
    onError: () => {
      snackbar.error("Error when updating sensitivity type!");
    },
  });

  return {
    getDatasetSensitivityList,
    moveDataSensitivityItem,
  };
}
