import { ChangeEvent, useEffect, useState } from "react";
import { Box, Pagination, styled, Typography } from "@mui/material";

import { arrayContained } from "src/utils";
import {
  Group,
  GroupDatasetPermissionsFields,
  GroupFeatures,
} from "src/types/UserGroup";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import theme from "src/theme";
import { SensitivityString } from "src/types/Dataset";

import DatasetPermissionsTable from "./DatasetPermissionsTable";

const StyledEmptyState = styled(Box)({
  width: "100%",
  marginTop: 16,
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  fontFamily: "Inter, sans-serif",
  color: theme.palette.primary.main,
}));

interface Props {
  sensitivity: SensitivityString;
  group: Group;
  filteredWikis: KnowledgeWiki[];
  wikis: KnowledgeWiki[];
  handleUpdateDatasets: (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => void;
  handleUpdateFeatures: (checked: boolean, featureName: GroupFeatures) => void;
  shouldDisableToggles: boolean;
}

const DatasetTable = ({
  group,
  sensitivity,
  wikis,
  filteredWikis,
  handleUpdateDatasets,
  handleUpdateFeatures,
  shouldDisableToggles,
}: Props) => {
  const [displayableWikis, setDisplayableWikis] =
    useState<KnowledgeWiki[]>(filteredWikis);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedAll, setCheckAll] = useState<
    Omit<Record<GroupDatasetPermissionsFields, boolean>, "sensitivity_levels"> // omitting sensitivity_levels
  >({
    datasets: false,
    dataset_downloads: false,
    kw_read: false,
    kw_write: false,
  });

  useEffect(() => {
    const _all = { ...checkedAll };
    const ds = wikis.map((dataset: KnowledgeWiki) => dataset.dataset);
    const dss = group.datasets;
    if (dss?.length > 0 && arrayContained(dss, ds)) {
      _all.datasets = true;
    } else {
      _all.datasets = false;
    }
    const dds = group.dataset_downloads;
    if (dds?.length > 0 && arrayContained(dds, ds)) {
      _all.dataset_downloads = true;
    } else {
      _all.dataset_downloads = false;
    }
    const kr = group.kw_read;
    const datasets_for_kw_read = wikis
      .filter((item: KnowledgeWiki) => kr?.includes(item.id))
      .map((item: KnowledgeWiki) => item.dataset);
    if (
      datasets_for_kw_read?.length > 0 &&
      arrayContained(datasets_for_kw_read, ds)
    ) {
      _all.kw_read = true;
    } else {
      _all.kw_read = false;
    }
    const kw = group.kw_write;
    const datasets_for_kw_write = wikis
      .filter((item: KnowledgeWiki) => kw?.includes(item.id))
      .map((item: KnowledgeWiki) => item.dataset);
    if (
      datasets_for_kw_write?.length > 0 &&
      arrayContained(datasets_for_kw_write, ds)
    ) {
      _all.kw_write = true;
    } else {
      _all.kw_write = false;
    }

    setCheckAll(_all);
  }, [group]);

  const sortWikis = (wikis: KnowledgeWiki[]) => {
    return (
      wikis
        // sort by any active permission
        .sort((a, b) => {
          let isDatasetActiveA = false;
          if (group.datasets.includes(a.dataset)) isDatasetActiveA = true;
          if (group.dataset_downloads.includes(a.dataset))
            isDatasetActiveA = true;
          if (group.kw_read.includes(a.id)) isDatasetActiveA = true;
          if (group.kw_write.includes(a.id)) isDatasetActiveA = true;

          let isDatasetActiveB = false;
          if (group.datasets.includes(b.dataset)) isDatasetActiveB = true;
          if (group.dataset_downloads.includes(b.dataset))
            isDatasetActiveB = true;
          if (group.kw_read.includes(b.id)) isDatasetActiveB = true;
          if (group.kw_write.includes(b.id)) isDatasetActiveB = true;

          return isDatasetActiveA > isDatasetActiveB && -1;
        })
    );
  };

  useEffect(() => {
    if (filteredWikis) {
      setCurrentPage(1);
      const wikisClone = [...filteredWikis];
      setPageCount(Math.ceil(wikisClone?.length / 10));
      setDisplayableWikis(sortWikis(wikisClone));
    }
  }, [filteredWikis]);

  useEffect(() => {
    if (group && displayableWikis) {
      setDisplayableWikis(sortWikis(displayableWikis));
    }
  }, [group]);

  const paginateData = (
    allWikis: KnowledgeWiki[],
    page: number
  ): KnowledgeWiki[] => {
    const startIndex = (page - 1) * 10;
    allWikis = allWikis.slice(startIndex, startIndex + 10);

    return allWikis;
  };

  const handlechangePage = (e: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  if (!wikis?.length || !filteredWikis.length) {
    return (
      <StyledEmptyState>
        <StyledDescription>No Results</StyledDescription>
      </StyledEmptyState>
    );
  }

  const isLimited = ["Restricted", "Sensitive"].includes(sensitivity);

  return (
    <Box sx={{ width: "100%" }}>
      <DatasetPermissionsTable
        group={group}
        knowledgeWikis={paginateData(displayableWikis, currentPage)}
        sensitivity={sensitivity}
        isLimited={isLimited}
        handleUpdate={handleUpdateDatasets}
        checkedAll={checkedAll}
        handleUpdateFeatures={handleUpdateFeatures}
        shouldDisableToggles={shouldDisableToggles}
      />
      {displayableWikis.length > 10 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Pagination
            page={currentPage}
            count={pageCount}
            shape="rounded"
            sx={{
              "&>ul>li>button.MuiButtonBase-root": {
                color: `${theme.palette.primary.light} !important`,
                fontSize: 14,
                lineHeight: "14px",
                fontWeight: "700",
              },
            }}
            onChange={handlechangePage}
          />
        </Box>
      )}
    </Box>
  );
};

export default DatasetTable;
