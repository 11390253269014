import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "src/modules/shared/api";
import { Paginated } from "src/types/Shared";
import { User } from "src/types/UserGroup";
import queryKeys from "src/modules/api/queryKeys";
import { queryClient } from "src/index";
import { useFetch } from "src/modules/api/fetch";
import { snackbar } from "src/view/toaster";

interface GetUsersParams {
  searchKey?: string;
  pageParam?: number;
}

interface UpdateSubscriptionDatePayload {
  membership_renewal_date: string;
}

interface UpdateUserVariables {
  id: string;
  payload: UpdateSubscriptionDatePayload;
}

export function useUsers() {
  const { putApi, postApi } = useFetch();

  const getUsersFn = async ({ searchKey, pageParam }: GetUsersParams) => {
    try {
      const res = await fetchApi(
        "users/?" +
          new URLSearchParams({
            ...(pageParam && { page: pageParam?.toString() }),
            ...(searchKey && { name_or_email: searchKey }),
          }),
        { method: "GET" }
      );
      const response = (await res.json()) as Paginated<User[]> & {
        detail: string;
      };
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"] as string,
        };
      }
    } catch (err) {
      return { error: true, detail: "Unknown fetching issue in getUsersFn" };
    }
  };

  const getUsers = (params: GetUsersParams = {}) =>
    useQuery<Paginated<User[]>>({
      queryFn: ({ pageParam }): Promise<Paginated<User[]>> => {
        return getUsersFn({
          searchKey: params.searchKey,
          pageParam,
        }) as Promise<Paginated<User[]>>;
      },
      queryKey: [queryKeys.USERS, params],
    });

  const getUsersInfinite = (params: GetUsersParams) =>
    useInfiniteQuery<Paginated<User[]>>({
      queryFn: ({ pageParam = 1 }) => {
        return getUsersFn({
          searchKey: params.searchKey,
          pageParam,
        }) as Promise<Paginated<User[]>>;
      },
      queryKey: [queryKeys.USERS, params],
      getNextPageParam: (lastPage) => lastPage.next_page_number,
    });

  const getUsersByIdsFn = async (ids: string[]) => {
    try {
      const res = await fetchApi("users/bulk_get/", {
        method: "POST",
        body: JSON.stringify({
          user_ids: ids,
        }),
      });

      if (res.ok) {
        return await res.json();
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getUsersByIds = (ids: string[]) =>
    useQuery<User[]>({
      queryFn: () => getUsersByIdsFn(ids),
      queryKey: [queryKeys.USERS_BY_IDS, ids],
      initialData: () => [],
      enabled: !!ids?.length,
    });

  const deleteUserFn = async (id: string) => {
    try {
      const res = await fetchApi(`users/${id}/`, {
        method: "DELETE",
      });
      if (!res) return;
      const response = await res.json();

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  };

  const deleteUser = () =>
    useMutation({
      mutationFn: (id: string) => deleteUserFn(id),
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.USERS]);
      },
    });

  const updateUser = () =>
    useMutation({
      mutationFn: ({ id, payload }: UpdateUserVariables) =>
        putApi(
          ["users", id],
          { body: JSON.stringify(payload) },
          (response: Response) => {
            return response
              .json()
              .then((data: any) => data?.["error"] || "Error updating user")
              .catch(() => "Error updating user");
          }
        ),
      onError: (error: Error) => {
        const [, message] = (error?.message ?? "").split("|");
        snackbar.error(message);
      },
    });

  const checkUserEmailFn = async (email: string) => {
    try {
      const response = await postApi(["users", "check_email_exists"], {
        body: JSON.stringify({ email }),
      });

      const awaitedResponse = await response;
      return [true, await awaitedResponse.json()];
    } catch {
      return [false, { message: "User with this email does not exist" }];
    }
  };

  const checkUserEmail = () =>
    useMutation({
      mutationFn: (email: string) => checkUserEmailFn(email),
    });

  return {
    getUsers,
    getUsersFn,
    getUsersByIds,
    getUsersInfinite,
    deleteUser,
    updateUser,
    checkUserEmail,
  };
}
