import { QueryItem } from "src/types/BulkSearch";

export interface BalancedInQuery {
  balance: boolean;
  openCount: number;
  closeCount: number;
  invalid: boolean;
}

// Checks if parentheses are balanced and valid in the query list.
export const checkParenthesesBalancedInQuery = (
  queryB: QueryItem[]
): BalancedInQuery => {
  let openCount = 0;
  let closeCount = 0;
  let invalid = false;

  // Iterate through the query items and count parentheses
  for (const query of queryB) {
    if (query.type === "operator") {
      if (query.value === "(") {
        openCount++;
      } else if (query.value === ")") {
        closeCount++;
        // Mark as invalid if there are unmatched closing parentheses
        if (closeCount > openCount) {
          invalid = true;
        }
      }
    }
  }

  // Parentheses are balanced only if the counts are equal and no invalid closures occurred
  return {
    balance: openCount === closeCount && !invalid,
    openCount,
    closeCount,
    invalid,
  };
};
