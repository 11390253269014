import { QueryVariables } from "../../../types/QueryVariables";

export const emptyQueryVariables: QueryVariables = {
  content: "",
  filters: {
    dataset_ids: [],
    exclude_dataset_ids: [],
    data_collection_ids: [],
    exclude_data_collection_ids: [],
    doc_types: [],
    dataset_types: [],
    countries: [],
    regions: [],
    languages: [],
  },
};
