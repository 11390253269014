import { useEffect, useMemo } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { Box, CircularProgress, styled } from "@mui/material";
import { useUsers } from "src/modules/api/users";
import Table from "./Table";
import EmptyState from "./EmptyState";

const StyledLoadingContainer = styled(Box)({
  minHeight: 200,
  backgroundColor: "#FFFFFF88",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

interface Props {
  searchKey: string;
}

const UsersTable = ({ searchKey }: Props) => {
  const { getUsersInfinite, deleteUser } = useUsers();
  const {
    data: users,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = getUsersInfinite({ searchKey });
  const { mutateAsync: deleteUserAsync } = deleteUser();

  const { ref: endOfListRef, isIntersecting } = useIntersectionObserver();

  const joinedUsersPages = useMemo(
    () =>
      ({ ...users }?.pages?.map((usersPage) => usersPage.results)?.flat() ||
      []),
    [users]
  );

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage]);

  if (isLoading)
    return (
      <StyledLoadingContainer>
        <CircularProgress />
      </StyledLoadingContainer>
    );

  if (!joinedUsersPages.length) return <EmptyState />;

  return (
    <>
      <Table
        users={joinedUsersPages}
        isLoading={isLoading}
        deleteUserAsync={deleteUserAsync}
      />

      <div ref={endOfListRef} />

      {isFetchingNextPage && (
        <Box
          sx={{
            display: "flex",
            padding: "30px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default UsersTable;
