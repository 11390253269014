import {
  DataIndex,
  Hit,
  MappedHit,
  MappedSearchResult,
  SearchResultResponse,
} from "../../../types/Search";
import { parseDataIndex } from "./SearchProvider";

export const searchMapper = (
  response: SearchResultResponse
): MappedSearchResult => {
  const items: Record<string, MappedHit[]> = {};

  const parseHit = (hit: Hit<DataIndex>) => {
    const datasetId = Array.isArray(hit._source.dataset_id)
      ? hit._source.dataset_id[0]
      : hit._source.dataset_id;
    if (!items[datasetId]) {
      items[datasetId] = [];
    }

    if (parseDataIndex(hit._index) === DataIndex.modeled) {
      items[datasetId].push({
        id: hit._id,
        dataIndex: DataIndex.modeled,
        highlight: hit.highlight,
        source: hit._source,
        datasetId,
      });
    } else if (parseDataIndex(hit._index) === DataIndex.source) {
      items[datasetId].push({
        id: hit._id,
        dataIndex: DataIndex.source,
        highlight: hit.highlight,
        source: hit._source,
        datasetId,
      });
    }

    if (hit.hits) {
      hit.hits.forEach(parseHit);
    }
  };

  response.hits.hits.forEach(parseHit);

  return {
    aggregations: response.aggregations,
    hits: {
      total: response.hits.total,
    },
    items,
    nextPageNumber: response.next_page_number,
    prevPageNumber: response.prev_page_number,
    page: response.page,
    size: response.size,
    totalCounts: response.total_counts,
  };
};
