import { useCallback, useMemo, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Pagination, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSearch } from "src/modules/api/search";
import searchStoreSelectors from "src/modules/api/search/store/selectors";
import searchStoreActions from "src/modules/api/search/store/actions";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.palette.grey[500],
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: "10px 26px",
  zIndex: 5,
  backgroundColor: theme.palette.common.white,
  boxSizing: "border-box",
}));

export default function SearchResultPagination() {
  const dispatch = useDispatch();
  const { data, fetchNextPage } = useSearch();
  const activePageIndex = useSelector(
    searchStoreSelectors.selectActivePageIndex
  );
  const variables = useSelector(searchStoreSelectors.selectVariables);

  const handleChange = useCallback(
    (_event: ChangeEvent<unknown>, value: number) => {
      if (value === 1) {
        searchStoreActions.setActivePageIndex(0)(dispatch);
      } else {
        const nextPageIndex = data.pageParams.indexOf(value);
        if (nextPageIndex < 0) {
          fetchNextPage({ pageParam: value });
          searchStoreActions.setActivePageIndex(data.pageParams.length)(
            dispatch
          );
        } else {
          searchStoreActions.setActivePageIndex(nextPageIndex)(dispatch);
        }
      }
    },
    [data, dispatch]
  );

  const { pageCount, totalCounts, size } = useMemo(() => {
    let pageCount;
    let totalCounts;
    let size;
    if (data.pages[0]) {
      const page = data.pages[0];
      totalCounts = page.totalCounts;
      size = page.size;
      pageCount = Math.ceil(totalCounts / size);
    }

    return { pageCount, totalCounts, size };
  }, [data, variables]);

  const activePage = (data.pageParams[activePageIndex] as number) ?? 1;

  const fromIndex = size * (activePage - 1) + 1;
  const toIndex = Math.min(size * (activePage - 1) + size, totalCounts);

  if (totalCounts === 0) {
    return null;
  }

  return (
    <StyledBox>
      <Typography variant="caption">
        Showing {fromIndex} - {toIndex} of {totalCounts}
      </Typography>
      <Pagination
        shape="rounded"
        count={pageCount}
        page={activePage}
        onChange={handleChange}
      />
      <Typography variant="caption">Show {size} results</Typography>
    </StyledBox>
  );
}
