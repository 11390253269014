import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useToggle } from "usehooks-ts";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { add } from "date-fns";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  DialogActions,
  DialogContent,
  styled,
  Typography,
} from "@mui/material";
import { useUsers } from "src/modules/api/users";
import { useUserGroups } from "src/modules/api/userGroups";
import { Group, User } from "src/types/UserGroup";
import { UserFields } from "src/view/users-groups/AddNewUsers/types";
import ImportUserFromSCV from "src/view/users-groups/AddNewUsers/ImportUserFromSCV";
import {
  Button,
  Dialog,
  Divider,
  Radio,
  Switch,
  CustomInput,
} from "src/components/ui";
import CustomDatePicker from "src/view/components/DatePicker/CustomDatePicker";
import { APIValidationErrors } from "src/types/Shared";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSaveUsers: (users: User[], type?: "manual" | "csv") => void;
  validationErrors: APIValidationErrors<UserFields>;
  resetValidationErrors: React.Dispatch<
    React.SetStateAction<APIValidationErrors<UserFields>>
  >;
  selectedGroup: Group;
  onUpdate: () => void;
}

const StyledRadioSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  color: theme.palette.primary.main,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.main,
  fontSize: "14px",
  fontWeight: 400,
  padding: 0,
}));

const StyledInputRow = styled(Box)(() => ({
  display: "flex",
  gap: "12px",
  marginTop: "-3px",
  "& > div": {
    flex: "1 1 50%",
  },
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  padding: 0,
  display: "flex",
  gap: "12px",
  alignItems: "center",
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSaveUsers: (users: User[], type?: "manual" | "csv") => void;
  validationErrors: APIValidationErrors<UserFields>;
  resetValidationErrors: React.Dispatch<
    React.SetStateAction<APIValidationErrors<UserFields>>
  >;
  selectedGroup: Group;
  groupUsers: User[];
  onUpdate: () => void;
}

const email = z.string().email({
  message: "Please enter a valid email address (i.e. name@example.com)",
});

const AddNewUserSchema = z.object({
  email,
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  membership_renewal_date: z.date(),
});

type AddNewUserSchemaType = z.infer<typeof AddNewUserSchema>;

export function AddNewUserModal({
  open,
  handleClose,
  handleSaveUsers,
  validationErrors,
  selectedGroup,
  groupUsers,
  onUpdate,
}: Props) {
  const newUserDefaultRenewalDate = add(new Date(), { months: 6 });
  const { getUsersFn, checkUserEmail } = useUsers();
  const { addObjectsToGroup } = useUserGroups();
  const { mutateAsync: addUserToGroupAsync, isLoading: groupUpdating } =
    addObjectsToGroup({
      successMessage: "Added user to group.",
    });
  const { mutateAsync: checkUserEmailAsync, isLoading: emailChecking } =
    checkUserEmail();
  const { handleSubmit, control, watch, setValue, setError, getValues, reset } =
    useForm<AddNewUserSchemaType>({
      resolver: zodResolver(AddNewUserSchema),
      defaultValues: {
        email: "",
        first_name: "",
        last_name: "",
        membership_renewal_date: newUserDefaultRenewalDate,
      },
      shouldFocusError: true,
      mode: "onChange",
      shouldUnregister: true,
    });

  const [userFetching, setUserFetching] = useState(false);
  const [creationType, setCreationType] = useState<"manual" | "csv">("manual");
  const [isCreatingNewUser, setIsCreatingNewUser] = useState<
    boolean | undefined
  >(undefined);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );
  const [isDatepickerOpen, setIsDatepickerOpen] = useState<boolean>(false);
  const [parsedCSVUsers, setParsedCSVUsers] = useState<UserFields[] | []>();
  const [isAddMore, toggleAddMore] = useToggle(false);
  const [userAlreadyInGroup, setUserAlreadyInGroup] = useState(false);
  const [emailEditing, setEmailEditing] = useState(false);

  const checkUserAlreadyInGroup = (email: string) => {
    const isInGroup = groupUsers.map((user) => user.email).includes(email);
    if (isInGroup) {
      setIsCreatingNewUser(false);
    }
    return isInGroup;
  };

  const checkForExistingUser = async (email: string) => {
    if (checkUserAlreadyInGroup(email)) {
      return;
    }
    const [userExists] = await checkUserEmailAsync(email);

    if (!userExists) {
      setIsCreatingNewUser(true);
      setValue("membership_renewal_date", newUserDefaultRenewalDate);
      return;
    }

    setUserFetching(true);
    const response = await getUsersFn({ searchKey: email });

    setUserFetching(false);
    setIsCreatingNewUser(false);
    setSelectedUserId(response["results"][0].id);
    setValue(
      "membership_renewal_date",
      new Date(response["results"][0].membership_renewal_date)
    );
  };

  // keeps track of whether it's a new user being created
  useEffect(() => {
    let lastEmail = getValues().email;

    const debounceCallback = () => {
      setEmailEditing(false);
      const { success } = email.safeParse(lastEmail);
      if (lastEmail && success) {
        checkForExistingUser(lastEmail);
      } else {
        setValue("membership_renewal_date", newUserDefaultRenewalDate);
        setIsCreatingNewUser(false);
      }
    };
    const debounceEmail = debounce(debounceCallback, 300);

    const { unsubscribe } = watch(({ email }) => {
      if (email !== lastEmail) {
        lastEmail = email;

        const userAlreadyInGroup = checkUserAlreadyInGroup(email);
        setUserAlreadyInGroup(userAlreadyInGroup);
        setEmailEditing(true);

        debounceEmail();
      }
    });

    debounceCallback();

    return () => unsubscribe();
  }, [groupUsers, creationType]);

  useEffect(() => {
    // reset date to default for new user
    if (isCreatingNewUser) {
      setValue("membership_renewal_date", newUserDefaultRenewalDate);
    }
  }, [isCreatingNewUser]);

  useEffect(() => {
    if (creationType === "csv") {
      setUserAlreadyInGroup(false);
      setEmailEditing(false);
    }
  }, [creationType]);

  useEffect(() => {
    // pass serializer error into RHF for single user, hence the [0]
    if (creationType === "manual" && !!validationErrors[0]) {
      for (const [key, value] of Object.entries(validationErrors[0])) {
        setError(key as keyof UserFields, {
          type: "manual",
          message: value.join(", "),
        });
      }
    }
  }, [validationErrors, creationType]);

  const addUser = async (formFields: AddNewUserSchemaType) => {
    // TODO: send ISO string in UTC
    // forcing type assertion for membership_renewal_date: string vs Date (legacy sucks)
    if (!isCreatingNewUser && selectedUserId && selectedGroup.group_id) {
      await addUserToGroupAsync({
        id: selectedGroup.group_id,
        data: {
          members: [selectedUserId],
        },
      });
      onUpdate();
    } else {
      handleSaveUsers([formFields] as unknown as User[]);
    }

    reset();

    if (!isAddMore) {
      closeReset();
    }
  };

  const bulkSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userAlreadyInGroup) {
      return;
    }

    if (!parsedCSVUsers?.length) {
      return;
    }
    handleSaveUsers(parsedCSVUsers as unknown as User[]);

    reset();

    if (!isAddMore) {
      closeReset();
    }
  };

  const closeReset = () => {
    setIsCreatingNewUser(false);
    handleClose();
    setCreationType("manual");
  };

  const handleChangeCreationType = (e: ChangeEvent<HTMLInputElement>) => {
    setCreationType(e.target.value as SetStateAction<"manual" | "csv">);
  };

  const toggleDatepickerOpen = () => setIsDatepickerOpen((prev) => !prev);

  const submitButtonDisabled =
    groupUpdating ||
    emailChecking ||
    userFetching ||
    userAlreadyInGroup ||
    emailEditing;

  return (
    <Dialog
      open={open}
      onClose={closeReset}
      scroll="paper"
      maxWidth={false}
      title={`Add User(s) to ${selectedGroup?.name}`}
      contentSx={{
        width: "628px",
      }}
    >
      <form
        onSubmit={
          creationType === "manual" ? handleSubmit(addUser) : bulkSubmit
        }
      >
        <StyledDialogContent>
          <Divider
            startComponent={
              <Typography component="span">Creation Type</Typography>
            }
          />
          <StyledRadioSectionWrapper>
            <Radio
              id="radio-manual"
              label="Manual Entry"
              checked={creationType === "manual"}
              onChange={handleChangeCreationType}
              value="manual"
              inputProps={{ "aria-label": "manual entry" }}
            />
            <Radio
              id="radio-csv"
              label="CSV Upload"
              checked={creationType === "csv"}
              onChange={handleChangeCreationType}
              value="csv"
              inputProps={{ "aria-label": "csv upload" }}
            />
          </StyledRadioSectionWrapper>

          <Divider
            startComponent={
              <Typography component="span">User Details</Typography>
            }
          />
          {creationType === "manual" ? (
            <>
              <StyledInputRow>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, formState: { errors } }) => (
                    <Box>
                      <CustomInput.InputLabel htmlFor="email">
                        Email Address
                      </CustomInput.InputLabel>
                      <CustomInput.Input
                        {...field}
                        id="email"
                        placeholder="Email"
                        type="email"
                        invalid={!!errors.email}
                      />
                      {errors.email && (
                        <CustomInput.InputMessage error>
                          {errors.email.message}
                        </CustomInput.InputMessage>
                      )}
                      {userAlreadyInGroup && (
                        <CustomInput.InputMessage error>
                          User is already in group.
                        </CustomInput.InputMessage>
                      )}
                    </Box>
                  )}
                />

                <Controller
                  control={control}
                  name="membership_renewal_date"
                  render={({ field, formState: { errors } }) => (
                    <Box>
                      <CustomInput.InputLabel htmlFor="date_snapshot">
                        Account Expiration
                      </CustomInput.InputLabel>
                      <CustomDatePicker
                        name="date_snapshot"
                        open={isDatepickerOpen}
                        setOpen={toggleDatepickerOpen}
                        onClose={toggleDatepickerOpen}
                        selected={
                          field.value
                            ? field.value
                            : (newUserDefaultRenewalDate as Date)
                        }
                        placeholderText="Account Expiration Date"
                        onChange={field.onChange}
                        minDate={add(new Date(), { days: 1 })}
                        maxDate={add(new Date(), { years: 1 })}
                        disabled={!isCreatingNewUser}
                        svgCssStyles={{
                          width: 18,
                          height: 18,
                        }}
                        popperPlacement="bottom-start"
                        popperProps={{
                          strategy: "fixed",
                        }}
                      />
                      {errors.membership_renewal_date && (
                        <CustomInput.InputMessage error>
                          {errors.membership_renewal_date.message}
                        </CustomInput.InputMessage>
                      )}
                    </Box>
                  )}
                />
              </StyledInputRow>

              {isCreatingNewUser && (
                <>
                  <Divider
                    startComponent={
                      <Typography component="span">
                        Account Creation Details
                      </Typography>
                    }
                  />
                  <StyledInputRow>
                    <Controller
                      control={control}
                      name="first_name"
                      render={({ field, formState: { errors } }) => (
                        <Box>
                          <CustomInput.InputLabel htmlFor="first_name">
                            First Name
                          </CustomInput.InputLabel>
                          <CustomInput.Input
                            {...field}
                            id="first_name"
                            placeholder="First Name"
                            type="text"
                            invalid={!!errors.first_name}
                          />
                          {errors.first_name && (
                            <CustomInput.InputMessage error>
                              {errors.first_name.message}
                            </CustomInput.InputMessage>
                          )}
                        </Box>
                      )}
                    />

                    <Controller
                      control={control}
                      name="last_name"
                      render={({ field, formState: { errors } }) => (
                        <Box>
                          <CustomInput.InputLabel htmlFor="last_name">
                            Last Name
                          </CustomInput.InputLabel>
                          <CustomInput.Input
                            {...field}
                            id="last_name"
                            placeholder="Last Name"
                            invalid={!!errors.last_name}
                          />
                          {errors.last_name && (
                            <CustomInput.InputMessage error>
                              {errors.last_name.message}
                            </CustomInput.InputMessage>
                          )}
                        </Box>
                      )}
                    />
                  </StyledInputRow>
                </>
              )}
            </>
          ) : (
            <ImportUserFromSCV setParsedCSVUsers={setParsedCSVUsers} />
          )}
        </StyledDialogContent>
        <StyledDialogActions>
          {creationType === "manual" && (
            <Switch
              id="add-more"
              label="Add more"
              checked={isAddMore}
              onChange={toggleAddMore}
            />
          )}
          <Button
            disabled={submitButtonDisabled}
            btnVariant="secondary"
            btnSize="medium"
            type="submit"
          >
            Add
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
}
