import theme from "src/theme";
import { QueryItem } from "src/types/BulkSearch";

export const getQueryColor = ({
  isSelected,
  queryB,
  index,
}: {
  isSelected: boolean;
  queryB: QueryItem[];
  index: number;
}): string => {
  if (isSelected && queryB.length !== 1 && queryB.length - 1 !== index) {
    return theme.palette.highlight.main;
  } else {
    return "transparent";
  }
};
