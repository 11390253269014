import { SyntheticEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  MenuItem,
  Select as MUISelect,
  SelectChangeEvent,
} from "@mui/material";
import { SelectSize, MultipleSelectProps, SingleSelectProps } from "./types";
import { SelectedValue } from "./tools";
import { selectSizes } from "./constants";

const StyledSelect = styled(MUISelect, {
  shouldForwardProp: (prop) =>
    !["selectSize", "filled", "focused", "invalid"].includes(prop.toString()),
})<{
  selectSize: SelectSize;
  filled: boolean;
  invalid: boolean;
  focused: boolean;
}>(({ theme, selectSize, filled, focused, invalid }) => {
  let borderColor = theme.palette.grey["400"];

  if (invalid) {
    borderColor = theme.palette.secondary.main;
  } else if (filled || focused) {
    borderColor = theme.palette.primary.main;
  }

  return {
    width: "100%",
    color: theme.palette.primary.main,
    fontSize: "14px",
    lineHeight: "18px",
    borderRadius: "8px",
    border: "1px solid",
    marginBottom: "10px",
    borderColor,
    "& .MuiSelect-select": {
      ...selectSizes[selectSize].select,
    },
    "& fieldset": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
  };
});

export const Select = <T,>({
  options,
  onChange: propOnChange,
  value: propValue,
  defaultValue,
  selectSize = "medium",
  placeholder,
  multiple,
  getLabel = (option) => option.label,
  invalid,
  ...props
}: SingleSelectProps<T> | MultipleSelectProps<T>) => {
  const [open, setOpen] = useState(props.open);
  const [value, setValue] = useState(propValue ?? defaultValue);

  useEffect(() => setValue(propValue ?? value), [propValue]);

  useEffect(() => setOpen(props.open ?? open), [props.open]);

  const onChange = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    setValue(value);
    propOnChange?.(value);
  };

  const onOpen = (e: SyntheticEvent) => {
    props.onOpen?.(e);
    setOpen(true);
  };

  const onClose = (e: SyntheticEvent) => {
    props.onClose?.(e);
    setOpen(false);
  };

  return (
    <StyledSelect
      multiple={!!multiple}
      displayEmpty
      value={multiple ? value ?? [] : value ?? ""}
      defaultValue={defaultValue}
      onChange={onChange}
      selectSize={selectSize}
      onOpen={onOpen}
      onClose={onClose}
      focused={open}
      filled={Array.isArray(value) ? !!value.length : !!value}
      invalid={invalid}
      MenuProps={{
        sx: {
          "& .MuiMenuItem-root": {
            fontSize: "14px",
            ...selectSizes[selectSize].select,
          },
        },
      }}
      {...props}
      renderValue={(value: any) => (
        <SelectedValue
          value={value}
          getLabel={getLabel}
          multiple={!!multiple}
          options={options}
          placeholder={placeholder}
        />
      )}
    >
      {options.map((option, index) => (
        <MenuItem
          key={`${String(option.value)}-${index}`}
          value={option.value as any}
        >
          {getLabel(option)}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
