import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { User } from "src/types/UserGroup";
import { StyledTabContainer } from "../StyledComponents";
import TableItself from "./TableItself";
import EmptyUserList from "./EmptyUserList";
import UserSearchInput from "./UserSearchInput";

const StyledHeader = styled(Box)(() => ({
  width: "100%",
  height: 32,
  margin: "14px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: 36,
  color: theme.palette.primary.main,
  backgroundColor: "#E7EAEC",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

interface Props {
  openCreateUserModal?: () => void;
  openImportExistingModal?: () => void;
  openImportCSVModal?: () => void;
  users?: User[];
  handleRemoveUser?: (user: User) => void;
  handleResendEmail?: (data: { user_id: string }) => void;
  handleEditUserSubscriptionDate?: ({
    user,
    newDate,
  }: {
    user: User;
    newDate: string;
  }) => void;
}

const UsersTableControlsWrapper = ({
  openCreateUserModal,
  users,
  handleRemoveUser,
  handleResendEmail,
  handleEditUserSubscriptionDate,
}: Props) => {
  const [filteredUserList, setFilteredUserList] = useState([]);

  useEffect(() => {
    setFilteredUserList(users);
  }, [users]);

  return (
    <StyledTabContainer
      sx={{
        // 62px is the height of the tabs. We shift the tab content up by this amount
        mt: "-62px",
        p: 0,
      }}
    >
      <StyledHeader>
        <UserSearchInput users={users} setUserList={setFilteredUserList} />

        <StyledButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={openCreateUserModal}
        >
          Add User(s)
        </StyledButton>
      </StyledHeader>

      {users.length === 0 ? (
        <EmptyUserList />
      ) : (
        <TableItself
          userList={filteredUserList}
          onRemoveUser={handleRemoveUser}
          onResendEmail={handleResendEmail}
          onEditUserSubscriptionDate={handleEditUserSubscriptionDate}
        />
      )}
    </StyledTabContainer>
  );
};

export default UsersTableControlsWrapper;
