import React from "react";
import { usePostHog } from "posthog-js/react";
import { Box, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { UserFeatureNames } from "src/types/UserGroup";
import { ConfirmDialog } from "src/components/ui";
import { HogEvent } from "src/types/PosthogEvents";

import { IFeatureItem } from ".";

const StyledContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 14,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "19.62px",
  fontWeight: "700",
  color: theme.palette.primary.main,
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "17.17px",
  fontWeight: "400",
  color: theme.palette.primary.main,
}));

const StyledSwitchItem = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledFeatureContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
}));

const StyledInformationBlock = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const FeatureItem = (props: IFeatureItem) => {
  const posthog = usePostHog();

  const showDisableSearchHistoryConfirmation =
    props.name === UserFeatureNames.SEARCH_HISTORY && !!props.value;

  const handleChange = () => {
    if (showDisableSearchHistoryConfirmation) {
      return;
    }
  };

  const switchItem = (
    <StyledSwitchItem>
      {showDisableSearchHistoryConfirmation ? (
        <ConfirmDialog
          onConfirm={() => {
            props.onChange(false);
            posthog.capture(HogEvent.FEATURE_DISABLED, {
              feature: props.featureKey,
            });
          }}
          title="Disable recent searches"
          description="Are you sure you want to disable recent searches? This will permanently delete all recent searches."
          confirmText="Yes"
          cancelText="No"
        >
          <Switch
            color="secondary"
            checked={props.value}
            onChange={handleChange}
          />
        </ConfirmDialog>
      ) : (
        <Switch
          color="secondary"
          checked={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.target.checked);
            posthog.capture(HogEvent.FEATURE_ENABLED, {
              feature: props.featureKey,
            });
          }}
        />
      )}
    </StyledSwitchItem>
  );

  return (
    <StyledContainer>
      <StyledFeatureContent>
        <img src={props.icon} width="48" height="48" />
        <StyledInformationBlock>
          <StyledTitle variant="h4">{props.name}</StyledTitle>
          <StyledDescription variant="caption">
            {props.description}
          </StyledDescription>
        </StyledInformationBlock>
      </StyledFeatureContent>
      {switchItem}
    </StyledContainer>
  );
};

export default FeatureItem;
