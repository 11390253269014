import { useEffect, useMemo } from "react";
import { debounce } from "lodash";

export function useDebounce<T>(callback: (value: T) => void, delay: number) {
  const debouncedCallback = useMemo(() => debounce(callback, delay), [delay]);

  useEffect(() => {
    return () => debouncedCallback.cancel();
  }, [debouncedCallback]);

  return debouncedCallback;
}
