import { QueryItem } from "src/types/BulkSearch";

// Function to construct the final query string based on a list of QueryItem objects
export const getFinalQuery = (queryB: QueryItem[]) => {
  // Reduce over the queryB array to construct the search query string
  const _searchValue = queryB.reduce((acc, q, index) => {
    // Determine if a space should be added (not for the first item)
    const prefix = index === 0 ? "" : " ";

    // Handle 'operator' type items (e.g., AND, OR, NOT)
    if (q.type === "operator") {
      // Special case for "NOT", it must be preceded by "AND"
      acc +=
        q.value === "NOT" ? `${prefix}AND ${q.value}` : `${prefix}${q.value}`;

      // Handle 'term' type items (the actual search terms)
    } else if (q.type === "term") {
      // If the term is quoted, replace double quotes with an asterisk for internal use
      const updatedValue = q.quote ? q.value.replace(/"/g, "&#34;") : q.value;

      // If fuzziness is specified (non-zero), add a tilde with fuzziness value; otherwise, wrap in quotes
      acc +=
        q.fuzziness !== 0
          ? `${prefix}${updatedValue}~${q.fuzziness}` // Fuzzy search term
          : `${prefix}"${updatedValue}"`; // Exact search term
    }
    return acc; // Accumulate the final query string
  }, "");

  // Clean up the final string by replacing any remaining double quotes and asterisks with appropriate values
  return _searchValue.replace(/"/g, "").replace(/&#34;/g, '"');
};
