import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Colors } from "../constants/colors";
import { email as emailRegex } from "src/utils/regex";
import CustomTextField from "../components/CustomTextField";
import logo from "src/assets/logo.svg";
import theme from "src/theme";
import { useUserAuth } from "src/modules/api/auth";

const RootContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Form = styled(Box)(() => ({
  height: 542,
  padding: "72px 80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `0px 3px 13px 0px  #0000001A`,
  borderRadius: 6,
  boxSizing: "border-box",
}));

const FormContainer = styled(Box)(() => ({
  width: "100%",
  maxWidth: "510px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Logo = styled("img")(() => ({
  width: 250,
  height: 84,
  objectFit: "contain",
  marginBottom: "24px",
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "19px",
  fontWeight: "600",
  color: theme.palette.primary.main,
  textAlign: "center",
  fontFamily: "Inter",
}));

const FormDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: "14.5px",
  fontWeight: "400",
  color: theme.palette.primary.main,
  textAlign: "center",
  width: "100%",
  maxWidth: 350,
  fontFamily: "Inter",
  marginTop: 20,
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Inter",
  lineHeight: "14.5px",
  color: theme.palette.primary.main,
  fontWeight: 400,
}));

const ErrorText = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  fontFamily: "Inter",
  color: Colors.secondary,
  textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 45,
  textTransform: "capitalize",
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: 16,
  backgroundColor: theme.palette.mist.light,
  color: theme.palette.primary.main,
  position: "relative",
  borderRadius: 6,
  margin: "27px 0",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& .MuiCircularProgress-svg": {
      color: theme.palette.common.white,
    },
  },
  "&>span.MuiButton-startIcon": {
    marginRight: 0,
    position: "absolute",
    left: "35%",
  },
}));

const StyledWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: 24,
});

export default function ForgotPassword() {
  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { resetPassword } = useUserAuth();
  const { mutateAsync: restorePassword, isLoading } = resetPassword;
  const errors = formState.errors;
  const navigate = useNavigate();

  const handleSendLink = async ({ email }: { email: string }) => {
    const res = await restorePassword({ email });
    if (!res.error) {
      navigate("/login", { state: { sentLink: true } });
    }
  };

  const textFieldInputStyles = {
    height: "44px",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: theme.palette.silver.lighter,
    width: "100%",
    "&:placeholder": {
      color: theme.palette.silver.lighter,
    },
  };

  return (
    <RootContainer>
      <Form>
        <FormContainer>
          <Logo src={logo} alt="SEAMLESS HORIZONS" />
          <FormTitle>Forgot your password?</FormTitle>
          <FormDescription>
            Forgot your password? Enter your email address and we will send you
            instructions to reset your password.
          </FormDescription>
          <form
            onSubmit={handleSubmit(handleSendLink)}
            style={{ width: "100%" }}
          >
            <StyledWrapper>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                  pattern: {
                    value: emailRegex,
                    message:
                      "Please enter a valid email address (i.e. name@example.com)",
                  },
                }}
                render={({ field }) => (
                  <Box>
                    <CustomTextField
                      {...field}
                      inputStyle={textFieldInputStyles}
                      containerStyle={{
                        marginBottom: 0,
                      }}
                      id="email"
                      placeholder="Email"
                      type="email"
                      invalid={!!errors.email}
                    />
                    {errors.email && (
                      <ErrorText>{errors.email.message}</ErrorText>
                    )}
                  </Box>
                )}
              />
            </StyledWrapper>
            <StyledButton
              variant="contained"
              type="submit"
              startIcon={
                isLoading ? (
                  <CircularProgress
                    size="small"
                    style={{
                      color: theme.palette.primary.main,
                      width: 20,
                      height: 20,
                    }}
                  />
                ) : null
              }
            >
              Submit
            </StyledButton>
          </form>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <StyledLink variant="caption">Back to Login</StyledLink>
          </Link>
        </FormContainer>
      </Form>
    </RootContainer>
  );
}
