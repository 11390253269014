import React, { FC } from "react";
import { InputBase, InputBaseProps, styled } from "@mui/material";

const StyledInput = styled(InputBase)(({ theme }) => ({
  marginRight: 14,
  borderRadius: 6,
  border: `1px solid ${theme.palette.grey[400]}`,
  height: "36px",
  fontSize: 12,
  fontFamily: "Inter, sans-serif",
  fontWeight: 400,
  paddingLeft: 10,
}));

interface Props extends InputBaseProps {
  placeholder: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const SearchInput: FC<Props> = ({ placeholder, onChange, ...other }) => {
  return (
    <StyledInput
      placeholder={placeholder}
      inputProps={{ "aria-label": `${placeholder}` }}
      onChange={onChange}
      startAdornment={
        <img
          src={require("src/assets/images/search-black.png")}
          style={{ width: 16, height: 16, marginRight: 12 }}
        />
      }
      {...other}
    />
  );
};

export default SearchInput;
