import { useMutation, useQuery } from "@tanstack/react-query";
import { useFetch } from "src/modules/api/fetch";
import queryKeys, { mutationKeys } from "src/modules/api/queryKeys";
import { KnowledgeWiki, WikiFilter } from "src/types/KnowledgeWiki";
import { queryClient } from "src/index";
import { useUser } from "src/modules/api/auth";
import { Features } from "src/types/Auth";
import { snackbar } from "src/view/toaster";

interface UpdateKnowledgeWikiVariables {
  id: string;
  payload: Partial<KnowledgeWiki>;
}

export function useKnowledgeWiki() {
  const { user } = useUser();
  const { getApi, putApi, deleteApi } = useFetch();

  const getKnowledgeWikisFn = async ({
    countries,
    dataset_type,
    languages,
    regions,
  }: WikiFilter) => {
    const params = new URLSearchParams({
      ...(countries && { countries: countries.join(",") }),
      ...(dataset_type && { dataset_type: dataset_type.join(",") }),
      ...(languages && { languages: languages.join(",") }),
      ...(regions && { regions: regions.join(",") }),
    });

    // TODO: revisit params with pagination
    return await getApi(
      ["knowledge_wiki", "wiki"],
      params as unknown as string
    );
  };

  const getKnowledgeWikis = (params: WikiFilter = {}) =>
    useQuery<KnowledgeWiki[]>({
      enabled: !!user && user.features.includes(Features.KNOWLEDGE_WIKI),
      queryFn: () => getKnowledgeWikisFn(params),
      queryKey: [queryKeys.KNOWLEDGE_WIKI, params],
      initialData: () => [],
      // expensive call - do not refetch on mount, use cache instead
      refetchOnMount: (query) =>
        !query.state.data.length || query.state.isInvalidated,
      onError: () => {
        snackbar.error("Failed to get datasets");
      },
    });

  const getKnowledgeWiki = (id: string) =>
    useQuery<KnowledgeWiki>({
      enabled:
        !!user && user.features.includes(Features.KNOWLEDGE_WIKI) && !!id,
      queryFn: () => {
        return getApi(["knowledge_wiki", "wiki", id]);
      },
      queryKey: [queryKeys.KNOWLEDGE_WIKI_BY_ID, id],
    });

  const getUpdateValidation = (response: any): string => {
    let apiErrorMsg = "";

    response?.json().then((errorResponse: Record<string, string[]>) => {
      if (typeof errorResponse !== "object" || errorResponse === null) {
        return false;
      }
      for (const key in errorResponse) {
        if (
          !Array.isArray(errorResponse[key]) ||
          !errorResponse[key].every((item: any) => typeof item === "string")
        ) {
          return false;
        }
      }
      // eslint-disable-next-line guard-for-in
      for (const key in errorResponse) {
        apiErrorMsg += `${key}: ${errorResponse[key].join(",")}";"\n`;
      }

      snackbar.error(apiErrorMsg || "Failed to update Dataset");
    });

    return apiErrorMsg;
  };

  const updateKnowledgeWiki = useMutation({
    mutationFn: ({ id, payload }: UpdateKnowledgeWikiVariables) => {
      return putApi(
        ["knowledge_wiki", "wiki", id],
        { body: JSON.stringify(payload) },
        getUpdateValidation
      );
    },
    mutationKey: [mutationKeys.UPDATE_KNOWLEDGE_WIKI_BY_ID],
    onSuccess: () => {
      snackbar.success("Dataset has been updated");
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === queryKeys.KNOWLEDGE_WIKI_BY_ID ||
          query.queryKey[0] === queryKeys.KNOWLEDGE_WIKI,
      });
    },
  });

  const deleteKnowledgeWiki = useMutation({
    mutationFn: ({ id }: Partial<UpdateKnowledgeWikiVariables>) => {
      return deleteApi(["knowledge_wiki", "wiki", id]);
    },
    mutationKey: [mutationKeys.DELETE_KNOWLEDGE_WIKI_BY_ID],
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.KNOWLEDGE_WIKI]);
      queryClient.removeQueries([queryKeys.KNOWLEDGE_WIKI_BY_ID]);
    },
  });

  return {
    getKnowledgeWikis,
    getKnowledgeWiki,
    updateKnowledgeWiki,
    deleteKnowledgeWiki,
  };
}
