import React, { useState } from "react";
import { forwardRef } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  WarningAmber as WarningAmberIcon,
  ReportGmailerrorred as ReportGmailerrorredIcon,
} from "@mui/icons-material";
import { Tooltip } from "src/components/ui";
import EditSubscriptionDate from "src/view/users-groups/users/UsersTable/EditSubscriptionDate";
import { checkIfGroupAdmin } from "src/utils/checkIfGroupAdmin";
import { getExpirationStatus } from "src/view/users-groups/utils";
import { useUser } from "src/modules/api/auth";
import { Role } from "src/types/Auth";
import { User } from "src/types/UserGroup";
import theme from "src/theme";

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

const StyledIcon = forwardRef<
  SVGSVGElement,
  {
    customStyles?: React.CSSProperties;
    icon: React.ElementType;
  }
>(function StyledIcon(
  { customStyles, icon: IconComponent, ...otherProps },
  ref
) {
  return (
    <IconComponent
      {...otherProps}
      ref={ref}
      style={{
        cursor: "pointer",
        fontSize: "20px",
        color: theme.palette.primary.main,
        margin: "0 5px",
        ...customStyles,
      }}
    />
  );
});

interface Props {
  row: User;
  onEditUserSubscriptionDate?: ({
    user,
    newDate,
  }: {
    user: User;
    newDate: string;
  }) => void;
}

const EditSubscription = ({ row, onEditUserSubscriptionDate }: Props) => {
  const { user } = useUser();
  const [openDatepickerId, setOpenDatepickerId] = useState<string | null>(null);

  const isAdmin = user?.role === Role.ADMIN;
  const currentDate = new Date();
  const renewalDate = new Date(row.membership_renewal_date);
  const { isExpired, isUpcomingExpiration, expirationMessage } =
    getExpirationStatus(renewalDate, currentDate);
  const isNotCurrentUser = row.id !== user.id;

  const toggleDatepickerVisibility = (id: string | null) => {
    if (!id || openDatepickerId === id) {
      return setOpenDatepickerId(null);
    }
    return setOpenDatepickerId(id);
  };

  return (
    <EditSubscriptionDate
      user={row}
      editable={
        isNotCurrentUser &&
        (isAdmin || checkIfGroupAdmin(row.user_groups.member))
      }
      onEdit={onEditUserSubscriptionDate}
      openDatepickerId={openDatepickerId}
      toggleDatepickerVisibility={toggleDatepickerVisibility}
    >
      {(isUpcomingExpiration || isExpired) && (
        <Tooltip
          arrow
          placement="bottom-end"
          enterDelay={0}
          stylesTooltip={{ marginTop: "10px" }}
          title={
            <StyledTooltipContent>
              <span dangerouslySetInnerHTML={{ __html: expirationMessage }} />
            </StyledTooltipContent>
          }
        >
          <StyledIcon
            icon={isExpired ? ReportGmailerrorredIcon : WarningAmberIcon}
            customStyles={{
              color: isExpired
                ? theme.palette.error.main
                : theme.palette.primary.main,
            }}
          />
        </Tooltip>
      )}
    </EditSubscriptionDate>
  );
};

export default EditSubscription;
