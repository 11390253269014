export const convertToUTCWithTime = (date: Date): string => {
  if (!date) return "";

  const currentDate = new Date();
  const updatedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
    currentDate.getMilliseconds()
  );

  const utcDate = new Date(
    Date.UTC(
      updatedDate.getUTCFullYear(),
      updatedDate.getUTCMonth(),
      updatedDate.getUTCDate(),
      updatedDate.getUTCHours(),
      updatedDate.getUTCMinutes(),
      updatedDate.getUTCSeconds(),
      updatedDate.getUTCMilliseconds()
    )
  );

  return utcDate.toISOString();
};
