import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { usePostHog } from "posthog-js/react";
import DownloadMenu from "../DownloadMenu";
import ActionIconButton from "../ActionIconButton";
import { DataIndex, GetDownloadDocumentURLParams } from "src/types/Search";
import { useSearch } from "src/modules/api/search";
import downloadIcon from "src/assets/icons/download_icon.svg";

const DownloadContainer = styled(Box)({
  position: "relative",
});

interface Props {
  docId: string;
  type: string;
}

const DownloadFile: React.FC<Props> = ({ docId, type }) => {
  const posthog = usePostHog();
  const { getDownloadDocumentUrl } = useSearch();
  const [gettingDownloadLink, setGettingDownloadLink] = useState(false);
  const sourceType = 1;
  const dataIndex = DataIndex.source;

  const [downloadParams, setDownloadParams] =
    useState<GetDownloadDocumentURLParams | null>(null);

  const { data: downloadUrl, status: downloadUrlStatus } =
    getDownloadDocumentUrl(downloadParams);

  useEffect(() => {
    if (downloadUrlStatus === "success") {
      setDownloadParams(null);
      setGettingDownloadLink(false);
      if (downloadUrl) {
        const downloadWindow = window.open(downloadUrl, "_blank");
        downloadWindow?.focus();
      }
    }
  }, [downloadUrlStatus]);

  const handleDownloadDoc = async (entireFile?: boolean) => {
    setGettingDownloadLink(true);
    posthog.capture(
      sourceType === 1 ? "source_data_downloaded" : "model_data_downloaded"
    );
    setDownloadParams({
      id: docId,
      dataIndex,
      entireFile,
    });
  };

  return (
    <DownloadContainer>
      {gettingDownloadLink ? (
        <Box sx={{ p: "4px" }}>
          <CircularProgress size={12} color="inherit" />
        </Box>
      ) : (
        <DownloadMenu
          type={type}
          handleDownloadDoc={handleDownloadDoc}
          sourceType={sourceType}
          trigger={<ActionIconButton icon={downloadIcon} />}
        />
      )}
    </DownloadContainer>
  );
};

export default DownloadFile;
