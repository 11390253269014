import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { useSearchParams } from "react-router-dom";
import { Colors } from "src/view/constants/colors";
import selector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import actions from "src/modules/bulk-search/query-group/queryGroupActions";
import viewActions from "src/modules/search/view/searchViewActions";
import { MappedSearchResult } from "src/types/Search";
import LoadingText from "src/view/components/LoadingText";
import { parsePreviewFromSearchParams } from "src/modules/api/search/SearchProvider";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import { HogEvent } from "src/types/PosthogEvents";
import BulkSearchPagination from "src/view/bulk-search/QueryGroupRun/components/BulkSearchPagination";
import { DatasetHits } from "../../../search-result/components/DatasetHits";
import { searchMapper } from "../../../../modules/api/search/searchMapper";

const StyledResultContent = styled("div")(() => ({
  height: "calc(100vh - 262px)",
  overflowY: "auto",
  padding: "20px 26px 40px 27px",
}));

const StyledContainer = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 300px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledNoData = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 300px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: Colors.twilight,
}));

const StyledPageLoadingContainer = styled("div")(() => ({
  width: "100%",
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledLoadingMessage = styled("div")(() => ({
  fontSize: 16,
  lineHeight: "23px",
  fontWeight: "bold",
  color: Colors.textGray300,
  marginBottom: 10,
  width: 64,
}));

const ResultContent = () => {
  const selectedSearchTerm = useSelector(selector.selectSelectedSearchTerm);
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const { setSelectedResult } = useContext(PreviewContext);

  const [loading, setLoading] = useState(false);
  const searchResultContentRef = useRef<HTMLDivElement | null>(null);
  const [searchData, setSearchData] = useState<MappedSearchResult>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (selectedSearchTerm) {
      run(selectedSearchTerm.id, page);
    }
  }, [selectedSearchTerm, page]);

  useEffect(() => {
    return () => {
      dispatch(viewActions.clear);
    };
  }, []);

  // TODO: blatantly duplicated from SearchResultContent
  // sync preview state with URL (respecting history)
  useEffect(() => {
    const parsedPreview = parsePreviewFromSearchParams(searchParams);
    if (parsedPreview) {
      const { _id, source_type, type, data } = parsedPreview;
      if (data?.[0]) setSelectedResult(data[0]);
      posthog.capture(
        source_type === 1
          ? HogEvent.SOURCE_DATA_PREVIEWED
          : HogEvent.MODEL_DATA_PREVIEWED
      );
      dispatch(viewActions.getDocumentPreview(_id, source_type, type));
      dispatch({
        type: viewActions.SET_PREVIEW_DOC,
        payload: parsedPreview,
      });
    } else {
      setSelectedResult(null);
      dispatch({
        type: viewActions.SET_PREVIEW_DOC,
        payload: null,
      });
      dispatch({
        type: viewActions.GET_PREVIEW_DOCUMENT,
        payload: null,
      });
    }
  }, [searchParams]);

  const run = async (id: string, page: number) => {
    setLoading(true);
    const res = await dispatch(actions.runSearch(id, page));

    if (res) {
      setSearchData(searchMapper(res));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Box>
      <StyledResultContent ref={searchResultContentRef}>
        {loading && (
          <StyledContainer sx={{ gap: 2 }}>
            <StyledLoadingMessage>
              <LoadingText />
            </StyledLoadingMessage>
            <CircularProgress size={20} />
          </StyledContainer>
        )}
        {!loading && searchData?.totalCounts > 0 ? (
          <>
            {Object.entries(searchData.items).map(([datasetId, hits]) => (
              <DatasetHits key={datasetId} datasetId={datasetId} hits={hits} />
            ))}
          </>
        ) : (
          <StyledNoData />
        )}
        {loading && (
          <StyledPageLoadingContainer>
            <CircularProgress color="info" size={20} disableShrink />
          </StyledPageLoadingContainer>
        )}
      </StyledResultContent>
      <BulkSearchPagination data={searchData} page={page} setPage={setPage} />
    </Box>
  );
};
export default ResultContent;
