import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import DownloadFile from "./DownloadFile";
import { StyledFileName } from "./DataTableView";
import { StyledHeader } from "./JSONView";
import HighlightingFailure from "./HighlightingFailure";
import { getHighlightText } from "src/utils/get-highlight-text";
import { isStringRTL } from "src/utils";
import theme from "src/theme";
import { Tooltip } from "src/components/ui";

const ButtonsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

export interface RawTextViewProps {
  data: string;
  fileName: string;
  docId: string;
  type: string;
  getDocumentPreview: () => void;
  isHighlightingFailure: boolean;
}

const RawTextView = ({
  data,
  docId,
  fileName,
  type,
  getDocumentPreview,
  isHighlightingFailure,
}: RawTextViewProps) => (
  <Box sx={{ paddingLeft: "8px" }}>
    <StyledHeader>
      <Tooltip
        arrow={true}
        placement="top-start"
        enterDelay={0}
        title={<StyledTooltipContent>{fileName}</StyledTooltipContent>}
      >
        <StyledFileName onClick={getDocumentPreview}>{fileName}</StyledFileName>
      </Tooltip>
      <ButtonsContainer>
        {isHighlightingFailure && <HighlightingFailure />}
        <DownloadFile docId={docId} type={type} />
      </ButtonsContainer>
    </StyledHeader>
    <Typography
      variant="body2"
      sx={{
        fontSize: "12px",
        fontWeight: "700",
        color: theme.palette.primary.light,
        textTransform: "capitalize",
        paddingLeft: "4px",
      }}
    >
      Excerpt:{" "}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        ml: 9,
        fontSize: "12px",
        lineHeight: "17px",
        wordBreak: "break-all",
        whiteSpace: "pre-wrap",
        color: theme.palette.primary.light,
        direction: isStringRTL(data) ? "rtl" : "ltr",
        paddingRight: isStringRTL(data) ? "10px" : "",
      }}
    >
      <HighlightActionMenu>
        {getHighlightText(data) as JSX.Element}
      </HighlightActionMenu>
    </Typography>
  </Box>
);

RawTextView.displayName = "RawTextView";

export default RawTextView;
