import CellWithSwitch, { CellWithSwitchProps } from "./CellWithSwitch";
import DialogEnableLeaked from "./DialogEnableLeaked";

import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";

import { Group, GroupFeatures } from "src/types/UserGroup";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";

interface Props extends CellWithSwitchProps {
  datasetsIds: KnowledgeWiki["dataset"][];
  handleUpdateFeatures?: (checked: boolean, featureName: GroupFeatures) => void;
  group: Group;
  shouldDisableToggles: boolean;
}

// Decorator for the CellWithSwitch component
const ColumnHeader = ({
  datasetsIds,
  handleUpdateFeatures,
  shouldDisableToggles,
  group,
  ...props
}: Props) => {
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data: datasets } = getKnowledgeWikis();
  const { value: isChecked } = props;

  const isContainLeaked = datasets.some((dataset) => dataset.is_leaked);

  const isLeakedEnabled = group?.features?.includes(
    GroupFeatures.LEAKED_DATASETS
  );

  if (!isLeakedEnabled && isContainLeaked)
    return (
      <DialogEnableLeaked
        handleUpdateFeatures={handleUpdateFeatures}
        description="Some of the datasets in this group will not be selected, as the leaked dataset feature is not enabled for this user group. Click below to enable leaked dataset access for this user group."
      >
        <CellWithSwitch
          {...props}
          disabled
          handleSwitch={isChecked ? props.handleSwitch : () => null}
        />
      </DialogEnableLeaked>
    );

  return <CellWithSwitch disabled={shouldDisableToggles} {...props} />;
};

export default ColumnHeader;
