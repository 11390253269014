import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { Colors } from "src/view/constants/colors";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";
import BulkIconUrl from "src/assets/images/bulk-search-icon-primary.png";

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: 370,
  height: "100%",
});

const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  minHeight: 64,
  borderBottom: "solid 1px #A0B9D0",
  paddingLeft: 8,
});

const Item = styled(Box)({
  width: "calc(100% - 58px)",
  display: "flex",
  alignItem: "center",
  justifyContent: "space-between",
  padding: 15,
  borderRadius: 6,
  backgroundColor: "#F7F8F9",
  cursor: "pointer",
});

interface Props {
  ids: string[];
  onClose: () => void;
}

const Content = ({ ids, onClose }: Props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Promise.all(ids.map((id) => BulkSearchService.getScreeningList(id))).then(
      (res) => {
        setData(res.filter((item) => !item?.error));
      }
    );
  }, [ids]);

  return (
    <Wrapper>
      <Header>
        <img src={BulkIconUrl} width="20" height="20" />
        <Typography
          variant="h6"
          fontSize="16px"
          fontWeight="700"
          color="#122945"
          pl={1}
          mr="auto"
        >
          Bulk searches
        </Typography>
        <IconButton size="large" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>
      </Header>

      <Box flex="1" overflow="auto" p="14px">
        {data?.map((item: any, index: number) => (
          <BulkSearchItem key={"bulk_search_item_" + index} data={item} />
        ))}
      </Box>
    </Wrapper>
  );
};

const BulkSearchItem = (props: { data: any }) => {
  const { data } = props;
  const navigate = useNavigate();

  const gotoDetailPage = () => {
    navigate(`/bulk-search/query-group/${data.id}`, {
      state: { data },
      replace: true,
    });
  };

  return (
    <Item>
      <Typography
        fontSize="14px"
        lineHeight="20px"
        color={Colors.textDark100}
        fontWeight="700"
        onClick={gotoDetailPage}
      >
        {data["name"]}
      </Typography>
      {/* <ConfirmDialog
                title="Remove Screening List"
                description="Are you sure you want to remove this screening list? This action cannot be undone."
                onConfirm={handleDelete}
                confirmText="Confirm"
                cancelText="Cancel"
            >
                <IconButton size="small">
                    <StyledImage src={require('src/assets/images/delete.png')} />
                </IconButton>
            </ConfirmDialog> */}
    </Item>
  );
};

export default Content;
