import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { getHighlightText } from "src/utils/get-highlight-text";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { useSearchInFile } from "src/modules/api/search/useSearchInFile";
import { compact, get, isEqual } from "lodash";
import { DataIndex, Hit } from "src/types/Search";
import { replaceHighlightTags } from "src/utils";
import { DocTypes } from "src/types/Dataset";
import theme from "src/theme";
import AutoSizer from "react-virtualized-auto-sizer";
import { useIntersectionObserver } from "usehooks-ts";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import searchStoreSelectors from "src/modules/api/search/store/selectors";

export interface DataTablePreviewProps {
  data?: { id: string; content: Record<string, any>[] }[];
  loading: boolean;
  previewDocInfo: PreviewDocProps;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const FileResults = ({
  handleClosePreview,
  previewDocInfo,
}: DataTablePreviewProps) => {
  const { selectedResult } = useContext(PreviewContext);
  const { getSearchResults } = useSearchInFile();
  const { content } = useSelector(searchStoreSelectors.selectVariables);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const prepContentWithFileName = useCallback((): string => {
    // assume query is built manually, use unchanged
    if (content.includes(`"${previewDocInfo.dataset.fileName}"`)) {
      return content;
    }
    // if a result is from a simpler query, attach filename
    else {
      return `"${previewDocInfo.dataset.fileName}" AND ${
        content.length ? content : "*"
      }`;
    }
  }, [previewDocInfo.dataset.fileName, content]);

  const { ref: endOfListRef } = useIntersectionObserver({
    initialIsIntersecting: false,
    onChange: (isIntersecting) =>
      isIntersecting && hasNextPage && fetchNextPage(),
  });

  const { data, hasNextPage, fetchNextPage } = getSearchResults({
    content: prepContentWithFileName(),
    datasetId: previewDocInfo.wikiItem.dataset,
  });

  const preppedHits = useMemo(() => {
    if (data?.pages?.length) {
      let preppedHits: Hit<DataIndex>[] = [];
      data.pages.forEach((page, index) => {
        return preppedHits.push(...get(data, `pages[${index}].hits.hits`));
      });

      preppedHits = preppedHits.flatMap((hit) =>
        hit._source.doc_type === DocTypes.CSV ||
        hit._source.doc_type === DocTypes.PARQUET
          ? prepCSVHit(hit)
          : null
      );
      preppedHits = compact(preppedHits);

      preppedHits = preppedHits.filter((hit) => !isEqual(hit, selectedResult));
      return preppedHits;
    }
  }, [data]);

  function prepCSVHit<T extends DataIndex>(hit: Hit<T>): Record<string, any> {
    let _data = JSON.parse(hit._source.content as string);

    if (hit.highlight?.content && Array.isArray(hit.highlight.content)) {
      _data = hit._source.content;
      hit.highlight.content.forEach((el: string) => {
        const h_txt = replaceHighlightTags(el);
        const r_txt = el.replace(/<\/em>/g, "");
        _data = _data.replace(r_txt, h_txt);
      });
      _data = JSON.parse(_data);
    }

    return _data;
  }

  if (!preppedHits?.length) {
    return null;
  }

  return (
    <>
      {isMounted ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              fontSize: "14px",
              fontWeight: 600,
              color: theme.palette.grey[900],
              marginBottom: 3,
            }}
          >
            <Typography sx={{ fontWeight: "inherit", fontSize: "inherit" }}>
              Other matches in {previewDocInfo?.dataset["fileName"]}
            </Typography>
            <Typography sx={{ opacity: "50%", fontSize: "inherit" }}>
              •
            </Typography>
            <Typography
              sx={{
                fontWeight: "inherit",
                fontSize: "inherit",
                opacity: "50%",
                whiteSpace: "nowrap",
              }}
            >
              {data.pages[0].total_counts - 1} Record
              {data.pages[0].total_counts - 1 > 1 ? "s" : ""}
            </Typography>
          </Box>

          <AutoSizer>
            {({ height, width }) => {
              return (
                <Box
                  sx={{
                    minHeight: "50vh",
                    height: height,
                    width: width,
                    overflow: "auto",
                    display: "block",
                    mb: 4,
                    borderTop: "1px solid",
                    borderColor: theme.palette.grey[400],
                    borderRadius: "4px",
                  }}
                >
                  <Table
                    stickyHeader
                    sx={{
                      border: "1px solid",
                      borderTop: "0px",
                      borderRadius: "4px",
                      borderColor: theme.palette.grey[400],
                    }}
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{ backgroundColor: "rgba(231, 231, 231, 0.5)" }}
                    >
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {Object.entries(preppedHits?.[0] || {}).map(
                          ([key], index) => {
                            return (
                              <TableCell
                                key={key + index}
                                sx={{
                                  whiteSpace: "nowrap",
                                  pt: 1,
                                  pb: 1,
                                  fontSize: "11px",
                                  lineHeight: "13px",
                                  backgroundColor: "red",
                                  color: "rgba(0, 0, 0, 0.7)",
                                }}
                              >
                                <Box className="table__cell">
                                  <HighlightActionMenu
                                    onSearchInTab={handleClosePreview}
                                  >
                                    <>{getHighlightText(key as string)}</>
                                  </HighlightActionMenu>
                                </Box>
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {preppedHits.map((hit, index) => {
                        return (
                          <TableRow
                            key={`search-in-dataset-${index}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {Object.entries(hit).map((entryKVP, entryIndex) => {
                              return (
                                <TableCell
                                  key={`search-in-dataset-page-${index}-row-${entryIndex}`}
                                  sx={{
                                    pt: 2,
                                    pb: 2,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#000000",
                                    maxWidth: "400px",
                                  }}
                                >
                                  <Tooltip
                                    placement={"bottom-start"}
                                    title={
                                      entryKVP[1].length ? (
                                        <>
                                          {getHighlightText(
                                            entryKVP[1] as string
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )
                                    }
                                    arrow
                                    sx={{
                                      mr: "20px",
                                    }}
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          p: 1,
                                          bgcolor: "#122945",
                                          "& .MuiTooltip-arrow": {
                                            color: "#122945",
                                          },
                                        },
                                      },
                                    }}
                                    PopperProps={{
                                      style: { zIndex: 1501 },
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -5],
                                          },
                                        },
                                      ],
                                    }}
                                  >
                                    <Box className="table__cell">
                                      {entryKVP[1].length ? (
                                        <HighlightActionMenu
                                          onSearchInTab={handleClosePreview}
                                        >
                                          <>
                                            {getHighlightText(
                                              entryKVP[1] as string
                                            )}
                                          </>
                                        </HighlightActionMenu>
                                      ) : (
                                        "-"
                                      )}
                                    </Box>
                                  </Tooltip>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Box
                    ref={endOfListRef}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      py: hasNextPage ? "15px" : 0,
                    }}
                  >
                    {hasNextPage && <CircularProgress />}
                  </Box>
                </Box>
              );
            }}
          </AutoSizer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: { xs: "auto", sm: "40vh" },
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      )}
    </>
  );
};

export default FileResults;
