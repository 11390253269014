import { ReactNode } from "react";
import {
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  Box,
  styled,
} from "@mui/material";

export interface RadioProps extends MuiRadioProps {
  label?: ReactNode;
}

const StyledRadioLabel = styled("label")(({ theme }) => ({
  fontFamily: "Inter",
  color: theme.palette.primary.main,
  fontSize: "14px",
  fontWeight: 400,
  cursor: "pointer",
  userSelect: "none",
}));

const StyledRadioWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const StyledRadioInput = styled(MuiRadio)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  color: theme.palette.grey[200],
  backgroundColor: theme.palette.grey[200],
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
  "&.MuiRadio-root": {
    padding: 0,
    width: 20,
    height: 20,
  },
  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: theme.palette.grey[200],
  },
  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
    transform: "scale(1.0)", // 10px
  },
}));

export const Radio = ({ label, id, ...props }: RadioProps) => {
  return (
    <StyledRadioWrapper>
      <StyledRadioInput id={id} {...props} />
      {typeof label === "string" ? (
        <StyledRadioLabel htmlFor={id}>{label}</StyledRadioLabel>
      ) : (
        label
      )}
    </StyledRadioWrapper>
  );
};
