import { styled } from "@mui/material/styles";
import { Link as _Link } from "react-router-dom";

const LinkContainer = styled(_Link)(() => ({
  textDecoration: "none",
}));

interface Props {
  to: string;
  children: JSX.Element;
}

const Link = (props: Props) => {
  return <LinkContainer to={props.to}>{props.children}</LinkContainer>;
};

export default Link;
