import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

interface InfoTooltipProps {
  title: string;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.grey["600"],
  mr: "5px",
  fontSize: "20px",
  cursor: "pointer",
}));

const InfoTooltip = ({ title }: InfoTooltipProps) => {
  return (
    <StyledTooltip
      title={title}
      arrow
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -5],
            },
          },
        ],
      }}
    >
      <StyledInfoIcon />
    </StyledTooltip>
  );
};

export default InfoTooltip;
