import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { BrowserHistory } from "history";
import search from "src/modules/search/searchReducers";
import bulk_search from "src/modules/bulk-search/bulkSearchReducers";
import auth from "src/modules/auth/reducers";
import searchStore from "src/modules/api/search/store/reducer";

export default (history: BrowserHistory) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    search,
    bulk_search,
    searchStore,
  });
