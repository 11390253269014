import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format, isValid } from "date-fns";
import posthog from "posthog-js";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import DataTypeIcon from "src/view/search-result/components/SearchResultCard/DataTypeIcon";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import SearchResultCardActions from "src/view/search-result/components/SearchResultCard/SearchResultCardActions";
import FilterTag from "src/view/search-result/components/SearchResultCard/FilterTag";
import IsLeakedTag from "src/view/components/IsLeakedTag";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import dataSelector from "src/modules/search/view/searchViewSelectors";
import actions from "src/modules/search/view/searchViewActions";
import { fetchApi } from "src/modules/shared/api";
import { getFileNameFromURL } from "src/utils";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import { HogEvent } from "src/types/PosthogEvents";

const DocumentHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3, 2, "12px", 2),
  gap: "12px",
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  ".MuiTypography-root": {
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: "700",
    color: theme.palette.primary.main,
  },
  display: "flex",
  gap: "1em",
}));

const StyledDateLabel = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: "700",
  color: theme.palette.primary.main,
}));

interface Props {
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
  modeledData: PreviewDocListProps["intervals"];
}

// RoT: 1 by @inperegelion
const PreviewHeader = ({ handleClosePreview, modeledData }: Props) => {
  const data = useSelector(dataSelector.selectPreviewDocumentList);
  const previewDocInfo = useSelector(dataSelector.selectPreviewDocInfo);
  const { headerRef } = useContext(PreviewContext) || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gettingDownloadLink, setGettingDownloadLink] = useState(false);

  const handleOpenKnowledgeWiki = () => {
    posthog.capture(HogEvent.DATA_COLLECTION_OPENED);
    navigate("/data-dictionary/data/" + previewDocInfo.wikiItem.id, {
      replace: false,
      state: { id: previewDocInfo.wikiItem.id },
    });
  };

  const handleDownloadDoc = async (entireFile?: boolean) => {
    setGettingDownloadLink(true);
    try {
      const downloadURL = await dispatch(
        actions.downloadDoc(
          previewDocInfo._id,
          previewDocInfo.source_type,
          entireFile
        )
      );
      setGettingDownloadLink(false);

      const a = document.createElement("a");
      const response = await fetchApi(downloadURL, {}, true);
      const fileName =
        previewDocInfo?.data?.name?.join(",") ||
        getFileNameFromURL(downloadURL);

      a.id = "temp-download-link";
      a.href = URL.createObjectURL(await response.blob());
      a.download =
        previewDocInfo?.data?.schema !== undefined
          ? getFileNameFromURL(downloadURL)
          : fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.getElementById("temp-download-link").remove();
    } catch (error) {
      setGettingDownloadLink(false);
      console.error(error);
    }
  };
  const handleDownloadDocBulk = async () => {
    if (!previewDocInfo) return;
    setGettingDownloadLink(true);
    let ids: string[] = [];
    if (previewDocInfo.source_type == 0) {
      Object.values(modeledData)?.map((item) => {
        if (Array.isArray(item)) {
          const __: string[] = item?.map((item) => item["_id"]);
          ids = ids.concat(__);
        }
      });
    } else {
      ids = Array.isArray(data) && data?.map((item) => item["id"]);
    }
    try {
      const downloadURL = await dispatch(
        actions.downloadDocAll({ doc_ids: ids })
      );
      setGettingDownloadLink(false);

      const a = document.createElement("a");
      const response = await fetchApi(downloadURL, {}, true);

      a.id = "temp-download-link";
      a.href = URL.createObjectURL(await response.blob());
      a.download = getFileNameFromURL(downloadURL);
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.getElementById("temp-download-link").remove();
    } catch (error) {
      console.error(error);
      setGettingDownloadLink(false);
    }
  };

  return (
    <DocumentHeader ref={headerRef}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            flex: "0 0 60px",
            height: 60,
            mr: 2,
            "& img": {
              height: "40px",
            },
          }}
        >
          <DataTypeIcon type={previewDocInfo?.type || "csv"} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            {previewDocInfo?.source_type === 0 ? (
              <HighlightActionMenu onSearchInTab={handleClosePreview}>
                <StyledTitle>
                  {Array.isArray(previewDocInfo?.title)
                    ? previewDocInfo?.title[0]
                    : previewDocInfo?.title}
                </StyledTitle>
              </HighlightActionMenu>
            ) : (
              <StyledTitle>
                <Typography>{previewDocInfo?.title}</Typography>
                <IsLeakedTag dataset={previewDocInfo?.wikiItem} />
              </StyledTitle>
            )}

            <StyledDateLabel>
              <SearchResultCardActions
                gettingDownloadLink={gettingDownloadLink}
                resultMetadata={previewDocInfo}
                type={previewDocInfo?.type}
                sourceType={previewDocInfo?.source_type}
                handleDownloadDoc={handleDownloadDoc}
                handleDownloadDocBulk={handleDownloadDocBulk}
              />
            </StyledDateLabel>
          </Box>
          {previewDocInfo?.source_type === 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "wrap",
                mt: 1,
                gap: 1,
              }}
            >
              <FilterTag
                compact
                label={"Type"}
                value={`${previewDocInfo?.dataset["Dataset Type"]}`}
              />
              {previewDocInfo?.dataset["Region"] && (
                <FilterTag
                  compact
                  label={"Region"}
                  value={`${previewDocInfo?.dataset["Region"]}`}
                />
              )}
              {previewDocInfo?.dataset["Language"] && (
                <FilterTag
                  compact
                  label={"Language"}
                  value={`${previewDocInfo?.dataset["Language"]}`}
                />
              )}
              {previewDocInfo?.dataset["Sensitivity"] && (
                <FilterTag
                  compact
                  label={"Sensitivity"}
                  value={`${previewDocInfo?.dataset["Sensitivity"]}`}
                />
              )}
              {previewDocInfo?.wikiItem && (
                <FilterTag
                  compact
                  label={"Dataset"}
                  value={previewDocInfo.wikiItem.name}
                  action={() => handleOpenKnowledgeWiki()}
                />
              )}
              {isValid(new Date(previewDocInfo?.updatedOn)) && (
                <Box sx={{ ml: "auto" }}>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      color: "#7A8796",
                      fontSize: "12px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    Added on:&nbsp;
                    {format(new Date(previewDocInfo.updatedOn), "MM/dd/yyyy")}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {previewDocInfo?.source_type === 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "wrap",
                mt: 2,
                mb: 2,
                gap: 1,
              }}
            >
              {previewDocInfo?.dataset &&
                Object.keys(previewDocInfo.dataset).map(
                  (filterKey: string, index: number) => {
                    const filterValue = previewDocInfo.dataset[filterKey];
                    let action = null;
                    if (
                      filterKey == "Data Dictionary" &&
                      previewDocInfo.wikiItem
                    ) {
                      action = () => handleOpenKnowledgeWiki();
                    }
                    return (
                      <FilterTag
                        compact
                        key={index}
                        label={filterKey}
                        value={filterValue}
                        action={action}
                        disableHighlight={true}
                      />
                    );
                  }
                )}
            </Box>
          )}
        </Box>
      </Box>
    </DocumentHeader>
  );
};

export default PreviewHeader;
