import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, styled, Typography } from "@mui/material";
import { setupHbs } from "src/utils/hbs";
import "./HubspotForm.css";

const RootContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Form = styled(Box)(() => ({
  padding: "40px 40px 62px",
  width: 590,
  height: 596,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `0px 3px 13px 0px  #0000001A`,
  borderRadius: 6,
  boxSizing: "border-box",
}));

const HubspotContainer = styled(Box)({
  maxHeight: 455,
  overflowY: "auto",
  paddingRight: 12,
  marginBottom: 24,
  position: "relative",
});

const StyledScrollBlur = styled(Box)(({ theme }) => ({
  position: "sticky",
  zIndex: "10",
  bottom: "-1px",
  width: "100%",
  height: "32px",
  backgroundImage: `linear-gradient(to top, ${theme.palette.common.white}, #00000000)`,
}));

const FormContainer = styled(Box)(() => ({
  width: "100%",
  maxWidth: "510px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledLink = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Inter",
  lineHeight: "14.5px",
  cursor: "pointer",
  display: "block",
  textAlign: "center",
});

export default function RequestAccess() {
  const navigate = useNavigate();
  useEffect(() => {
    setupHbs();

    return () => {
      const hbs = document.getElementById("hubspot");

      hbs &&
        document.documentElement.removeChild(
          document.getElementById("hubspot")
        );
    };
  }, []);

  return (
    <RootContainer>
      <Form>
        <FormContainer>
          <HubspotContainer className="request-form">
            <Box id="request-form" />
            <StyledScrollBlur />
          </HubspotContainer>
          <StyledLink
            variant="caption"
            color="primary"
            fontWeight="400"
            onClick={() => {
              navigate("/login", { replace: true });
              window.location.reload();
            }}
          >
            Back to Login
          </StyledLink>
        </FormContainer>
      </Form>
    </RootContainer>
  );
}
