import React from "react";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import ActionIconButton from "./ActionIconButton";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { HogEvent } from "src/types/PosthogEvents";
import wikiPrimaryIcon from "src/assets/icons/knowledge_wiki.svg";
import linkPrimaryIcon from "src/assets/images/link_primary.svg";
import downloadIcon from "src/assets/images/download_icon.png";
import DownloadMenu from "./DownloadMenu";

interface Props {
  resultMetadata?: PreviewDocProps;
  gettingDownloadLink?: boolean;
  skeleton?: boolean;
  sourceType?: number;
  type: string;
  handleDownloadDoc?: (entireFile?: boolean) => void;
  handleDownloadDocBulk?: () => void;
}

const SearchResultCardActions = ({
  resultMetadata,
  gettingDownloadLink,
  skeleton,
  sourceType,
  type,
  handleDownloadDoc,
  handleDownloadDocBulk,
}: Props) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleOpenWiki = () => {
    if (skeleton) return;
    posthog.capture(HogEvent.DATA_COLLECTION_OPENED);
    return navigate("/data-dictionary/data/" + resultMetadata?.wikiItem?.id, {
      replace: false,
      state: { id: resultMetadata?.wikiItem?.id },
    });
  };

  const handleOpenWikiInNewTab = () => {
    if (skeleton) return;
    posthog.capture(HogEvent.DATA_COLLECTION_OPENED);
    return window.open(
      `${window.location.origin}/data-dictionary/data/${resultMetadata?.wikiItem?.id}`
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      {(skeleton || resultMetadata?.wikiItem) && (
        <>
          <Tooltip
            title={`View Dataset: ${resultMetadata?.wikiItem?.name} in Data Dictionary`}
            placement="top"
            enterDelay={250}
            PopperProps={{ style: { zIndex: 1501 } }}
          >
            <ActionIconButton onClick={handleOpenWiki} icon={wikiPrimaryIcon} />
          </Tooltip>

          <Tooltip
            title={`View Dataset: ${resultMetadata?.wikiItem?.name} in Data Dictionary (New Tab)`}
            placement="top"
            enterDelay={250}
            PopperProps={{ style: { zIndex: 1501 } }}
          >
            <ActionIconButton
              onClick={handleOpenWikiInNewTab}
              icon={linkPrimaryIcon}
            />
          </Tooltip>
        </>
      )}
      {sourceType === 0 && (
        // Show download button in header only for modeled data
        <>
          {gettingDownloadLink ? (
            <Box
              sx={{
                p: "4px",
              }}
            >
              <CircularProgress size={15} color="primary" />
            </Box>
          ) : (
            <DownloadMenu
              type={type}
              sourceType={sourceType}
              handleDownloadDoc={handleDownloadDoc}
              handleDownloadDocBulk={handleDownloadDocBulk}
              trigger={<ActionIconButton icon={downloadIcon} />}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default SearchResultCardActions;
