import React from "react";
import {
  Box,
  CircularProgress,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CSVPreview from "./Type/DataTablePreview";
import HTMLPreview from "./Type/HTMLPreview";
import JSONPreview from "./Type/JSONPreview";
import PDFPreview from "./Type/PDFPreview";
import RTFPreview from "./Type/RTFPreview";
import TXTPreview from "./Type/TXTPreview";
import EMLPreview from "./Type/EMLPreview";
import {
  PreviewDocListProps,
  PreviewDocProps,
} from "src/modules/search/view/searchViewReducers";
import { Colors } from "src/view/constants/colors";
import IMGPreview from "src/view/search-result/components/Preview/Source/Type/IMGPreview";
import XLSPreview from "./Type/XLSPreview";
import DOCXPreview from "src/view/search-result/components/Preview/Source/Type/DOCXPreview";

export const StyledContainer = styled("div")(() => ({
  width: "100%",
  borderRadius: "6px",
  backgroundColor: "#FFFFFF",
  position: "relative",
}));

export const StyledHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: 12,
  paddingRight: 12,
  marginLeft: 45,
  marginRight: 0,
  marginTop: 16,
  marginBottom: 16,
}));

export const Title = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "17px",
  color: Colors.textDark100,
  fontWeight: "700",
}));

export const Label = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "17px",
  color: Colors.textDark100,
  fontWeight: "700",
  opacity: 0.5,
}));

export const AntTabs = styled(Tabs)({
  paddingLeft: 14,
  overflow: "visible",
  borderBottom: "1px solid #e8e8e8",
  backgroundColor: "rgba(247, 81, 81, 0.14)",
  borderRadius: "8px 8px 0px 0px",
  "& .MuiTabs-indicator": {
    backgroundColor: Colors.secondary,
    height: 3,
  },
  "& .MuiTabs-scroller": {
    overflow: "visible !important",
  },
});

interface StyledTabProps {
  label: React.ReactElement | string;
}
export const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(1),
  color: Colors.textDark,
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: Colors.secondary,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

interface Props {
  data: PreviewDocListProps | unknown[];
  loading: boolean;
  previewDocInfo: PreviewDocProps;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const SourcePreviews = ({
  data,
  loading,
  previewDocInfo,
  handleClosePreview,
}: Props) => {
  switch (previewDocInfo?.type) {
    case "csv":
    case "parquet":
      return (
        <CSVPreview
          data={data as { id: string; content: Record<string, any>[] }[]}
          loading={loading}
          previewDocInfo={previewDocInfo}
          handleClosePreview={handleClosePreview}
        />
      );
    case "xls":
    case "xlsx":
      return (
        <XLSPreview
          data={data as { id: string; content: Record<string, any>[] }[]}
          loading={loading}
          previewDocInfo={previewDocInfo}
          handleClosePreview={handleClosePreview}
        />
      );
    case "doc":
    case "docx":
      return (
        <DOCXPreview
          data={data}
          loading={loading}
          handleClosePreview={handleClosePreview}
        />
      );
    case "htm":
    case "html":
    case "jhtml":
    case "shtml":
      return (
        <HTMLPreview
          data={data}
          loading={loading}
          handleClosePreview={handleClosePreview}
        />
      );
    case "json":
    case "jsonl":
      return <JSONPreview data={data} loading={loading} />;
    case "pdf":
      return (
        <PDFPreview
          data={data}
          loading={loading}
          handleClosePreview={handleClosePreview}
        />
      );
    case "rtf":
      return <RTFPreview data={data} loading={loading} />;
    case "eml":
    case "msg":
      return (
        <EMLPreview
          data={data}
          loading={loading}
          handleClosePreview={handleClosePreview}
        />
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "heic":
      return <IMGPreview data={data} loading={loading} />;
    case "txt":
    default:
      return (
        <TXTPreview
          data={data}
          loading={loading}
          handleClosePreview={handleClosePreview}
        />
      );
  }
};

export const renderContent = (
  data: PreviewDocListProps | unknown[],
  loading: boolean,
  content: React.ReactNode
) => {
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: { xs: "auto", sm: "40vh" },
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      ) : data === null || (Array.isArray(data) && data?.length === 0) ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography color="primary" variant="h5">
            No Records
          </Typography>
        </Box>
      ) : (
        content
      )}
    </>
  );
};

export default SourcePreviews;
