import { DocTypes } from "src/types/Dataset";

export interface SearchResultModelProps {
  modeled: SearchResultResponse;
  source: SearchResultResponse;
}

export interface SearchResultResponse {
  took: number;
  timed_out: boolean;
  _shards: Shards;
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: number;
    hits: Hit<DataIndex>[];
  };
  aggregations: {
    [key: string]: {
      doc_count_error_upper_bound?: number;
      sum_other_doc_count?: number;
      buckets?: {
        key?: string;
        doc_count?: number;
      }[];
    };
  };
  page: number;
  size: number;
  total_counts: number;
  prev_page_number: number | null;
  next_page_number: number | null;
}

export interface Shards {
  total: number;
  successful: number;
  skipped: number;
  failed: number;
}

export enum DataIndex {
  source = "source_data",
  modeled = "modeled_data",
}

export interface Source {
  last_updated?: string;
  name?: string[];
  address?: string[];
  schema?: string;
  legalForm?: string[];
  status?: string[];
  dataset_id: string;
  incorporationDate?: string[];
  doc_type: DocTypes;
}

export interface SourceInterface {
  dataset_id: string;
  doc_type: DocTypes;
  file_name: string;
  ingest_timestamp: string;
}

export interface SourceData extends SourceInterface {
  content: string;
}

export interface ModeledDataInterface {
  [key: string]: string[];
}

export type ModeledData = SourceInterface & ModeledDataInterface;

export type DataIndexToSource = {
  source_data: SourceData;
  modeled_data: ModeledData;
};

export type Highlight = Record<string, string[]>;
export interface Hit<T extends DataIndex> {
  _id: string;
  _index: T;
  highlight?: Highlight;
  _source: DataIndexToSource[T];
  hits?: Hit<DataIndex>[];
}

export type GetDownloadDocumentURLParams = {
  id: string;
  dataIndex?: DataIndex;
  entireFile?: boolean;
};

export type GetDownloadAllDocumentsURLParams = {
  doc_ids: string[];
};

export type GetDocumentPreviewParams = {
  id: string;
  dataIndex?: DataIndex;
  range?: number;
};

export interface SearchHistory {
  id?: string;
  content: string;
  dataset_ids: string[];
  exclude_dataset_ids: string[];
  data_collection_ids: string[];
  dataset_types?: string[];
  countries: string[];
  regions: string[];
  languages: string[];
  doc_types: string[];
  result_count: number;
  result_count_relation: string;
  timestamp: string;
}

export interface SearchHistoryResponse {
  record_count: number;
  search_history_entries: SearchHistory[];
}

type DatasetId = string;

export interface MappedHit {
  id: string;
  dataIndex: DataIndex;
  highlight?: Highlight;
  source: SourceData | ModeledData;
  datasetId: string;
}

export interface MappedSearchResult {
  hits: {
    total: {
      value: number;
      relation: string;
    };
  };
  items: Record<DatasetId, MappedHit[]>;
  // TODO: refactor aggregations
  aggregations: {
    [key: string]: {
      doc_count_error_upper_bound?: number;
      sum_other_doc_count?: number;
      buckets?: {
        key?: string;
        doc_count?: number;
      }[];
    };
  };
  page: number;
  size: number;
  totalCounts: number;
  prevPageNumber: number | null;
  nextPageNumber: number | null;
}
