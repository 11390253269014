import { Box, Container, Grid, GridProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomCard from "src/view/components/Card";
import bulk_search from "src/assets/images/bulk-search-light.svg";
import knowledge_wiki from "src/assets/images/knowledge-wiki-light.svg";
import { Features } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";

const StyledContent = styled(Grid)(({ theme }) => ({
  paddingTop: 25,
  paddingBottom: 24,
  alignItems: "flex-start",
  [theme.breakpoints.up("xs")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: "600",
  fontFamily: "Inter, sans-serif",
}));

const StyledDescriptionContainer = styled(Grid)(() => ({
  width: "100%",
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "400",
  lineHeight: "24px",
  fontSize: "14px",
  fontFamily: "Inter, sans-serif",
}));

const StyledFeaturesContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "block",
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    paddingLeft: 32,
    paddingRight: 32,
  },
  alignItems: "space-between",
  justifyContent: "space-between",
  paddingBottom: 66,
}));

interface StyledFeatureItemProps extends GridProps {
  isFirst: boolean;
}

const StyledFeatureItem = styled(
  ({ isFirst, ...rest }: StyledFeatureItemProps) => <Grid {...rest} />
)(({ theme, isFirst }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "100%",
    marginBottom: isFirst ? "20px" : 0,
  },
  [theme.breakpoints.up("sm")]: {
    width: "48%",
  },
}));

const StyledDecorationLine = styled(Box)(({ theme }) => ({
  width: 148,
  height: 2,
  backgroundColor: theme.palette.red.main,
  margin: "14px 0",
}));

export default function FeaturesSection() {
  const { user } = useUser();

  const features = [
    {
      id: 1,
      title: "Bulk Search",
      description:
        "Query the data lake using screening lists of entities. Monitor and receive notifications for updates when new data is added.",
      image: bulk_search,
      link: user?.features?.includes(Features.BULK_SEARCH)
        ? "/bulk-search"
        : null,
    },
    {
      id: 2,
      title: "Data Dictionary",
      description:
        "View information about each dataset including provenance, sourcing, credibility, and other important details.",
      image: knowledge_wiki,
      link: user?.features?.includes(Features.KNOWLEDGE_WIKI)
        ? "/data-dictionary/data"
        : null,
    },
  ];

  return (
    <Container>
      <StyledContent item>
        <Grid item>
          <StyledHeading variant="h2">Other Features</StyledHeading>
        </Grid>
        <StyledDecorationLine />
        <StyledDescriptionContainer item>
          <StyledDescription variant="inherit">
            An analytical toolkit for investigators to aid in open-source
            research and support ongoing work disrupting transnational illicit
            networks.
          </StyledDescription>
        </StyledDescriptionContainer>
      </StyledContent>
      <StyledFeaturesContainer item>
        {features.map((feature, index) => (
          <StyledFeatureItem isFirst={index === 0} item key={feature.id}>
            <CustomCard
              image={feature.image}
              title={feature.title}
              description={feature.description}
              height={281}
              link={feature.link}
            />
          </StyledFeatureItem>
        ))}
      </StyledFeaturesContainer>
    </Container>
  );
}
