import { ReactNode } from "react";
import { format, isValid } from "date-fns";
import { styled } from "@mui/material/styles";
import { Box, Skeleton, Typography } from "@mui/material";
import DataTypeIcon from "./SearchResultCard/DataTypeIcon";

interface EntityContainerProps {
  type: string;
  title: ReactNode;
  titleActions?: ReactNode;
  subTitle?: ReactNode;
  tags: ReactNode;
  addedOn?: string;
  cardActions?: ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  customIcon?: ReactNode;
}

const StyledCardHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: theme.spacing(2),
  boxSizing: "border-box",
  padding: "8px",
  zIndex: 1,
  backgroundColor: theme.palette.common.white,
  borderTop: "1px solid rgba(0, 0, 0, 0.05)",
  boxShadow: "0px 3px 14px 0px #0000001A",
  borderRadius: "6px",
}));

const TitleContainer = styled(Box)(() => ({
  display: "flex",
  gap: "5px",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "600",
  color: theme.palette.grey[900],
  cursor: "pointer",
  paddingTop: "2px",
}));

const CardHeaderContent = styled(Box)(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const CardHeaderContentInner = styled(Box)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: 0.5,
  fontSize: "10px",
  color: theme.palette.grey[800],
}));

const TagsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  paddingBottom: 8,
});

const AddedOnText = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  fontSize: 10,
  lineHeight: "12px",
  color: theme.palette.grey[700],
  fontWeight: "400",
}));

export const EntityTemplate = ({
  type,
  title,
  titleActions,
  subTitle,
  tags,
  addedOn,
  cardActions,
  isLoading,
  onClick,
  customIcon,
}: EntityContainerProps) => {
  return (
    <StyledCardHeader>
      <Box id="card-header-gutter" sx={{ height: 40, flex: "0 0 40px" }}>
        {isLoading ? (
          <Skeleton variant="rounded" width={40} height={40} />
        ) : (
          <>{customIcon ?? <DataTypeIcon type={type} onClick={onClick} />}</>
        )}
      </Box>
      <CardHeaderContent id="card-header-content">
        <CardHeaderContentInner>
          <Box sx={{ mr: "5px" }} id="card-title-box">
            {isLoading ? (
              <>
                <Skeleton variant="text" width={300} />
                <Skeleton variant="text" width={100} />
              </>
            ) : (
              <>
                <TitleContainer>
                  {typeof title === "string" ? (
                    <StyledTitle onClick={onClick}>{title}</StyledTitle>
                  ) : (
                    title
                  )}
                  {titleActions}
                </TitleContainer>
                {typeof subTitle === "string" ? (
                  <StyledSubtitle>{subTitle}</StyledSubtitle>
                ) : (
                  subTitle
                )}
              </>
            )}
          </Box>
          <Box
            id="card-action-box"
            sx={{ ml: "auto", display: "flex", gap: 1 }}
          >
            {cardActions}
          </Box>
        </CardHeaderContentInner>
        <TagsContainer>
          {tags}
          <Box id="card-date-box" sx={{ textAlign: "right" }}>
            {isValid(new Date(addedOn)) && (
              <AddedOnText variant="body1">
                {`Added on: ${format(new Date(addedOn), "MM/dd/yyyy")}`}
              </AddedOnText>
            )}
          </Box>
        </TagsContainer>
      </CardHeaderContent>
    </StyledCardHeader>
  );
};
