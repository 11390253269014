import { Group, GroupFeatures } from "src/types/UserGroup";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";

import CellWithSwitch, { CellWithSwitchProps } from "./CellWithSwitch";
import DialogEnableLeaked from "./DialogEnableLeaked";

interface Props extends CellWithSwitchProps {
  dataset: KnowledgeWiki;
  handleUpdateFeatures?: (checked: boolean, featureName: GroupFeatures) => void;
  group: Group;
  shouldDisableToggles: boolean;
}

// Decorator for the CellWithSwitch component
const CellDecoratedWithFeatureToggle = ({
  dataset,
  handleUpdateFeatures,
  group,
  shouldDisableToggles,
  ...props
}: Props) => {
  const isLeakedEnabled = group?.features?.includes(
    GroupFeatures.LEAKED_DATASETS
  );

  // if leaked feature disabled and dataset is leaked and not yet enabled
  if (!isLeakedEnabled && dataset?.is_leaked && !props.value) {
    return (
      <DialogEnableLeaked
        description="In order to toggle the selected dataset on, you must first enable the leaked dataset feature for this user group. Click below to enable this feature."
        handleUpdateFeatures={handleUpdateFeatures}
      >
        <CellWithSwitch disabled {...props} />
      </DialogEnableLeaked>
    );
  }

  return <CellWithSwitch disabled={shouldDisableToggles} {...props} />;
};

export default CellDecoratedWithFeatureToggle;
