import { useNavigate } from "react-router-dom";
import { Button, Container, styled } from "@mui/material";
import SearchBox from "./SearchBox";
import { FocusProvider } from "./context/focus";

const BrowseAllData = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: 30,
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: "#CADEF0",
  color: "#0B83F2",
  fontSize: 14,
  fontWeight: "700",
  textTransform: "none",
  height: 36,
  borderRadius: 25,
  paddingLeft: 20,
  paddingRight: 20,
  border: "1px solid #0B83F2",
}));

const Search = () => {
  const navigate = useNavigate();

  const gotoWikiPage = () => {
    navigate("/data-dictionary/data");
  };

  return (
    <Container
      sx={{
        pt: { xs: "16px", sm: "20px", md: "50px" },
        pb: "52px",
      }}
    >
      <FocusProvider>
        <SearchBox />
      </FocusProvider>

      <BrowseAllData>
        <StyledButton
          onClick={() => gotoWikiPage()}
          startIcon={
            <img
              src={require("src/assets/images/book_primary.png")}
              style={{ width: 24, height: 24 }}
              alt=""
            />
          }
        >
          Browse all datasets in the Data Dictionary
        </StyledButton>
      </BrowseAllData>
    </Container>
  );
};

export default Search;
