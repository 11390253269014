import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "src/view/constants/colors";
import actions from "src/modules/bulk-search/query-group/queryGroupActions";
import selector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import { useNavigate } from "react-router-dom";
import { SearchTerm as SearchTermModel } from "src/types/BulkSearch";
import { MAX_RESULT_LIMIT } from "src/utils/constants";
import { formatVariablesSearchParam } from "src/modules/api/search/SearchProvider";

const StyledContainer = styled("div", {
  shouldForwardProp: (prop) => prop != "run",
})<{ run?: boolean }>(({ run }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 6,
  marginBottom: 6,
  ...(run && {
    borderRadius: 8,
    backgroundColor: "rgba(4, 61, 93, 0.05)",
    padding: 12,
  }),
}));

const StyledTerm = styled("div", {
  shouldForwardProp: (prop) => prop != "run",
})<{ run?: boolean }>(({ run }) => ({
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  color: "#5C5C5C",
  width: 180,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  cursor: "pointer",
  ...(run && {
    color: Colors.link1,
    textDecoration: "underline",
  }),
}));
const StyleResultCount = styled("div", {
  shouldForwardProp: (prop) => prop != "run",
})<{ run?: boolean }>(({ run }) => ({
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "400",
  color: Colors.twilight,
  ...(run && {
    fontWeight: "700",
  }),
}));

interface Props {
  data?: SearchTermModel;
  updateSelectedItem?: () => void;
}

const SearchTerm = (props: Props) => {
  const { data, updateSelectedItem } = props;

  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const selectedSearchTerm = useSelector(selector.selectSelectedSearchTerm);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSearchTerm && selectedSearchTerm.id === data.id) {
      setRun(true);
    } else {
      if (run) {
        setRun(false);
      }
    }
  }, [selectedSearchTerm]);

  const handleRun = () => {
    updateSelectedItem();
    dispatch({ type: actions.SELECT_SEARCH_TERM, payload: data });
  };

  const handleDoubleClick = () => {
    navigate(
      `/search?q=${formatVariablesSearchParam({
        content: data.query,
        filters: {
          dataset_ids: [],
          exclude_dataset_ids: [],
          data_collection_ids: [],
          exclude_data_collection_ids: [],
          doc_types: [],
          dataset_types: [],
          regions: [],
          countries: [],
          languages: [],
        },
      })}`
    );
  };

  return (
    <StyledContainer
      run={run}
      onClick={handleRun}
      onDoubleClick={handleDoubleClick}
    >
      <StyledTerm run={run}>{data.query}</StyledTerm>
      <StyleResultCount>
        {data.total_results !== null
          ? data.total_results === MAX_RESULT_LIMIT && data?.relation === "gte"
            ? `+${data.total_results}`
            : data.total_results
          : "-"}
      </StyleResultCount>
    </StyledContainer>
  );
};

export default SearchTerm;
