import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import theme from "src/theme";

import { ReactComponent as TransparentHuman } from "src/assets/icons/transparent-human.svg";

const StyledTitle = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledDescrption = styled(Typography)(() => ({
  color: theme.palette.grey[800],
  fontSize: 14,
  textAlign: "center",
  mt: "8px",
}));

const Wrapper = styled(Box)(() => ({
  padding: "70px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
}));

const EmptyUserList = () => {
  return (
    <Wrapper>
      <TransparentHuman />

      <StyledTitle>Empty User List</StyledTitle>

      <StyledDescrption>
        There are currently no users in this group. Click “Add Users” to start.
      </StyledDescrption>
    </Wrapper>
  );
};

export default EmptyUserList;
