import style from "./LoadingText.module.css";

interface Props {
  text?: string;
}

const LoadingText = ({ text = "Loading" }: Props) => {
  return <p className={style.loading}>{text}</p>;
};

export default LoadingText;
