import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { ScreeningList } from "src/types/BulkSearch";
import BulkSearchPagination from "src/view/bulk-search/QueryGroupListTable/BulkSearchPagination";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import DataGrid from "src/view/components/DataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import actions from "src/modules/bulk-search/screeningList/screeningActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DataCollection } from "src/types/DataCatalog";
import { format } from "date-fns";
import React from "react";
import EditIcon from "@mui/icons-material/EditOutlined";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete.svg";
import { ReactComponent as ShareIcon } from "src/assets/icons/share-line.svg";
import { ConfirmDialog } from "src/components/ui";
import ShareDialog from "src/view/bulk-search/ShareDialog";
import theme from "src/theme";
import { User } from "src/types/UserGroup";
import SearchIcon from "@mui/icons-material/SearchOutlined";

const StyledImage = styled("img")(() => ({
  width: "100%",
  height: 226,
  objectFit: "contain",
}));

const StyledSearchBox = styled("div")(() => ({
  position: "relative",
  height: 40,
  width: "100%",
  maxWidth: 300,
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  gap: 10,
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  position: "absolute",
  left: 12,
  fontSize: 24,
  color: theme.palette.grey[800],
}));

const SearchInput = styled("input")(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  paddingLeft: 42,
  paddingRight: 40,
  fontFamily: "Inter",
  fontSize: 12,
  border: "1px solid rgba(65, 64, 66, 0.16)",
  "&:focus-visible": {
    outline: "none",
  },
}));

interface Props {
  loading?: boolean;
  data?: ScreeningList[];
  tabIndex?: number;
  isMine?: boolean;
}

interface PaginatedScreeningLists {
  size: number;
  total_counts: number;
  pageCount: number;
  items: ScreeningList[];
}

interface Props extends ScreeningList {
  id?: string;
  name?: string;
  description?: string;
  createdAt?: string;
  sharedUsers?: Array<string | User>;
  lastResult?: string;
  tab?: number;
}

const BSTable = ({ loading, data, tabIndex, isMine }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const [activePageIndex, setActivePageIndex] = useState<number>(1);
  const [shareList, setShareList] = useState<ScreeningList | null>();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "last_run",
      sort: "desc",
    },
  ]);
  const [displayData, setDisplayData] = useState<PaginatedScreeningLists>({
    pageCount: 0,
    size: 0,
    total_counts: 0,
    items: [],
  });
  const [searchValue, setSearchValue] = useState<string>("");

  const paginateData = (data: ScreeningList[], pageIndex: number) => {
    const size = 10;
    const offset = size * (pageIndex - 1);
    const pageCount = Math.ceil(data.length / size);
    const paginatedItems = data.slice(offset, offset + size);

    return {
      size,
      total_counts: data.length,
      pageCount,
      items: paginatedItems,
    };
  };

  useEffect(() => {
    if (data) {
      const filteredData = data.filter((list: ScreeningList) =>
        list.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      );
      setDisplayData(paginateData(filteredData, activePageIndex));
    }
  }, [data, tabIndex, searchValue, activePageIndex]);

  const baseColumns: GridColDef[] = [
    {
      field: "name",
      type: "string",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "updated",
      headerName: "Last Updated",
      type: "dateTime",
      sortable: false,
      width: 152,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value) : null,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value ? format(params.value, "MMMM dd, yyyy") : "-";
      },
    },
    {
      field: "last_run",
      headerName: "Last Run",
      type: "dateTime",
      width: 225,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value) : null,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value ? format(params.value, "MMMM dd, yyyy") : "-";
      },
    },
  ];

  const actionColumn: GridColDef = {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    width: 232,
    getActions: ({ row }: GridRowParams<DataCollection>) => {
      const actions = [];
      if (isMine) {
        actions.push(
          <GridActionsCellItem
            label="Edit"
            icon={<EditIcon color="primary" />}
            onClick={() => goToEdit(row)}
          />
        );
      }
      actions.push(
        <GridActionsCellItem
          label="Share"
          icon={
            <ShareIcon
              color={theme.palette.primary.main}
              height={20}
              width={20}
            />
          }
          onClick={() => setShareList(row)}
        />,
        <ConfirmDialog
          title="Delete Screening List"
          description="Are you sure you want to delete this screening list? This action cannot be undone."
          onConfirm={() => handleDelete(row)}
          confirmText="Confirm"
          cancelText="Cancel"
        >
          <GridActionsCellItem
            label="Delete"
            icon={
              <DeleteIcon
                color={theme.palette.primary.main}
                height={20}
                width={20}
              />
            }
          />
        </ConfirmDialog>
      );

      return actions;
    },
  };

  const columns: GridColDef[] = [...baseColumns, actionColumn];

  const NoRowsOverlayComponent = () => (
    <Box
      sx={{
        height: 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledImage
        src={require("src/assets/images/not-found.png")}
        alt="Not Found"
      ></StyledImage>
      <Typography variant="h5" color="primary.light" textAlign="center">
        No Screening Lists Found
      </Typography>
    </Box>
  );

  const getDetailLocationState = (item: ScreeningList): Props => ({
    id: item.id,
    name: item.name,
    description: item.description,
    createdAt: item.created,
    sharedUsers: item.shared_users,
    lastResult: item.last_result_at,
    tab: tabIndex,
  });

  const goToEdit = (screeningList: ScreeningList) => {
    const payload = getDetailLocationState(screeningList);
    dispatch({
      type: actions.EDIT,
      payload,
    });
    navigate(`query-group/${screeningList.id}`, {
      state: { data: payload },
      replace: true,
    });
  };

  const handleDelete = (screeningList: ScreeningList) => {
    dispatch(actions.delete(screeningList.id));
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Box sx={{ mt: "10px", mb: 2, position: "relative" }}>
      <Box sx={{ width: "100%", minHeight: 600 }}>
        <StyledSearchBox>
          <StyledSearchIcon />
          <SearchInput
            placeholder={"Search screening lists"}
            value={searchValue}
            onChange={onSearch}
          />
        </StyledSearchBox>

        <AutoSizer>
          {({ width, height }) => (
            <Box sx={{ width: width, height: height }}>
              <DataGrid
                apiRef={apiRef}
                rows={displayData.items}
                onRowClick={({ row }) => goToEdit(row)}
                columns={columns}
                loading={loading}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                    },
                  },
                }}
                slots={{
                  noRowsOverlay: NoRowsOverlayComponent,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                  },
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                hideFooter
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            </Box>
          )}
        </AutoSizer>
      </Box>

      <Box
        sx={{
          marginTop: 4,
        }}
      >
        <BulkSearchPagination
          {...displayData}
          activePageIndex={activePageIndex}
          setActivePageIndex={setActivePageIndex}
        />
      </Box>

      <ShareDialog
        open={!!shareList}
        onClose={() => setShareList(null)}
        title={`Share '${shareList?.name}' with groups`}
        data={shareList}
      />
    </Box>
  );
};

export default BSTable;
