import { useState, SetStateAction, Dispatch } from "react";
import Dropzone from "react-dropzone";
import Papa from "papaparse";
import {
  Box,
  BoxProps,
  CircularProgress,
  Typography,
  styled,
} from "@mui/material";

import theme from "src/theme";
import { User } from "src/types/UserGroup";
import { snackbar } from "src/view/toaster";
import { ReactComponent as UploadIcon } from "src/assets/icons/upload_bracket.svg";
import { UserFields } from "./types";
import SelectedFile from "./SelectedFile";

const StyledWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  cursor: "pointer",
  gap: 24,
}));

const DropInputPlaceholder = styled(Box)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  "& p": {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "400",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  "& a": {
    fontSize: "12px",
    lineHeight: "18px",
    color: theme.palette.primary.main,
  },
}));

interface StyledDropZoneContentProps extends BoxProps {
  isError?: boolean;
  isParsing?: boolean;
}

const StyledDropZoneContent = styled(Box, {
  shouldForwardProp: (prop) =>
    !["isError", "isParsing"].includes(prop as string),
})<StyledDropZoneContentProps>(({ isError, isParsing, theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: 180,
  border: "1px dashed",
  borderColor:
    isError && isParsing === false
      ? theme.palette.secondary.main
      : theme.palette.grey[400],
  borderRadius: 6,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "30px",
}));

interface Props {
  setParsedCSVUsers: Dispatch<SetStateAction<UserFields[] | []>>;
}

const ImportUserFromSCV = ({ setParsedCSVUsers }: Props) => {
  const [file, setFile] = useState<File | undefined>();
  const [isParsing, setIsParsing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean | undefined>();

  const handleDrop = async (files: File[]) => {
    if (!files.length) return;
    const file = files[0];
    const postData = new FormData();
    postData.append("file", file);

    setIsParsing(true);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: { data: Array<User> }) {
        const rowsArray: string[][] = [];
        const valuesArray: string[][] = [];

        // Iterating data to get column name and their values
        results.data?.map((d: User) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        const emailRows = rowsArray[0].filter(
          (item: string) => item.toLowerCase() === "email"
        );
        if (emailRows.length === 0) {
          setIsError(true);
          snackbar.error("Error: No 'email' column found in the file.");
          setIsParsing(false);
        } else {
          // Parsed Data Response in array format
          const users: UserFields[] = [];
          for (let i = 0; i < results.data.length; i++) {
            const user = results.data[i];
            const _user: UserFields = {
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
            };
            users.push(_user);
          }
          setParsedCSVUsers(users);
          setIsParsing(false);
          setFile(file);
          if (isError) setIsError(false);
        }
      },
    });
  };

  const handleClearFile = (): SetStateAction<void> => {
    setFile(null);
    setIsError(false);
    setParsedCSVUsers([]);
  };

  return (
    <StyledWrapper>
      <Dropzone
        maxFiles={1}
        accept={{ "text/plain": [".csv"] }}
        onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <StyledDropZoneContent isError={isError} isParsing={isParsing}>
            <div
              style={{ width: "100%", height: "100%", display: "flex" }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <DropInputPlaceholder>
                {isParsing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <UploadIcon
                    style={{
                      width: 22,
                      height: 22,
                      color: isError
                        ? theme.palette.secondary.main
                        : theme.palette.grey[600],
                    }}
                  />
                )}

                {isError ? (
                  <Typography>
                    The file may not be formatted correctly.
                    <br />
                    Please make use of the&nbsp;
                    <a
                      href={window.location.origin + "/template.csv"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      template
                    </a>
                  </Typography>
                ) : (
                  <Typography>
                    Click to upload files or drag and drop them here.
                    <br />
                    <a
                      href={window.location.origin + "/template.csv"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Download CVS Template
                    </a>
                  </Typography>
                )}
              </DropInputPlaceholder>
            </div>
          </StyledDropZoneContent>
        )}
      </Dropzone>

      {file && <SelectedFile file={file} onClear={handleClearFile} />}
    </StyledWrapper>
  );
};

export default ImportUserFromSCV;
