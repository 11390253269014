import React, { useCallback, useRef, MouseEvent, CSSProperties } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import AutoScalingInput from "./AutoScalingInput";
import { getQueryColor } from "./utils";
import { useFocus } from "./context/focus";
import { QueryItem } from "src/types/BulkSearch";

const StyledQueryBox = styled(Box, {
  shouldForwardProp: (prop) =>
    !["isSelected", "queryB", "index"].includes(prop.toString()),
})<{
  isSelected: boolean;
  queryB: QueryItem[];
  index: number;
}>(({ theme, isSelected, queryB, index }) => {
  const previousItem: QueryItem | undefined = queryB[index - 1];
  const nextItem: QueryItem | undefined = queryB[index + 1];
  const hasLeftParenthesis =
    previousItem?.type === "operator" && previousItem?.value === "(";
  const hasRightParenthesis =
    nextItem?.type === "operator" && nextItem?.value === ")";

  return {
    display: "flex",
    alignItems: "center",
    width: "auto",
    backgroundColor: getQueryColor({ isSelected, queryB, index }),
    fontSize: "16px",
    fontWeight: 600,
    padding: "5px 4px",
    marginLeft: hasLeftParenthesis ? 0 : theme.spacing(0.5),
    marginRight: hasRightParenthesis ? 0 : theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor:
        queryB.length > 1 ? theme.palette.grey[300] : "transparent",
    },
  };
});

const FuzzinessInfo = styled(Box)(() => ({
  minWidth: "10px",
  fontSize: "16px",
  fontWeight: 600,
}));

interface Props {
  index: number;
  query: QueryItem;
  queryB: QueryItem[];
  onClick?: (index: number) => void;
  onChangeInput: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  onRemovePreviousQuery: (index: number) => void;
  inputStyles: CSSProperties;
}

const QueryBox = React.memo(
  ({
    index,
    query,
    queryB,
    onChangeInput,
    onClick,
    onRemovePreviousQuery,
    inputStyles,
  }: Props) => {
    const { focusedQuery } = useFocus();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleFocusInput = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick && onClick(index);
    };

    return (
      <StyledQueryBox
        index={index}
        isSelected={focusedQuery.index === index}
        queryB={queryB}
        onClick={handleClick}
        aria-label={`Query box ${index}`}
      >
        <AutoScalingInput
          ref={inputRef}
          value={query.value}
          index={index}
          onFocus={handleFocusInput}
          isQuote={query.quote}
          onChange={(e) => onChangeInput(e, index)}
          queryB={queryB}
          onRemovePreviousQuery={onRemovePreviousQuery}
          inputStyles={inputStyles}
        />
        {query.fuzziness > 0 && (
          <FuzzinessInfo
            sx={inputStyles}
          >{`~${query.fuzziness}`}</FuzzinessInfo>
        )}
      </StyledQueryBox>
    );
  }
);

export default QueryBox;
