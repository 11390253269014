import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

// Helper function to calculate expiration status and time differences
export const getExpirationStatus = (renewalDate: Date, currentDate: Date) => {
  const differenceInDaysCount = differenceInDays(renewalDate, currentDate);
  const differenceInHoursCount = differenceInHours(renewalDate, currentDate);
  const differenceInMinutesCount = differenceInMinutes(
    renewalDate,
    currentDate
  );

  // Check if the renewal date has passed, including same-day expiration
  const isExpired =
    differenceInDaysCount < 0 ||
    (differenceInDaysCount === 0 && differenceInHoursCount < 0) ||
    (differenceInDaysCount === 0 &&
      differenceInHoursCount === 0 &&
      differenceInMinutesCount < 0);

  const isUpcomingExpiration =
    differenceInDaysCount <= 7 && differenceInDaysCount >= 0;

  let expirationMessage = "";
  if (isExpired) {
    expirationMessage = "This user’s account is expired.";
  } else if (differenceInDaysCount === 1) {
    expirationMessage =
      "This user’s account will expire in <strong>1</strong> day.";
  } else if (differenceInDaysCount > 1) {
    expirationMessage = `This user’s account will expire in <strong>${differenceInDaysCount}</strong> days.`;
  } else if (Math.abs(differenceInHoursCount) === 1) {
    expirationMessage = `This user’s account will expire in <strong>1</strong> hour.`;
  } else if (
    Math.abs(differenceInHoursCount) < 24 &&
    Math.abs(differenceInHoursCount) > 1
  ) {
    expirationMessage = `This user’s account will expire in <strong>${Math.abs(
      differenceInHoursCount
    )}</strong> hours.`;
  } else if (Math.abs(differenceInMinutesCount) === 1) {
    expirationMessage = `This user’s account will expire in <strong>1</strong> minute.`;
  } else {
    expirationMessage = `This user’s account will expire in <strong>${Math.abs(
      differenceInMinutesCount
    )}</strong> minutes.`;
  }

  return {
    isExpired,
    isUpcomingExpiration,
    expirationMessage,
  };
};
