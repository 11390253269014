import { Box, IconButton, styled } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { format } from "date-fns";

import { ConfirmDialog } from "src/components/ui";
import { User } from "src/types/UserGroup";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete.svg";
import { getFullName } from "src/utils";
import UserGroupMemberColumn from "src/view/users-groups/users/UsersTable/UserGroupMemberColumn";
import DataGrid from "src/view/components/DataGrid";

const StyledIcon = styled(IconButton)(({ theme }) => ({
  height: "100%",
  color: theme.palette.primary.main,

  "& svg": {
    width: "20px",
    height: "20px",
  },
}));

type Props = {
  users: User[];
  isLoading: boolean;
  deleteUserAsync: (id: string) => void;
};

const Table = ({ users, isLoading, deleteUserAsync }: Props) => {
  const apiRef = useGridApiRef();

  const columns: GridColDef<User>[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: ({ row }) => getFullName(row),
    },
    {
      field: "user_groups",
      headerName: "Member Of",
      flex: 1,
      renderCell: ({ row }) => (
        <UserGroupMemberColumn user_groups={row.user_groups} />
      ),
    },
    {
      field: "date_joined",
      headerName: "Created On",
      flex: 1,

      valueGetter: ({ row }) =>
        row.date_joined
          ? format(new Date(row.date_joined), "MMM dd, yyyy")
          : "",
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box display="flex" alignItems="center">
          <ConfirmDialog
            title="Delete User"
            description="Are you sure you want to delete this user? This action cannot be undone."
            confirmText="Confirm"
            cancelText="Cancel"
            onConfirm={() => deleteUserAsync(row.id)}
          >
            <StyledIcon size="small">
              <DeleteIcon color="primary" />
            </StyledIcon>
          </ConfirmDialog>
        </Box>
      ),
    },
  ];

  return (
    <DataGrid
      apiRef={apiRef}
      rows={users}
      columns={columns}
      loading={isLoading}
      hideFooter
      disableRowSelectionOnClick
      disableColumnMenu
      sx={{
        ".MuiDataGrid-columnHeaders": {
          fontSize: "12px",
          borderBottom: "none",
          ".MuiDataGrid-columnHeaderTitleContainerContent": {
            mr: 1,
          },
        },
        ".MuiDataGrid-row": {
          color: "#122945",
        },
        ".MuiDataGrid-virtualScroller": {
          overflow: "visible",
        },
        ".MuiDataGrid-main": {
          overflow: "visible",
        },
        ".MuiDataGrid-cell": {
          overflow: "visible !important",
        },
      }}
    />
  );
};

export default Table;
