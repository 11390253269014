// Helper function to update the selected date with an object parameter
interface Props {
  yearAdjustment?: number;
  monthAdjustment?: number;
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date | null;
}

export const updateSelectedDate = ({
  yearAdjustment = 0,
  monthAdjustment = 0,
  selectedDate,
  onChange,
  minDate,
}: Props) => {
  if (selectedDate) {
    const updatedDate = new Date(
      selectedDate.getFullYear() + yearAdjustment,
      selectedDate.getMonth() + monthAdjustment,
      1 // Always set the day to the first of the month
    );

    if (minDate) {
      // Check if the minDate is not exactly 1 day before the newDate
      const isOneDayLess =
        updatedDate.getTime() - minDate.getTime() === 86400000; // 86400000ms = 1 day

      if (!isOneDayLess && updatedDate < minDate) {
        // If not one day less and newDate is less than minDate, set it to minDate
        onChange(minDate);
        return;
      }
    }

    onChange(updatedDate);
  }
};
