import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Typography, styled } from "@mui/material";
import { Popover } from "../Popover";
import { CustomInput } from "../Input";
import { Checkbox } from "../Checkbox";
import { SelectedValue } from "./tools";
import { ReactComponent as SelectDownIcon } from "src/assets/icons/select_down.svg";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";
import { selectSizes } from "./constants";
import { SelectSize } from "./types";

interface Option<T> {
  label: ReactNode;
  value: T;
}

interface AutocompleteSelectProps<T> {
  options: Option<T>[];
  value?: T[];
  mode?: "auto" | "manual";
  multiple?: boolean;
  onChange?: (value: T[]) => void;
  searchQuery?: string;
  onSearchQuery?: (query: string) => void;
  placeholder?: string;
  invalid?: boolean;
  className?: string;
  contentClassName?: string;
  inputPlaceholder?: string;
  footer?: ReactNode;
  noResults?: ReactNode;
  selectSize?: SelectSize;
  checked?: boolean;
  hideSearch?: boolean;
}

const RelativeBox = styled(Box)({
  position: "relative",
  width: "100%",
});

const SelectButton = styled("button", {
  shouldForwardProp: (prop) =>
    !["invalid", "filled", "focused", "size"].includes(prop.toString()),
})<{
  filled: boolean;
  focused: boolean;
  invalid: boolean;
  size: SelectSize;
}>(({ theme, invalid, filled, focused, size }) => {
  let borderColor = theme.palette.grey["400"];

  if (invalid) {
    borderColor = theme.palette.secondary.main;
  } else if (filled || focused) {
    borderColor = theme.palette.primary.main;
  }

  return {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    fontSize: "10px",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: borderColor,
    background: "none",
    width: "100%",
    cursor: "pointer",
    marginBottom: "10px",
    outline: "none",
    ...selectSizes[size].select,
  };
});

const OptionsContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  padding: "12px",
});

const Options = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxHeight: "240px",
  height: "100%",
  minWidth: "240px",
});

const OptionItem = styled(Box, {
  shouldForwardProp: (prop) =>
    !["selected", "focused", "size", "checked"].includes(prop.toString()),
})<{
  selected?: boolean;
  focused?: boolean;
  checked?: boolean;
  size: SelectSize;
}>(({ theme, selected, focused, size, checked }) => {
  let background = "";
  if (focused) {
    background = theme.palette.grey["200"];
  } else if (selected && !checked) {
    background = theme.palette.grey["400"];
  }

  const optionSizes: Record<SelectSize, CSSProperties> = {
    big: {
      padding: "8px",
    },
    medium: {
      padding: "6px",
    },
    small: {
      padding: "4px",
    },
  };

  return {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    borderRadius: "8px",
    outline: "none",
    background,
    ...optionSizes[size],
    "&:hover": {
      background: theme.palette.grey["100"],
    },
    "&:active": {
      background: theme.palette.grey["500"],
    },
  };
});

const OptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "12px",
}));

const DropdownIcon = styled(SelectDownIcon, {
  shouldForwardProp: (prop) => !["open"].includes(prop.toString()),
})<{ open: string }>(({ open }) => ({
  width: "18px",
  height: "18px",
  transform: open === "true" ? "rotate(180deg)" : "rotate(0deg)",
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.grey["600"],
}));

const OPTION_CLASSNAME = "option";
const OPTION_SIZES = {
  big: 36,
  medium: 32,
  small: 28,
};

export const AutocompleteSelect = <T,>({
  multiple,
  options,
  value: propValue,
  onChange: propOnChange,
  searchQuery: propSearchQuery,
  onSearchQuery,
  mode = "auto",
  placeholder,
  invalid,
  className,
  contentClassName,
  inputPlaceholder,
  footer,
  noResults,
  selectSize = "medium",
  checked = true,
  hideSearch = false,
}: AutocompleteSelectProps<T>) => {
  const optionsRef = useRef<HTMLDivElement>();
  const [value, setValue] = useState<T[]>(propValue ?? []);
  const [searchQuery, setSearchQuery] = useState(propSearchQuery);
  const [open, setOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [optionsVisible, setOptionsVisible] = useState(false);

  useEffect(() => setValue(propValue ?? value), [propValue]);

  const onChangeInput = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    onSearchQuery?.(inputValue);
    setFocusedIndex(0);
  };

  const preparedOptions = useMemo(() => {
    if (mode === "auto") {
      if (!searchQuery) {
        return options;
      }

      const preparedSearchQuery = searchQuery.toLowerCase();

      return options.filter((option) => {
        const label = option.label as string;

        return label.toLowerCase().includes(preparedSearchQuery);
      });
    }

    return options;
  }, [options, mode, searchQuery]);

  const onSelect = (selectedOption: Option<T>) => {
    return () => {
      setFocusedIndex(
        preparedOptions.findIndex(
          (option) => option.value === selectedOption.value
        )
      );
      if (multiple) {
        const newValue = value.includes(selectedOption.value)
          ? value.filter((item) => item !== selectedOption.value)
          : value.concat(selectedOption.value);
        propOnChange?.(newValue);
        setValue(newValue);
      } else {
        const newValue =
          selectedOption.value === value ? [] : [selectedOption.value];
        propOnChange?.(newValue);
        setValue(newValue);
      }
    };
  };

  useEffect(() => {
    const optionsContainer = optionsRef.current;
    if (!optionsContainer) {
      return;
    }

    const getOptionElement = (index: number) => {
      return optionsContainer.querySelector(
        `.${OPTION_CLASSNAME}[data-index="${OPTION_CLASSNAME}-${index}"]`
      );
    };
    const onKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        setFocusedIndex((prev) => {
          let newIndex = prev - 1;
          newIndex = newIndex < 0 ? preparedOptions.length - 1 : newIndex;
          const element = getOptionElement(newIndex);
          element?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
          return element ? newIndex : prev;
        });
      } else if (e.key === "ArrowDown") {
        setFocusedIndex((prev) => {
          let newIndex = prev + 1;
          newIndex = newIndex > preparedOptions.length - 1 ? 0 : newIndex;
          const element = getOptionElement(newIndex);
          element?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
          return element ? newIndex : prev;
        });
      } else if (e.key === "Enter") {
        onSelect(preparedOptions[focusedIndex])();
        if (!multiple) {
          onClose();
        }
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [preparedOptions, focusedIndex, value, multiple, optionsVisible]);

  const togglePopover = () => setOpen(!open);

  const onClose = () => {
    setOpen(false);
    setOptionsVisible(false);
  };

  return (
    <RelativeBox className={className}>
      <Popover
        trigger={
          <SelectButton
            size={selectSize}
            focused={open}
            filled={value.length > 0}
            invalid={!!invalid}
            onClick={togglePopover}
          >
            <SelectedValue
              value={(multiple ? value : value[0]) as any}
              getLabel={(option) => option.label}
              multiple={!!multiple}
              options={options}
              placeholder={placeholder}
            />
            <DropdownIcon open={String(open)} />
          </SelectButton>
        }
        onClose={onClose}
      >
        <OptionsContent className={contentClassName}>
          {!hideSearch && (
            <CustomInput.Input
              autoFocus
              placeholder={inputPlaceholder}
              prefixIcon={<StyledSearchIcon />}
              onChange={onChangeInput}
              containerStyle={{ padding: "6px" }}
            />
          )}
          <Options
            style={{
              height: preparedOptions.length
                ? preparedOptions.length * OPTION_SIZES[selectSize]
                : "",
            }}
            ref={(optionsElement) => {
              optionsRef.current = optionsElement as HTMLDivElement;
              setOptionsVisible(true);
            }}
          >
            {preparedOptions.length === 0 &&
              (noResults ?? (
                <OptionItem tabIndex={-1} size={selectSize}>
                  <OptionLabel>No Results</OptionLabel>
                </OptionItem>
              ))}
            <AutoSizer
              style={{ display: preparedOptions.length === 0 ? "none" : "" }}
            >
              {({ height, width }) => (
                <FixedSizeList
                  width={width}
                  height={height}
                  itemSize={OPTION_SIZES[selectSize]}
                  itemCount={preparedOptions.length}
                  overscanCount={10}
                >
                  {({ index, style }) => {
                    const option = preparedOptions[index];
                    return (
                      <OptionItem
                        style={style}
                        tabIndex={0}
                        checked={checked}
                        size={selectSize}
                        key={`${String(option.value)}-${index}`}
                        onClick={onSelect(option)}
                        focused={focusedIndex === index}
                        selected={value.includes(option.value)}
                        className={OPTION_CLASSNAME}
                        data-index={`${OPTION_CLASSNAME}-${index}`}
                      >
                        {checked && (
                          <Checkbox
                            tabIndex={-1}
                            checked={value.includes(option.value)}
                          />
                        )}
                        <OptionLabel>{option.label}</OptionLabel>
                      </OptionItem>
                    );
                  }}
                </FixedSizeList>
              )}
            </AutoSizer>
            {footer}
          </Options>
        </OptionsContent>
      </Popover>
    </RelativeBox>
  );
};
