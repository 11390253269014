import { formatInit, formatUrl } from "./utils";
import { AuthToken } from "../../auth/authToken";
import { accessTokenExpired } from "../../../utils/response";

export function useFetch() {
  const fetchApi = async (
    path: string | string[],
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    options?: {
      searchParams?: string;
      initParams?: RequestInit;
      // extracts error message from API responses
      // to be passed into onError via thrown Error
      getAPIErrorMessage?: (response: Response) => string | Promise<string>;
    },
    accessTokenRefreshed?: boolean
  ): Promise<any> => {
    const { searchParams, initParams, getAPIErrorMessage } = options;

    const url = formatUrl(path, searchParams);
    const init = formatInit(method, initParams);
    const req = fetch(url, init);
    const res = await req;

    if (res.status == 401) {
      if (accessTokenRefreshed || !AuthToken.getShouldRemember()) {
        AuthToken.logout(true);
        return;
      } else {
        const accessTokenIsExpired = await accessTokenExpired(res);

        if (accessTokenIsExpired) {
          if (!AuthToken.getShouldRemember()) {
            AuthToken.logout(true);
            return;
          }

          const tokenRefreshed = await AuthToken.refreshAccessToken();
          if (!tokenRefreshed) {
            AuthToken.logout(true);
          } else {
            await AuthToken.updateCSRFToken();

            return fetchApi(path, method, options, true);
          }
        }
      }
    }

    if (res.status === 403) {
      const responseData = await res.json();
      if (
        responseData.detail &&
        typeof responseData.detail === "string" &&
        responseData.detail.includes("CSRF Failed")
      ) {
        AuthToken.logout();
        return fetchApi(path, method, options, true);
      }
    }

    const successResponse = res.ok && res.status >= 200 && res.status <= 299;
    if (!successResponse) {
      let errorMsg = `${res.status} Error: ${res.statusText}`;
      if (typeof getAPIErrorMessage === "function") {
        errorMsg = await getAPIErrorMessage(res);
      }
      throw new Error(`${res.status}|${errorMsg}`);
    }

    if (method !== "GET") {
      // ReactQuery useQuery (GET) hooks expect a Promise with data
      return req;
    }

    return res.json();
  };

  const getApi = async (
    path: string | string[],
    searchParams?: string,
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string | Promise<any>
  ) => {
    return fetchApi(path, "GET", {
      ...init,
      searchParams,
      getAPIErrorMessage,
    });
  };

  const postApi = async (
    path: string | string[],
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string | Promise<any>,
    searchParams?: string
  ) => {
    return fetchApi(path, "POST", {
      initParams: init,
      searchParams,
      getAPIErrorMessage,
    });
  };

  const putApi = async (
    path: string | string[],
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string | Promise<any>
  ) => {
    return fetchApi(path, "PUT", {
      initParams: init,
      getAPIErrorMessage,
    });
  };

  const deleteApi = async (
    path: string | string[],
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string | Promise<any>
  ) => {
    return fetchApi(path, "DELETE", {
      initParams: init,
      getAPIErrorMessage,
    });
  };

  return {
    fetchApi,
    getApi,
    postApi,
    putApi,
    deleteApi,
  };
}
