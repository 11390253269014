import { styled } from "@mui/material/styles";
import { Container, Grid, Typography, Box } from "@mui/material";
import Search from "src/view/components/Search";

const StyledBanner = styled(Box)(() => ({
  position: "relative",
  backgroundImage: `url(${"/images/landing-bg.png"})`,
  backgroundSize: "contain",
  backgroundPosition: "50vw -20px",
  backgroundRepeat: "no-repeat",
  backgroundColor: "gradient(255,255,255,0)",
}));

const RootContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  background: `linear-gradient(transparent, ${theme.palette.mist.lighter})`,
}));

const StyledHeadingContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: 140,
    paddingLeft: 20,
    paddingRight: 20,
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: 160,
    paddingLeft: 48,
    paddingRight: 48,
  },
  paddingBottom: 8,
  alignItems: "flex-start",
}));

const StyledHeadingLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: 24,
    lineHeight: "29px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 32,
    lineHeight: "39px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 42,
    lineHeight: "51.5px",
  },
  fontWeight: "700",
  color: theme.palette.mist.dark,
}));

const StyledHeadingDescription = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: 12,
    lineHeight: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 15,
    lineHeight: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 18,
    lineHeight: "26px",
  },
  fontWeight: "400",
  color: theme.palette.twilight.dark,
}));

const StyledDescriptionLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 15,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 18,
  },
  color: theme.palette.red.main,
  fontWeight: "700",
}));

const LandingBanner = () => {
  return (
    <RootContainer>
      <StyledBanner>
        <Container>
          <StyledHeadingContainer container>
            <Grid item>
              <StyledHeadingLabel variant="h1">
                C4ADS Analyst Dashboard
              </StyledHeadingLabel>
            </Grid>
            <Grid item>
              <StyledHeadingDescription variant="inherit">
                A premier{" "}
                <StyledDescriptionLabel variant="caption">
                  search and discovery
                </StyledDescriptionLabel>{" "}
                platform for C4ADS&apos; repository of{" "}
                <StyledDescriptionLabel variant="caption">
                  investigative data.
                </StyledDescriptionLabel>
              </StyledHeadingDescription>
            </Grid>
          </StyledHeadingContainer>
          <Search />
        </Container>
      </StyledBanner>
    </RootContainer>
  );
};

export default LandingBanner;
