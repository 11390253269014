// Filters the months based on the selected year and the range defined by minDate and maxDate
import { getMonth, getYear } from "date-fns";

export const filterMonths = (
  date: Date,
  months: string[],
  minDate?: Date,
  maxDate?: Date
): string[] => {
  const currentYear = getYear(date);

  // Year matches both minDate and maxDate
  if (
    minDate &&
    currentYear === getYear(minDate) &&
    maxDate &&
    currentYear === getYear(maxDate)
  ) {
    return months.slice(getMonth(minDate), getMonth(maxDate) + 1);
  }

  // Year matches minDate only
  if (minDate && currentYear === getYear(minDate)) {
    return months.slice(getMonth(minDate));
  }

  // Year matches maxDate only
  if (maxDate && currentYear === getYear(maxDate)) {
    return months.slice(0, getMonth(maxDate) + 1);
  }

  // Default case where the year doesn't match minDate or maxDate
  return months;
};
