import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as ImgIconSearchHistory } from "src/assets/icons/search_history.svg";
import { SearchHistory } from "src/types/Search";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Dictionary, get, groupBy, has } from "lodash";
import { useFilterOptions } from "src/modules/api/filters";
import { useUser } from "src/modules/api/auth";
import { useSearchHistory } from "src/modules/api/searchHistory";
import SearchHistoryItem from "src/view/search-result/components/SearchHistory/SearchHistoryItem";
import { ConfirmDialog } from "src/components/ui";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledHeaderWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "26px 36px 14px",
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontWeight: 700,
  fontSize: 14,
  padding: "0 0 0 6px",
}));

const StyledClearAllButton = styled(Button)(() => ({
  color: "#536478",
  fontSize: 12,
  fontWeight: 400,
  whiteSpace: "nowrap",
  padding: "6px",
}));

const StyledCloseButton = styled(IconButton)(() => ({
  padding: 4,
  marginRight: -4,
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  width: 686,
  overflowX: "hidden",
  minHeight: 360,
  padding: "0 30px 0 36px",
}));

const StyledSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

const StyledDivider = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  "& span": {
    color: theme.palette.grey[800],
    fontSize: 12,
    fontWeight: 400,
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  "& hr": {
    flex: "1 1 100%",
    color: theme.palette.grey[400],
  },
}));

interface Props {
  isSearchHistoryOpen: boolean;
  onClosePopup: () => void;
  handleSearch: (data: SearchHistory) => void;
}

const SearchHistoryPopup = ({
  isSearchHistoryOpen,
  onClosePopup,
  handleSearch,
}: Props) => {
  const posthog = usePostHog();
  const { user } = useUser();
  const { getSearchHistory, clearSearchHistory } = useSearchHistory();
  const { data } = getSearchHistory;
  const { mutateAsync: clear } = clearSearchHistory;

  const { datasets, data_collections } = useFilterOptions();
  const timeIntervals = ["Today", "Yesterday", "Past 7 days", "Older"];

  const recordsByTimeIntervals: Dictionary<SearchHistory[]> = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const pastWeek = new Date(today);
    pastWeek.setDate(yesterday.getDate() - 7);
    const dates = [
      today.toISOString(),
      yesterday.toISOString(),
      pastWeek.toISOString(),
      "",
    ];

    let dateCount = 0;
    data.search_history_entries.forEach((row) => {
      while (dateCount < 3 && row.timestamp < dates[dateCount]) {
        dateCount += 1;
      }
      row["timebucket"] = timeIntervals[dateCount];
    });

    return groupBy(data.search_history_entries, "timebucket");
  }, [data]);

  return (
    <Dialog
      open={isSearchHistoryOpen}
      onClose={onClosePopup}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          paddingRight: "4px",
        },
      }}
      maxWidth={false}
      sx={{ zIndex: 1505, maxHeight: "75vh", margin: "auto" }}
    >
      <StyledHeaderWrapper>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ImgIconSearchHistory height={20} width={20} />
          <StyledDialogTitle id="scroll-dialog">
            Recent Searches
          </StyledDialogTitle>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ConfirmDialog
            title="Clear Recent Searches"
            description={"Are you sure you want to clear your recent searches?"}
            onConfirm={() => {
              clear();
              posthog.capture(HogEvent.CLEAR_SEARCH_HISTORY);
            }}
            confirmText="Confirm"
            cancelText="Cancel"
          >
            <StyledClearAllButton variant="text">
              Clear All
            </StyledClearAllButton>
          </ConfirmDialog>

          <StyledCloseButton aria-label="close" onClick={onClosePopup}>
            <CloseIcon color="primary" />
          </StyledCloseButton>
        </Box>
      </StyledHeaderWrapper>
      <StyledDialogContent dividers={false} className="styled-scrollbars">
        {data.record_count > 0 ? (
          timeIntervals.map(
            (timeInterval, index) =>
              has(recordsByTimeIntervals, timeInterval) && (
                <Box key={timeInterval + index} sx={{ gap: 3 }}>
                  <StyledDivider>
                    <Typography component="span">{timeInterval}</Typography>
                    <Divider />
                  </StyledDivider>
                  <StyledSectionWrapper>
                    {get(recordsByTimeIntervals, timeInterval).map(
                      (item: SearchHistory) => {
                        // check if the user still has access to the datasets and collections from the item's search filters
                        // TODO: deleted items are not sent along, so we just get empty filters
                        const isAvailable = item.dataset_ids.every(
                          (id) =>
                            datasets.some((dataset) => dataset.value === id) &&
                            item.data_collection_ids.every((id) =>
                              data_collections.some(
                                (collection) => collection.value === id
                              )
                            )
                        );

                        return (
                          <SearchHistoryItem
                            key={item.id}
                            isAvailable={isAvailable}
                            handleSearch={handleSearch}
                            item={item}
                          />
                        );
                      }
                    )}
                  </StyledSectionWrapper>
                </Box>
              )
          )
        ) : (
          <Typography textAlign="center">
            {user?.search_history_opted_in ? (
              "No recent searches"
            ) : (
              <>
                This feature is currently disabled. You can enable it within
                your{" "}
                <Link to="/profile" onClick={() => onClosePopup()}>
                  account settings page
                </Link>
                .
              </>
            )}
          </Typography>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};
export default SearchHistoryPopup;
