import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { InfiniteData } from "@tanstack/react-query";
import {
  AggregationCountsKeys,
  DataCollectionFilterOption,
  FilterKeys,
  FilterOption,
} from "src/types/SearchFilter";
import { MappedSearchResult } from "src/types/Search";
import { Features, Role } from "src/types/Auth";
import { chain, flatMap } from "lodash";
import { useUser } from "src/modules/api/auth";
import { useSearch } from "src/modules/api/search";
import { useFilterOptions } from "src/modules/api/filters";
import { Button, IconButton } from "src/components/ui";
import { parseVariablesFromSearchParams } from "src/modules/api/search/utils";
import searchStoreSelectors from "src/modules/api/search/store/selectors";
import { SortButton, SortType } from "./SortButton";
import FilterTabs from "./FilterTabs";
import DataCollectionFilter from "./DataCollectionFilter";
import { FilterOptionRow } from "./FilterOptionRow";

const StyledFilterBox = styled("div")(() => ({
  width: 370,
  height: "100%",
  borderRight: "solid 1px #A0B9D0",
  display: "flex",
  flexDirection: "column",
}));

const StyledFilterHeader = styled(Box)(() => ({
  height: 64,
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
}));

const StyledFilterActions = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginRight: 16,
}));

const StyledFilterBody = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 14,
    fontFamily: "Inter",
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
}));

const ListWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.75),
  padding: theme.spacing(1),
  boxSizing: "border-box",
}));

const AutoSizerWrapper = styled(Box)({
  flex: 1,
});

const IsLoadingDatasetsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[400],
}));

const CloseDrawerButton = styled(IconButton)({
  borderRadius: "50%",
});

interface FiltersProps {
  disableCounts?: boolean;
  handleClose: () => void;
}

export default function Filters({
  handleClose,
  disableCounts = false,
}: FiltersProps) {
  const { user } = useUser();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, handleSearch, resetFilters, updateFilters } = useSearch();
  const variables = useSelector(searchStoreSelectors.selectVariables);
  const unappliedFilters = useSelector(
    searchStoreSelectors.selectUnappliedFilters
  );

  const { isLoading, getFilterOptionsKey, ...filterOptions } =
    useFilterOptions();

  const [sort, setSort] = useState<Partial<Record<FilterKeys, SortType>>>({});
  const [activeFilterKey, setActiveFilterKey] = useState<FilterKeys>(
    FilterKeys.DATASET_IDS
  );
  const [optionFilter, setOptionFilter] = useState<string>("");

  const enableDataCollectionFilter = useMemo(() => {
    if (!user) {
      return false;
    }

    if (activeFilterKey !== FilterKeys.DATASET_IDS) {
      return false;
    }

    if (user.role === Role.ADMIN) {
      return true;
    }

    if (user.features.includes(Features.DATA_COLLECTION)) {
      return true;
    }
  }, [user, activeFilterKey]);

  const isSelected = useCallback(
    (o: FilterOption) => {
      const activeFilters =
        parseVariablesFromSearchParams(searchParams).filters;
      const selectedDataCollectionIds = flatMap([
        activeFilters.data_collection_ids,
        activeFilters.exclude_data_collection_ids,
      ]);
      const implicitSelectedDatasetIds = flatMap(
        filterOptions.data_collections.filter(({ value }) =>
          selectedDataCollectionIds.includes(value)
        ),
        ({ dataset_ids }) => dataset_ids
      );

      const selectedValues = [
        ...flatMap(activeFilters),
        ...implicitSelectedDatasetIds,
      ];

      return selectedValues.includes(o.value);
    },
    [searchParams, filterOptions]
  );

  const itemData = useMemo(() => {
    const aggregations = getAggregations(data, activeFilterKey);

    const sortBy = sort[activeFilterKey] ?? "numeric";
    const options = chain(filterOptions[getFilterOptionsKey(activeFilterKey)])
      .filter(
        (o: FilterOption) =>
          o.label.toLowerCase().includes(optionFilter.toLowerCase()) ||
          !optionFilter
      )
      .map((o: FilterOption) => ({
        ...o,
        count:
          (!disableCounts &&
            aggregations.find(({ key }) => key === o.value || key === o.label)
              ?.doc_count) ||
          0,
        isSelected: isSelected(o),
      }))
      .orderBy(
        [sortBy === "alphabetic" ? "label" : "count"],
        [sortBy === "alphabetic" ? "asc" : "desc"]
      )
      .value();

    const excludable = activeFilterKey === FilterKeys.DATASET_IDS;
    let areAnyChecked = variables.filters[activeFilterKey].length > 0;

    const implicitDatasetIds = flatMap(
      filterOptions.data_collections.filter(
        ({ value }: DataCollectionFilterOption) =>
          variables.filters.data_collection_ids.includes(value)
      ),
      ({ dataset_ids }) => dataset_ids
    );

    const implicitExcludeDatasetIds = flatMap(
      filterOptions.data_collections.filter(
        ({ value }: DataCollectionFilterOption) =>
          variables.filters.exclude_data_collection_ids.includes(value)
      ),
      ({ dataset_ids }) => dataset_ids
    );

    if (excludable) {
      areAnyChecked = areAnyChecked || implicitDatasetIds.length > 0;
    }

    return {
      options,
      filters: variables.filters,
      filterKey: activeFilterKey,
      updateFilters,
      excludable,
      areAnyChecked,
      implicitDatasetIds,
      implicitExcludeDatasetIds,
    };
  }, [
    filterOptions,
    activeFilterKey,
    optionFilter,
    variables,
    updateFilters,
    data,
    disableCounts,
  ]);

  const handleActiveFilterKey = (key: FilterKeys) => {
    setActiveFilterKey(key);
    setOptionFilter("");
  };

  const onSort = (key: FilterKeys) => {
    return (newSort: SortType) => {
      setSort((prev) => ({ ...prev, [key]: newSort }));
    };
  };

  return (
    <StyledFilterBox sx={{ top: "64px" }}>
      <StyledFilterHeader>
        <CloseDrawerButton
          btnSize="big"
          btnVariant="text"
          onClick={handleClose}
        >
          <ChevronLeft />
        </CloseDrawerButton>
        <StyledFilterActions>
          <Button
            sx={{
              textDecoration: "underline",
            }}
            btnSize="small+"
            btnVariant="text"
            onClick={() => resetFilters()}
            disableRipple
          >
            Reset filters
          </Button>
          {unappliedFilters && (
            <Button
              btnVariant="primary"
              btnSize="small+"
              onClick={() => handleSearch()}
            >
              Apply
            </Button>
          )}
        </StyledFilterActions>
      </StyledFilterHeader>
      <StyledFilterBody>
        <FilterTabs
          selectedTab={activeFilterKey}
          setSelectedTab={handleActiveFilterKey}
          filters={variables.filters}
        />
        <ListWrapper>
          <StyledTextField
            size="small"
            placeholder="Search..."
            value={optionFilter}
            onChange={(e) => setOptionFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: -0.25 }}>
                  <SortButton
                    sort={sort[activeFilterKey]}
                    onSort={onSort(activeFilterKey)}
                  />
                </InputAdornment>
              ),
            }}
          />
          {enableDataCollectionFilter && (
            <DataCollectionFilter options={filterOptions.data_collections} />
          )}
          <AutoSizerWrapper>
            <AutoSizer>
              {({ height, width }) => {
                return isLoading ? (
                  <IsLoadingDatasetsContainer
                    sx={{
                      height,
                      width,
                    }}
                  >
                    <CircularProgress color="inherit" size={28} />
                    <Typography variant="caption">
                      Fetching datasets...
                    </Typography>
                  </IsLoadingDatasetsContainer>
                ) : (
                  <FixedSizeList
                    width={width}
                    height={height}
                    itemSize={45}
                    itemData={itemData}
                    itemCount={itemData.options.length}
                    className="filters-list"
                  >
                    {FilterOptionRow}
                  </FixedSizeList>
                );
              }}
            </AutoSizer>
          </AutoSizerWrapper>
        </ListWrapper>
      </StyledFilterBody>
    </StyledFilterBox>
  );
}

function getAggregations(
  data: InfiniteData<MappedSearchResult>,
  activeFilterKey: FilterKeys
) {
  let aggregations: { key?: string; doc_count?: number }[] = [];
  if (activeFilterKey === FilterKeys.DOC_TYPES) {
    const modeled_aggregations =
      data?.pages[0]?.aggregations["schema_counts"]?.buckets || [];
    const source_aggregations =
      data?.pages[0]?.aggregations["doc_type_counts"]?.buckets || [];
    aggregations = [...modeled_aggregations, ...source_aggregations];
  } else {
    const aggregationKey = AggregationCountsKeys[activeFilterKey];
    aggregations = data?.pages[0]?.aggregations[aggregationKey]?.buckets || [];
  }
  return aggregations;
}
