import { Card, CardContent, Typography, IconButton, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const ImageContainer = styled("div")(() => ({
  width: "100%",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  backgroundColor: Colors.twilight,
  height: "281px",
  position: "relative",
}));

const LinkContainer = styled("div")(() => ({
  width: 35,
  height: 35,
}));

interface Props {
  image?: string;
  imageStyle?: React.CSSProperties;
  title?: string;
  description?: string;
  height?: number;
  link?: string;
}

export default function CustomCard(props: Props) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: "100%",
        borderRadius: "8px",
        border: "1px solid #C6D0D7",
      }}
    >
      <ImageContainer>
        <img
          src={props.image}
          style={
            props.imageStyle
              ? props.imageStyle
              : { objectFit: "contain", width: 266, height: 195 }
          }
        />
      </ImageContainer>
      <CardContent sx={{ borderTop: `3px solid ${Colors.secondary}` }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="caption"
            color={Colors.textDark100}
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "22px",
              fontFamily: "Inter, sans-serif",
            }}
          >
            {props.title}
          </Typography>
          {props.link && (
            <LinkContainer>
              <IconButton
                size="large"
                edge="start"
                aria-label="open drawer"
                sx={{
                  mr: 0,
                  bgcolor: Colors.secondary,
                  width: 35,
                  height: 35,
                  color: "white",
                  "&:hover": {
                    bgcolor: Colors.secondary,
                  },
                }}
                onClick={() => {
                  if (props.link.includes("http")) {
                    window.open(props.link, "_blank");
                  } else {
                    navigate(props.link);
                  }
                }}
              >
                <ArrowForwardIcon fontSize="small" />
              </IconButton>
            </LinkContainer>
          )}
        </Box>
        <Box sx={{ height: 11 }}></Box>
        <Typography
          variant="body2"
          color={Colors.textGray500}
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            letterSpacing: "0.01em",
            lineHeight: "24px",
            fontFamily: "Inter, sans-serif",
          }}
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
