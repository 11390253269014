import {
  Alert,
  Box,
  CircularProgress,
  SvgIconProps,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MyTextField from "../components/MyTextField";
import { Button } from "../../components/ui";
import actions from "src/modules/auth/actions";

const StyledPasswordPage = styled(Box)(() => ({
  width: "100%",
  marginBottom: 32,
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontFamily: "Inter",
  lineHeight: "24px",
  fontWeight: "600",
  color: theme.palette.primary.main,
  marginTop: 24,
  marginBottom: 32,
}));

const StyledResetPasswordForm = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  marginBottom: 32,
}));

const StyledErrorContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  marginBottom: 16,
}));

const StyledInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 24,
  [theme.breakpoints.down("sm")]: {
    alignItems: "start",
    flexDirection: "column",
    gap: 14,
  },
}));

const StyledLabelsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1",
  gap: 8,
}));

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  flex: "2 1",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledPasswordLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: "Inter",
  lineHeight: "14.52px",
  fontWeight: "600",
  color: theme.palette.primary.main,
}));

const StyledPasswordDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: "Inter",
  lineHeight: "14.52px",
  fontWeight: "400",
  color: theme.palette.primary.main,
}));

interface FormIconProps extends SvgIconProps {
  isVisible: boolean;
}

const FormIcon = styled(({ isVisible, ...rest }: FormIconProps) =>
  isVisible ? (
    <VisibilityOffOutlinedIcon {...rest} fontSize="medium" color="primary" />
  ) : (
    <VisibilityOutlinedIcon {...rest} fontSize="medium" color="primary" />
  )
)(() => ({
  width: 20,
  height: 20,
  cursor: "pointer",
}));

const PasswordManagement = () => {
  const [visible, setVisible] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [message, setMessage] = useState({
    error: "",
    warning: "",
    success: "",
  });
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();

  const changeVisible = (type: string) => {
    const _visi = { ...visible };
    _visi[type] = !_visi[type];
    setVisible(_visi);
  };

  const handleChangeFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _fd = { ...formData };
    _fd[e.target.name] = e.target.value;
    setFormData(_fd);
  };

  const handleUpdateUserPassword = async () => {
    const msg = { ...message };
    if (!formData.current_password) {
      msg.error = "Please enter your old password.";
      msg.warning = "";
      setMessage(msg);
      return;
    }
    if (!formData.new_password) {
      msg.warning = "Please enter your new password.";
      msg.error = "";
      setMessage(msg);
      return;
    }
    if (!formData.confirm_password) {
      msg.warning = "Please enter your confirm password.";
      msg.error = "";
      setMessage(msg);
      return;
    }
    if (formData.confirm_password != formData.new_password) {
      msg.warning = "These two passwords don't currently match.";
      msg.error = "";
      setMessage(msg);
      return;
    }
    msg.warning = "";
    msg.error = "";
    setMessage(msg);
    setUpdating(true);
    const res = await dispatch(actions.updatePasswords(formData));

    if (!res?.error) {
      setUpdating(false);
      msg.warning = "";
      msg.error = "";
      msg.success = "Your New Password was updated successfully.";
      const _fd = { ...formData };
      _fd.current_password = "";
      _fd.new_password = "";
      _fd.confirm_password = "";
      setFormData(_fd);
      setMessage(msg);
      setTimeout(() => {
        msg.success = "";
        setMessage(msg);
      }, 3000);
    } else {
      msg.error = res.detail || "Something went wrong!";
      setMessage(msg);
      setUpdating(false);
    }
  };

  const textFieldStyles = {
    containerStyle: { maxWidth: 386, minWidth: 100 },
    inputContainerStyle: {
      justifyContent: "space-between",
      paddingLeft: 0,
      paddingRight: "12px",
    },
    textFieldStyle: {
      width: "100%",
    },
    inputStyle: {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
      height: "auto",
    },
  };

  return (
    <StyledPasswordPage>
      <StyledHeader variant="h3">Change Password</StyledHeader>
      {message.error || message.warning || message.success ? (
        <StyledErrorContainer>
          {message.error && <Alert severity="error">{message.error}</Alert>}
          {message.warning && (
            <Alert severity="warning">{message.warning}</Alert>
          )}
          {message.success && (
            <Alert severity="success">{message.success}</Alert>
          )}
        </StyledErrorContainer>
      ) : null}
      <StyledResetPasswordForm>
        <StyledInputContainer>
          <StyledLabelsContainer>
            <StyledPasswordLabel variant="h4">
              Current Password
            </StyledPasswordLabel>
            <StyledPasswordDescription variant="caption">
              Enter your currently used password first.
            </StyledPasswordDescription>
          </StyledLabelsContainer>
          <StyledTextFieldContainer>
            <MyTextField
              name="current_password"
              value={formData.current_password}
              type={visible.old ? "text" : "password"}
              suffixIcon={
                <FormIcon
                  isVisible={visible.old}
                  onClick={() => changeVisible("old")}
                />
              }
              containerStyle={{ ...textFieldStyles.containerStyle }}
              inputContainerStyle={{ ...textFieldStyles.inputContainerStyle }}
              inputStyle={{ ...textFieldStyles.inputStyle }}
              textFieldStyle={{ ...textFieldStyles.textFieldStyle }}
              onChange={handleChangeFormData}
            />
          </StyledTextFieldContainer>
        </StyledInputContainer>

        <StyledInputContainer>
          <StyledLabelsContainer>
            <StyledPasswordLabel variant="h4">New Password</StyledPasswordLabel>
            <StyledPasswordDescription variant="caption">
              Add your new password here.
            </StyledPasswordDescription>
          </StyledLabelsContainer>
          <StyledTextFieldContainer>
            <MyTextField
              name="new_password"
              value={formData.new_password}
              type={visible.new ? "text" : "password"}
              suffixIcon={
                <FormIcon
                  isVisible={visible.new}
                  onClick={() => changeVisible("new")}
                />
              }
              containerStyle={{ ...textFieldStyles.containerStyle }}
              inputContainerStyle={{ ...textFieldStyles.inputContainerStyle }}
              inputStyle={{ ...textFieldStyles.inputStyle }}
              textFieldStyle={{ ...textFieldStyles.textFieldStyle }}
              onChange={handleChangeFormData}
            />
          </StyledTextFieldContainer>
        </StyledInputContainer>

        <StyledInputContainer>
          <StyledLabelsContainer>
            <StyledPasswordLabel variant="h4">
              Confirm Password
            </StyledPasswordLabel>
            <StyledPasswordDescription variant="caption">
              Let’s make sure it’s really the password you want.
            </StyledPasswordDescription>
          </StyledLabelsContainer>
          <StyledTextFieldContainer>
            <MyTextField
              name="confirm_password"
              value={formData.confirm_password}
              type={visible.confirm ? "text" : "password"}
              suffixIcon={
                <FormIcon
                  isVisible={visible.confirm}
                  onClick={() => changeVisible("confirm")}
                />
              }
              containerStyle={{ ...textFieldStyles.containerStyle }}
              inputContainerStyle={{ ...textFieldStyles.inputContainerStyle }}
              inputStyle={{ ...textFieldStyles.inputStyle }}
              textFieldStyle={{ ...textFieldStyles.textFieldStyle }}
              onChange={handleChangeFormData}
            />
          </StyledTextFieldContainer>
        </StyledInputContainer>
      </StyledResetPasswordForm>
      <Button
        btnVariant="secondary"
        btnSize="medium"
        onClick={handleUpdateUserPassword}
        startIcon={
          updating ? <CircularProgress size={20} color={"inherit"} /> : null
        }
      >
        Update
      </Button>
    </StyledPasswordPage>
  );
};

export default PasswordManagement;
