import React from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import theme from "src/theme";

type Placement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

interface Props extends TooltipProps {
  children: React.ReactElement;
  stylesTooltip?: React.CSSProperties;
  stylesArrow?: React.CSSProperties;
  zIndex?: number;
  disableHoverListener?: boolean;
  placement?: Placement;
}

export const Tooltip = ({
  title,
  children,
  placement = "bottom-start",
  enterDelay = 500,
  enterNextDelay = 500,
  stylesTooltip = {},
  stylesArrow = {},
  zIndex = 1000,
  arrow = true,
  disableHoverListener = false,
  ...props
}: Props) => {
  const defaultTooltipStyles: React.CSSProperties = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "6px",
    padding: "8px 12px",
    zIndex,
    ...stylesTooltip,
  };

  const defaultArrowStyles: React.CSSProperties = {
    color: theme.palette.primary.main,
    ...stylesArrow,
  };

  return (
    <MuiTooltip
      arrow={arrow}
      title={title}
      placement={placement}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      disableHoverListener={disableHoverListener}
      PopperProps={{
        sx: { zIndex },
      }}
      componentsProps={{
        tooltip: {
          style: defaultTooltipStyles,
        },
        ...(arrow && {
          arrow: {
            style: defaultArrowStyles,
          },
        }),
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};
