import SearchHeader from "../layout/SearchHeader";
import Profile from "./Profile";

const UserProfile = () => {
  return (
    <div>
      <SearchHeader />
      <Profile />
    </div>
  );
};

export default UserProfile;
