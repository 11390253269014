import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import DataTypeIcon from "./DataTypeIcon";
import { Alert, Collapse, Typography } from "@mui/material";
import { Colors } from "src/view/constants/colors";
import "react-json-pretty/themes/monikai.css";

const StyledCard = styled(Box)(() => ({
  backgroundColor: "white",
  boxShadow: "0px 0px 54px rgba(160, 185, 208, 0.3)",
  borderRadius: 4,
  padding: 20,
  marginBottom: 20,
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: "700",
  color: Colors.twilight,
  cursor: "pointer",
}));

const StyledMetaDataContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: 6,
  marginBottom: 6,
}));

const StyledLabel = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "700",
  color: Colors.textGray200,
  textTransform: "capitalize",
  width: 220,
}));

const StyledValue = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "700",
  color: Colors.twilight,
}));

const NoResultCard = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickMoreButton = () => {
    setOpen(!open);
  };

  return (
    <StyledCard className="search_result_card_item">
      <Box sx={{ width: 80, height: 80 }}>
        <DataTypeIcon type={"no_result"} />
      </Box>
      <Box sx={{ width: 20 }} />
      <Box sx={{ width: "calc(100% - 100px)" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "column",
            // mb: 1,
          }}
        >
          <Box sx={{ width: 20 }} />
          <Box sx={{ width: "calc(100%)" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 1,
              }}
            >
              <StyledTitle>No Results</StyledTitle>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                mt: "30px",
                mb: "-46px",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: 10,
                    lineHeight: "12px",
                    color: "#7A8796",
                    fontWeight: "400",
                  }}
                >
                  Added on: 00/00/0000
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
                mt: 2,
                position: "relative",
                overflow: "hidden",
                flexWrap: "wrap",
              }}
            >
              <StyledMetaDataContainer
                className={"metadata_tag"}
                style={{
                  border: "none",
                  borderRadius: 4,
                  padding: "6px 12px",
                  background: "#F0F1F3",
                }}
              >
                <StyledLabel sx={{ width: "max-content", fontSize: 10 }}>
                  Jurisdiction
                </StyledLabel>
                <StyledLabel style={{ width: 5, fontSize: 10, marginLeft: 2 }}>
                  {" "}
                  :
                </StyledLabel>
                <StyledValue
                  sx={{
                    width: "max-content",
                    maxWidth: { xs: "300px", sm: "500px", md: "700px" },
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontSize: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  000000
                </StyledValue>
              </StyledMetaDataContainer>
              <StyledMetaDataContainer
                className={"metadata_tag"}
                style={{
                  border: "none",
                  borderRadius: 4,
                  padding: "6px 12px",
                  background: "#F0F1F3",
                }}
              >
                <StyledLabel sx={{ width: "max-content", fontSize: 10 }}>
                  Capital
                </StyledLabel>
                <StyledLabel style={{ width: 5, fontSize: 10, marginLeft: 2 }}>
                  {" "}
                  :
                </StyledLabel>
                <StyledValue
                  sx={{
                    width: "max-content",
                    maxWidth: { xs: "300px", sm: "500px", md: "700px" },
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontSize: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  000000
                </StyledValue>
              </StyledMetaDataContainer>
              <StyledMetaDataContainer
                className={"metadata_tag"}
                style={{
                  border: "none",
                  borderRadius: 4,
                  padding: "6px 12px",
                  background: "#F0F1F3",
                }}
              >
                <StyledLabel sx={{ width: "max-content", fontSize: 10 }}>
                  Name
                </StyledLabel>
                <StyledLabel style={{ width: 5, fontSize: 10, marginLeft: 2 }}>
                  {" "}
                  :
                </StyledLabel>
                <StyledValue
                  sx={{
                    width: "max-content",
                    maxWidth: { xs: "300px", sm: "500px", md: "700px" },
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontSize: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  000000
                </StyledValue>
              </StyledMetaDataContainer>
              <StyledMetaDataContainer
                style={{
                  border: "none",
                  borderRadius: 4,
                  padding: "6px 12px",
                  cursor: "pointer",
                  background: "#F0F1F3",
                }}
                onClick={handleClickMoreButton}
              >
                {open ? (
                  <StyledLabel sx={{ width: "max-content", fontSize: 10 }}>
                    Less
                    <img
                      src={require("src/assets/images/arrow-up.png")}
                      style={{ width: 16, height: 16 }}
                    />
                  </StyledLabel>
                ) : (
                  <StyledLabel sx={{ width: "max-content", fontSize: 10 }}>
                    More
                    <img
                      src={require("src/assets/images/arrow-down.png")}
                      style={{ width: 16, height: 16 }}
                    />
                  </StyledLabel>
                )}
              </StyledMetaDataContainer>
            </Box>
            <Collapse in={open}>
              <Alert severity="info" sx={{ mt: 2 }}>
                No results were found for your search. Try expanding your
                search, or contact us if you continue to get no results
              </Alert>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default NoResultCard;
