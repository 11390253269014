import React, { CSSProperties } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FuzzyButton from "./FuzzyButton";
import { SearchOperatorsInfo } from "src/view/constants/searchOperatorsInfo";
import StyledButton from "./StyledButton";
import SearchTooltip from "./SearchTooltip";
import { QueryItem } from "src/types/BulkSearch";
import { useFocus } from "./context/focus";

const StyledSearchDropdown = styled("div")<{
  showDropdown: boolean;
}>(({ showDropdown }) => ({
  opacity: showDropdown ? 1 : 0,
  visibility: showDropdown ? "visible" : "hidden",
  transform: showDropdown ? "translateY(0)" : "translateY(-10px)",
  transition: showDropdown
    ? `opacity 300ms ease-in-out, max-height 300ms ease-in-out, visibility 0s linear 0ms, transform 300ms ease-in-out`
    : `visibility 0s linear 299ms, transform 300ms ease-in-out, max-height 300ms ease-in-out, opacity 300ms ease-in-out`,
  position: "absolute",
  marginLeft: "24px",
  zIndex: 1,
  maxHeight: showDropdown ? "200px" : "0",
}));

const QueryBuilder = styled(Paper)(({ theme }) => ({
  position: "relative",
  borderRadius: "0 0 4px 4px",
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.primary.contrastText,
  marginTop: -1,
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
  height: "auto",
  display: "block",
  alignItems: "flex-start",
  boxShadow: "none",
  padding: theme.spacing(2, 1, 2, 3),
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchPanel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
}));

const FuzzinessPanel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginRight: "12px",
}));

const Label = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "400",
  color: "#1B4962",
  marginRight: "12px",
}));

const OperatorsPanel = styled(Box)(() => ({
  display: "flex",
  gap: "12px",
  borderLeft: `1px solid #D0DCE8`,
  borderRight: `1px solid #D0DCE8`,
  padding: "6px 12px",
  width: "auto",
}));

const AccentedText = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.light,
}));

const ExampleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "16px",
  color: theme.palette.grey[600],
}));

interface Props {
  queryB: QueryItem[];
  handleChangeFuzziness: (fuzziness: number) => void;
  onAddOperator: (operator: string) => void;
  customStyles: CSSProperties;
  showDropdown: boolean;
  openedTipsModalEl: HTMLDivElement;
  onOpenTipsModalEl: (event: React.MouseEvent<HTMLElement>) => void;
  onCloseTipsModalEl: () => void;
}
const SearchDropdown = ({
  queryB,
  handleChangeFuzziness,
  onAddOperator,
  customStyles = {},
  showDropdown,
  openedTipsModalEl,
  onOpenTipsModalEl,
  onCloseTipsModalEl,
}: Props) => {
  const { focusedQuery } = useFocus();
  return (
    <StyledSearchDropdown
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      sx={customStyles}
      showDropdown={showDropdown}
    >
      <QueryBuilder>
        <SearchPanel>
          <FuzzinessPanel>
            <Label>Fuzziness:</Label>
            <FuzzyButton
              key={`fuzzy-button-id-${
                queryB[focusedQuery.index]?.["fuzziness"]
              }`}
              text={queryB[focusedQuery.index]?.["fuzziness"]}
              handleChangeFuzziness={handleChangeFuzziness}
              disabled={
                !queryB[focusedQuery.index]?.["value"] ||
                queryB[focusedQuery.index]?.type === "operator" ||
                !!openedTipsModalEl
              }
            />
          </FuzzinessPanel>
          <OperatorsPanel>
            {SearchOperatorsInfo.map((item) => (
              <StyledButton
                key={item.operator}
                sx={{
                  fontSize: 12,
                  minWidth: 24,
                }}
                text={item.operator}
                onClick={() => onAddOperator(item.operator)}
              />
            ))}
          </OperatorsPanel>
          <SearchTooltip
            openedEl={openedTipsModalEl}
            onOpen={onOpenTipsModalEl}
            onClose={onCloseTipsModalEl}
          />
        </SearchPanel>
        <ExampleTypography>
          Example: &#34;World&#34; <AccentedText>AND</AccentedText>{" "}
          &#34;Beyond&#34; <AccentedText>AND</AccentedText> (&#34;New&#34;{" "}
          <AccentedText>OR</AccentedText> &#34;10/12/2022&#34;)
        </ExampleTypography>
      </QueryBuilder>
    </StyledSearchDropdown>
  );
};

export default SearchDropdown;
