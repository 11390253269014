import { snackbar } from "src/view/toaster";
import Box from "@mui/material/Box";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import {
  renderContent,
  StyledContainer,
  StyledHeader,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDocument } from "src/hooks/useDocument";
import { renderAsync } from "docx-preview";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { CircularProgress, Divider, styled } from "@mui/material";
import ControlBar from "src/view/search-result/components/Preview/Source/ControlBar";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import { DocTypes } from "src/types/Dataset";
import { createPhraseRegex } from "src/utils/regex";
import { getPlainTextFromHighlight } from "src/utils/get-highlight-text";
import theme from "src/theme";

interface Props {
  data: PreviewDocListProps | any[];
  loading: boolean;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const ContentWrapper = styled("div", {
  shouldForwardProp: (prop) => prop != "split" && prop != "font",
})<{ split?: boolean; font?: number }>(({ split, font }) => ({
  // using !important a lot to override lib's baked-in inline styles
  // respect my authoritah!

  padding: theme.spacing(4),
  overflow: "auto",
  "& .docx-wrapper": {
    padding: 0,
    background: "unset",
    ...(split && {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      columnGap: "6px",
      alignItems: "flex-start",
    }),
  },
  "& .docx": {
    width: "100% !important",
    padding: "62px 90px !important",
    ...(split && {
      padding: "50px 64px !important",
    }),
    boxShadow: "none !important",
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    fontSize: `${font}pt !important`,
  },
}));

export const StyledTextStingWrap = styled("div", {
  shouldForwardProp: (prop) => prop !== "font",
})<{ font: number }>(({ font }) => ({
  fontSize: `${font}pt`,
  lineHeight: `${font * 1.5}pt`,
  whiteSpace: "pre-wrap",
}));

const DocPreview = ({ data, loading, handleClosePreview }: Props) => {
  const { previewDocInfo, page, split, font, setNumPages, originalPage } =
    useContext(PreviewContext);
  const { state } = useLocation();
  const [pageNodeList, setPageNodeList] = useState<NodeListOf<Element>>();
  const [renderError, setRenderError] = useState("");
  const { documentURL, isLoading, error } = useDocument(previewDocInfo);

  const highlightText = useCallback((container: HTMLElement) => {
    if (!state?.highlightContent) return;

    const walker = document.createTreeWalker(
      container,
      NodeFilter.SHOW_TEXT, // show text nodes
      null
    );

    const sentencesToHighlight: string = getPlainTextFromHighlight(
      state.highlightContent.content
    );

    const textNodes = [];
    let node;
    while ((node = walker.nextNode())) {
      textNodes.push(node);
    }

    textNodes.forEach((node) => {
      const originalText = node.nodeValue.trim();

      const regex = new RegExp(`${sentencesToHighlight}`, "gi");

      if (regex.test(originalText)) {
        const replacedHTML = originalText.replaceAll(
          regex,
          `<mark>${sentencesToHighlight}</mark>`
        );
        const parent = node.parentNode;
        if (parent) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = replacedHTML;

          while (tempDiv.firstChild) {
            parent.insertBefore(tempDiv.firstChild, node);
          }
          parent.removeChild(node);
        }
      }
    });
  }, []);

  const highlightPlainText = useCallback((text: string) => {
    if (!state?.highlightContent) return text;

    let updatedText = text;

    state.highlightContent.content.forEach((sentence: string) => {
      const plainSentence = sentence
        .match(/<\/em>(.*?)<\/em>/g) // find text for highlight
        .join(" ")
        .replace(/<\/?em>/g, ""); // get plain text

      updatedText = updatedText.replace(
        createPhraseRegex(plainSentence),
        `<mark>${plainSentence}</mark>`
      );
    });

    return updatedText;
  }, []);

  useEffect(() => {
    if (previewDocInfo?.type === DocTypes.DOCX && documentURL) {
      (async () => {
        try {
          const f = await fetch(documentURL);
          const ab = await f.arrayBuffer();
          renderAsync(
            ab,
            document.getElementById("doc-preview-container"),
            null
          )
            .then(() => {
              const pages = document.querySelectorAll(".docx");
              const container = document.getElementById(
                "doc-preview-container"
              );
              setPageNodeList(pages);
              setNumPages(pages.length);
              container && highlightText(container);
            })
            .catch(() =>
              setRenderError(
                "Failed to get document. Please try closing and reopening the preview"
              )
            );
        } catch (e) {
          setRenderError(
            "Failed to get document. Please try closing and reopening the preview"
          );
        }
      })();
    }
  }, [data, documentURL, previewDocInfo]);

  useEffect(() => {
    pageNodeList?.[page]?.scrollIntoView();
  }, [page]);

  useEffect(() => {
    if (originalPage) {
      pageNodeList?.[page]?.scrollIntoView();
    }
  }, [originalPage]);

  useEffect(() => {
    if (!error || !renderError) {
      return;
    }
    snackbar.error(renderError || error);
  }, [error, renderError]);

  return (
    <StyledContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <StyledHeader>
        <ControlBar />
      </StyledHeader>

      <Divider />

      {renderContent(
        data,
        loading || isLoading,
        <ContentWrapper split={split} font={font}>
          {/* <Box>/!*  figure out pagination here*!/</Box>*/}

          <HighlightActionMenu onSearchInTab={handleClosePreview}>
            <>
              {/* raw text for unsupported DOC*/}
              {previewDocInfo.type === DocTypes.DOC && (
                <StyledTextStingWrap font={font}>
                  {Array.isArray(data) &&
                    data?.map((row: unknown, index: number) => (
                      <span
                        key={`raw-text-span-${index}`}
                        dangerouslySetInnerHTML={{
                          __html: highlightPlainText(row["content"][index]),
                        }}
                      />
                    ))}
                </StyledTextStingWrap>
              )}

              {previewDocInfo.type === DocTypes.DOCX && (
                <Box id="doc-preview-container">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {(loading || isLoading) && (
                        <>
                          <CircularProgress
                            size={20}
                            sx={{ marginRight: "10px" }}
                          />
                          Loading...
                        </>
                      )}
                      {renderError && <>{renderError}</>}
                    </>
                  </Box>
                </Box>
              )}
            </>
          </HighlightActionMenu>
        </ContentWrapper>
      )}
    </StyledContainer>
  );
};

export default DocPreview;
