import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { TagBoxProps } from "./DatasetHits/DatasetHitsHeader";

export const TagBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expand",
})<TagBoxProps>(({ expand, theme }) => ({
  width: "calc(100% - 80px)",
  overflow: "hidden",
  maxHeight: "28px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  transition: theme.transitions.create(["all"], {
    duration: theme.transitions.duration.standard,
  }),
  ...(expand && {
    maxHeight: "300px",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.standard,
    }),
  }),
}));
