import { useState } from "react";
import { Box, styled } from "@mui/material";
import { CustomInput } from "src/components/ui";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";

type Props = {
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
};

const JobSearchHeader = styled(Box)({
  fontSize: 32,
  fontWeight: 700,
  display: "flex",
  justifyContent: "space-between",
});

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.grey["600"],
  cursor: "pointer",
}));

const JobSearchBar = ({ setSearchKey }: Props) => {
  const [searchInput, setSearchInput] = useState("");
  const handleSearch = () => setSearchKey(searchInput);

  return (
    <JobSearchHeader>
      <CustomInput.Input
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        containerStyle={{
          width: "25%",
          minWidth: 200,
        }}
        placeholder="Search"
        prefixIcon={<StyledSearchIcon onClick={handleSearch} />}
      />
    </JobSearchHeader>
  );
};

export default JobSearchBar;
