import Box from "@mui/material/Box";
import { JsonViewer, defineDataType, DataItemProps } from "@textea/json-viewer";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import {
  StyledContainer,
  StyledHeader,
  Title,
  Label,
  renderContent,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";

interface Props {
  data: PreviewDocListProps | unknown[];
  loading: boolean;
}

const JsonPreview = ({ data, loading }: Props) => {
  const highlightDataType = defineDataType({
    is: (value) => typeof value === "string" && /<[^>]+>/.test(value),
    Component: (props: DataItemProps<any>) => (
      <span
        dangerouslySetInnerHTML={{
          __html: props.value,
        }}
      />
    ),
  });

  return (
    <StyledContainer>
      <StyledHeader>
        <Box sx={{ display: "flex" }}>
          <Title></Title>
          {Array.isArray(data) && <Label>&nbsp;• {data?.length} Records</Label>}
        </Box>
      </StyledHeader>

      {renderContent(
        data,
        loading,
        <Box
          sx={{
            width: "calc(100% - 70px)",
            height: "calc(100vh - 350px)",
            overflowY: "auto",
            display: "block",
            ml: "43px",
            mr: "36px",
            border: "1px solid #EEEEEE",
            borderRadius: "6px",
            padding: 1,
          }}
          aria-label="simple table"
        >
          <JsonViewer
            enableClipboard
            value={data}
            valueTypes={[highlightDataType]}
          />
        </Box>
      )}
    </StyledContainer>
  );
};

export default JsonPreview;
