import React, { useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import { Colors } from "src/view/constants/colors";
import { Group, User } from "src/types/UserGroup";
import { getFullName } from "src/utils";
import { useUsers } from "src/modules/api/users";
import { useToggle } from "usehooks-ts";
import {
  StyledCardContainer,
  CardTitle,
  CardDescription,
  AllAdminsButton,
} from "./StyledComponents";
import GroupAdminsDrawer from "./GroupAdminsDrawer";

type Props = {
  formData: Group;
};

const GroupAdmins = ({ formData }: Props) => {
  const [isGroupAdminsOpen, toggleGroupAdminsOpen] = useToggle();

  const { getUsersByIds } = useUsers();
  const { data: groupAdmins } = getUsersByIds(formData.group_admins);
  const [allAdminsAnchor, setAllAdminsAnchor] = useState<null | HTMLElement>(
    null
  );

  const renderGroupAdmins = (admins: User[]) => {
    if (!groupAdmins?.length) return <>None</>;

    let visibleAdmins = admins;
    const hiddenAdmins = [...admins.slice(1, admins.length)];
    const shouldTruncate = admins.length > 1;

    if (shouldTruncate) {
      visibleAdmins = [...admins].slice(0, 1);
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {visibleAdmins?.map((user) => {
          const name = getFullName(user);
          return (
            <Typography
              key={name}
              sx={{
                fontWeight: "700",
                color: "#122945",
                fontSize: 18,
              }}
            >
              {name}
            </Typography>
          );
        })}
        {shouldTruncate ? (
          <>
            <AllAdminsButton onClick={handleOpenAllAdmins}>
              +{hiddenAdmins.length}
            </AllAdminsButton>

            <Popover
              id="counry-list-menu"
              anchorEl={allAdminsAnchor}
              open={!!allAdminsAnchor}
              onClose={closeAllAdmins}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ color: Colors.primary, mt: "35px" }}
            >
              <Box
                sx={{
                  width: "152px",
                  padding: "10px",
                  fontWeight: 600,
                }}
              >
                {hiddenAdmins.map((admin: User) => (
                  <Box
                    key={admin.id}
                    sx={{ "&:not(:last-child)": { marginBottom: "10px" } }}
                  >
                    {getFullName(admin)}
                  </Box>
                ))}
              </Box>
            </Popover>
          </>
        ) : null}
      </Box>
    );
  };

  const handleOpenAllAdmins = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAllAdminsAnchor(event.currentTarget);
  };

  const closeAllAdmins = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAllAdminsAnchor(null);
  };

  return (
    <>
      <StyledCardContainer onClick={toggleGroupAdminsOpen}>
        <Box sx={{ display: "flex" }}>
          <img
            src={require("src/assets/images/admin.png")}
            style={{ width: 18, height: 18 }}
            alt="admin"
          />
          <CardTitle>Group Admins</CardTitle>
        </Box>
        <CardDescription>{renderGroupAdmins(groupAdmins)}</CardDescription>
      </StyledCardContainer>

      <GroupAdminsDrawer
        open={isGroupAdminsOpen}
        onClose={toggleGroupAdminsOpen}
        selectedGroup={formData}
      />
    </>
  );
};

export default GroupAdmins;
