const queryKeys = {
  DATASET_METADATA: "dataset_metadata",
  DATASET_METADATA_BY_ID: "dataset_metadata_by_id",
  DATASET_INDEXING_TASKS_BY_ID: "dataset_indexing_tasks_by_id",
  DATASET_METADATA_RECORD_COUNT_BY_ID: "dataset_metadata_record_count_by_id",
  KNOWLEDGE_WIKI: "knowledge_wiki",
  KNOWLEDGE_WIKI_BY_ID: "knowledge_wiki_by_id",
  USER_PERMISSIONS: "user_permissions",
  GROUPS: "groups",
  USERS: "users",
  USERS_BY_IDS: "users_by_ids",
  OCR_TASKS: "ocr_tasks",
  OCR_TASK_RESULT_BY_ID: "ocr_task_result_by_id",
  DATA_COLLECTIONS: "data_collections",
  DATASET_SENSITIVITY: "dataset_sensitivity",
  DATA_COLLECTION_BY_ID: "data_collections_by_id",
  SEARCH_HISTORY: "search_history",
  SEARCH_IN_FILE: "search_in_file",
  USERS_GROUP_BY_ID: "users_group_by_id",
  GROUP_ID_USERS: "group_id_users",
};

export const mutationKeys = {
  UPLOAD_OCR_FILE: "upload_ocr_file",
  UPDATE_KNOWLEDGE_WIKI_BY_ID: "update_knowledge_wiki_by_id",
  DELETE_KNOWLEDGE_WIKI_BY_ID: "delete_knowledge_wiki_by_id",
  UPDATE_DATASET_METADATA_BY_ID: "update_dataset_metadata_by_id",
  DELETE_DATASET_METADATA_BY_ID: "delete_dataset_metadata_by_id",
  RETRY_DATASET_INDEXING_TASK: "retry_dataset_indexing_task",
};

export default queryKeys;
