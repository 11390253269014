import { Box, IconButton, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { ConfirmDialog } from "src/components/ui";
import GroupAdminColumn from "./GroupAdminColumn";
import DataGrid from "src/view/components/DataGrid";
import { GroupShort } from "src/types/UserGroup";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete.svg";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  ".MuiDataGrid-columnHeaders": {
    fontSize: "12px",
    borderBottom: "none",
    ".MuiDataGrid-columnHeaderTitleContainerContent": {
      mr: 1,
    },
  },
  ".MuiDataGrid-row": {
    color: theme.palette.primary.main,
  },
}));

const StyledIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  "& svg": {
    width: "20px",
    height: "20px",
  },
}));

type Props = {
  groups: GroupShort[];
  isLoading: boolean;
  deleteGroupAsync: (id: string) => void;
};

const Table = ({ groups, isLoading, deleteGroupAsync }: Props) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const columns: GridColDef<GroupShort>[] = [
    {
      field: "name",
      headerName: "Organization Name",
      flex: 1,
      renderCell: ({ row, value }) => (
        <Link
          to={`edit/${row.group_id}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {value || "--"}
        </Link>
      ),
    },
    {
      field: "num_users",
      headerName: "Users",
      flex: 1,
    },
    {
      field: "group_admins",
      headerName: "Group Admin(s)",
      flex: 1,
      renderCell: ({ row }) => <GroupAdminColumn admins={row.group_admins} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <ConfirmDialog
            title="Delete Group"
            description="Are you sure you want to delete this group? This action cannot be undone."
            confirmText="Confirm"
            cancelText="Cancel"
            isStopPropagation={true}
            onConfirm={() => deleteGroupAsync(row.group_id)}
          >
            <StyledIcon size="small">
              <DeleteIcon color="primary" />
            </StyledIcon>
          </ConfirmDialog>
        </Box>
      ),
    },
  ];

  return (
    <StyledDataGrid
      apiRef={apiRef}
      rows={groups}
      columns={columns as GridColDef[]}
      loading={isLoading}
      getRowId={(row) => row.group_id}
      hideFooter
      disableRowSelectionOnClick
      disableColumnMenu
      onRowClick={(params) => navigate(`/groups/edit/${params.id}`)}
    />
  );
};

export default Table;
