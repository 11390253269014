import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { FocusProvider } from "src/view/components/Search/context/focus";
import SearchBox from "src/view/components/Search/SearchBox";
import SearchHistoryPopup from "../search-result/components/SearchHistory/SearchHistoryPopup";
import { useUser } from "src/modules/api/auth";
import { formatVariablesSearchParam } from "src/modules/api/search/SearchProvider";
import { emptyQueryVariables } from "src/modules/api/search";
import { useSearchHistory } from "src/modules/api/searchHistory";
import { HogEvent } from "src/types/PosthogEvents";
import { SearchHistory } from "src/types/Search";
import logo from "src/assets/logo-white.svg";
import { ReactComponent as ImgIconSearchHistory } from "src/assets/icons/search_history.svg";
import theme from "src/theme";

const LogoWrapper = styled("div")(() => ({
  height: 50,
  width: 160,
  minWidth: 160,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

const StyledContainer = styled(Container)(() => ({
  maxWidth: "100% !important",
  backgroundColor: "transparent",
}));

export default function SearchHeader() {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const { getSearchHistory } = useSearchHistory();
  const { refetch: refetchSearchHistory } = getSearchHistory;
  const [anchorEl, setAnchorEl] = useState(null);
  const mobileView = useMediaQuery("(max-width:1280px)");
  const isMenuOpen = Boolean(anchorEl);
  const [showSearchHistoryPopup, setShowSearchHistoryPopup] = useState(false);
  const posthog = usePostHog();

  const handleProfileMenuOpen = (event: unknown) => {
    setAnchorEl(event["currentTarget"]);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const gotoProfilePage = () => {
    handleMenuClose();
    navigate("/profile");
  };

  const openSidebar = () => {
    document.dispatchEvent(new CustomEvent("openSideBar"));
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const toggleShowSearchHistory = () => {
    if (!showSearchHistoryPopup) {
      refetchSearchHistory();
    }
    setShowSearchHistoryPopup(!showSearchHistoryPopup);
    if (showSearchHistoryPopup)
      posthog.capture(HogEvent.SEARCH_HISTORY_POPUP_OPENED);
    else posthog.capture(HogEvent.SEARCH_HISTORY_POPUP_CLOSED);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ListItem sx={{ cursor: "default" }}>{user?.email}</ListItem>
      <MenuItem onClick={gotoProfilePage}>My account</MenuItem>
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const SearchComponent = () => {
    const handleSearchInHistory = (data: SearchHistory) => {
      const {
        id,
        content,
        result_count,
        result_count_relation,
        timestamp,
        ...filters
      } = data;
      const newVariables = {
        content: data.content,
        filters: {
          ...emptyQueryVariables.filters,
          ...filters,
        },
      };

      const searchParams = formatVariablesSearchParam(newVariables);
      return window.open(`${window.location.origin}/search?q=${searchParams}`);
    };
    return (
      <>
        <SearchHistoryPopup
          isSearchHistoryOpen={showSearchHistoryPopup}
          onClosePopup={toggleShowSearchHistory}
          handleSearch={handleSearchInHistory}
        />

        <FocusProvider>
          <SearchBox
            customStyles={{
              common: {
                width: "340px",
                minWidth: "340px",
                maxWidth: "340px",
                [theme.breakpoints.up("md")]: {
                  minWidth: "570px",
                  width: "570px",
                  maxWidth: "570px",
                },
                [theme.breakpoints.up("lg")]: {
                  minWidth: "670px",
                  width: "670px",
                  maxWidth: "670px",
                },
              },
              form: {
                padding: "0px 12px",
                margin: 0,
                height: "40px",
                maxWidth: "640px !important",
              },
              controls: { gap: 0 },
              icon: {
                color: theme.palette.grey[800],
                backgroundColor: "transparent",
              },
              input: {
                color: theme.palette.grey[800],
              },
            }}
            showFilters={false}
          />
        </FocusProvider>
      </>
    );
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: theme.palette.primary.main,
        boxShadow: "none",
      }}
    >
      <StyledContainer>
        <Toolbar
          sx={{
            justifyContent: mobileView ? "space-between" : "flex-start",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 0 }}
              onClick={openSidebar}
            >
              <MenuIcon />
            </IconButton>
            <LogoWrapper onClick={() => navigate("/")}>
              <img src={logo} alt="Logo" />
            </LogoWrapper>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: mobileView ? "40%" : "100%",
              justifyContent: "center",
            }}
          >
            {/* eslint-disable-next-line new-cap */}
            {SearchComponent()}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
            <Box
              sx={{
                width: "24px",
              }}
            >
              <IconButton
                size="large"
                edge="end"
                aria-label="recent searches of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={toggleShowSearchHistory}
                color="inherit"
              >
                <ImgIconSearchHistory
                  style={{
                    color: theme.palette.common.white,
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>
            </Box>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
        {renderMenu}
      </StyledContainer>
    </AppBar>
  );
}
