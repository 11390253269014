import { isEqual } from "lodash";
import { QueryVariables } from "src/types/QueryVariables";
import { QueryItem } from "src/types/BulkSearch";
import { parseVariablesFromSearchParams } from "../utils";
import {
  SET_VARIABLES,
  SET_QUERY_B,
  SET_PREVIEW_DATA,
  SET_ACTIVE_PAGE_INDEX,
} from "./constants";

export interface SearchStoreInterface {
  unappliedFilters: boolean;
  variables: QueryVariables;
  queryB: QueryItem[];
  activePageIndex: number;
  previewData: any | null;
}

const initialQueryB: QueryItem[] = [
  {
    type: "term", // operator | term
    value: "",
    quote: false,
    fuzziness: 0,
  },
];

const initialData: SearchStoreInterface = {
  unappliedFilters: false,
  variables: parseVariablesFromSearchParams(
    new URLSearchParams(window.location.search)
  ),
  queryB: initialQueryB,
  activePageIndex: 0,
  previewData: null,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload?: any;
  }
) => {
  switch (type) {
    case SET_VARIABLES:
      return {
        ...state,
        variables: payload,
        unappliedFilters: !isEqual(
          parseVariablesFromSearchParams(
            new URLSearchParams(window.location.search)
          ).filters,
          payload
        ),
      };
    case SET_QUERY_B:
      return {
        ...state,
        queryB: payload,
      };
    case SET_ACTIVE_PAGE_INDEX:
      return {
        ...state,
        activePageIndex: payload,
      };
    case SET_PREVIEW_DATA:
      return {
        ...state,
        previewData: payload,
      };
    default:
      return state;
  }
};
