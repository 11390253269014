import { useEffect, useState } from "react";

import {
  Group,
  GroupDatasetPermissionsFields,
  GroupFeatures,
} from "src/types/UserGroup";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { SensitivityString } from "src/types/Dataset";

import { StyledTabContainer } from "../StyledComponents";
import SensitivityGroup from "./SensitivityGroup";

interface Props {
  handleUpdateDatasets: (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => void;
  data: Group;
  handleUpdateFeatures: (checked: boolean, featureName: GroupFeatures) => void;
  isDatasetsUpdating: boolean;
}

const Datasets = ({
  handleUpdateDatasets,
  data,
  handleUpdateFeatures,
  isDatasetsUpdating,
}: Props) => {
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data: datasets } = getKnowledgeWikis();

  const [shouldDisableToggles, setShouldDisableToggles] = useState(false);

  const [sensitivityGroups, setSensitivityGroups] = useState<
    Record<SensitivityString, KnowledgeWiki[]>
  >({
    [SensitivityString.NONSENSITIVE]: [],
    [SensitivityString.PRIVILEGED]: [],
    [SensitivityString.SENSITIVE]: [],
    [SensitivityString.RESTRICTED]: [],
  });

  useEffect(() => {
    if (isDatasetsUpdating) setShouldDisableToggles(true);
    else setTimeout(() => setShouldDisableToggles(false), 300);
  }, [isDatasetsUpdating]);

  useEffect(() => {
    const newSensitivityGroups: Record<SensitivityString, KnowledgeWiki[]> = {
      [SensitivityString.NONSENSITIVE]: [],
      [SensitivityString.PRIVILEGED]: [],
      [SensitivityString.SENSITIVE]: [],
      [SensitivityString.RESTRICTED]: [],
    };

    datasets.forEach((item) => {
      const key = item.sensitivity as unknown as SensitivityString;
      if (
        key === SensitivityString.RESTRICTED &&
        !data?.datasets?.includes(item.dataset) &&
        newSensitivityGroups[key].indexOf(item) !== -1
      ) {
        /* Not accessible */
        return;
      }
      newSensitivityGroups[key].push(item);
    });

    setSensitivityGroups(newSensitivityGroups);
  }, [datasets, data]);

  const onUpdateDatasets = (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => {
    setShouldDisableToggles(true);
    handleUpdateDatasets(checked, key, value);
  };

  const onUpdateFeatures = (checked: boolean, featureName: GroupFeatures) => {
    setShouldDisableToggles(true);
    handleUpdateFeatures(checked, featureName);
  };

  return (
    <StyledTabContainer>
      {Object.entries(sensitivityGroups).map(([sensitivityGroup, item]) => {
        if (!item.length) {
          return null;
        }
        return (
          <SensitivityGroup
            key={sensitivityGroup}
            sensitivityGroup={sensitivityGroup as SensitivityString}
            data={data}
            datasets={item}
            handleUpdateDatasets={onUpdateDatasets}
            handleUpdateFeatures={onUpdateFeatures}
            isDatasetsUpdating={shouldDisableToggles}
          />
        );
      })}
    </StyledTabContainer>
  );
};

export default Datasets;
