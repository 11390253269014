import { Box, styled } from "@mui/material";

export const FormTemplate = styled(Box)(() => ({
  height: 542,
  padding: "72px 80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `0px 3px 13px 0px #0000001A`,
  borderRadius: 6,
  boxSizing: "border-box",
}));
