import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Input, Typography } from "@mui/material";
import { Colors } from "../constants/colors";
import DeleteIcon from "@mui/icons-material/DeleteOutlineSharp";
import { ReactComponent as ShareIcon } from "src/assets/icons/share-line.svg";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  DataCollection,
  DataCollectionUpdateProps,
} from "src/types/DataCatalog";
import { debounce, difference } from "lodash";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import DataGrid from "../components/DataGrid";
import AutoSizer from "react-virtualized-auto-sizer";
import DatasetToolbar from "./DatasetToolbar";
import RemoveDatasetsDialog from "./RemoveDatasetDialog";
import { Dataset } from "src/types/Dataset";
import { useDataCollections } from "src/modules/api/dataCollections";
import theme from "src/theme";
import ShareCollectionDialog from "./ShareCollectionDialog";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

declare module "@mui/x-data-grid-premium" {
  interface ToolbarPropsOverrides {
    collection: DataCollection;
    handleUpdateCollection: (update: DataCollectionUpdateProps) => void;
    handlePromptRemoveDatasets: (datasets: string[]) => void;
  }
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: 149,
  },
  marginTop: 64,
}));

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(4, 0),
}));

const StyledHeaderRow = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StyledShareButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: Colors.textDark,
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  height: 46,
  padding: 15,
  textTransform: "capitalize",
}));

const StyledInputBoxTitle = styled(Input)(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "rgba(20, 74, 104)",
  border: "none",
  "&::after": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&::before": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&:focus-visible": {
    outline: "none",
    borderBottomStyle: "none !important",
  },
  "&:hover": {
    "&::after": {
      border: "none",
      borderBottomStyle: "none !important",
    },
    "&::before": {
      border: "none",
      borderBottomStyle: "none !important",
    },
  },
}));

const StyledInputBoxDescription = styled(Input)(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  fontSize: "1rem",
  lineHeight: "24px",
  fontWeight: 400,
  color: "rgba(20, 74, 104)",
  border: "none",
  "&::after": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&::before": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&:focus-visible": {
    outline: "none",
    borderBottomStyle: "none !important",
  },
}));

const StyledImage = styled("img")(() => ({
  width: "100%",
  height: 226,
  objectFit: "contain",
}));

export default function DataCollectionPage() {
  const params = useParams();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const location = useLocation();
  const fromTabIndex = location.state?.fromTabIndex ?? 0;
  const { getDatasetMetadata } = useDatasetMetadata();
  const {
    getDataCollection,
    createDataCollection,
    updateDataCollection,
    shareDataCollection,
  } = useDataCollections();
  const apiRef = useGridApiRef();

  const { data, isLoading, isError, error } = getDataCollection(params.id);
  const { data: datasets, isLoading: loading } = getDatasetMetadata();

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const isCreatingNewCollection = params.id === "create";
  const [collection, setCollection] = useState<DataCollection | null>(null);
  const [rows, setRows] = useState<Dataset[]>([]);
  const [removeDatasets, setRemoveDatasets] = useState<string[]>([]);

  const toggleShareDialog = () => setIsShareDialogOpen(!isShareDialogOpen);

  useEffect(() => {
    if (data) {
      setCollection(data);
    }
  }, [data]);

  useEffect(() => {
    if (collection && datasets && Array.isArray(collection.datasets)) {
      setRows(datasets.filter((d) => collection.datasets.includes(d.id)));
    }
  }, [datasets, collection]);

  useEffect(() => {
    // update collection id on create without triggering UI refresh
    if (params.id !== "create") {
      setCollection((prev) => ({ ...prev, id: params.id }));
    }
  }, [params]);

  const handleSubmit = useMemo(
    () =>
      debounce((data: DataCollection) => {
        if (params.id === "create") {
          posthog.capture(HogEvent.DATA_COLLECTION_CREATED);
          createDataCollection.mutate(data);
        } else {
          updateDataCollection.mutate(data);
        }
      }, 500),
    [params]
  );

  const handleUpdateCollection = (update: DataCollectionUpdateProps) => {
    setCollection((prev) => ({ ...prev, ...update }));
    handleSubmit({ ...collection, ...update });
  };

  const handleRemoveDatasets = (datasets: string[]) => {
    setCollection((prev) => ({
      ...prev,
      datasets: difference(prev.datasets, datasets),
    }));
    handleSubmit({
      ...collection,
      datasets: difference(collection.datasets, datasets),
    });
  };

  const handleShare = (collection: DataCollection, group_ids: string[]) => {
    shareDataCollection.mutate({ collection, group_ids });
  };

  const NoRowsOverlayComponent = () => (
    <Box
      sx={{
        height: 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledImage
        src={require("src/assets/images/not-found.png")}
        alt="Not Found"
      ></StyledImage>
      <Typography variant="h5" color="primary.light" textAlign="center">
        No Datasets Found
      </Typography>
      <Typography color="grey.500" variant="subtitle1" textAlign="center">
        Please select some datasets to add to this collection.
      </Typography>
    </Box>
  );

  const baseColumns: GridColDef[] = [
    {
      field: "name",
      type: "string",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "sensitivity",
      headerName: "Sensitivity",
      type: "string",
      width: 200,
    },
  ];

  let columns: GridColDef[];

  if (fromTabIndex !== 1) {
    columns = [
      ...baseColumns,
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 200,
        getActions: ({ row }: GridRowParams<DataCollection>) => [
          <GridActionsCellItem
            key={row.id}
            label="Delete"
            icon={<DeleteIcon color="primary" />}
            onClick={() => setRemoveDatasets([row.id])}
          />,
        ],
      },
    ];
  } else {
    columns = [...baseColumns];
  }

  if (!isCreatingNewCollection) {
    if (isLoading) {
      return <div>Loading collection...</div>;
    }

    if (isError && error instanceof Error) {
      return <div>Error loading collection: {error?.message}</div>;
    }

    if (!collection) {
      return <div>No data collections available.</div>;
    }
  }

  return (
    <StyledContainer>
      <StyledHeaderBox>
        <StyledHeaderRow>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon fontSize="small" />}
            color="primary"
            onClick={() =>
              navigate("/data-collections", {
                state: { tabIndex: fromTabIndex },
              })
            }
          >
            Back
          </Button>
          {fromTabIndex === 0 && (
            <StyledShareButton
              startIcon={
                <ShareIcon
                  style={{
                    width: "40px",
                    height: "40px",
                    color: theme.palette.primary.main,
                  }}
                />
              }
              onClick={toggleShareDialog}
            >
              Share
            </StyledShareButton>
          )}
          {isShareDialogOpen && (
            <ShareCollectionDialog
              collection={collection}
              handleClose={toggleShareDialog}
              handleShare={handleShare}
            />
          )}
        </StyledHeaderRow>
        <StyledHeaderRow
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <StyledInputBoxTitle
            placeholder="Write your collection name"
            value={collection?.name ?? ""}
            name="name"
            onChange={({ target }) =>
              handleUpdateCollection({ name: target.value })
            }
            inputProps={{
              "data-1p-ignore": true,
            }}
          />
          <StyledInputBoxDescription
            placeholder="Write a description for your collection"
            value={collection?.description ?? ""}
            name="description"
            onChange={({ target }) =>
              handleUpdateCollection({ description: target.value })
            }
            maxRows={10}
            multiline
            type="text"
          />
        </StyledHeaderRow>
      </StyledHeaderBox>
      <AutoSizer>
        {({ width, height }) => (
          <Box sx={{ width: width, height: height, minHeight: 600 }}>
            <DataGrid
              apiRef={apiRef}
              rows={rows}
              columns={columns}
              loading={loading}
              slots={{
                toolbar: DatasetToolbar,
                noRowsOverlay: NoRowsOverlayComponent,
              }}
              slotProps={{
                toolbar: {
                  collection: collection,
                  handleUpdateCollection: handleUpdateCollection,
                  handlePromptRemoveDatasets: (datasets: string[]) =>
                    setRemoveDatasets(datasets),
                  showQuickFilter: true,
                },
              }}
              hideFooter
              checkboxSelection
              disableRowSelectionOnClick
              disableColumnMenu
            />
          </Box>
        )}
      </AutoSizer>
      <RemoveDatasetsDialog
        datasets={removeDatasets}
        handleRemove={handleRemoveDatasets}
        handleClose={() => setRemoveDatasets([])}
      />
    </StyledContainer>
  );
}
