import { useState, useMemo, MouseEvent, ChangeEvent } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import { useDatasetSensitivity } from "src/modules/api/datasetSensitivity";
import { SensitivityString, SensitivityValues } from "src/types/Dataset";
import { DataTypeCard } from "./DataTypeCard";

const StyledRootWrapper = styled(Container)<{ mobile: boolean }>(
  ({ mobile }) => ({
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    paddingBottom: 100,
    marginTop: mobile ? "56px" : "64px",
  })
);

const HeaderContainer = styled(Grid)({
  display: "flex",
  paddingTop: 8,
  marginLeft: 0,
  marginRight: 0,
});

const StyledHeader = styled("h3")(({ theme }) => ({
  fontSize: 32,
  lineHeight: "39px",
  fontWeight: "700",
  color: theme.palette.twilight.dark,
  marginRight: 18,
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: "400",
  color: theme.palette.twilight.dark,
}));

const DatasetsContainer = styled(Grid)({
  padding: 8,
});

const LoaderContainer = styled(Grid)({
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  opacity: 0.5,
  justifyContent: "center",
  position: "absolute",
});

const ScaffoldingPage = () => {
  const { getDatasetSensitivityList, moveDataSensitivityItem } =
    useDatasetSensitivity();
  const { data: datasets, isLoading } = getDatasetSensitivityList();
  const [selectedItems, setSelectedItems] = useState({
    nonsensitive: [],
    privileged: [],
    sensitive: [],
    restricted: [],
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuType, setMenuType] = useState("");
  const mobileView = useMediaQuery("(max-width:678px)");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    key: string
  ) => {
    const sItems = { ...selectedItems };

    if (e.target.checked) {
      sItems[key].push(id);
    } else {
      sItems[key].splice(id, 1);
    }
    setSelectedItems(sItems);
  };

  const handleClickMoveButton = (
    e: MouseEvent<HTMLButtonElement>,
    type: string
  ) => {
    setMenuType(type);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (to: SensitivityString) => {
    handleMoveItems(to);
    setAnchorEl(null);
  };

  const handleMoveItems = async (to: SensitivityString) => {
    const items = selectedItems[menuType];
    const data = {
      sensitivity: to,
    };

    for (let index = 0; index < items.length; index++) {
      const id = items[index];
      await moveDataSensitivityItem.mutateAsync({ id, data });
    }
    const sItems = { ...selectedItems };
    sItems[menuType] = [];
    setSelectedItems(sItems);
  };

  const datasetsOptions = useMemo(() => {
    return [
      {
        type: "nonsensitive",
        title: "Non Sensitive",
        isLeaked: true,
        someDatasetsSelected: selectedItems["nonsensitive"]?.length > 0,
        datasets:
          datasets?.filter(
            (dataset) => dataset.sensitivity === SensitivityValues.NONSENSITIVE
          ) ?? [],
      },
      {
        type: "privileged",
        title: "Privileged",
        someDatasetsSelected: selectedItems["privileged"]?.length > 0,
        datasets:
          datasets?.filter(
            (dataset) => dataset.sensitivity === SensitivityValues.PRIVILEGED
          ) ?? [],
      },
      {
        type: "sensitive",
        title: "Sensitive",
        someDatasetsSelected: selectedItems["sensitive"]?.length > 0,
        datasets:
          datasets?.filter(
            (dataset) => dataset.sensitivity === SensitivityValues.SENSITIVE
          ) ?? [],
      },
      {
        type: "restricted",
        title: "Restricted",
        someDatasetsSelected: selectedItems["restricted"]?.length > 0,
        datasets:
          datasets?.filter(
            (dataset) => dataset.sensitivity === SensitivityValues.RESTRICTED
          ) ?? [],
      },
    ];
  }, [selectedItems, datasets]);

  return (
    <StyledRootWrapper mobile={mobileView}>
      <HeaderContainer justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <StyledHeader>Dataset Sensitivity</StyledHeader>
            </Grid>
          </Grid>
        </Grid>
      </HeaderContainer>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={8}>
          <StyledText variant="body1">
            Manage the assigned sensitivity levels for datasets within the
            application.
          </StyledText>
        </Grid>
      </Grid>
      <Box sx={{ height: 32 }} />
      <DatasetsContainer container spacing={2}>
        {isLoading && (
          <LoaderContainer item xs={12}>
            <CircularProgress />
          </LoaderContainer>
        )}
        {datasetsOptions.map((dataTypeCardProps) => (
          <DataTypeCard
            key={dataTypeCardProps.type}
            {...dataTypeCardProps}
            onMove={handleClickMoveButton}
            onChange={handleChange}
            isChecked={(datasetId) =>
              selectedItems[dataTypeCardProps.type].includes(datasetId)
            }
          />
        ))}
      </DatasetsContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuType != "nonsensitive" && (
          <MenuItem
            onClick={() => handleClickItem(SensitivityString.NONSENSITIVE)}
          >
            Non Sensitive
          </MenuItem>
        )}
        {menuType != "privileged" && (
          <MenuItem
            onClick={() => handleClickItem(SensitivityString.PRIVILEGED)}
          >
            Privileged
          </MenuItem>
        )}
        {menuType != "sensitive" && (
          <MenuItem
            onClick={() => handleClickItem(SensitivityString.SENSITIVE)}
          >
            Sensitive
          </MenuItem>
        )}
        {menuType != "restricted" && (
          <MenuItem
            onClick={() => handleClickItem(SensitivityString.RESTRICTED)}
          >
            Restricted
          </MenuItem>
        )}
      </Menu>
      <Box sx={{ height: 32 }} />
    </StyledRootWrapper>
  );
};

export default ScaffoldingPage;
