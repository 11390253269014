import { Route, Routes } from "react-router-dom";
import { PageRoutes } from "./view/routes";
import Layout from "./view/layout/Layout";
import Sidebar from "./view/components/Sidebar";
import LaunchNotesWidget from "./view/components/LaunchNotesWidget";
import FeedbackModal from "./view/components/FeedbackModal";
import UserExpiredModal from "./view/components/UserExpiredModal";
import { Snackbar } from "./view/toaster";
import "./App.css";

export default function App() {
  return (
    <Layout>
      <FeedbackModal />
      <Sidebar />
      <LaunchNotesWidget />
      <Snackbar />
      <UserExpiredModal />
      <Routes>
        {PageRoutes.map(({ key, ...props }) => (
          <Route key={key} {...props} />
        ))}
      </Routes>
    </Layout>
  );
}
