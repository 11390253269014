import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { SelectChip } from "./SelectChip";
import { SelectedMultipleValueProps, SelectedValueProps } from "./types";

const Placeholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey["600"],
  fontSize: "14px",
  lineHeight: "18px",
}));

const ChipsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  flexWrap: "wrap",
});

export const SelectedValue = <T,>({
  options,
  value,
  placeholder,
  getLabel,
}: SelectedValueProps<T> | SelectedMultipleValueProps<T>) => {
  const getOption = (value: T) =>
    options.find((option) => option.value === value);

  if (Array.isArray(value)) {
    if (value.length) {
      return (
        <ChipsBox>
          {value.map(getOption).map((option) => (
            <SelectChip key={option.value as any}>
              {getLabel(option)}
            </SelectChip>
          ))}
        </ChipsBox>
      );
    }
    return <Placeholder>{placeholder}</Placeholder>;
  }

  return !value ? (
    <Placeholder>{placeholder}</Placeholder>
  ) : (
    <>{getLabel(getOption(value))}</>
  );
};
