import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, Tooltip, Typography, styled } from "@mui/material";
import * as ftm from "@alephdata/followthemoney";
import { usePostHog } from "posthog-js/react";
import { format, isValid } from "date-fns";
import { EntityTemplate } from "../../EntityTemplate";
import { getHighlightText } from "../../../../../utils/get-highlight-text";
import SearchResultCardActions from "../SearchResultCardActions";
import { titleCase } from "../../../../../utils";
import FilterTag from "../FilterTag";
import LoadMoreButton from "./LoadMoreButton";
import { useFilterOptions } from "../../../../../modules/api/filters";
import {
  DataIndex,
  GetDownloadDocumentURLParams,
  Highlight,
  MappedHit,
} from "../../../../../types/Search";
import { prepSearchResultData } from "../../../../../modules/api/search/SearchProvider";
import { TagBox } from "../../TagBox";
import { HogEvent } from "../../../../../types/PosthogEvents";
import { useSearch } from "../../../../../modules/api/search";
import { useDatasetHitsContext } from "../../DatasetHits/DatasetHitsContext";

interface ModeledEntityProps {
  type: string;
  highlight?: Highlight;
  hit: MappedHit;
  getDocumentPreview: () => void;
}

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "600",
  color: theme.palette.grey[900],
  cursor: "pointer",
  paddingTop: "2px",
}));

export const ModeledEntity = ({
  type,
  highlight,
  hit,
  getDocumentPreview,
}: ModeledEntityProps) => {
  const ref = useRef<HTMLDivElement>();
  const posthog = usePostHog();
  const { countries } = useFilterOptions();
  const { getDownloadDocumentUrl } = useSearch();
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [gettingDownloadLink, setGettingDownloadLink] = useState(false);
  const [downloadParams, setDownloadParams] =
    useState<GetDownloadDocumentURLParams | null>(null);
  const { datasetMetadata, isLoading } = useDatasetHitsContext(hit.datasetId);
  const { data: downloadUrl, status: downloadUrlStatus } =
    getDownloadDocumentUrl(downloadParams);

  const sourceType = hit.dataIndex === DataIndex.modeled ? 0 : 1;

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const trigger = () => {
        setExpand(false);
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflow(hasOverflow);
      };

      window.addEventListener("resize", trigger);
      trigger();
      return () => window.removeEventListener("resize", trigger);
    }
  }, [ref]);

  if (downloadUrlStatus === "success") {
    setDownloadParams(null);
    setGettingDownloadLink(false);
    if (downloadUrl) {
      const downloadWindow = window.open(downloadUrl, "_blank");
      downloadWindow?.focus();
    }
  }

  if (downloadUrlStatus === "error") {
    setDownloadParams(null);
    setGettingDownloadLink(false);
  }

  const handleDownloadDoc = async (entireFile?: boolean) => {
    setGettingDownloadLink(true);
    posthog.capture(
      sourceType === 1
        ? HogEvent.SOURCE_DATA_DOWNLOADED
        : HogEvent.MODEL_DATA_DOWNLOADED
    );
    setDownloadParams({
      id: hit.id,
      dataIndex: hit.dataIndex,
      entireFile,
    });
  };

  const toggleExpand = () => setExpand(!expand);

  const data = useMemo(
    () => (sourceType ? hit : prepSearchResultData(hit)),
    [sourceType]
  );

  const tags = useMemo(() => {
    return Object.entries(data)
      .filter(
        ([k]) =>
          ![
            "file_name",
            "schema",
            "dataset_id",
            "sourceUrl",
            "ingest_timestamp",
          ].includes(k)
      )
      .map(([k, v]) => {
        const label = k;
        let icon;
        let value = v;
        const labelHighlight = highlight?.[k];
        if (typeof v === "number") {
          value = v.toString();
        }
        if (typeof v === "string" && isValid(v)) {
          value = format(new Date(v), "yyyy-MM-dd");
        }
        if (typeof v === "object") {
          if (Array.isArray(v)) {
            value = v.join(", ");
          } else {
            value = JSON.stringify(v);
          }
        }
        if (
          ["jurisdiction", "nationality", "main_country"].includes(label) &&
          Array.isArray(v)
        ) {
          const iconCode = v[0].toString().toUpperCase();
          icon = (
            <img
              alt={iconCode}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${iconCode}.svg`}
              style={{
                width: 20,
                height: 16,
                objectFit: "contain",
                marginRight: 5,
                flex: "none",
              }}
            />
          );
          value = countries.find(
            (country) => country.value.toLowerCase() == v[0]
          )?.label;
          if (v.length > 1) {
            const extra = v.length - 1;
            value = `${value} +${extra}`;
          }
        }
        return { label, icon, value, hasMatchHighlight: !!labelHighlight };
      });
  }, [data]);

  const title = useMemo(() => {
    return highlight && highlight?.name ? (
      <>{getHighlightText(highlight.name[0])}</>
    ) : (
      data.name
    );
  }, [highlight, data]);

  return (
    <EntityTemplate
      type={type}
      onClick={getDocumentPreview}
      isLoading={isLoading}
      subTitle={type}
      title={
        <>
          {Array.isArray(title) && title.length > 1 ? (
            <Tooltip
              title={
                <Typography
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    fontSize: "10px",
                  }}
                >
                  {title.join(",\n")}
                </Typography>
              }
              placement={"bottom"}
            >
              <Box>
                <StyledTitle onClick={getDocumentPreview}>
                  {title[0] + " ..."}
                </StyledTitle>
              </Box>
            </Tooltip>
          ) : (
            <StyledTitle onClick={getDocumentPreview}>{title}</StyledTitle>
          )}
        </>
      }
      tags={
        <>
          <TagBox id="card-tag-box" expand={expand} ref={ref}>
            {tags.map((tag, i: number) => {
              let { value } = tag;
              const { label } = tag;
              if (
                ["jurisdiction", "nationality", "main_country"].includes(label)
              ) {
                const country = countries.find(
                  (country) =>
                    country.label.toLowerCase() == value?.toLowerCase()
                );
                if (country) {
                  value = country.value;
                }
              }

              const keyValueQuery = `${label}:"${value}"`;
              const ftmPropertyLabel =
                ftm.defaultModel.schemata?.[data.schema]?.properties?.[label]
                  ?.label;
              const titleCaseLabel = titleCase(label);

              return (
                <FilterTag
                  {...tag}
                  compact
                  key={`${keyValueQuery}-${i}`}
                  {...(sourceType === 0 && {
                    label: ftmPropertyLabel ?? titleCaseLabel,
                    highlightKeyValueQuery: keyValueQuery,
                  })}
                />
              );
            })}
          </TagBox>
          <LoadMoreButton
            expand={expand}
            isOverflow={isOverflow}
            onClick={toggleExpand}
          />
        </>
      }
      cardActions={
        <SearchResultCardActions
          resultMetadata={datasetMetadata as any}
          gettingDownloadLink={gettingDownloadLink}
          sourceType={sourceType}
          type={type}
          handleDownloadDoc={handleDownloadDoc}
        />
      }
    />
  );
};
