import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { nonFilterableEntities } from "src/view/constants/non-filterable-entities";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { emptyQueryVariables } from "src/modules/api/search";
import { RecordCount } from "src/types/Dataset";
import { useSearch } from "src/modules/api/search";
import { cloneDeep, union } from "lodash";
import { styled } from "@mui/material/styles";
import theme from "src/theme";
import { ThingInheritors } from "src/utils/constants";
import { formatKey } from "src/utils/string";

interface Props {
  recordCount: RecordCount;
  datasetId: string;
  anchorEl: Element;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const LinkLabel = styled("div")(({ theme }) => ({
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "20px",
  color: theme.palette.grey[900],
  fontWeight: "400",
}));

const RecordCountItem = (props: {
  label: string;
  _key: string;
  docTypeCount: number;
  docTypeFileCount?: number;
  handleClick?: () => void;
}) => {
  const { label, _key, docTypeCount, docTypeFileCount, handleClick } = props;
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    if (nonFilterableEntities.includes(_key) == false) {
      setHovered(true);
    }
  };

  return (
    <>
      <MenuItem
        sx={{ width: "auto" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setHovered(false)}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "6px",
          }}
        >
          <TypeImage
            _key={_key === "hyperText" ? "html" : _key.toLowerCase()}
          />
          <LinkLabel
            sx={{
              fontSize: "16px",
              color: theme.palette.grey[800],
              fontWeight: "400",
            }}
          >
            {docTypeCount?.toLocaleString()}
          </LinkLabel>
          <LinkLabel>{label}</LinkLabel>
          {docTypeCount && docTypeFileCount && (
            <Box
              sx={{
                color: theme.palette.grey[800],
              }}
            >
              from
            </Box>
          )}
          {docTypeFileCount && (
            <LinkLabel
              sx={{
                fontSize: "16px",
                color: theme.palette.grey[800],
                fontWeight: "400",
              }}
            >
              {docTypeFileCount.toLocaleString()}{" "}
              {`file${docTypeFileCount > 1 ? "s" : ""}`}
            </LinkLabel>
          )}

          {hovered ? (
            <Tooltip title={"See results of this type"}>
              <img
                src={require("src/assets/images/call-made.png")}
                style={{ width: 20, height: 20, marginLeft: "auto" }}
                alt="call-made"
              />
            </Tooltip>
          ) : (
            <Box sx={{ width: "20px" }} />
          )}
        </Box>
      </MenuItem>
    </>
  );
};

const RecordCounts = ({
  recordCount,
  datasetId,
  anchorEl,
  setAnchorEl,
}: Props) => {
  const { handleSearch } = useSearch();

  const handleClickRecord = (_key: string) => {
    handleSearch({
      filters: {
        ...emptyQueryVariables.filters,
        dataset_ids: [datasetId],
        doc_types: [_key],
      },
      content: "*",
    });
  };

  const prepCounts = (obj: Record<string, number>) => {
    return Object.keys(obj).reduce(
      (acc, key) => {
        // remove potential leading period from the file extension
        // https://github.com/C-Research/seho/pull/771/files#diff-46c6162e6050e03ef570bc5ee4fec0bf7e63a1c55e2202a8186e24cf03824c10R2055
        const cleanedKey = key.startsWith(".") ? key.substring(1) : key;

        // group html-like files under hyperText key
        if (cleanedKey.includes("htm")) {
          acc.hyperText = (acc.hyperText || 0) + obj[key];
        } else {
          acc[cleanedKey] = (acc[cleanedKey] || 0) + obj[key];
        }
        return acc;
      },
      {
        hyperText: 0,
      }
    );
  };

  const formattedCounts = useMemo(() => {
    const formattedDocTypeCounts: RecordCount = cloneDeep(recordCount);

    formattedDocTypeCounts.doc_type_counts = prepCounts(
      formattedDocTypeCounts.doc_type_counts
    );

    formattedDocTypeCounts.doc_type_file_counts = prepCounts(
      formattedDocTypeCounts.doc_type_file_counts
    );

    return formattedDocTypeCounts;
  }, [recordCount]);

  const nonHtmlKeys = useMemo(() => {
    // get file types from both sources in case one is missing
    const allFileTypes = union(
      Object.keys(formattedCounts.doc_type_counts),
      Object.keys(formattedCounts.doc_type_file_counts)
    );

    return allFileTypes.filter(
      (key) => !key.includes("htm") && key !== "hyperText"
    );
  }, [recordCount, formattedCounts]);

  const formatRecordLabel = (key: string, value: number): string => {
    const isValueBiggerThanOne = value > 1;
    if (ThingInheritors.includes(key)) {
      return formatKey(`${key} Entit${isValueBiggerThanOne ? "ies" : "y"}`);
    }

    return formatKey(`${key} Relationshi${isValueBiggerThanOne ? "ps" : "p"}`);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "0px 6px 8px 0px #12294514",
        },
        ".MuiMenu-list": {
          padding: "16px 4px",
        },
      }}
    >
      {formattedCounts.doc_type_counts["hyperText"] > 0 && (
        <RecordCountItem
          _key="hyperText"
          label="HTML pages"
          docTypeCount={formattedCounts.doc_type_counts["hyperText"]}
          docTypeFileCount={formattedCounts.doc_type_file_counts["hyperText"]}
          handleClick={() => handleClickRecord("html")}
        />
      )}
      {nonHtmlKeys.map((key: string, index: number) => {
        const value = formattedCounts.doc_type_counts[key];
        let label = key;

        if (key == "csv") label = "CSV Row";
        else if (key == "xls" || key == "xlsx") label = "Excel Row";
        else if (key == "eml") label = "Email";
        else if (key == "msg") label = "Email";
        else if (key == "pdf") label = "PDF Page";
        else if (key == "doc" || key == "docx") label = "Word Page";
        else if (key == "jpg" || key == "jpeg" || key == "png") label = "Image";
        else label = key.toUpperCase();

        // take care with plurals, very basic logic here
        if (value > 1) {
          label = label.concat("s");
        }

        return (
          <RecordCountItem
            key={index}
            _key={key}
            label={label}
            docTypeCount={value}
            docTypeFileCount={formattedCounts.doc_type_file_counts?.[key]}
            handleClick={() => handleClickRecord(key)}
          />
        );
      })}
      {formattedCounts?.schema_type_total_count > 0 &&
        Object.keys(formattedCounts.schema_type_counts).map((key, index) => {
          const value = formattedCounts.schema_type_counts[key];
          const label = formatRecordLabel(key, value);
          return (
            <RecordCountItem
              key={index}
              _key={key}
              label={label}
              docTypeCount={value}
              handleClick={() => handleClickRecord(key)}
            />
          );
        })}
    </Menu>
  );
};

const TypeImage = (props: { _key: string }) => {
  const svgRequireContext = require.context(
    "/src/assets/icons/type/",
    true,
    /\.svg$/
  );

  const allTypeSvgs = svgRequireContext.keys().reduce((images, path) => {
    images[path] = svgRequireContext(path);
    return images;
  }, {});

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const defaultSrc = require("src/assets/icons/default_document.png");

  return (
    <Box
      sx={{
        width: "24px",
        height: "24px",
        padding: "3px",
        borderRadius: "2px",
        background: "#E1E1E1",
        marginRight: "10px",
      }}
    >
      <img
        src={
          allTypeSvgs[`./source/type_${props._key.toLowerCase()}.svg`] ||
          allTypeSvgs[`./modeled/type_${props._key.toLowerCase()}.svg`] ||
          defaultSrc
        }
        style={{ width: 24, height: 24, margin: "auto" }}
        alt={props._key}
      />
    </Box>
  );
};

export default RecordCounts;
