import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { snackbar } from "src/view/toaster";
import { cloneDeep, differenceWith, isEqual } from "lodash";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import Datasets from "./DatasetsTab";
import Features from "./Features";
import {
  Group,
  GroupDatasetPermissionsFields,
  GroupFeatures,
  User,
} from "src/types/UserGroup";
import { getGroupObjectDiff } from "src/utils/get-group-object-diff";
import { isAPIValidationErrors } from "src/utils";
import { queryClient } from "src";
import queryKeys from "src/modules/api/queryKeys";
import { AddNewUserModal } from "src/view/users-groups/AddNewUsers";
import { UserFields } from "src/view/users-groups/AddNewUsers/types";
import { useToggle } from "usehooks-ts";
import {
  PageWrapper,
  StyledBackButton,
  StyledTabsWrapper,
} from "./StyledComponents";
import GroupNameEdit from "./components/GroupNameEdit";
import GroupAdmins from "./GroupAdmins";
import BulkSearch from "./BulkSearch";
import GroupUsersTable from "./GroupUsersTable";
import { APIValidationErrors } from "src/types/Shared";
import { useUserGroups } from "src/modules/api/userGroups";
import { CustomTabs } from "../../../../components/ui";

const EditGroupPage = () => {
  const navigate = useNavigate();
  const {
    getGroupData,
    getGroupUsers: getUsers,
    saveUsers,
    addObjectsToGroup,
    removeObjectsToGroup,
  } = useUserGroups();
  const { mutateAsync: addToGroup, isLoading: isAddObjectsLoading } =
    addObjectsToGroup({});
  const { mutateAsync: removeFromGroup, isLoading: isRemoveObjectsLoading } =
    removeObjectsToGroup({});
  const { mutateAsync: saveUsersAsync } = saveUsers();
  const { id: groupId } = useParams();
  const {
    data: selectedGroup,
    refetch: refetchGroupData,
    isFetching: isGroupLoading,
  } = getGroupData(groupId);
  const {
    data: groupUsers,
    refetch: refetchGroupUsers,
    isFetching: isUsersLoading,
  } = getUsers(groupId);
  const mobileView = useMediaQuery("(max-width:678px)");
  const [isAddNewUserModalOpen, toggleAddNewUserModalOpen] = useToggle();
  const [formData, setFormData] = useState<Group>({
    name: "",
    description: null,
    members: [],
    sensitivity_levels: ["Nonsensitive"],
    datasets: [],
    dataset_downloads: [],
    external_apis: [],
    features: [],
    kw_read: [],
    kw_write: [],
    permissions: [],
    screening_lists: [],
    data_catalogs: [],
    group_admins: [],
  });

  const [isFormDataSynced, setIsFormDataSynced] = useState(false);
  const [addUserAPIValidationErrors, setAddUserAPIValidationErrors] = useState<
    APIValidationErrors<UserFields>
  >([]);

  useEffect(() => {
    /** auto-update group changes any time a user makes a change to a group */
    const autoUpdateGroupChanges = async () => {
      if (
        !isFormDataSynced ||
        !selectedGroup ||
        isEqual(formData, selectedGroup)
      ) {
        return;
      }

      const { addObjs, removeObjs } = getDiffObjects();

      if (Object.keys(addObjs).length || Object.keys(removeObjs).length) {
        const group_id = selectedGroup.group_id || groupId;

        await handleGroupUpdates(addObjs, removeObjs, group_id);
      }
    };

    autoUpdateGroupChanges();
  }, [selectedGroup, formData, isFormDataSynced]);

  useEffect(() => {
    // clean up the selected Group in store on unmount before selecting other groups
    return () => {
      queryClient.removeQueries([queryKeys.USERS_GROUP_BY_ID]);
      queryClient.removeQueries([queryKeys.GROUP_ID_USERS]);
    };
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setFormData(cloneDeep({ ...selectedGroup }));
      setIsFormDataSynced(true);
      refetchGroupUsers();
    }
  }, [selectedGroup]);

  const getDiffObjects = () => {
    const updatedFields = getGroupObjectDiff(formData, selectedGroup);
    const addObjs: Group = {};
    const removeObjs: Group = {};

    updatedFields.forEach((field) => {
      if (field === "name") return;

      const add = differenceWith(
        formData[field],
        selectedGroup[field],
        isEqual
      );
      const remove = differenceWith(
        selectedGroup[field],
        formData[field],
        isEqual
      );

      if (add.length) addObjs[field] = add;
      if (remove.length) removeObjs[field] = remove;
    });

    // if exists - remove members field since it is updated separately (when adding a user)
    !!addObjs["members"] && delete addObjs.members;

    return { addObjs, removeObjs };
  };

  const handleGroupUpdates = async (
    addObjs: Group,
    removeObjs: Group,
    group_id: string
  ) => {
    if (Object.keys(addObjs).length) {
      await addToGroup({ data: addObjs, id: group_id });
    }
    if (Object.keys(removeObjs).length) {
      await removeFromGroup({ data: removeObjs, id: group_id });
    }
    setIsFormDataSynced(false);
    // TODO: instead of refetching / invalidating each time, maybe it's better to try update group data in the cache?
    await refetchGroupData();
  };

  const goBack = () => {
    navigate("/groups", { replace: true });
  };

  const handleSaveUsers = async (_users: User[]) => {
    if (_users?.length === 0) return;
    if (formData.group_id) {
      for (let i = 0; i < _users.length; i++) {
        _users[i].group_id = formData.group_id;
      }
    }
    // bulk-add from CSV or otherwise
    const res = await saveUsersAsync(_users);

    if (res.error) {
      if (res.detail && isAPIValidationErrors(res.detail)) {
        setAddUserAPIValidationErrors(res.detail);
        snackbar.error("Please double-check your user data");
      } else {
        snackbar.error("Something went wrong");
      }
    } else {
      setIsFormDataSynced(false);

      const _formState = { ...formData };
      _formState.members = _formState.members
        ? [..._formState.members, ...res]
        : res;
      setFormData(_formState);
      setAddUserAPIValidationErrors([]);
      if (formData.group_id) {
        // TODO: instead of refetching / invalidating each time, maybe it's better to try update group data in the cache?
        await refetchGroupData();
      } else {
        const _formState = { ...formData };
        _formState.members.push(...res);
        setFormData(_formState);
      }
      setIsFormDataSynced(true);
    }
  };

  const handleUpdateDatasets = (
    checked: boolean,
    key: GroupDatasetPermissionsFields,
    value: string
  ) => {
    const formDataCopy: Group = { ...formData };

    const indexOfDataSetInList = formDataCopy[key].indexOf(value);
    const isDatasetInListAlready = formDataCopy[key].includes(value);

    if (checked && isDatasetInListAlready) return;
    if (!checked && !isDatasetInListAlready) return;
    if (checked && !isDatasetInListAlready) {
      formDataCopy[key].push(value);
      setFormData(formDataCopy);
      return;
    }
    if (!checked && isDatasetInListAlready) {
      formDataCopy[key].splice(indexOfDataSetInList, 1);
      setFormData(formDataCopy);
      return;
    }
  };

  const handleUpdateFeatures = (
    checked: boolean,
    featureKey: GroupFeatures
  ): void => {
    const _formState = { ...formData };

    // value = value.toLowerCase().replace(/\ /gi, '_');
    const _index = _formState.features.indexOf(featureKey);
    if (checked) {
      if (_index < 0) {
        _formState.features.push(featureKey);
      }
    } else {
      if (_index > -1) {
        _formState.features.splice(_index, 1);
      }
    }

    setFormData(_formState);
  };

  const onUpdate = async () => {
    setIsFormDataSynced(false);
    await refetchGroupData();
  };

  return (
    <PageWrapper sx={{ mt: mobileView ? "56px" : "64px" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2 }}
      >
        <Grid item>
          <StyledBackButton
            startIcon={<ArrowBackIcon />}
            color="primary"
            onClick={goBack}
          >
            Back
          </StyledBackButton>
        </Grid>
        {isGroupLoading && (
          <Grid item>
            <CircularProgress size={20} color="inherit" />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ pt: 2, mb: "30px" }}
      >
        <Box sx={{ flex: 1 }}>
          <GroupNameEdit
            formData={formData}
            setFormData={setFormData}
            selectedGroup={selectedGroup}
          />
        </Box>
        <Stack direction="row" spacing={2} sx={{ mr: 0, display: "flex" }}>
          <GroupAdmins formData={formData} />
          <BulkSearch formData={formData} />
        </Stack>
      </Grid>
      <StyledTabsWrapper>
        <CustomTabs.Tabs
          aria-label="Group settings tabs"
          commonTabProps={{ disableRipple: true }}
          items={[
            {
              key: "users",
              label: "Users",
              content: (
                <GroupUsersTable
                  loadingUsers={isUsersLoading}
                  formData={formData}
                  setFormData={setFormData}
                  groupUsers={groupUsers}
                  toggleAddNewUserModalOpen={toggleAddNewUserModalOpen}
                  groupId={groupId}
                />
              ),
            },
            {
              key: "datasets",
              label: "Datasets",
              content: (
                <Datasets
                  handleUpdateDatasets={handleUpdateDatasets}
                  data={formData}
                  handleUpdateFeatures={handleUpdateFeatures}
                  isDatasetsUpdating={
                    isAddObjectsLoading || isRemoveObjectsLoading
                  }
                />
              ),
            },
            {
              key: "feature",
              label: "Features",
              content: (
                <Features
                  handleUpdate={handleUpdateFeatures}
                  data={formData.features}
                  isFeaturesUpdating={
                    isAddObjectsLoading || isRemoveObjectsLoading
                  }
                />
              ),
            },
          ]}
        />
      </StyledTabsWrapper>
      <AddNewUserModal
        open={isAddNewUserModalOpen}
        handleClose={toggleAddNewUserModalOpen}
        handleSaveUsers={handleSaveUsers}
        validationErrors={addUserAPIValidationErrors}
        resetValidationErrors={() => setAddUserAPIValidationErrors([])}
        selectedGroup={selectedGroup}
        groupUsers={groupUsers}
        onUpdate={onUpdate}
      />
    </PageWrapper>
  );
};

export default EditGroupPage;
