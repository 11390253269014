import React, { useCallback, useState } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ViewModeSwitch, { VIEW_MODE } from "./ViewModeSwitch";
import TableView from "./TableView";
import ListView from "./ListView";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import DownloadFile from "../DownloadFile";
import HighlightingFailure from "../HighlightingFailure";
import { Tooltip } from "src/components/ui";

interface ContainerProps extends BoxProps {
  scrollStatus: number;
  isTableView: boolean;
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  backgroundColor: theme.palette.common.white,
  overflowX: "hidden",
}));

const commonPseudoStyles = {
  content: '" "',
  position: "absolute",
  left: 0,
  right: 0,
  width: "calc(100% - 30px)",
  height: 24,
  zIndex: 1,
};

const pseudoStyles = (scrollStatus: number, isTableView: boolean) =>
  isTableView
    ? {
        "&:before": {
          ...commonPseudoStyles,
          display: scrollStatus === 0 ? "none" : "block",
          top: -2,
          backgroundImage: "linear-gradient(to bottom, #fff, #00000000)",
        },
        "&:after": {
          ...commonPseudoStyles,
          display: scrollStatus === 1 ? "none" : "block",
          bottom: -2,
          backgroundImage: "linear-gradient(to top, #fff, #00000000)",
        },
      }
    : {};

const StyledContainer = styled((props: ContainerProps) => <Box {...props} />)(
  ({ scrollStatus, isTableView }) => ({
    width: "100%",
    position: "relative",
    overflowX: "auto",
    overflowY: "hidden",
    ...pseudoStyles(scrollStatus, isTableView),
  })
);

const StyledHeader = styled(Box)({
  width: "calc(100% - 4px)",
  height: 35,
  display: "flex",
  alignItems: "center",
  paddingLeft: 12,
  paddingRight: 12,
});

const StyledClickableName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.light,
  fontWeight: "700",
  marginRight: "auto",
  maxWidth: "90%",
  cursor: "pointer",
}));

const ButtonsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const StyledFileName = styled(Typography)(({ theme }) => ({
  position: "relative",
  width: "100%",
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: "10px",
  cursor: "pointer",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const StyledTooltipContent = styled(Typography)(({ theme }) => ({
  lineHeight: "16px",
  fontSize: "10px",
  color: theme.palette.common.white,
}));

interface Props {
  data: Array<unknown>;
  docId: string;
  getDocumentPreview: () => void;
  fileName: string;
  type: string;
  isHighlightingFailure: boolean;
}

const DataTableView = ({
  data,
  getDocumentPreview,
  fileName,
  type,
  docId,
  isHighlightingFailure,
}: Props) => {
  const [viewMode, setViewMode] = useState(VIEW_MODE.TABLE);
  const [scrollStatus, setScrollStatus] = useState<number>(0);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;

    const { scrollTop, scrollHeight, clientHeight } = target;

    if (scrollTop === 0) {
      setScrollStatus(0); // start position
    } else if (scrollTop + clientHeight >= scrollHeight) {
      setScrollStatus(1); // end position
    }
  }, []);

  return (
    <StyledWrapper>
      {/* TODO: make it a fn header component already*/}
      <StyledHeader>
        <StyledClickableName onClick={getDocumentPreview}>
          <Tooltip
            arrow={true}
            placement="top-start"
            enterDelay={0}
            title={<StyledTooltipContent>{fileName}</StyledTooltipContent>}
          >
            <StyledFileName>{fileName}</StyledFileName>
          </Tooltip>
        </StyledClickableName>
        <ButtonsContainer>
          {isHighlightingFailure && <HighlightingFailure />}
          <ViewModeSwitch value={viewMode} onChange={setViewMode} />
          <DownloadFile docId={docId} type={type} />
        </ButtonsContainer>
      </StyledHeader>
      <StyledContainer
        scrollStatus={scrollStatus}
        isTableView={viewMode === VIEW_MODE.TABLE}
      >
        {data?.length ? (
          <HighlightActionMenu>
            <>
              {viewMode === VIEW_MODE.LIST && <ListView data={data} />}
              {viewMode === VIEW_MODE.TABLE && (
                <TableView data={data} handleScroll={handleScroll} />
              )}
            </>
          </HighlightActionMenu>
        ) : (
          <Typography color="primary" variant="h4">
            No Records
          </Typography>
        )}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default DataTableView;
