import React, { ReactElement, useMemo } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Box,
} from "@mui/material";
import { getHighlightText } from "src/utils/get-highlight-text";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import theme from "src/theme";

interface Props {
  data: unknown[];
  handleScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}

const TableView = ({ data, handleScroll }: Props) => {
  const numberOfColumns = 3;
  const headerTitles = useMemo(() => {
    const highlightedFields: string[] = [];
    const regularFields: string[] = [];
    Object.keys(data[0]).forEach((key) => {
      if (data.some((row) => row[key].includes("<span"))) {
        highlightedFields.push(key);
      } else {
        regularFields.push(key);
      }
    });

    return [...highlightedFields, ...regularFields]
      .filter((field) => data[0][field]?.length)
      .reduce((accum, field, index) => {
        const chunkIndex = Math.floor(index / numberOfColumns);

        if (!accum[chunkIndex]) {
          accum[chunkIndex] = [];
        }

        accum[chunkIndex].push(field);

        return accum;
      }, []);
  }, [data]);

  return (
    <Box
      sx={{
        maxHeight: 145,
        overflow: "auto",
      }}
      onScroll={handleScroll}
    >
      <Table>
        <TableBody>
          {headerTitles.map((headerTitlesRow, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow sx={{ backgroundColor: "#f8f9fb" }}>
                  {headerTitlesRow.map((field: string) => {
                    return (
                      <TableCell
                        sx={{
                          width: "33%",
                          whiteSpace: "nowrap",
                          py: "6px",
                          fontSize: "10px",
                          lineHeight: "12px",
                          color: theme.palette.grey[600],
                          textTransform: "uppercase",
                          borderBottom: "none",
                        }}
                        key={field}
                      >
                        {getHighlightText(field)}
                      </TableCell>
                    );
                  })}
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {headerTitlesRow.map((field: string) => {
                    return (
                      <TableCell
                        key={field}
                        sx={{
                          whiteSpace: "nowrap",
                          py: 1,
                          fontSize: "12px",
                          lineHeight: "12px",
                          color: theme.palette.grey[900],
                          maxWidth: "250px",
                          borderBottom: "none",
                        }}
                      >
                        <Tooltip
                          placement={"bottom-start"}
                          title={
                            <HighlightActionMenu>
                              {
                                getHighlightText(data[0][field], {
                                  color: theme.palette.primary.main,
                                }) as unknown as ReactElement
                              }
                            </HighlightActionMenu>
                          }
                          arrow
                          sx={{
                            mr: "20px",
                            backgroundColor: "red",
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                p: 1,
                                bgcolor: "#122945",
                                "& .MuiTooltip-arrow": {
                                  color: "#122945",
                                },
                              },
                            },
                          }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -5],
                                },
                              },
                            ],
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {
                              getHighlightText(
                                data[0][field]
                              ) as unknown as string
                            }
                          </div>
                        </Tooltip>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TableView;
