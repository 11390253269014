import React, { useCallback, useMemo } from "react";
import { Box, Pagination, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MappedSearchResult } from "src/types/Search";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.palette.grey[500],
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: "10px 26px",
  zIndex: 5,
  backgroundColor: theme.palette.common.white,
  boxSizing: "border-box",
  borderLeft: "1px solid",
}));

export interface Props {
  data: MappedSearchResult;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const BulkSearchPagination = ({ data, page, setPage }: Props) => {
  const { pageCount, totalCounts, size } = useMemo(() => {
    let pageCount;
    let totalCounts;
    let size;
    if (data) {
      totalCounts = data.totalCounts;
      pageCount = Math.ceil(totalCounts / data.size);
      size = data.size;
    }

    return { pageCount, totalCounts, size };
  }, [data]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      if (value === 1) {
        setPage(0);
      }

      setPage(value);
    },
    [data, page]
  );

  const activePage = (data?.page as number) ?? 1;

  const fromIndex = size * (activePage - 1) + 1;
  const toIndex = Math.min(size * (activePage - 1) + size, totalCounts);

  if (totalCounts === 0) {
    return null;
  }

  return (
    <StyledBox>
      <Typography variant="caption">
        Showing {fromIndex} - {toIndex} of {totalCounts}
      </Typography>
      <Pagination
        shape="rounded"
        count={pageCount}
        page={activePage}
        onChange={handleChange}
      />
      <Typography variant="caption">Show {size} results</Typography>
    </StyledBox>
  );
};

export default BulkSearchPagination;
