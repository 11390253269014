import React, { useEffect, useRef } from "react";

import { snackbar } from "src/view/toaster";
import { Group } from "src/types/UserGroup";

import { StyledTextAreaTitle } from "../StyledComponents";
import { useUserGroups } from "src/modules/api/userGroups";

type Props = {
  formData: Group;
  setFormData: React.Dispatch<React.SetStateAction<Group>>;
  selectedGroup: Group;
};
const GroupNameEdit = ({ formData, setFormData, selectedGroup }: Props) => {
  const { updateGroupDetails } = useUserGroups();
  const { mutateAsync: updateGroupName } = updateGroupDetails({
    successMessage: "Group name was updated successfully!",
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleGroupNameChange = async () => {
    const newName = formData.name;

    if (newName === selectedGroup.name) {
      return;
    }

    if (!newName) {
      snackbar.error("The name is required.");
      return;
    }

    await updateGroupName({
      id: selectedGroup?.group_id,
      name: newName,
    });
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [formData.name]);

  return (
    <StyledTextAreaTitle
      ref={textareaRef}
      placeholder="Enter Your Group's name"
      value={formData.name}
      name="name"
      onChange={(e) =>
        setFormData((prev) => ({
          ...prev,
          name: e.target.value,
        }))
      }
      onBlur={handleGroupNameChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleGroupNameChange();
        }
      }}
    />
  );
};

export default GroupNameEdit;
