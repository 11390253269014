import { styled, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const StyledLink = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Inter",
  lineHeight: "14.5px",
  cursor: "pointer",
  display: "block",
});

export const StyledRouterLink = styled(RouterLink)(() => ({
  textDecoration: "none",
}));
