import { Switch } from "@mui/material";
import { ConfirmDialog, ConfirmDialogProps } from "src/components/ui";

export interface CellWithSwitchProps {
  value: boolean;
  label: string;
  handleSwitch: (isChecked: boolean) => void;
  withConfirm?: boolean;
  disabled?: boolean;
  confirmDialogProps?: ConfirmDialogProps;
}

const CellWithSwitch = ({
  label,
  handleSwitch,
  withConfirm,
  value,
  disabled = false,
  confirmDialogProps,
}: CellWithSwitchProps) => {
  if (withConfirm)
    return (
      <ConfirmDialog
        onConfirm={() => handleSwitch(!value)}
        title={`${label} Dataset Access`}
        description="Have you talked with the dataset owner to give an external org access to this dataset?"
        confirmText="Yes"
        cancelText="No"
        disabled={disabled}
        {...confirmDialogProps}
      >
        <Switch color="secondary" disabled={disabled} checked={value} />
      </ConfirmDialog>
    );
  return (
    <Switch
      color="secondary"
      checked={value}
      onChange={(e) => handleSwitch(e.target.checked)}
      disabled={disabled}
    />
  );
};

export default CellWithSwitch;
