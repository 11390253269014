import { SelectSize } from "./types";

export const selectSizes: Record<SelectSize, any> = {
  big: {
    select: {
      padding: "16px 18px",
      minHeight: "18px !important",
    },
  },
  medium: {
    select: {
      padding: "12px 18px",
      minHeight: "18px !important",
    },
  },
  small: {
    select: {
      padding: "8px 10px",
      minHeight: "18px !important",
    },
  },
};
