// Generates a range of years between the minimum and maximum dates
import { subYears } from "date-fns";

export const getYearsRange = (
  minDate: Date = subYears(new Date(), 80),
  maxDate: Date = new Date()
): number[] => {
  const minYear = minDate.getFullYear();
  const maxYear = maxDate.getFullYear();
  const years: number[] = [];

  for (let i = minYear; i <= maxYear; i++) {
    years.push(i);
  }

  return years;
};
