import {
  useContext,
  useRef,
  useMemo,
  forwardRef,
  PropsWithChildren,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { JSONView } from "./JSONView";
import { EMLView } from "./EMLView";
import DataTableView from "./DataTableView";
import RawTextView from "./RawTextView";
import ObjectView from "./ObjectView";
import { DataIndex, Highlight, MappedHit } from "src/types/Search";
import { usePostHog } from "posthog-js/react";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { HogEvent } from "src/types/PosthogEvents";
import {
  formatPreviewSearchParam,
  formatVariablesSearchParam,
  prepSearchResultData,
  getFileNameForIndex,
} from "src/modules/api/search/SearchProvider";
import { DocTypes } from "src/types/Dataset";
import searchStoreSelectors from "src/modules/api/search/store/selectors";
import { ModeledEntity } from "./ModeledEntity";
import { useDatasetHitsContext } from "../../DatasetHits/DatasetHitsContext";

const CardPreviewBox = styled(Box)<BoxProps>({
  display: "block",
  maxHeight: "235px",
  boxSizing: "border-box",
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  marginBottom: "12px",
  borderRadius: "6px",
  border: "1px solid #ced3d9",
  "&:focus": {
    outline: "none",
  },
});

const StyledSimplePreviewWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
  marginBottom: 12,
}));

export interface SearchResultCardPreviewProps {
  id: string;
  datasetId: string;
  hit: MappedHit;
  type: DocTypes | string;
  isLastEntity?: boolean;
  highlight?: Highlight;
  withoutWrapper?: boolean;
}

interface WrapperProps extends PropsWithChildren {
  isLastEntity?: boolean;
}

// RoT: 1 by @inperegelion
const SearchResultCardPreview = ({
  id,
  hit,
  highlight,
  type,
  isLastEntity,
  datasetId,
  withoutWrapper,
}: SearchResultCardPreviewProps) => {
  const posthog = usePostHog();
  const location = useLocation();
  const data = prepSearchResultData(hit);
  const variables = useSelector(searchStoreSelectors.selectVariables);
  const { setSelectedResult } = useContext(PreviewContext);
  const { datasetMetadata } = useDatasetHitsContext(datasetId);
  const navigate = useNavigate();
  const cardPreviewRef = useRef<HTMLDivElement>(null);
  const fileName = getFileNameForIndex(hit);

  const getDocumentPreview = () => {
    const source_type = hit.dataIndex === DataIndex.modeled ? 0 : 1;

    const previewDoc: PreviewDocProps = {
      title: data?.name ?? datasetMetadata.title ?? "",
      type,
      source_type,
      dataset: datasetMetadata.dataset,
      updatedOn: datasetMetadata.updatedOn?.toString(),
      // old types coming through, silence with any
      data: data as any,
      _id: id,
      wikiItem: datasetMetadata.wikiItem,
      highlight,
    };

    posthog.capture(
      source_type === 1
        ? HogEvent.SOURCE_DATA_PREVIEWED
        : HogEvent.MODEL_DATA_PREVIEWED
    );
    setSelectedResult(data[0]);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("q", formatVariablesSearchParam({ ...variables }));
    searchParams.set("preview", formatPreviewSearchParam(previewDoc));

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      {
        state: { ...(location.state || {}), highlightContent: highlight }, // Merge old state with the new state
      }
    );
  };

  // TODO: a way to DRY up the props
  const renderContent = (type: string, data: any, id: string) => {
    // if empty search row or using * wild card search
    const isWildCardSearch =
      variables.content === "*" || !variables.content.trim().length;

    switch (type) {
      case "csv":
      case "parquet":
        return (
          <DataTableView
            data={data}
            docId={id}
            getDocumentPreview={getDocumentPreview}
            fileName={fileName}
            type={type}
            isHighlightingFailure={!isWildCardSearch && !highlight}
          />
        );
      case "json":
      case "jsonl":
        return (
          <JSONView
            data={`<pre>${JSON.stringify(data, null, 2)}</pre>`}
            docId={id}
            getDocumentPreview={getDocumentPreview}
            fileName={fileName}
            type={type}
            isHighlightingFailure={!isWildCardSearch && !highlight}
          />
        );
      case "msg":
      case "eml":
        return (
          <EMLView
            data={data}
            docId={id}
            getDocumentPreview={getDocumentPreview}
            fileName={fileName}
            type={type}
            isHighlightingFailure={!isWildCardSearch && !highlight}
          />
        );
      default:
        break;
    }

    // TODO: test this: what result types get here?
    if (typeof data === "string") {
      const resolvedContent =
        type === "pdf" || !hit?.highlight?.content?.[0]
          ? data
          : hit.highlight.content[0];

      return (
        // TODO: lots of props here come from the first hit (follow the highlight prop to see),
        <RawTextView
          data={resolvedContent}
          docId={id}
          getDocumentPreview={getDocumentPreview}
          fileName={fileName}
          type={type}
          isHighlightingFailure={!isWildCardSearch && !highlight}
        />
      );
    }

    // TODO: and here
    if (Array.isArray(data)) {
      return (
        <ObjectView
          data={data[0]}
          highlight={highlight}
          docId={id}
          getDocumentPreview={getDocumentPreview}
          fileName={fileName}
          type={type}
          isHighlightingFailure={!isWildCardSearch && !highlight}
        />
      );
    }

    return (
      <ModeledEntity
        type={type ?? ""}
        hit={hit}
        highlight={highlight}
        getDocumentPreview={getDocumentPreview}
      />
    );
  };

  const previewContent = useMemo(() => {
    return renderContent(type, data, id);
  }, [type, data, id, variables, fileName, highlight, getDocumentPreview]);

  const ContentWrapper = useMemo(() => {
    return withoutWrapper ? SimpleContentWrapper : Wrapper;
  }, [withoutWrapper]);

  return (
    <ContentWrapper isLastEntity={isLastEntity} ref={cardPreviewRef}>
      {previewContent}
    </ContentWrapper>
  );
};

const SimpleContentWrapper = forwardRef(({ children }: WrapperProps, ref) => (
  <StyledSimplePreviewWrapper ref={ref} tabIndex={0}>
    {children}
  </StyledSimplePreviewWrapper>
));

const Wrapper = forwardRef(({ children, isLastEntity }: WrapperProps, ref) => {
  return (
    <Box
      sx={{
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          bottom: isLastEntity ? "50%" : 0,
          left: "10px",
          width: "1px",
          height: isLastEntity ? "calc(50% + 15px)" : "calc(100% + 15px)",
          backgroundColor: "#ced3D9",
        },
        "&:after": isLastEntity
          ? {
              content: '""',
              position: "absolute",
              bottom: "calc(50% - 18px)",
              left: "10px",
              width: "21px",
              height: "21px",
              border: "1px solid #CED3D9",
              borderTop: "none",
              borderRight: "none",
              borderRadius: "0 0 0 4px",
            }
          : {
              content: '""',
              position: "absolute",
              bottom: "50%",
              left: "11px",
              width: "21px",
              height: "1px",
              backgroundColor: "#ced3D9",
            },
      }}
    >
      <Box
        id="card-preview"
        ref={ref}
        tabIndex={0}
        sx={{
          mt: 1,
          flexGrow: 1,
          marginLeft: 4,
        }}
      >
        <CardPreviewBox onWheel={(e) => e.stopPropagation()}>
          {children}
        </CardPreviewBox>
      </Box>
    </Box>
  );
});

SearchResultCardPreview.displayName = "SearchResultCardPreview";

export default SearchResultCardPreview;
