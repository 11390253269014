import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/index";
import { fetchApi } from "src/modules/shared/api";

import queryKeys from "src/modules/api/queryKeys";
import { APIValidationErrors, Paginated } from "src/types/Shared";
import { Group, GroupShort, User } from "src/types/UserGroup";
import { useUser } from "src/modules/api/auth";
import { Features } from "src/types/Auth";
import { snackbar } from "src/view/toaster";
import { useFetch } from "../fetch";

interface GetGroupsParams {
  searchKey?: string;
  pageParam?: number;
  enabled?: boolean;
}

export function useUserGroups() {
  const { user } = useUser();
  const { getApi, postApi, deleteApi, putApi } = useFetch();

  const getGroupsFn = async ({ searchKey, pageParam }: GetGroupsParams) => {
    try {
      const params = new URLSearchParams({
        ...(searchKey && { name: searchKey }),
        ...(pageParam && { page: pageParam.toString() }),
      });
      const res = await fetchApi("groups/?" + params, {
        method: "GET",
      });
      return await res.json();
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getGroups = (params: GetGroupsParams = { enabled: true }) =>
    useQuery<Paginated<GroupShort[]>>({
      enabled:
        !!user &&
        !!user.features.includes(Features.USER_GROUP) &&
        params.enabled,
      queryFn: ({ pageParam }) => {
        return getGroupsFn({ searchKey: params.searchKey, pageParam });
      },
      queryKey: [queryKeys.GROUPS, params],
    });

  const getGroupsInfinite = (params: GetGroupsParams) =>
    useInfiniteQuery<Paginated<GroupShort[]>>({
      enabled: !!user && !!user.features.includes(Features.USER_GROUP),
      queryFn: ({ pageParam = 1 }) => {
        return getGroupsFn({
          searchKey: params.searchKey,
          pageParam,
        });
      },
      queryKey: [queryKeys.GROUPS, params],
      getNextPageParam: (lastPage) => lastPage.next_page_number,
    });

  const getGroupDataFn = async (id: string) => {
    const data = await getApi(["groups", id]);

    return data;
  };

  const getGroupData = (id: string) =>
    useQuery<Group>({
      enabled: !!user && user.features.includes(Features.USER_GROUP),
      queryFn: () => getGroupDataFn(id),
      queryKey: [queryKeys.USERS_GROUP_BY_ID, { id }],
    });

  const getGroupUsersFn = async (id: string) => {
    const data = await getApi(["groups", id, "users"]);
    return data;
  };

  const getGroupUsers = (id: string) =>
    useQuery<User[]>({
      enabled: !!user && user.features.includes(Features.USER_GROUP),
      queryFn: () => getGroupUsersFn(id),
      initialData: [],
      queryKey: [queryKeys.GROUP_ID_USERS, { id }],
    });

  const getValidation = (response: Response): string => {
    let apiErrorMsg = "";

    response?.json().then((errorResponse: APIValidationErrors<Group>) => {
      if (typeof errorResponse !== "object" || errorResponse === null) {
        return false;
      } else if (errorResponse) {
        for (const [, value] of Object.entries(errorResponse)) {
          apiErrorMsg = Object.values(value).join(", ");
        }
      }

      snackbar.error(apiErrorMsg || "Something went wrong");
    });

    return apiErrorMsg;
  };

  const createGroup = useMutation({
    mutationFn: async (data: Group) => {
      const newGroup = await postApi(
        "groups",
        { body: JSON.stringify(data) },
        getValidation
      );
      const newGroupData = await newGroup.json();

      return newGroupData;
    },
    onSuccess: () => {
      snackbar.success("Group was created successfully");
      queryClient.invalidateQueries([queryKeys.GROUPS]);
    },
  });

  const updateGroupDetails = ({
    successMessage,
  }: {
    successMessage?: string;
  }) =>
    useMutation({
      mutationFn: ({ id, ...data }: Omit<Group, "group_id">) =>
        putApi(
          ["groups", id],
          {
            body: JSON.stringify(data),
          },
          getValidation
        ),
      onSuccess: (_, { id, ...updatedFields }) => {
        snackbar.success(successMessage || "Group was updated successfully");
        queryClient.setQueryData(
          [queryKeys.USERS_GROUP_BY_ID, { id }],
          (oldGroupData: Group) => {
            return {
              ...oldGroupData,
              ...updatedFields,
            };
          }
        );
      },
    });

  const addObjectsToGroup = ({ successMessage }: { successMessage?: string }) =>
    useMutation({
      mutationFn: ({ data, id }: { data: Group; id: string }) =>
        postApi(
          ["groups", id, "add_objects"],
          { body: JSON.stringify(data) },
          getValidation
        ),
      onSuccess: () => {
        snackbar.success(successMessage || "Group was updated successfully");
      },
    });

  const removeObjectsToGroup = ({
    successMessage,
  }: {
    successMessage?: string;
  }) =>
    useMutation({
      mutationFn: ({ data, id }: { data: Group; id: string }) =>
        postApi(
          ["groups", id, "delete_objects"],
          {
            body: JSON.stringify(data),
          },
          getValidation
        ),
      onSuccess: () => {
        snackbar.success(successMessage || "Group was updated successfully");
      },
    });

  const resendEmail = () =>
    useMutation({
      mutationFn: (data: { user_id: string }) =>
        postApi(["users", "resend_signup_email"], {
          body: JSON.stringify(data),
        }),
      onSuccess: () => {
        snackbar.success("Email invitation sent.");
      },
      onError: () => {
        snackbar.error("Something went wrong");
      },
    });

  const deleteGroup = () =>
    useMutation({
      mutationFn: (id: string) => deleteApi(["groups", id]),
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.GROUPS]);
      },
    });

  const saveUsers = () =>
    useMutation({
      mutationFn: async (users: User[]) => {
        const newUsers = await postApi(["users", "bulk"], {
          body: JSON.stringify(users),
        });
        const newUsersData = await newUsers.json();

        return newUsersData;
      },
      onSuccess: () => {
        snackbar.success(
          "Created new users. They should receive an invite email soon."
        );
      },
    });

  return {
    getGroups,
    getGroupsInfinite,
    getGroupData,
    getGroupUsers,
    saveUsers,
    resendEmail,
    createGroup,
    updateGroupDetails,
    deleteGroup,
    addObjectsToGroup,
    removeObjectsToGroup,
  };
}
