import React, { useEffect, useMemo, useRef, useState } from "react";
import lodash from "lodash";
import { FieldError, UseFormSetError } from "react-hook-form";
import DatePicker, { DatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from "date-fns";
import { Box, IconButton, InputBase } from "@mui/material";
import { styled, SxProps, Theme } from "@mui/material/styles";
import { updateSelectedDate } from "src/view/components/DatePicker/utils/update-selected-date";
import { updateSelectedDateByYearOrMonth } from "src/view/components/DatePicker/utils/update-selected-date-by-year-or-month";
import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar-event-line.svg";
import CustomDatePickerFooter from "./CustomDatePickerFooter";
import CustomDatePickerHeader, {
  CustomDatePickerHeaderProps,
} from "./CustomDatePickerHeader";
import theme from "src/theme";

type Props = Partial<DatePickerProps> & {
  sx?: SxProps<Theme>;
  label?: string;
  helperText?: string;
  error?: FieldError;
  setError?: UseFormSetError<any>;
  onChange?: (
    date: Date | null,
    event?:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => void;
  showStepButton?: boolean;
  showSaveButton?: boolean;
  // for single date select - TS error when spreading props otherwise (see DatePickerProps)
  selectsRange?: never;
  selectsMultiple?: never;
  setOpen?: (open: boolean) => void;
  iconButton?: boolean;
  inputStyles?: SxProps<Theme>;
  iconStyles?: SxProps<Theme>;
  onClose?: () => void;
  iconClassName?: string;
  svgCssStyles?: React.CSSProperties;
  startDate?: Date;
  invalid?: boolean;
} & CustomDatePickerHeaderProps;

const StyledInputGroupWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !["invalid", "hasCustomInput"].includes(prop as string),
})<{ invalid?: boolean; sx: SxProps<Theme>; hasCustomInput?: boolean }>(
  ({ invalid, theme }) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    marginBottom: 10,
    "& .react-datepicker": {
      border: "none",
      position: "absolute",
      boxShadow: "0px 3px 13px 0px #0000001A",
      top: 50,
      zIndex: 100,
    },
    "& .react-datepicker__header": {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
    },
    "& .react-datepicker__day-names": {
      marginBottom: "-10px",
      marginTop: "0px",
    },
    "& .react-datepicker__day-name": {
      margin: "2px",
      lineHeight: "32px",
      width: "32px",
      color: "#8894A2",
      fontWeight: 400,
      fontSize: "12px",
    },
    "& .react-datepicker__day--disabled": {
      color: theme.palette.grey[400],
    },
    "& .react-datepicker__day--selected": {
      fontWeight: 500,
    },
    "& .react-datepicker__input-container": {
      display: "flex",
      alignItems: "center",
      "& input": {
        height: 48,
        padding: "6px 10px 5px 40px",
        border: `1px solid`,
        fontFamily: "inherit",
        borderColor: invalid
          ? theme.palette.secondary.main
          : theme.palette.grey[400],
        borderRadius: "4px",
      },
    },
    ".react-datepicker__month-container": {
      float: "none",
    },
    ".react-datepicker__day": {
      lineHeight: "32px",
      width: "32px",
      textAlign: "center",
      margin: "2px",
      cursor: "pointer",
      transition: "background-color 0.3s, color 0.3s",
      borderRadius: "4px",
    },
    ".react-datepicker__day:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected":
      {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    ".react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover":
      {
        backgroundColor: theme.palette.grey[800],
        borderColor: theme.palette.grey[800],
        color: theme.palette.primary.contrastText,
      },
    "& .react-datepicker__calendar-icon": {
      paddingLeft: "12px",
      width: "20px",
      height: "20px",
    },
    ".react-datepicker__children-container": {
      width: "250px",
    },
  })
);

const StyledLabel = styled("label")(({ theme }) => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: theme.palette.primary.main,
  marginBottom: 10,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ".MuiBox-root": {
    marginBottom: 0,
  },
  "& svg": {
    color: theme.palette.primary.main,
    height: 18,
    width: 18,
  },
}));

const DateInput = styled(InputBase, {
  shouldForwardProp: (prop) =>
    !["invalid", "filled", "focused", "disabled"].includes(prop.toString()),
})<{
  filled?: boolean;
  invalid?: boolean;
  focused?: boolean;
  disabled?: boolean;
}>(({ theme, invalid, filled, focused, disabled }) => {
  let borderColor = theme.palette.grey["400"];

  if (disabled) {
    borderColor = theme.palette.grey[400];
  } else if (invalid) {
    borderColor = theme.palette.secondary.main;
  } else if (filled || focused) {
    borderColor = theme.palette.primary.main;
  }

  return {
    border: "1px solid",
    borderColor,
    width: "100%",
    padding: "12px 18px",
    borderRadius: "8px",
    height: "auto !important",
    "& .MuiInputBase-input": {
      color: disabled ? theme.palette.grey[400] : theme.palette.primary.main,
      fontSize: "14px",
      lineHeight: "18px",
      padding: 0,
      height: "18px",
    },
    "&:focus-visible": {
      outline: "none",
      borderColor: theme.palette.primary.main,
    },
  };
});

const StyledCalendarIcon = styled(CalendarIcon, {
  shouldForwardProp: (prop) => !["disabled"].includes(prop.toString()),
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.grey[400] : theme.palette.primary.main,
  width: "16px",
  height: "16px",
}));

const CustomDatePicker = React.forwardRef(
  (
    {
      sx,
      label,
      error,
      setError,
      name,
      onChange,
      placeholderText = "Select Date",
      customInput,
      renderCustomHeader,
      showMonthYearDropdown,
      minDate,
      maxDate,
      selected,
      showStepButton = false,
      showSaveButton = false,
      open,
      setOpen,
      onClose,
      iconButton = false,
      inputStyles = {},
      iconStyles = {},
      svgCssStyles = {},
      iconClassName,
      disabled,
      startDate = new Date(),
      invalid,
      ...restDatepickerProps
    }: Props,
    ref: React.Ref<DatePicker>
  ) => {
    const step = 3;
    const [selectedDate, setSelectedDate] = useState<Date | null>(
      selected || null
    );
    const [showCalendar, setShowCalendar] = useState(open);
    const [inputError, setInputInputError] = useState<{
      code: number;
      msg: string;
    }>(null);
    const dateToUse = selectedDate || startDate;
    const datePickerRef = useRef<HTMLDivElement>(null);

    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };

    useEffect(() => setShowCalendar(open ?? showCalendar), [open]);

    const adjustedDateWithStep = useMemo(() => {
      return addMonths(selectedDate, step);
    }, [selectedDate]);

    const isAdjustedDateLessThanMaxDate = useMemo(() => {
      if (!adjustedDateWithStep || !maxDate) return false;
      return adjustedDateWithStep <= maxDate;
    }, [adjustedDateWithStep, maxDate]);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false);
        setOpen?.(false);
        onClose?.();
      }
    };

    useEffect(() => {
      if (showCalendar) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showCalendar]);

    useEffect(() => {
      setSelectedDate(selected || null);
    }, [selected]);

    const handleInputError = (error: { code: number; msg: string }) => {
      setInputInputError(error);
      setError && setError(name, { message: error.msg, type: "value" });
    };

    const handleInputClick = () => {
      !disabled && setShowCalendar(!showCalendar);
      !disabled && setOpen?.(!showCalendar);
    };

    const handleChange = (date: Date | null) => {
      inputError && setInputInputError(null);
      date && setSelectedDate(date);
      !showSaveButton && onChange(date);
    };

    const handleAddThreeMonths = () => {
      selectedDate && setSelectedDate(adjustedDateWithStep);
    };

    const handleSave = () => {
      selectedDate && onChange?.(selectedDate);
      setShowCalendar(false);
      setOpen?.(false);
      onClose();
    };

    const changeMonth = (month: number) =>
      updateSelectedDateByYearOrMonth({
        newMonth: month,
        selectedDate: dateToUse,
        onChange: showSaveButton ? setSelectedDate : onChange,
        minDate,
        maxDate,
      });

    const changeYear = (year: number) =>
      updateSelectedDateByYearOrMonth({
        newYear: year,
        selectedDate: dateToUse,
        onChange: showSaveButton ? setSelectedDate : onChange,
        minDate,
        maxDate,
      });

    const decreaseMonth = () =>
      updateSelectedDate({
        monthAdjustment: -1,
        selectedDate: dateToUse,
        onChange: showSaveButton ? setSelectedDate : onChange,
        minDate,
      });

    const increaseMonth = () =>
      updateSelectedDate({
        monthAdjustment: 1,
        selectedDate: dateToUse,
        onChange: showSaveButton ? setSelectedDate : onChange,
      });

    const decreaseYear = () =>
      updateSelectedDate({
        yearAdjustment: -1,
        selectedDate: dateToUse,
        onChange: showSaveButton ? setSelectedDate : onChange,
      });

    const increaseYear = () =>
      updateSelectedDate({
        yearAdjustment: 1,
        selectedDate: dateToUse,
        onChange: showSaveButton ? setSelectedDate : onChange,
      });

    return (
      <StyledInputGroupWrapper ref={datePickerRef} sx={sx}>
        {label && <StyledLabel>{label}</StyledLabel>}
        {iconButton ? (
          <StyledIconButton
            size="small"
            onClick={handleInputClick}
            sx={{ ...iconStyles }}
            className={iconClassName}
          >
            <CalendarIcon
              style={{ fill: theme.palette.primary.main, ...svgCssStyles }}
            />
          </StyledIconButton>
        ) : (
          <DateInput
            key={lodash.uniqueId("date-input-")}
            value={
              selectedDate
                ? selectedDate.toLocaleDateString("en-US", options)
                : ""
            }
            onClick={handleInputClick}
            placeholder={placeholderText}
            sx={{ width: "100%", ...inputStyles }}
            readOnly
            disabled={disabled}
            invalid={invalid}
            filled={!!selectedDate}
            focused={showCalendar}
            endAdornment={
              <StyledCalendarIcon disabled={disabled} style={svgCssStyles} />
            }
          />
        )}
        {showCalendar && (
          <DatePicker
            open={open}
            ref={ref}
            selected={selectedDate || startDate}
            onChange={handleChange}
            inline
            minDate={minDate}
            maxDate={maxDate}
            onInputError={handleInputError}
            renderCustomHeader={(props) => (
              <CustomDatePickerHeader
                {...props}
                minDate={minDate}
                maxDate={maxDate}
                date={selectedDate || startDate}
                changeMonth={changeMonth}
                changeYear={changeYear}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
              />
            )}
            {...restDatepickerProps}
          >
            <CustomDatePickerFooter
              showStepButton={showStepButton}
              showSaveButton={showSaveButton}
              step={step}
              isAdjustedDateLessThanMaxDate={isAdjustedDateLessThanMaxDate}
              handleAddThreeMonths={handleAddThreeMonths}
              handleSave={handleSave}
            />
          </DatePicker>
        )}
      </StyledInputGroupWrapper>
    );
  }
);

export default CustomDatePicker;
