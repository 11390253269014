import { useEffect, useState } from "react";
import { GroupFeatures } from "src/types/UserGroup";

import { StyledTabContainer } from "../StyledComponents";

import { FeatureRow } from "./FeatureRow";
import { features } from "./constants";

interface Props {
  handleUpdate?: (checked: boolean, featureName: GroupFeatures) => void;
  data?: Array<string>;
  isFeaturesUpdating: boolean;
}

const Features = ({ handleUpdate, data, isFeaturesUpdating }: Props) => {
  const [shouldDisableToggles, setShouldDisableToggles] = useState(false);

  useEffect(() => {
    if (isFeaturesUpdating) setShouldDisableToggles(true);
    else setTimeout(() => setShouldDisableToggles(false), 600);
  }, [isFeaturesUpdating]);

  const handleFeatureUpdate = (
    checked: boolean,
    featureName: GroupFeatures
  ) => {
    handleUpdate(checked, featureName);
    setShouldDisableToggles(true);
  };

  return (
    <StyledTabContainer>
      {Object.entries(features).map(([featureKey, featureName]) => (
        <FeatureRow
          key={`feature-toggle-${featureKey}`}
          featureKey={featureKey as GroupFeatures}
          featureName={featureName}
          handleUpdate={
            handleFeatureUpdate as (
              checked: boolean,
              featureName: GroupFeatures
            ) => void
          }
          isOn={data?.includes(featureKey)}
          isFeaturesUpdating={shouldDisableToggles}
        />
      ))}
    </StyledTabContainer>
  );
};

export default Features;
