// Determines if the previous or next month should be disabled based on minDate and maxDate constraints
import { getMonth, getYear } from "date-fns";

export const isMonthDisabled = (
  date: Date,
  minDate?: Date,
  maxDate?: Date
): { isPrevMonthDisabled: boolean; isNextMonthDisabled: boolean } => {
  const isPrevMonthDisabled = minDate
    ? getYear(date) === getYear(minDate) && getMonth(date) <= getMonth(minDate)
    : false;

  const isNextMonthDisabled = maxDate
    ? getYear(date) === getYear(maxDate) && getMonth(date) >= getMonth(maxDate)
    : false;

  return { isPrevMonthDisabled, isNextMonthDisabled };
};
