import React, { CSSProperties } from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as ImgIconFilter } from "src/assets/icons/filters.svg";
import { ReactComponent as ImgIconBookmark } from "src/assets/icons/bookmark.svg";

const StyledClearButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[800],
  fontWeight: 400,
  fontSize: "16px",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
  },
}));

const FilterButtonBox = styled(Box)(() => ({
  height: "calc(100% - 28px)",
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  gap: 20,
}));

const FilterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: 30,
  width: 30,
  height: 30,
  margin: 4,
  padding: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "transparent",
  "&:hover": {
    color: theme.palette.grey[400],
    backgroundColor: "transparent",
  },
  "& svg": {
    height: 24,
    width: 24,
  },
}));

interface Props {
  hasQuery: boolean;
  onClear: () => void;
  onToggleFilter: () => void;
  showFilters: boolean;
  showBookmark: boolean;
  customStyles?: CSSProperties;
  iconStyles?: CSSProperties;
}

const SearchControls = ({
  hasQuery,
  onClear,
  onToggleFilter,
  showFilters,
  showBookmark,
  customStyles,
  iconStyles,
}: Props) => (
  <FilterButtonBox sx={customStyles}>
    {hasQuery && (
      <StyledClearButton onClick={onClear}>
        <span>Clear</span>
      </StyledClearButton>
    )}
    {showFilters && (
      <FilterButton disableRipple onClick={onToggleFilter} sx={iconStyles}>
        <ImgIconFilter style={{ height: 24, width: 24 }} />
      </FilterButton>
    )}
    {showBookmark && (
      <FilterButton
        disableRipple
        onClick={() => console.log("Click by bookmark")}
        sx={iconStyles}
      >
        <ImgIconBookmark style={{ height: 20 }} />
      </FilterButton>
    )}
  </FilterButtonBox>
);

export default React.memo(SearchControls);
