import { FC } from "react";
import { styled, Box, Typography, Button } from "@mui/material";
import Link from "./Link";
import { useUser } from "src/modules/api/auth";

interface Props {
  message: string;
}

const StyledErrorMessage = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "500",
  fontFamily: "Inter, sans-serif",
  color: theme.palette.primary.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: "block",
  width: "fit-content",
  padding: "6px 8px",
  backgroundColor: theme.palette.red.main,
  margin: "4px auto 0 auto",
  "&:hover": {
    backgroundColor: theme.palette.red.main,
  },
}));

const StyledTextLink = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "600",
  fontFamily: "Inter, sans-serif",
  color: theme.palette.common.white,
  textAlign: "center",
}));

const ErrorNotice: FC<Props> = ({ message }) => {
  const { logout } = useUser();
  return (
    <Box>
      <StyledErrorMessage>{message}</StyledErrorMessage>
      <StyledButton onClick={() => logout()}>
        <Link to="/login">
          <StyledTextLink>Back to Login</StyledTextLink>
        </Link>
      </StyledButton>
    </Box>
  );
};

export default ErrorNotice;
