import React from "react";

import { Box, styled, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StyledCloseButton = styled(IconButton)(() => ({
  padding: 0,
}));

const StyledFileWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 6,
  width: "100%",
  boxSizing: "border-box",
  padding: 6,
  // theme color mismatch here between figma and implementation
  // backgroundColor: theme.palette.grey[100],
  backgroundColor: "#F8F9FB",
}));

const StyledFileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "12px",
  fontWeight: 400,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

interface Props {
  file: File;
  onClear: () => React.SetStateAction<void>;
}

const SelectedFile = ({ file, onClear }: Props) => {
  return (
    <StyledFileWrapper>
      <StyledFileName>{file.name}</StyledFileName>
      <StyledCloseButton aria-label="close" onClick={onClear}>
        <CloseIcon color="primary" fontSize="small" />
      </StyledCloseButton>
    </StyledFileWrapper>
  );
};

export default SelectedFile;
