import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Popper, { PopperProps } from "@mui/material/Popper";
import { Box, Button, Divider, IconButton, styled } from "@mui/material";
import { ArrowOutward as ArrowIcon } from "@mui/icons-material";
import theme from "src/theme";
import { formatVariablesSearchParam } from "src/modules/api/search/SearchProvider";
import { useSearch, emptyQueryVariables } from "src/modules/api/search";
import { parseQueryString } from "src/view/components/Search/utils";
import { snackbar } from "src/view/toaster";
import searchStoreActions from "src/modules/api/search/store/actions";

const TooltipButton = styled(Button)(({ theme }) => ({
  borderRadius: "2px",
  "&:hover": {
    background: theme.palette.grey["200"],
  },
}));

const TooltipDivider = styled(Divider)(() => ({
  margin: "0 3px",
}));

interface Props {
  children: ReactElement | ReactElement[];
  onSearchInTab?: (shouldSetURLParam: boolean) => void;
  keyValueQuery?: string | undefined;
}

const HighlightActionMenu = ({
  children,
  onSearchInTab,
  keyValueQuery,
}: Props) => {
  const dispatch = useDispatch();
  const { handleSearch } = useSearch();
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = useState<Selection | null>(null);
  const targetWrapperRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const saveSelection = (e: any) => {
    e.stopPropagation();
    const _selection = window.getSelection();

    const isWithinTargetWrapper = targetWrapperRef?.current?.contains(
      _selection?.anchorNode
    );

    if (isWithinTargetWrapper && _selection.type === "Range") {
      setOpen(true);
      setSelection(_selection);
      setAnchorEl({
        getBoundingClientRect: () =>
          _selection.getRangeAt(0).getBoundingClientRect(),
      });
    } else {
      setOpen(false);
      setAnchorEl(null);
      setSelection(null);
    }
    return;
  };

  useEffect(() => {
    document.addEventListener("selectionchange", saveSelection);
    return () => document.removeEventListener("selectionchange", saveSelection);
  }, []);

  const handleSubmit = (newTab = false, keyValuePair?: string) => {
    const content = keyValuePair ?? selection.toString();
    const formattedContent = keyValuePair ?? `"${content.replace(/\n/g, "")}"`;

    const newVariables = {
      ...emptyQueryVariables,
      content: formattedContent,
    };

    const searchParams = formatVariablesSearchParam(newVariables);

    if (newTab) {
      return window.open(`${window.location.origin}/search?q=${searchParams}`);
    } else {
      // route change happens on the next line (including removing preview param)
      handleSearch(newVariables);
      const newQueryB = parseQueryString(newVariables.content);
      searchStoreActions.setQueryB(newQueryB)(dispatch);

      if (typeof onSearchInTab === "function") {
        // pass shouldSetParams FALSE in handleClosePreview
        // preventing setting outdated searchParams (handled above)
        onSearchInTab(false);
      }
    }

    return handleClose();
  };

  const handleClose = () => {
    return setOpen(false);
  };

  const handleCopy = () => {
    handleClose();
    snackbar.success("Text copied to clipboard.");
    return navigator.clipboard.writeText(selection?.toString() ?? "");
  };

  return (
    <>
      <Popper
        id={open ? "virtual-element-popper" : undefined}
        open={open}
        anchorEl={anchorEl as PopperProps["anchorEl"]}
        placement="top"
        sx={{
          p: "2px",
          display: "flex",
          bottom: "4px",
          height: "25px",
          borderRadius: 1,
          background: "#FFF",
          boxShadow: "0px 3.16px 6.32px 0px rgba(0, 0, 0, 0.40)",
          zIndex: 1504,
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 6],
            },
          },
        ]}
      >
        <TooltipButton
          disableRipple
          variant="text"
          onClick={() => handleSubmit()}
        >
          Search as text
        </TooltipButton>
        <TooltipDivider
          color={theme.palette.grey[400]}
          orientation="vertical"
          sx={{ opacity: 0.4 }}
        />
        <IconButton
          disableRipple
          onClick={() => handleSubmit(true)}
          sx={{
            borderRadius: "2px",
            padding: "3px",
            "&:hover": {
              background: theme.palette.grey[200],
            },
          }}
        >
          <ArrowIcon fill={theme.palette.grey[800]} />
        </IconButton>
        <TooltipDivider
          orientation="vertical"
          color={theme.palette.grey[400]}
        />

        {keyValueQuery && (
          <>
            <TooltipButton
              disableRipple
              variant="text"
              onClick={() => handleSubmit(false, keyValueQuery)}
            >
              Search as key:value
            </TooltipButton>
            <TooltipDivider
              color={theme.palette.grey[400]}
              orientation="vertical"
              sx={{ opacity: 0.4 }}
            />
            <IconButton
              disableRipple
              onClick={() => handleSubmit(true, keyValueQuery)}
              sx={{
                borderRadius: "2px",
                padding: "3px",
                "&:hover": {
                  background: theme.palette.grey[200],
                },
              }}
            >
              <ArrowIcon fill={theme.palette.grey[800]} />
            </IconButton>
            <TooltipDivider
              orientation="vertical"
              color={theme.palette.grey[400]}
            />
          </>
        )}

        <TooltipButton disableRipple variant="text" onClick={handleCopy}>
          Copy
        </TooltipButton>
      </Popper>

      <Box
        ref={targetWrapperRef}
        sx={{
          "& ::selection": {
            background: "#30C5FF38",
            color: "red",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default HighlightActionMenu;
