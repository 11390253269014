export const capitalizeFirstLetter = (val: string) => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};

const splitCamelCase = (str: string) => {
  return str
    .trim()
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((key) => key.trim());
};

export const splitSnakeCase = (str: string) => {
  return str
    .trim()
    .split("_")
    .map((key) => key.trim());
};

export const formatKey = (str: string) => {
  return splitSnakeCase(str)
    .flatMap(splitCamelCase)
    .map(capitalizeFirstLetter)
    .join(" ");
};
