/* eslint-disable */

export function setupHbs() {
  (function() {
    let w = window;
    let d = document;

    if (w.location.pathname !== "/request-access") return;

    let hbs = function() {
      let hubspot = d.getElementById("hubspot");

      if (hubspot) return;

      let e = d.createElement("script");
      e.setAttribute("id", "hubspot");
      e.setAttribute("type", "text/javascript");
      e.setAttribute("async", "true");
      e.setAttribute("charset", "utf-8");
      e.setAttribute("src", "//js.hsforms.net/forms/embed/v2.js");

      d.documentElement.appendChild(e);
      e.onload = showForm;
    }

    if (d.readyState === "complete") {
      hbs();
    } else if (w.addEventListener) {
      w.addEventListener("load", hbs, false);
    }
  })();
};

function showForm() {
  (function() {
    let hubspot = document.getElementById("hubspot");
    let existingForm = document.getElementsByClassName("hbspt-form")[0];

    if (!hubspot) return;

    if (existingForm) return;
    
    window.hbspt.forms.create({
      portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
      formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
      target: '#request-form'
    });
  })();
};
