import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  DialogContentText,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataCollection } from "src/types/DataCatalog";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { GroupShort } from "src/types/UserGroup";
import { useUserGroups } from "src/modules/api/userGroups";

const StyledInlineFormControl = styled(Box)(() => ({
  display: "flex",
}));

const StyledGroupList = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxHeight: "250px",
  overflow: "auto",
  paddingLeft: "1px",
  marginTop: "30px",
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 8,
  top: 9,
}));

export interface DeleteCollectionDialogProps {
  collection: DataCollection;
  handleClose: () => void;
  handleShare: (collection: DataCollection, groups: string[]) => void;
}

export default function ShareCollectionDialog({
  collection,
  handleClose,
  handleShare,
}: DeleteCollectionDialogProps) {
  const { getGroups } = useUserGroups();
  const { data: groups, isError } = getGroups();
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(
    collection?.shared_groups || []
  );

  const handleCheck = (item: GroupShort) => {
    const vals = [...selectedGroupIds];
    if (!selectedGroupIds.includes(item.group_id)) {
      vals.push(item.group_id);
    } else {
      const index = vals.indexOf(item.group_id);
      vals.splice(index, 1);
    }

    setSelectedGroupIds(vals);
  };

  const handleSubmit = () => {
    handleClose();
    handleShare(collection, selectedGroupIds);
    setSelectedGroupIds([]);
  };

  return (
    <Dialog open={Boolean(collection)} sx={{ width: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <DialogTitle
          sx={{
            wordBreak: "break-word",
            overflowWrap: "break-word",
            maxWidth: "calc(100% - 60px)",
          }}
        >
          Share &quot;{collection?.name}&quot; with your groups
        </DialogTitle>
        <StyledCloseButton
          color="primary"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </StyledCloseButton>
      </Box>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <DialogContentText>
          Select one or more groups below to share this collection with.
        </DialogContentText>
        <StyledGroupList>
          {!groups?.results?.length && !isError && (
            <Typography>No groups to share with</Typography>
          )}
          {!!groups?.results?.length && isError && (
            <Typography>Error fetching groups</Typography>
          )}

          {groups?.results?.map((group: GroupShort, index: number) => {
            return (
              <StyledInlineFormControl key={index}>
                <Checkbox
                  sx={{ padding: 0, width: "16px", marginRight: "8px" }}
                  color={"secondary"}
                  checked={selectedGroupIds.includes(group.group_id)}
                  onChange={() => handleCheck(group)}
                />
                <Typography>{group.name}</Typography>
              </StyledInlineFormControl>
            );
          })}
        </StyledGroupList>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Share
        </Button>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
