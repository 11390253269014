import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  CSSProperties,
  useLayoutEffect,
  useEffect,
} from "react";
import { styled } from "@mui/material/styles";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { useFocus } from "./context/focus";
import { QueryItem } from "src/types/BulkSearch";

const AutoScalingInputStyled = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "isFocused" && prop !== "queryB",
})<{ isFocused: boolean; queryB?: QueryItem[] }>(
  ({ isFocused, theme, queryB }) => ({
    fontSize: "16px",
    fontWeight: 400,
    minWidth: queryB.length <= 1 && !queryB[0]?.value ? "200px" : "10px",
    boxSizing: "border-box",
    caretColor: isFocused
      ? theme.palette.primary.main
      : theme.palette.text.primary,
    "& .MuiInputBase-input": {
      padding: "0",
      overflow: isFocused ? "visible" : "hidden",
      whiteSpace: "nowrap",
      textOverflow: isFocused ? "clip" : "ellipsis",
    },
  })
);

const HiddenSpan = styled("span")(() => ({
  position: "absolute",
  visibility: "hidden",
  height: 0,
  overflow: "scroll",
  whiteSpace: "pre",
  fontSize: "16px",
  fontWeight: 600,
}));

interface AutoScalingInputProps
  extends Omit<InputBaseProps, "value" | "onChange"> {
  queryB: QueryItem[];
  value: string;
  index: number;
  isQuote: boolean;
  onFocus: () => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onRemovePreviousQuery: (index: number) => void;
  inputStyles: CSSProperties;
}

const AutoScalingInput = forwardRef(
  (
    {
      value: propValue,
      onChange: propOnChange,
      index,
      queryB,
      onFocus,
      isQuote,
      onRemovePreviousQuery,
      inputStyles,
      ...otherProps
    }: AutoScalingInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { focusedQuery, setFocusedQuery } = useFocus();
    const [inputWidth, setInputWidth] = useState("10px");
    const [value, setValue] = useState(propValue);
    const spanRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => setValue(propValue), [propValue]);

    useLayoutEffect(() => {
      if (spanRef.current) {
        setInputWidth(`${spanRef.current.offsetWidth}px`);
      }
    }, [focusedQuery, value, isQuote, index]);

    useEffect(() => {
      if (
        focusedQuery.index === index &&
        inputRef.current &&
        !inputRef.current.matches(":focus")
      ) {
        const length = inputRef.current.value.length;
        const cursorPosition = isQuote && length > 1 ? length - 1 : length;
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        onFocus();
      }
    }, [focusedQuery, value, isQuote, index]);

    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus(),
      ...inputRef.current,
    }));

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { selectionStart, selectionEnd } = e.target as HTMLInputElement;

      if (
        e.key === "Backspace" &&
        selectionStart === 0 &&
        selectionStart === selectionEnd
      ) {
        e.preventDefault();
        onRemovePreviousQuery(index);
      }
    };

    const onChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(e.target.value);
      propOnChange(e);
    };

    return (
      <div
        style={{
          position: "relative",
          display: "inline-flex",
          maxWidth: "100%",
          flexGrow: 1,
        }}
      >
        <AutoScalingInputStyled
          {...otherProps}
          inputRef={inputRef}
          value={value}
          onChange={onChange}
          onClick={() => setFocusedQuery({ index })}
          onKeyDown={handleKeyDown}
          style={{ width: inputWidth }}
          isFocused={focusedQuery.index === index}
          sx={inputStyles}
          queryB={queryB}
          placeholder={
            queryB.length <= 1 && !queryB[0]?.value
              ? "Begin your investigation..."
              : undefined
          }
        />
        <HiddenSpan ref={spanRef}>{value || " "}</HiddenSpan>
      </div>
    );
  }
);

export default React.memo(AutoScalingInput);
